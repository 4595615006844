import "./main.css";
import "./svg-with-js.css";

import { Elm } from "./Main.elm";

const privacyAgreed = localStorage.getItem("privacyAgreed");

const audience = (() => {
  // eslint-disable-next-line no-undef
  const audience = process.env.ELM_APP_AUDIENCE || "developer";
  switch (audience) {
    case "developer":
    case "enduser":
      return audience;
    default:
      throw new Error(`unknown audience: ${audience}`);
  }
})();

const app = Elm.Main.init({
  flags: {
    audience,
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    privacyAgreed: privacyAgreed ? JSON.parse(privacyAgreed) : null,
  },
});

app.ports.setPrivacyAgreed.subscribe((state) => {
  localStorage.setItem("privacyAgreed", JSON.stringify(state));
});

window.navigator.serviceWorker.register("/sw.js");
