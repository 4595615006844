(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cL.aX === region.df.aX)
	{
		return 'on line ' + region.cL.aX;
	}
	return 'on lines ' + region.cL.aX + ' through ' + region.df.aX;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gj,
		impl.im,
		impl.hV,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		V: func(record.V),
		cM: record.cM,
		cy: record.cy
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.V;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cM;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cy) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.gS) { flags += 'm'; }
	if (options.fp) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gj,
		impl.im,
		impl.hV,
		function(sendToApp, initialModel) {
			var view = impl.iq;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gj,
		impl.im,
		impl.hV,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cJ && impl.cJ(sendToApp)
			var view = impl.iq;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.fc);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ie) && (_VirtualDom_doc.title = title = doc.ie);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.g2;
	var onUrlRequest = impl.g3;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cJ: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.d5 === next.d5
							&& curr.dr === next.dr
							&& curr.dO.a === next.dO.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		gj: function(flags)
		{
			return A3(impl.gj, flags, _Browser_getUrl(), key);
		},
		iq: impl.iq,
		im: impl.im,
		hV: impl.hV
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { f3: 'hidden', fq: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { f3: 'mozHidden', fq: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { f3: 'msHidden', fq: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { f3: 'webkitHidden', fq: 'webkitvisibilitychange' }
		: { f3: 'hidden', fq: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		ef: _Browser_getScene(),
		eG: {
			a8: _Browser_window.pageXOffset,
			a9: _Browser_window.pageYOffset,
			a7: _Browser_doc.documentElement.clientWidth,
			b9: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		a7: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		b9: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			ef: {
				a7: node.scrollWidth,
				b9: node.scrollHeight
			},
			eG: {
				a8: node.scrollLeft,
				a9: node.scrollTop,
				a7: node.clientWidth,
				b9: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			ef: _Browser_getScene(),
			eG: {
				a8: x,
				a9: y,
				a7: _Browser_doc.documentElement.clientWidth,
				b9: _Browser_doc.documentElement.clientHeight
			},
			fP: {
				a8: x + rect.left,
				a9: y + rect.top,
				a7: rect.width,
				b9: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.b2.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.b2.b, xhr)); });
		$elm$core$Maybe$isJust(request.eB) && _Http_track(router, xhr, request.eB.a);

		try {
			xhr.open(request.cn, request.cQ, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.cQ));
		}

		_Http_configureRequest(xhr, request);

		request.fc.a && xhr.setRequestHeader('Content-Type', request.fc.a);
		xhr.send(request.fc.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.dn; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.eA.a || 0;
	xhr.responseType = request.b2.d;
	xhr.withCredentials = request.eX;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		cQ: xhr.responseURL,
		hL: xhr.status,
		hM: xhr.statusText,
		dn: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			hw: event.loaded,
			aI: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			hi: event.loaded,
			aI: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Types$UrlChange = function (a) {
	return {$: 0, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.g) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.k),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.k);
		} else {
			var treeLen = builder.g * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.o) : builder.o;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.g);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.k) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.k);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{o: nodeList, g: (len / $elm$core$Array$branchFactor) | 0, k: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$CustomProtocol = function (a) {
	return {$: 2, a: a};
};
var $elm$url$Url$Http = {$: 0};
var $elm$url$Url$Https = {$: 1};
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {dl: fragment, dr: host, dM: path, dO: port_, d5: protocol, he: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {gh: index, gJ: match, gX: number, hU: submatches};
	});
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{fp: false, gS: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$protocolRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[A-Za-z][0-9A-Za-z+.-]*://'));
var $elm$url$Url$getProtocol = A2(
	$elm$core$Basics$composeL,
	A2(
		$elm$core$Basics$composeL,
		$elm$core$Maybe$map(
			A2(
				$elm$core$Basics$composeL,
				$elm$core$String$dropRight(3),
				function ($) {
					return $.gJ;
				})),
		$elm$core$List$head),
	A2($elm$regex$Regex$findAtMost, 1, $elm$url$Url$protocolRegex));
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	if (A2($elm$core$String$startsWith, 'http://', str)) {
		return A2(
			$elm$url$Url$chompAfterProtocol,
			$elm$url$Url$Http,
			A2($elm$core$String$dropLeft, 7, str));
	} else {
		if (A2($elm$core$String$startsWith, 'https://', str)) {
			return A2(
				$elm$url$Url$chompAfterProtocol,
				$elm$url$Url$Https,
				A2($elm$core$String$dropLeft, 8, str));
		} else {
			var _v0 = $elm$url$Url$getProtocol(str);
			if (!_v0.$) {
				var scheme = _v0.a;
				return A2(
					$elm$url$Url$chompAfterProtocol,
					$elm$url$Url$CustomProtocol(scheme),
					A2(
						$elm$core$String$dropLeft,
						$elm$core$String$length(scheme) + 3,
						str));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
	}
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Audience$Developer = 0;
var $author$project$Types$UpdatePrismic = function (a) {
	return {$: 13, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Types$LandingPage = {$: 0};
var $author$project$Ui$Scale$Lg = 3;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{o: nodeList, g: nodeListSize, k: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $author$project$Prismic$defaultOptions = {bn: 'master'};
var $author$project$Prismic$Model = $elm$core$Basics$identity;
var $author$project$Prismic$initWith = F2(
	function (url, options) {
		return {aN: $elm$core$Maybe$Nothing, ad: $elm$core$Dict$empty, gW: 0, ct: options, cQ: url};
	});
var $author$project$Prismic$init = function (url) {
	return A2($author$project$Prismic$initWith, url, $author$project$Prismic$defaultOptions);
};
var $author$project$Api$Prismic$connect = function (_v0) {
	return $author$project$Prismic$init('https://miaengiadina.prismic.io/api');
};
var $author$project$Api$Prismic$Types$init = {
	bo: {bM: $elm$core$Maybe$Nothing, bN: $elm$core$Maybe$Nothing, bT: $elm$core$Maybe$Nothing, bU: $elm$core$Maybe$Nothing, bV: $elm$core$Maybe$Nothing, bX: $elm$core$Maybe$Nothing, bY: $elm$core$Maybe$Nothing, bZ: $elm$core$Maybe$Nothing, b0: $elm$core$Maybe$Nothing, b1: $elm$core$Maybe$Nothing, cd: $elm$core$Maybe$Nothing, ce: $elm$core$Maybe$Nothing, cf: $elm$core$Maybe$Nothing, cg: $elm$core$Maybe$Nothing, ch: $elm$core$Maybe$Nothing, ci: $elm$core$Maybe$Nothing, cj: $elm$core$Maybe$Nothing, cl: $elm$core$Maybe$Nothing, co: $elm$core$Maybe$Nothing, cq: $elm$core$Maybe$Nothing, cB: $elm$core$Maybe$Nothing, cN: $elm$core$Maybe$Nothing},
	bq: _List_Nil,
	a: $author$project$Api$Prismic$connect(0)
};
var $author$project$Ui$Component$Menu$init = false;
var $author$project$Content$landingPageTestimonial1 = {cc: 'https://images.prismic.io/miaengiadina/e7162576-a961-446f-9eca-c2e2f225c115_IRENE_MUELLER+bearbeitet.jpg', Y: 'Unsere Gäste sind begeistert von diesem neuen, schnellen Internet. Auch unsere Mitarbeitenden, die von überall herkommen, schätzen die gute Verbindung zur Aussenwelt.', _: 'Irene Müller', aa: 'Direktion Hotel Castell, Zuoz', ac: 'https://youtu.be/CUTG1cXm0LM'};
var $author$project$Content$landingPageTestimonial2 = {cc: 'https://images.prismic.io/miaengiadina/b654e505-a21e-409e-b2ab-2175f585d188_MARTINA_ZOGG+bearbeitet.jpg', Y: 'Seit wir mit miaEngiadina zusammenarbeiten, haben wir keine Reklamationen mehr von Gästen. Und wenn unsere Gäste zufrieden sind, dann sind wir es auch.', _: 'Martina Zogg', aa: 'Direktion Hotel Arnica, Scuol', ac: 'https://youtu.be/hk8vVEpN1rU'};
var $author$project$Content$landingPageTestimonial3 = {cc: 'https://images.prismic.io/miaengiadina/15998c25-e64d-476d-942d-97a8655e5dbd_SAMUEL_WIESMANN+bearbeitet.jpg', Y: 'Mit dem Glasfasernetz von miaEngiadina haben wir eine 10-20mal höhere Leistung zu einem Drittel des Preises. Zudem sind wir erst noch bei einem Anbieter, der das Engadin ins Zentrum rückt und nicht nur als Peripherie behandelt.', _: 'Samuel Wiesmann', aa: 'Leiter Geoinformation und ICT\nSchweizerischer Nationalpark, Zernez', ac: 'https://youtu.be/k_VjN1enB2w'};
var $author$project$Content$landingPageTestimonial5 = {cc: 'https://images.prismic.io/miaengiadina/bb32a814-c57b-40a3-8858-afd519636dc8_CHRISTOPH_WEISS.jpg?auto=compress,format', Y: 'Wir sind sehr zufrieden mit miaEngiadina. In unserer Arztpraxis funktioniert vieles internetbasiert – ein schnelles, sicheres und stabiles Internet ist für uns enorm wichtig.', _: 'Dr. med. Christoph Weiss', aa: 'Arztpraxis Bogn Engiadina, Scuol', ac: 'https://www.youtube.com/watch?v=ap-swn55jCM&feature=youtu.be'};
var $author$project$Content$landingPageTestimonial6 = {cc: 'https://images.prismic.io/miaengiadina/d0e1e5df-cc37-4c1a-9784-944b0e6c5a15_STEIVAN_PEER.jpg?auto=compress,format', Y: 'Das miaEngiadina-Netz ermöglicht uns eine schnelle und zuverlässige Kommunikation mit unseren Kunden. Insbesondere bei unserem Onlineshop haben wir grosse Datenvolumen und müssen permanent erreichbar sein.', _: 'Steivan Peer', aa: 'Peertools AG, Ftan', ac: 'https://www.youtube.com/watch?v=oOw5pHtQhIM&feature=youtu.be'};
var $author$project$Content$landingPageTestimonial7 = {cc: 'https://images.prismic.io/miaengiadina/d730d72a-e9f3-4bb1-8800-76041c14360f_THOMAS_LAMPERT.jpg?auto=compress,format', Y: 'Auch ein Handwerker muss sich mit der digitalen Kommunikation anfreunden. miaEngiadina hat für meine Schmiede und mein Wohnhaus eine massgeschneiderte Lösung entwickelt und termingerecht umgesetzt.', _: 'Thomas Lampert', aa: 'Fuschina da Guarda', ac: 'https://youtu.be/sKuK3VRCE2Y&feature=youtu.be'};
var $author$project$Content$landingPageTestimonial8 = {cc: 'https://images.prismic.io/miaengiadina/705d2012-3533-4486-8a1b-47ab7f1a4ccd_SIMONE_NUBER.jpg?auto=compress,format', Y: 'Pensa global, cumpra local. Also, denke global, kaufe lokal! Dieses Motto setze ich in meiner Buchhandlung um. So ist es für mich ganz selbstverständlich, miaEngiadina als Internetanbieter zu wählen.', _: 'Simone Nuber', aa: 'poesia libraria clozza', ac: 'https://youtu.be/PqBfLUYfd8I&feature=youtu.be'};
var $author$project$Content$landingPageTestimonialTitle = 'Das sagen die Kunden zum Glasfasernetz';
var $author$project$Support$emptyModel = F2(
	function (audience, key) {
		return {
			cZ: audience,
			fb: false,
			fA: false,
			by: false,
			gR: false,
			gV: key,
			g5: $elm$core$Set$empty,
			g6: $author$project$Ui$Component$Menu$init,
			a: $author$project$Api$Prismic$Types$init,
			cz: $elm$core$Maybe$Nothing,
			ee: $author$project$Types$LandingPage,
			hq: 3,
			hY: -1,
			hZ: {
				cY: $elm$core$Array$fromList(
					_List_fromArray(
						[$author$project$Content$landingPageTestimonial7, $author$project$Content$landingPageTestimonial8, $author$project$Content$landingPageTestimonial5, $author$project$Content$landingPageTestimonial6, $author$project$Content$landingPageTestimonial1, $author$project$Content$landingPageTestimonial2, $author$project$Content$landingPageTestimonial3])),
				ei: 0,
				ie: $author$project$Content$landingPageTestimonialTitle
			}
		};
	});
var $author$project$Api$Prismic$Types$AboutOrganisationPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Prismic$Types$AboutPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Prismic$Types$CommunityDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Prismic$Types$CommunityPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Prismic$Types$CommunityPartnershipPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Prismic$Types$CoworkingMountainHubsPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$Prismic$Types$CoworkingPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Prismic$Types$DigitalPage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Api$Prismic$Types$EducationPage = function (a) {
	return {$: 8, a: a};
};
var $author$project$Api$Prismic$Types$Event = function (a) {
	return {$: 9, a: a};
};
var $author$project$Api$Prismic$Types$InternetCompanyPage = function (a) {
	return {$: 13, a: a};
};
var $author$project$Api$Prismic$Types$InternetDownloadPage = function (a) {
	return {$: 10, a: a};
};
var $author$project$Api$Prismic$Types$InternetInformationPage = function (a) {
	return {$: 12, a: a};
};
var $author$project$Api$Prismic$Types$InternetPage = function (a) {
	return {$: 11, a: a};
};
var $author$project$Api$Prismic$Types$InternetPrivatePage = function (a) {
	return {$: 14, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev = function (a) {
	return {$: 15, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2 = function (a) {
	return {$: 16, a: a};
};
var $author$project$Api$Prismic$Types$MediaEcho = function (a) {
	return {$: 17, a: a};
};
var $author$project$Api$Prismic$Types$MiaEngiadinaPartner = function (a) {
	return {$: 20, a: a};
};
var $author$project$Api$Prismic$Types$MintEngiadina = function (a) {
	return {$: 21, a: a};
};
var $author$project$Api$Prismic$Types$ReferencePage = function (a) {
	return {$: 18, a: a};
};
var $author$project$Api$Prismic$Types$TeamMember = function (a) {
	return {$: 19, a: a};
};
var $author$project$Api$Prismic$Types$GenericPage$Component = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Prismic$Types$GenericPage$IntroSection = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Prismic$Types$GenericPage$Left = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Prismic$Types$GenericPage$Right = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Prismic$Types$GenericPage$TextField = function (a) {
	return {$: 4, a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Prismic$Internal$Decoder = $elm$core$Basics$identity;
var $author$project$Prismic$Internal$succeed = function (x) {
	return function (_v0) {
		return $elm$core$Result$Ok(x);
	};
};
var $author$project$Prismic$Internal$decode = $author$project$Prismic$Internal$succeed;
var $author$project$Prismic$decode = $author$project$Prismic$Internal$decode;
var $author$project$Api$Prismic$Types$GenericPage$Intro = F4(
	function (title, text, title_field, text_field) {
		return {eu: text, ib: text_field, ie: title, ig: title_field};
	});
var $author$project$Prismic$Internal$decodeValue = F2(
	function (_v0, val) {
		var decoder = _v0;
		return decoder(val);
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Prismic$Internal$map = F2(
	function (f, decoder) {
		return function (x) {
			return A2(
				$elm$core$Result$map,
				f,
				A2($author$project$Prismic$Internal$decodeValue, decoder, x));
		};
	});
var $author$project$Prismic$map = $author$project$Prismic$Internal$map;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $author$project$Prismic$Group$getKey = function (key) {
	return A2(
		$elm$core$Basics$composeL,
		$elm$core$Maybe$map($elm$core$Result$Ok),
		$elm$core$Dict$get(key));
};
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $author$project$Prismic$Internal$apply = F2(
	function (f, a) {
		return function (doc) {
			return A3(
				$elm$core$Result$map2,
				$elm$core$Basics$apL,
				A2($author$project$Prismic$Internal$decodeValue, f, doc),
				A2($author$project$Prismic$Internal$decodeValue, a, doc));
		};
	});
var $author$project$Prismic$Internal$custom = F2(
	function (a, f) {
		return A2($author$project$Prismic$Internal$apply, f, a);
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $author$project$Prismic$Internal$optionalField = F4(
	function (getKey, key, fieldDecoder, _default) {
		var addContext = function (msg) {
			return 'While decoding field \'' + (key + ('\': ' + msg));
		};
		return function (doc) {
			var _v0 = A2(getKey, key, doc);
			if (!_v0.$) {
				if (!_v0.a.$) {
					var field = _v0.a.a;
					return A2(
						$elm$core$Result$mapError,
						addContext,
						A2($author$project$Prismic$Internal$decodeValue, fieldDecoder, field));
				} else {
					var msg = _v0.a.a;
					return $elm$core$Result$Err(
						addContext(msg));
				}
			} else {
				return $elm$core$Result$Ok(_default);
			}
		};
	});
var $author$project$Prismic$Internal$optional = F5(
	function (getKey, key, fieldDecoder, _default, decoder) {
		return A2(
			$author$project$Prismic$Internal$custom,
			A4($author$project$Prismic$Internal$optionalField, getKey, key, fieldDecoder, _default),
			decoder);
	});
var $author$project$Prismic$Group$optional = $author$project$Prismic$Internal$optional($author$project$Prismic$Group$getKey);
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $author$project$Prismic$Internal$andThen = F2(
	function (f, a) {
		return function (val) {
			return A2(
				$elm$core$Result$andThen,
				function (x) {
					return A2(
						$author$project$Prismic$Internal$decodeValue,
						f(x),
						val);
				},
				A2($author$project$Prismic$Internal$decodeValue, a, val));
		};
	});
var $author$project$Prismic$Internal$fail = function (msg) {
	return function (_v0) {
		return $elm$core$Result$Err(msg);
	};
};
var $author$project$Prismic$Internal$requiredField = F3(
	function (getKey, key, fieldDecoder) {
		return A2(
			$author$project$Prismic$Internal$andThen,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Maybe$withDefault(
					$author$project$Prismic$Internal$fail('No field at ' + key)),
				$elm$core$Maybe$map($author$project$Prismic$Internal$succeed)),
			A4(
				$author$project$Prismic$Internal$optionalField,
				getKey,
				key,
				A2($author$project$Prismic$Internal$map, $elm$core$Maybe$Just, fieldDecoder),
				$elm$core$Maybe$Nothing));
	});
var $author$project$Prismic$Internal$required = F4(
	function (getKey, key, fieldDecoder, decoder) {
		return A2(
			$author$project$Prismic$Internal$apply,
			decoder,
			A3($author$project$Prismic$Internal$requiredField, getKey, key, fieldDecoder));
	});
var $author$project$Prismic$Group$required = $author$project$Prismic$Internal$required($author$project$Prismic$Group$getKey);
var $author$project$Prismic$Internal$fieldTypeToString = function (field) {
	switch (field.$) {
		case 0:
			return 'Text';
		case 1:
			return 'StructuredTextField';
		case 2:
			return 'Select';
		case 3:
			return 'Color';
		case 4:
			return 'Image';
		case 5:
			return 'Number';
		case 6:
			return 'Date';
		case 7:
			return 'Timestamp';
		case 8:
			return 'Geo';
		case 10:
			return 'Boolean';
		case 9:
			return 'Link';
		default:
			return 'IntegrationFields';
	}
};
var $author$project$Prismic$Field$structuredText = function (field) {
	if (field.$ === 1) {
		var x = field.a;
		return $elm$core$Result$Ok(x);
	} else {
		return $elm$core$Result$Err(
			'Expected a StructuredText field, but got \'' + ($author$project$Prismic$Internal$fieldTypeToString(field) + '\'.'));
	}
};
var $author$project$Api$Prismic$Types$GenericPage$intro = A3(
	$author$project$Prismic$Group$required,
	'intro_text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Prismic$Group$required,
		'intro_title_filed',
		$author$project$Prismic$Field$structuredText,
		A4(
			$author$project$Prismic$Group$optional,
			'intro_text',
			A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
			$elm$core$Maybe$Nothing,
			A4(
				$author$project$Prismic$Group$optional,
				'intro_title',
				A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
				$elm$core$Maybe$Nothing,
				$author$project$Prismic$decode($author$project$Api$Prismic$Types$GenericPage$Intro)))));
var $author$project$Prismic$Slice$oneOf = function (sliceDecoders) {
	return function (slice_) {
		var go = F2(
			function (decoders, errors) {
				go:
				while (true) {
					if (!decoders.b) {
						return $elm$core$Result$Err(
							'No slices matched: \n* ' + A2($elm$core$String$join, '\n* ', errors));
					} else {
						var decoder = decoders.a;
						var rest = decoders.b;
						var _v1 = decoder(slice_);
						if (!_v1.$) {
							var x = _v1.a;
							return $elm$core$Result$Ok(x);
						} else {
							var err = _v1.a;
							var $temp$decoders = rest,
								$temp$errors = A2($elm$core$List$cons, err, errors);
							decoders = $temp$decoders;
							errors = $temp$errors;
							continue go;
						}
					}
				}
			});
		return A2(go, sliceDecoders, _List_Nil);
	};
};
var $author$project$Api$Prismic$Types$GenericPage$Organisation = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Prismic$Types$GenericPage$OrganisationChart = F2(
	function (organisationText, columns) {
		return {fx: columns, g7: organisationText};
	});
var $author$project$Prismic$Group$field = $author$project$Prismic$Internal$requiredField($author$project$Prismic$Group$getKey);
var $author$project$Prismic$Group$optionalField = $author$project$Prismic$Internal$optionalField($author$project$Prismic$Group$getKey);
var $author$project$Result$Extra$collect = function (results) {
	var go = F2(
		function (rs, acc) {
			go:
			while (true) {
				if (rs.b) {
					if (!rs.a.$) {
						var x = rs.a.a;
						var rest = rs.b;
						var $temp$rs = rest,
							$temp$acc = _Utils_ap(
							acc,
							_List_fromArray(
								[x]));
						rs = $temp$rs;
						acc = $temp$acc;
						continue go;
					} else {
						var err = rs.a.a;
						return $elm$core$Result$Err(err);
					}
				} else {
					return $elm$core$Result$Ok(acc);
				}
			}
		});
	return A2(go, results, _List_Nil);
};
var $author$project$Prismic$Slice$slice = F3(
	function (sliceType, nonRepeatDecoder, repeatDecoder) {
		return function (slice_) {
			if (_Utils_eq(sliceType, slice_.hD)) {
				var _v0 = slice_.hB;
				if (_v0.$ === 1) {
					var doc = _v0.a;
					var docs = _v0.b;
					return A2(
						$elm$core$Result$mapError,
						function (msg) {
							return 'While decoding slice with type \'' + (slice_.hD + ('\': ' + msg));
						},
						A3(
							$elm$core$Result$map2,
							F2(
								function (a, b) {
									return _Utils_Tuple2(a, b);
								}),
							A2(
								$elm$core$Result$mapError,
								function (msg) {
									return 'While decoding non-repeating part: ' + msg;
								},
								A2($author$project$Prismic$Internal$decodeValue, nonRepeatDecoder, doc)),
							A2(
								$elm$core$Result$mapError,
								function (msg) {
									return 'While decoding repeating part: ' + msg;
								},
								$author$project$Result$Extra$collect(
									A2(
										$elm$core$List$map,
										$author$project$Prismic$Internal$decodeValue(repeatDecoder),
										docs)))));
				} else {
					return $elm$core$Result$Err('Expected a new-style slice but got an old-style one. Try using v1Slice instead.');
				}
			} else {
				return $elm$core$Result$Err('Expected slice with type \'' + (sliceType + ('\' but got \'' + (slice_.hD + '\'.'))));
			}
		};
	});
var $author$project$Api$Prismic$Types$GenericPage$organisationSlice = function () {
	var organisationText = A3(
		$author$project$Prismic$Group$optionalField,
		'organisation_text',
		A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
		$elm$core$Maybe$Nothing);
	var column = A2($author$project$Prismic$Group$field, 'column', $author$project$Prismic$Field$structuredText);
	return A2(
		$author$project$Prismic$map,
		function (_v0) {
			var text = _v0.a;
			var columns = _v0.b;
			return $author$project$Api$Prismic$Types$GenericPage$Organisation(
				A2($author$project$Api$Prismic$Types$GenericPage$OrganisationChart, text, columns));
		},
		A3($author$project$Prismic$Slice$slice, 'organisation', organisationText, column));
}();
var $author$project$Api$Prismic$Types$GenericPage$TextImg = F3(
	function (text, image, imageCopyright) {
		return {cc: image, ga: imageCopyright, eu: text};
	});
var $author$project$Prismic$Field$image = function (field) {
	if (field.$ === 4) {
		var x = field.a;
		return $elm$core$Result$Ok(x);
	} else {
		return $elm$core$Result$Err(
			'Expected an Image field, but got \'' + ($author$project$Prismic$Internal$fieldTypeToString(field) + '\'.'));
	}
};
var $author$project$Prismic$Field$text = function (field) {
	if (!field.$) {
		var ftext = field.a;
		return $elm$core$Result$Ok(ftext);
	} else {
		return $elm$core$Result$Err(
			'Expected a Text field, but got \'' + ($author$project$Prismic$Internal$fieldTypeToString(field) + '\'.'));
	}
};
var $author$project$Api$Prismic$Types$GenericPage$textImg = function (_v0) {
	var textKey = _v0.bJ;
	var imageKey = _v0.bv;
	return A4(
		$author$project$Prismic$Group$optional,
		'image_copyright',
		A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$text),
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Prismic$Group$optional,
			imageKey,
			A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$image),
			$elm$core$Maybe$Nothing,
			A3(
				$author$project$Prismic$Group$required,
				textKey,
				$author$project$Prismic$Field$structuredText,
				$author$project$Prismic$decode($author$project$Api$Prismic$Types$GenericPage$TextImg))));
};
var $author$project$Api$Prismic$Types$GenericPage$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$GenericPage$IntroSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'intro_section',
				$author$project$Api$Prismic$Types$GenericPage$intro,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$GenericPage$Component),
			A3(
				$author$project$Prismic$Slice$slice,
				'component_section',
				$author$project$Api$Prismic$Types$GenericPage$textImg(
					{bv: 'component_image', bJ: 'component_text'}),
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$GenericPage$Left),
			A3(
				$author$project$Prismic$Slice$slice,
				'text-img_section',
				$author$project$Api$Prismic$Types$GenericPage$textImg(
					{bv: 'image_right', bJ: 'text_left'}),
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$GenericPage$Right),
			A3(
				$author$project$Prismic$Slice$slice,
				'img-text_section',
				$author$project$Api$Prismic$Types$GenericPage$textImg(
					{bv: 'image_left', bJ: 'text_right'}),
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$GenericPage$TextField),
			A3(
				$author$project$Prismic$Slice$slice,
				'text_field_section',
				A3(
					$author$project$Prismic$Group$required,
					'text_field',
					$author$project$Prismic$Field$structuredText,
					$author$project$Prismic$decode($elm$core$Basics$identity)),
				$author$project$Prismic$decode(0))),
			$author$project$Api$Prismic$Types$GenericPage$organisationSlice
		]));
var $author$project$Prismic$custom = $author$project$Prismic$Internal$custom;
var $author$project$Prismic$sliceZoneField = F2(
	function (key, sliceDecoder) {
		return function (doc) {
			var _v0 = A2($elm$core$Dict$get, key, doc.fE);
			if ((!_v0.$) && (_v0.a.$ === 2)) {
				var slices = _v0.a.a;
				return $author$project$Result$Extra$collect(
					A2(
						$elm$core$List$map,
						$author$project$Prismic$Internal$decodeValue(sliceDecoder),
						slices));
			} else {
				return $elm$core$Result$Err('Expected a SliceZone field.');
			}
		};
	});
var $author$project$Prismic$sliceZone = F2(
	function (key, sliceDecoder) {
		return $author$project$Prismic$custom(
			A2($author$project$Prismic$sliceZoneField, key, sliceDecoder));
	});
var $author$project$Api$Prismic$Types$GenericPage$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$GenericPage$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$CommunityDocument$CommunityDocument = F3(
	function (title, link, linkText) {
		return {gB: link, gC: linkText, ie: title};
	});
var $author$project$Prismic$Field$link = function (field) {
	if (field.$ === 9) {
		var x = field.a;
		return $elm$core$Result$Ok(x);
	} else {
		return $elm$core$Result$Err(
			'Expected a Link field, but got \'' + ($author$project$Prismic$Internal$fieldTypeToString(field) + '\'.'));
	}
};
var $author$project$Prismic$Internal$Text = function (a) {
	return {$: 0, a: a};
};
var $author$project$Prismic$Internal$Timestamp = function (a) {
	return {$: 7, a: a};
};
var $author$project$Prismic$getKey = F2(
	function (key, doc) {
		switch (key) {
			case '__id':
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						$author$project$Prismic$Internal$Text(doc.f7)));
			case '__uid':
				var _v1 = doc.ik;
				if (!_v1.$) {
					var uid_ = _v1.a;
					return $elm$core$Maybe$Just(
						$elm$core$Result$Ok(
							$author$project$Prismic$Internal$Text(uid_)));
				} else {
					return $elm$core$Maybe$Just(
						$elm$core$Result$Err('No uid'));
				}
			case '__first_publication_date':
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						$author$project$Prismic$Internal$Timestamp(doc.fX)));
			default:
				var _v2 = A2($elm$core$Dict$get, key, doc.fE);
				if (!_v2.$) {
					switch (_v2.a.$) {
						case 0:
							var field = _v2.a.a;
							return $elm$core$Maybe$Just(
								$elm$core$Result$Ok(field));
						case 2:
							return $elm$core$Maybe$Just(
								$elm$core$Result$Err(
									A2(
										$elm$core$String$join,
										' ',
										_List_fromArray(
											['Expected a Field but got a SliceZone.', '(Hint: use sliceZone to decode this.)']))));
						default:
							return $elm$core$Maybe$Just(
								$elm$core$Result$Err(
									A2(
										$elm$core$String$join,
										' ',
										_List_fromArray(
											['Expected a Field but got a Group.', '(Hint: use group to decode this.)']))));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
		}
	});
var $author$project$Prismic$optional = $author$project$Prismic$Internal$optional($author$project$Prismic$getKey);
var $author$project$Api$Prismic$Helper$maybe = F2(
	function (str, dec) {
		return A3(
			$author$project$Prismic$optional,
			str,
			A2($author$project$Prismic$map, $elm$core$Maybe$Just, dec),
			$elm$core$Maybe$Nothing);
	});
var $author$project$Prismic$required = $author$project$Prismic$Internal$required($author$project$Prismic$getKey);
var $author$project$Api$Prismic$Types$CommunityDocument$decoder = A3(
	$author$project$Api$Prismic$Helper$maybe,
	'link_text',
	$author$project$Prismic$Field$text,
	A3(
		$author$project$Api$Prismic$Helper$maybe,
		'link',
		$author$project$Prismic$Field$link,
		A3(
			$author$project$Prismic$required,
			'title',
			$author$project$Prismic$Field$text,
			$author$project$Prismic$decode($author$project$Api$Prismic$Types$CommunityDocument$CommunityDocument))));
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$Prismic$FetchApiError = function (a) {
	return {$: 3, a: a};
};
var $author$project$Prismic$Api = function (refs) {
	return function (bookmarks) {
		return function (types) {
			return function (tags) {
				return function (version) {
					return function (forms) {
						return function (oauthInitiate) {
							return function (oauthToken) {
								return function (license) {
									return function (experiments) {
										return {c0: bookmarks, fS: experiments, dk: forms, bz: license, g_: oauthInitiate, g$: oauthToken, d6: refs, et: tags, ij: types, bL: version};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Prismic$Experiments = F2(
	function (draft, running) {
		return {fO: draft, hp: running};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Prismic$decodeExperiments = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'running',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'draft',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$Prismic$Experiments)));
var $author$project$Prismic$Form = F6(
	function (method, enctype, action, fields, rel, name) {
		return {ay: action, fR: enctype, dh: fields, cn: method, gU: name, hj: rel};
	});
var $author$project$Prismic$FormField = F3(
	function (fieldType, multiple, _default) {
		return {da: _default, fV: fieldType, gT: multiple};
	});
var $author$project$Prismic$Integer = 1;
var $author$project$Prismic$String = 0;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Prismic$decodeFieldType = function () {
	var decodeOnType = function (str) {
		switch (str) {
			case 'String':
				return $elm$json$Json$Decode$succeed(0);
			case 'Integer':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unknown field type: ' + str);
		}
	};
	return A2($elm$json$Json$Decode$andThen, decodeOnType, $elm$json$Json$Decode$string);
}();
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Prismic$decodeFormField = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'default',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'multiple',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'type',
			$author$project$Prismic$decodeFieldType,
			$elm$json$Json$Decode$succeed($author$project$Prismic$FormField))));
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Prismic$decodeForm = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'name',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'rel',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'fields',
			$elm$json$Json$Decode$dict($author$project$Prismic$decodeFormField),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'action',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'enctype',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'method',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Prismic$Form)))))));
var $author$project$Prismic$RefProperties = F4(
	function (id, ref, label, isMasterRef) {
		return {f7: id, gt: isMasterRef, gw: label, Z: ref};
	});
var $author$project$Prismic$Ref = $elm$core$Basics$identity;
var $author$project$Prismic$decodeRef = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Prismic$decodeRefProperties = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'isMasterRef',
	$elm$json$Json$Decode$bool,
	false,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'label',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'ref',
			$author$project$Prismic$decodeRef,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Prismic$RefProperties)))));
var $author$project$Prismic$decodeApi = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'experiments',
	$author$project$Prismic$decodeExperiments,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'license',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'oauth_token',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'oauth_initiate',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'forms',
					$elm$json$Json$Decode$dict($author$project$Prismic$decodeForm),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'version',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'tags',
							$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'types',
								$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'bookmarks',
									$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'refs',
										$elm$json$Json$Decode$list($author$project$Prismic$decodeRefProperties),
										$elm$json$Json$Decode$succeed($author$project$Prismic$Api)))))))))));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$Prismic$handleJsonResponse = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 3:
				var statusCode = response.a.hL;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(statusCode));
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			default:
				var body = response.b;
				var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
				if (_v1.$ === 1) {
					return $elm$core$Result$Err(
						$elm$http$Http$BadBody(body));
				} else {
					var result = _v1.a;
					return $elm$core$Result$Ok(result);
				}
		}
	});
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$core$Task$mapError = F2(
	function (convert, task) {
		return A2(
			$elm$core$Task$onError,
			A2($elm$core$Basics$composeL, $elm$core$Task$fail, convert),
			task);
	});
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{eX: false, fc: r.fc, b2: r.eb, dn: r.dn, cn: r.cn, eA: r.eA, eB: $elm$core$Maybe$Nothing, cQ: r.cQ});
};
var $author$project$Prismic$api = function (_v0) {
	var model = _v0;
	var _v1 = model.aN;
	if (!_v1.$) {
		var api_ = _v1.a;
		return $elm$core$Task$succeed(
			_Utils_Tuple2(model, api_));
	} else {
		return A2(
			$elm$core$Task$map,
			function (api_) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aN: $elm$core$Maybe$Just(api_)
						}),
					api_);
			},
			A2(
				$elm$core$Task$mapError,
				$author$project$Prismic$FetchApiError,
				$elm$http$Http$task(
					{
						fc: $elm$http$Http$emptyBody,
						dn: _List_Nil,
						cn: 'GET',
						eb: $elm$http$Http$stringResolver(
							$author$project$Prismic$handleJsonResponse($author$project$Prismic$decodeApi)),
						eA: $elm$core$Maybe$Nothing,
						cQ: model.cQ
					})));
	}
};
var $author$project$Prismic$At = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Prismic$at = F2(
	function (fragment, value) {
		return A2($author$project$Prismic$At, fragment, value);
	});
var $author$project$Prismic$FormDoesNotExist = function (a) {
	return {$: 0, a: a};
};
var $author$project$Prismic$Request = $elm$core$Basics$identity;
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Prismic$getRefById = F2(
	function (refId, api_) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (r) {
					return _Utils_eq(r.f7, refId);
				},
				api_.d6));
	});
var $author$project$Prismic$form = F2(
	function (formId, apiTask) {
		var addForm = function (_v1) {
			var model = _v1.a;
			var api_ = _v1.b;
			var ref_ = A2(
				$elm$core$Maybe$withDefault,
				model.ct.bn,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.Z;
					},
					A2($author$project$Prismic$getRefById, model.ct.bn, api_)));
			var mForm = A2($elm$core$Dict$get, formId, api_.dk);
			if (mForm.$ === 1) {
				return $elm$core$Task$fail(
					$author$project$Prismic$FormDoesNotExist(formId));
			} else {
				var form_ = mForm.a;
				var q = A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.da;
						},
						A2($elm$core$Dict$get, 'q', form_.dh)));
				return $elm$core$Task$succeed(
					{
						aN: api_,
						G: {ay: form_.ay, aW: '', a2: q, Z: ref_},
						bA: model
					});
			}
		};
		return A2($elm$core$Task$andThen, addForm, apiTask);
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $author$project$Prismic$predicatesToStr = function (predicates) {
	var wrapQuotes = function (value) {
		return '\"' + (value + '\"');
	};
	var toStrList = function (values) {
		var valueStrs = A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, wrapQuotes, values));
		return '[' + (valueStrs + ']');
	};
	var predicateToStr = function (predicate) {
		var query_ = function () {
			switch (predicate.$) {
				case 0:
					var fragment = predicate.a;
					var value = predicate.b;
					return 'at(' + (fragment + (', ' + (wrapQuotes(value) + ')')));
				case 1:
					var fragment = predicate.a;
					var values = predicate.b;
					return 'at(' + (fragment + (', ' + (toStrList(values) + ')')));
				case 2:
					var fragment = predicate.a;
					var values = predicate.b;
					return 'any(' + (fragment + (', ' + (toStrList(values) + ')')));
				default:
					var fragment = predicate.a;
					var value = predicate.b;
					return 'fulltext(' + (fragment + (', ' + (wrapQuotes(value) + ')')));
			}
		}();
		return '[:d = ' + (query_ + ']');
	};
	return '[' + ($elm$core$String$concat(
		A2($elm$core$List$map, predicateToStr, predicates)) + ']');
};
var $author$project$Prismic$query = F2(
	function (predicates, requestTask) {
		var setQuery = F2(
			function (query_, _v0) {
				var request = _v0;
				var config = request.G;
				return _Utils_update(
					request,
					{
						G: _Utils_update(
							config,
							{a2: query_})
					});
			});
		var addQuery = function (request) {
			return $elm$core$Task$succeed(
				A2(
					setQuery,
					$author$project$Prismic$predicatesToStr(predicates),
					request));
		};
		return A2($elm$core$Task$andThen, addQuery, requestTask);
	});
var $author$project$Prismic$DecodeDocumentError = function (a) {
	return {$: 5, a: a};
};
var $author$project$Prismic$SubmitRequestError = function (a) {
	return {$: 4, a: a};
};
var $author$project$Prismic$Response = function (license) {
	return function (nextPage) {
		return function (page) {
			return function (prevPage) {
				return function (results) {
					return function (resultsPerPage) {
						return function (resultsSize) {
							return function (totalPages) {
								return function (totalResultsSize) {
									return function (version) {
										return {bz: license, cr: nextPage, cv: page, cx: prevPage, f: results, cF: resultsPerPage, cG: resultsSize, cO: totalPages, cP: totalResultsSize, bL: version};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Prismic$Internal$Document = function (data) {
	return function (href) {
		return function (id) {
			return function (linkedDocuments) {
				return function (slugs) {
					return function (tags) {
						return function (resultType) {
							return function (uid) {
								return function (first_publication_date) {
									return function (last_publication_date) {
										return {fE: data, fX: first_publication_date, f5: href, f7: id, gx: last_publication_date, gD: linkedDocuments, hl: resultType, hE: slugs, et: tags, ik: uid};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $author$project$Prismic$Internal$Field = function (a) {
	return {$: 0, a: a};
};
var $author$project$Prismic$Internal$Groups = function (a) {
	return {$: 1, a: a};
};
var $author$project$Prismic$Internal$SliceZone = function (a) {
	return {$: 2, a: a};
};
var $author$project$Prismic$Internal$Boolean = function (a) {
	return {$: 10, a: a};
};
var $author$project$Prismic$Internal$Color = function (a) {
	return {$: 3, a: a};
};
var $author$project$Prismic$Internal$Date = function (a) {
	return {$: 6, a: a};
};
var $author$project$Prismic$Internal$Geo = function (a) {
	return {$: 8, a: a};
};
var $author$project$Prismic$Internal$Image = function (a) {
	return {$: 4, a: a};
};
var $author$project$Prismic$Internal$IntegrationFields = function (a) {
	return {$: 11, a: a};
};
var $author$project$Prismic$Internal$Link = function (a) {
	return {$: 9, a: a};
};
var $author$project$Prismic$Internal$Number = function (a) {
	return {$: 5, a: a};
};
var $author$project$Prismic$Internal$Point = F2(
	function (latitude, longitude) {
		return {gy: latitude, gH: longitude};
	});
var $author$project$Prismic$Internal$Select = function (a) {
	return {$: 2, a: a};
};
var $author$project$Prismic$Internal$StructuredTextField = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {c7: col, fz: contextStack, hd: problem, hn: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.hn, s.c7, x, s.c));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.eq),
			s.aH) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.eq);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.aH, offset) < 0,
					0,
					{c7: col, c: s0.c, d: s0.d, aH: offset, hn: row, eq: s0.eq});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.aH, s.hn, s.c7, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.aH, s1.aH, s0.eq),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.aH, s.eq);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{c7: 1, c: s.c, d: s.d, aH: s.aH + 1, hn: s.hn + 1, eq: s.eq}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{c7: s.c7 + 1, c: s.c, d: s.d, aH: newOffset, hn: s.hn, eq: s.eq}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.aH, s.hn, s.c7, s.eq);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{c7: newCol, c: s.c, d: s.d, aH: newOffset, hn: newRow, eq: s.eq});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {c7: col, hd: problem, hn: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.hn, p.c7, p.hd);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{c7: 1, c: _List_Nil, d: 1, aH: 0, hn: 1, eq: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$Prismic$Internal$decodeIsoString = F2(
	function (time, str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(
			_Utils_ap(str, time));
		if (!_v0.$) {
			var posix = _v0.a;
			return $elm$json$Json$Decode$succeed(posix);
		} else {
			return $elm$json$Json$Decode$fail('Invalid date: ' + str);
		}
	});
var $author$project$Prismic$Internal$decodeDate = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Prismic$Internal$decodeIsoString('T00:00:00.000Z'),
	$elm$json$Json$Decode$string);
var $author$project$Prismic$Internal$ImageViews = F2(
	function (main, views) {
		return {dC: main, eH: views};
	});
var $author$project$Prismic$Internal$ImageView = F4(
	function (alt, copyright, url, dimensions) {
		return {eY: alt, bW: copyright, b_: dimensions, cQ: url};
	});
var $author$project$Prismic$Internal$ImageDimensions = F2(
	function (width, height) {
		return {b9: height, a7: width};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Prismic$Internal$decodeImageDimensions = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'height',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'width',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$ImageDimensions)));
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Prismic$Internal$decodeImageView = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'dimensions',
	$author$project$Prismic$Internal$decodeImageDimensions,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'url',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'copyright',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'alt',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$ImageView)))));
var $author$project$Prismic$Internal$decodeImageViews = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'views',
	$elm$json$Json$Decode$dict($author$project$Prismic$Internal$decodeImageView),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'main',
		$author$project$Prismic$Internal$decodeImageView,
		$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$ImageViews)));
var $author$project$Prismic$Internal$DocumentLink = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Prismic$Internal$FileLink = function (a) {
	return {$: 2, a: a};
};
var $author$project$Prismic$Internal$WebLink = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Prismic$Internal$DocumentReference = F5(
	function (id, uid, slug, tags, linkedDocumentType) {
		return {f7: id, dA: linkedDocumentType, el: slug, et: tags, ik: uid};
	});
var $author$project$Prismic$Internal$decodeDocumentReferenceJson = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'type',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'tags',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'slug',
			$elm$json$Json$Decode$string,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'uid',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$DocumentReference))))));
var $author$project$Prismic$Internal$FileReference = F4(
	function (name, kind, url, size) {
		return {dz: kind, gU: name, aI: size, cQ: url};
	});
var $author$project$Prismic$Internal$decodeFileReferenceJson = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'size',
	A2($elm$json$Json$Decode$map, $elm$core$String$toInt, $elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'url',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'kind',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$FileReference)))));
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt = F4(
	function (path, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$Prismic$Internal$decodeLink = function () {
	var decodeOnType = function (typeStr) {
		switch (typeStr) {
			case 'Link.document':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['value', 'isBroken']),
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['value', 'document']),
						$author$project$Prismic$Internal$decodeDocumentReferenceJson,
						$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$DocumentLink)));
			case 'Link.web':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['value', 'url']),
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['value', 'target']),
						A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$WebLink)));
			case 'Link.file':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['value', 'file']),
					$author$project$Prismic$Internal$decodeFileReferenceJson,
					$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$FileLink));
			default:
				return $elm$json$Json$Decode$fail('Unknown link type: ' + typeStr);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeOnType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $author$project$Prismic$Internal$StructuredText = $elm$core$Basics$identity;
var $author$project$Prismic$Internal$ListItem = function (a) {
	return {$: 7, a: a};
};
var $author$project$Prismic$Internal$OListItem = function (a) {
	return {$: 11, a: a};
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm_community$list_extra$List$Extra$groupWhile = F2(
	function (isSameGroup, items) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					if (!acc.b) {
						return _List_fromArray(
							[
								_Utils_Tuple2(x, _List_Nil)
							]);
					} else {
						var _v1 = acc.a;
						var y = _v1.a;
						var restOfGroup = _v1.b;
						var groups = acc.b;
						return A2(isSameGroup, x, y) ? A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								x,
								A2($elm$core$List$cons, y, restOfGroup)),
							groups) : A2(
							$elm$core$List$cons,
							_Utils_Tuple2(x, _List_Nil),
							acc);
					}
				}),
			_List_Nil,
			items);
	});
var $author$project$Prismic$Internal$collateListItems = function () {
	var filterOListItems = $elm$core$List$concatMap(
		function (block) {
			if (block.$ === 11) {
				var x = block.a;
				return x;
			} else {
				return _List_Nil;
			}
		});
	var filterListItems = $elm$core$List$concatMap(
		function (block) {
			if (block.$ === 7) {
				var x = block.a;
				return x;
			} else {
				return _List_Nil;
			}
		});
	return A2(
		$elm$core$Basics$composeR,
		$elm_community$list_extra$List$Extra$groupWhile(
			F2(
				function (x, y) {
					var _v0 = _Utils_Tuple2(x, y);
					_v0$2:
					while (true) {
						switch (_v0.a.$) {
							case 7:
								if (_v0.b.$ === 7) {
									return true;
								} else {
									break _v0$2;
								}
							case 11:
								if (_v0.b.$ === 11) {
									return true;
								} else {
									break _v0$2;
								}
							default:
								break _v0$2;
						}
					}
					return false;
				})),
		$elm$core$List$concatMap(
			function (_v1) {
				var first = _v1.a;
				var rest = _v1.b;
				_v2$2:
				while (true) {
					switch (first.$) {
						case 7:
							if (first.a.b && (!first.a.b.b)) {
								var _v3 = first.a;
								var x = _v3.a;
								return _List_fromArray(
									[
										$author$project$Prismic$Internal$ListItem(
										A2(
											$elm$core$List$cons,
											x,
											filterListItems(rest)))
									]);
							} else {
								break _v2$2;
							}
						case 11:
							if (first.a.b && (!first.a.b.b)) {
								var _v4 = first.a;
								var x = _v4.a;
								return _List_fromArray(
									[
										$author$project$Prismic$Internal$OListItem(
										A2(
											$elm$core$List$cons,
											x,
											filterOListItems(rest)))
									]);
							} else {
								break _v2$2;
							}
						default:
							break _v2$2;
					}
				}
				return A2($elm$core$List$cons, first, rest);
			}));
}();
var $author$project$Prismic$Internal$Heading1 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Prismic$Internal$Heading2 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Prismic$Internal$Heading3 = function (a) {
	return {$: 2, a: a};
};
var $author$project$Prismic$Internal$Heading4 = function (a) {
	return {$: 3, a: a};
};
var $author$project$Prismic$Internal$Heading5 = function (a) {
	return {$: 4, a: a};
};
var $author$project$Prismic$Internal$Heading6 = function (a) {
	return {$: 5, a: a};
};
var $author$project$Prismic$Internal$Paragraph = function (a) {
	return {$: 6, a: a};
};
var $author$project$Prismic$Internal$Preformatted = function (a) {
	return {$: 10, a: a};
};
var $author$project$Prismic$Internal$SEmbed = function (a) {
	return {$: 9, a: a};
};
var $author$project$Prismic$Internal$SImage = function (a) {
	return {$: 8, a: a};
};
var $author$project$Prismic$Internal$Block = F3(
	function (text, spans, label) {
		return {gw: label, hJ: spans, eu: text};
	});
var $author$project$Prismic$Internal$Span = F3(
	function (start, end, spanElement) {
		return {df: end, hI: spanElement, cL: start};
	});
var $author$project$Prismic$Internal$Em = {$: 0};
var $author$project$Prismic$Internal$Hyperlink = function (a) {
	return {$: 2, a: a};
};
var $author$project$Prismic$Internal$Strong = {$: 1};
var $author$project$Prismic$Internal$decodeSpanType = function () {
	var decodeOnType = function (typeStr) {
		switch (typeStr) {
			case 'em':
				return $elm$json$Json$Decode$succeed($author$project$Prismic$Internal$Em);
			case 'strong':
				return $elm$json$Json$Decode$succeed($author$project$Prismic$Internal$Strong);
			case 'hyperlink':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Hyperlink,
					A2($elm$json$Json$Decode$field, 'data', $author$project$Prismic$Internal$decodeLink));
			default:
				return $elm$json$Json$Decode$fail('Unknown span type: ' + typeStr);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeOnType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $author$project$Prismic$Internal$decodeSpan = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Prismic$Internal$decodeSpanType,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'end',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'start',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$Span))));
var $author$project$Prismic$Internal$decodeBlock = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'label',
	$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'spans',
		$elm$json$Json$Decode$list($author$project$Prismic$Internal$decodeSpan),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'text',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$Block))));
var $author$project$Prismic$Internal$ERich = function (a) {
	return {$: 1, a: a};
};
var $author$project$Prismic$Internal$EVideo = function (a) {
	return {$: 0, a: a};
};
var $author$project$Prismic$Internal$EmbedRich = function (authorName) {
	return function (authorUrl) {
		return function (cacheAge) {
			return function (embedUrl) {
				return function (height) {
					return function (html) {
						return function (providerName) {
							return function (providerUrl) {
								return function (title) {
									return function (url) {
										return function (version) {
											return function (width) {
												return {bb: authorName, bc: authorUrl, c2: cacheAge, bp: embedUrl, b9: height, f6: html, bE: providerName, bF: providerUrl, ie: title, cQ: url, bL: version, a7: width};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Prismic$Internal$decodeEmbedRich = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'width',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'version',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'url',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'title',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'provider_url',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'provider_name',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'html',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'height',
								$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'embed_url',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'cache_age',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'author_url',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'author_name',
												$elm$json$Json$Decode$string,
												$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$EmbedRich)))))))))))));
var $author$project$Prismic$Internal$EmbedVideo = function (authorName) {
	return function (authorUrl) {
		return function (embedUrl) {
			return function (height) {
				return function (html) {
					return function (providerName) {
						return function (providerUrl) {
							return function (thumbnailHeight) {
								return function (thumbnailUrl) {
									return function (thumbnailWidth) {
										return function (title) {
											return function (version) {
												return function (width) {
													return {bb: authorName, bc: authorUrl, bp: embedUrl, b9: height, f6: html, bE: providerName, bF: providerUrl, ex: thumbnailHeight, ey: thumbnailUrl, ez: thumbnailWidth, ie: title, bL: version, a7: width};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Prismic$Internal$decodeEmbedVideo = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'width',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'version',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'title',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'thumbnail_width',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'thumbnail_url',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'thumbnail_height',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'provider_url',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'provider_name',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'html',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'height',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'embed_url',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'author_url',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'author_name',
													$elm$json$Json$Decode$string,
													$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$EmbedVideo))))))))))))));
var $author$project$Prismic$Internal$decodeEmbed = function () {
	var decodeOnType = function (typeStr) {
		switch (typeStr) {
			case 'video':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$EVideo, $author$project$Prismic$Internal$decodeEmbedVideo);
			case 'rich':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$ERich, $author$project$Prismic$Internal$decodeEmbedRich);
			default:
				return $elm$json$Json$Decode$fail('Unknown embed type: ' + typeStr);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeOnType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$Prismic$Internal$decodeStructuredTextBlock = function () {
	var decodeOnType = function (typeStr) {
		switch (typeStr) {
			case 'heading1':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Heading1, $author$project$Prismic$Internal$decodeBlock);
			case 'heading2':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Heading2, $author$project$Prismic$Internal$decodeBlock);
			case 'heading3':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Heading3, $author$project$Prismic$Internal$decodeBlock);
			case 'heading4':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Heading4, $author$project$Prismic$Internal$decodeBlock);
			case 'heading5':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Heading5, $author$project$Prismic$Internal$decodeBlock);
			case 'heading6':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Heading6, $author$project$Prismic$Internal$decodeBlock);
			case 'paragraph':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Paragraph, $author$project$Prismic$Internal$decodeBlock);
			case 'list-item':
				return A2(
					$elm$json$Json$Decode$map,
					A2($elm$core$Basics$composeR, $elm$core$List$singleton, $author$project$Prismic$Internal$ListItem),
					$author$project$Prismic$Internal$decodeBlock);
			case 'o-list-item':
				return A2(
					$elm$json$Json$Decode$map,
					A2($elm$core$Basics$composeR, $elm$core$List$singleton, $author$project$Prismic$Internal$OListItem),
					$author$project$Prismic$Internal$decodeBlock);
			case 'image':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$SImage, $author$project$Prismic$Internal$decodeImageView);
			case 'embed':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$SEmbed,
					A2($elm$json$Json$Decode$field, 'oembed', $author$project$Prismic$Internal$decodeEmbed));
			case 'preformatted':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Preformatted, $author$project$Prismic$Internal$decodeBlock);
			default:
				return $elm$json$Json$Decode$fail('Unknown structured field type: ' + typeStr);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeOnType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $author$project$Prismic$Internal$decodeStructuredText = A2(
	$elm$json$Json$Decode$map,
	A2($elm$core$Basics$composeR, $author$project$Prismic$Internal$collateListItems, $elm$core$Basics$identity),
	$elm$json$Json$Decode$list($author$project$Prismic$Internal$decodeStructuredTextBlock));
var $author$project$Prismic$Internal$decodeTimestamp = A2(
	$elm$json$Json$Decode$andThen,
	$author$project$Prismic$Internal$decodeIsoString(''),
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Prismic$Internal$decodeField = function () {
	var decodeOnType = function (typeStr) {
		switch (typeStr) {
			case 'Text':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Text,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
			case 'Select':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Select,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
			case 'Color':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Color,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
			case 'Number':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Number,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$float));
			case 'Date':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Date,
					A2($elm$json$Json$Decode$field, 'value', $author$project$Prismic$Internal$decodeDate));
			case 'Timestamp':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Timestamp,
					A2($elm$json$Json$Decode$field, 'value', $author$project$Prismic$Internal$decodeTimestamp));
			case 'Image':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Image,
					A2($elm$json$Json$Decode$field, 'value', $author$project$Prismic$Internal$decodeImageViews));
			case 'Boolean':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Boolean,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$bool));
			case 'StructuredText':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$StructuredTextField,
					A2($elm$json$Json$Decode$field, 'value', $author$project$Prismic$Internal$decodeStructuredText));
			case 'Link.document':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Link, $author$project$Prismic$Internal$decodeLink);
			case 'Link.web':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Link, $author$project$Prismic$Internal$decodeLink);
			case 'Link.file':
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Link, $author$project$Prismic$Internal$decodeLink);
			case 'GeoPoint':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Geo,
					A2(
						$elm$json$Json$Decode$field,
						'value',
						A3(
							$elm$json$Json$Decode$map2,
							$author$project$Prismic$Internal$Point,
							A2($elm$json$Json$Decode$field, 'latitude', $elm$json$Json$Decode$float),
							A2($elm$json$Json$Decode$field, 'longitude', $elm$json$Json$Decode$float))));
			case 'IntegrationFields':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$IntegrationFields,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$value));
			default:
				return $elm$json$Json$Decode$fail('Unknown document field type: ' + typeStr);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeOnType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $author$project$Prismic$Internal$decodeGroups = $elm$json$Json$Decode$list(
	$elm$json$Json$Decode$dict($author$project$Prismic$Internal$decodeField));
var $author$project$Prismic$Internal$Slice = F3(
	function (sliceLabel, sliceType, sliceContent) {
		return {hB: sliceContent, hC: sliceLabel, hD: sliceType};
	});
var $author$project$Prismic$Internal$SliceContentV1 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Prismic$Internal$SliceContentV2 = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Prismic$Internal$SliceContentV1Field = function (a) {
	return {$: 0, a: a};
};
var $author$project$Prismic$Internal$SliceContentV1Groups = function (a) {
	return {$: 1, a: a};
};
var $author$project$Prismic$Internal$decodeSliceContentField = function () {
	var decodeOnType = function (typeStr) {
		if (typeStr === 'Group') {
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Prismic$Internal$SliceContentV1Groups,
				A2($elm$json$Json$Decode$field, 'value', $author$project$Prismic$Internal$decodeGroups));
		} else {
			return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$SliceContentV1Field, $author$project$Prismic$Internal$decodeField);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeOnType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $author$project$Prismic$Internal$decodeSliceContent = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Prismic$Internal$SliceContentV1,
			A2(
				$elm$json$Json$Decode$field,
				'value',
				$elm$json$Json$Decode$lazy(
					function (_v0) {
						return $author$project$Prismic$Internal$decodeSliceContentField;
					}))),
			function () {
			var miniDocument = $elm$json$Json$Decode$dict(
				$elm$json$Json$Decode$lazy(
					function (_v1) {
						return $author$project$Prismic$Internal$decodeField;
					}));
			return A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'repeat',
				$elm$json$Json$Decode$list(miniDocument),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'non-repeat',
					miniDocument,
					$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$SliceContentV2)));
		}()
		]));
var $author$project$Prismic$Internal$decodeSlice = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Prismic$Internal$decodeSliceContent,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'slice_type',
		$elm$json$Json$Decode$string,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'slice_label',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$Slice))));
var $author$project$Prismic$Internal$decodeSliceZone = $elm$json$Json$Decode$list(
	$elm$json$Json$Decode$lazy(
		function (_v0) {
			return $author$project$Prismic$Internal$decodeSlice;
		}));
var $author$project$Prismic$Internal$decodeDocumentField = function () {
	var decodeOnType = function (typeStr) {
		switch (typeStr) {
			case 'Group':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$Groups,
					A2($elm$json$Json$Decode$field, 'value', $author$project$Prismic$Internal$decodeGroups));
			case 'SliceZone':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Prismic$Internal$SliceZone,
					A2($elm$json$Json$Decode$field, 'value', $author$project$Prismic$Internal$decodeSliceZone));
			default:
				return A2($elm$json$Json$Decode$map, $author$project$Prismic$Internal$Field, $author$project$Prismic$Internal$decodeField);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeOnType,
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}();
var $author$project$Prismic$Internal$decodeDocumentJson = A2(
	$elm$json$Json$Decode$andThen,
	function (docType) {
		return A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', docType]),
			$elm$json$Json$Decode$dict($author$project$Prismic$Internal$decodeDocumentField));
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Prismic$Internal$decodeSearchResult = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'last_publication_date',
	$author$project$Prismic$Internal$decodeTimestamp,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'first_publication_date',
		$author$project$Prismic$Internal$decodeTimestamp,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'uid',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'type',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'tags',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'slugs',
						$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'linked_documents',
							$elm$json$Json$Decode$list($author$project$Prismic$Internal$decodeDocumentReferenceJson),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'id',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'href',
									$elm$json$Json$Decode$string,
									A2(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
										$author$project$Prismic$Internal$decodeDocumentJson,
										$elm$json$Json$Decode$succeed($author$project$Prismic$Internal$Document)))))))))));
var $author$project$Prismic$decodeResponse = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'version',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'total_results_size',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'total_pages',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'results_size',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'results_per_page',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'results',
						$elm$json$Json$Decode$list($author$project$Prismic$Internal$decodeSearchResult),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'prev_page',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'page',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'next_page',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'license',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Prismic$Response)))))))))));
var $author$project$Task$Extra$fromResult = function (result) {
	if (!result.$) {
		var value = result.a;
		return $elm$core$Task$succeed(value);
	} else {
		var err = result.a;
		return $elm$core$Task$fail(err);
	}
};
var $author$project$Prismic$requestToKey = function (requestConfig) {
	var _v0 = requestConfig.Z;
	var ref_ = _v0;
	return A2(
		$elm$core$String$join,
		':::',
		_List_fromArray(
			[requestConfig.ay, ref_, requestConfig.a2, requestConfig.aW]));
};
var $author$project$Prismic$getFromCache = F2(
	function (request, _v0) {
		var model = _v0;
		return A2(
			$elm$core$Dict$get,
			$author$project$Prismic$requestToKey(request),
			model.ad);
	});
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $author$project$Prismic$withQuery = F2(
	function (params, base) {
		var sep = $elm$core$List$isEmpty(params) ? '' : '?';
		var joinParamPair = function (_v1) {
			var key = _v1.a;
			var val = _v1.b;
			return $elm$url$Url$percentEncode(key) + ('=' + $elm$url$Url$percentEncode(val));
		};
		var paramsPart = A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, joinParamPair, params));
		var _v0 = A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.he;
			},
			$elm$url$Url$fromString(base));
		if (!_v0.$) {
			var query_ = _v0.a;
			return _Utils_ap(
				base,
				(sep === '') ? '' : ('&' + paramsPart));
		} else {
			return _Utils_ap(
				base,
				_Utils_ap(sep, paramsPart));
		}
	});
var $author$project$Prismic$requestToUrl = function (config) {
	var ifNotEmpty = F2(
		function (key, val) {
			return $elm$core$String$isEmpty(val) ? _List_Nil : _List_fromArray(
				[
					_Utils_Tuple2(key, val)
				]);
		});
	var _v0 = config.Z;
	var refStr = _v0;
	return A2(
		$author$project$Prismic$withQuery,
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						_Utils_Tuple2('ref', refStr),
						_Utils_Tuple2('pageSize', '100')
					]),
					A2(ifNotEmpty, 'q', config.a2),
					A2(ifNotEmpty, 'lang', config.aW)
				])),
		config.ay);
};
var $author$project$Prismic$setInCache = F3(
	function (request, response, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				ad: A3(
					$elm$core$Dict$insert,
					$author$project$Prismic$requestToKey(request),
					response,
					model.ad)
			});
	});
var $author$project$Prismic$updateResults = F2(
	function (response, results) {
		return {bz: response.bz, cr: response.cr, cv: response.cv, cx: response.cx, f: results, cF: response.cF, cG: response.cG, cO: response.cO, cP: response.cP, bL: response.bL};
	});
var $author$project$Prismic$submit = F2(
	function (decodeDocType, requestTask) {
		var doSubmit = function (_v1) {
			var request = _v1;
			var decodeResponseToUserDocType = function (response) {
				return A2(
					$elm$core$Task$mapError,
					$author$project$Prismic$DecodeDocumentError,
					$author$project$Task$Extra$fromResult(
						A2(
							$elm$core$Result$map,
							$author$project$Prismic$updateResults(response),
							$author$project$Result$Extra$collect(
								A2(
									$elm$core$List$map,
									$author$project$Prismic$Internal$decodeValue(decodeDocType),
									response.f)))));
			};
			var _v0 = A2($author$project$Prismic$getFromCache, request.G, request.bA);
			if (!_v0.$) {
				var response = _v0.a;
				return A2(
					$elm$core$Task$map,
					$elm$core$Tuple$pair(request.bA),
					decodeResponseToUserDocType(response));
			} else {
				return A2(
					$elm$core$Task$andThen,
					function (origResponse) {
						return A2(
							$elm$core$Task$map,
							function (response) {
								return _Utils_Tuple2(
									A3($author$project$Prismic$setInCache, request.G, origResponse, request.bA),
									response);
							},
							decodeResponseToUserDocType(origResponse));
					},
					A2(
						$elm$core$Task$mapError,
						$author$project$Prismic$SubmitRequestError,
						$elm$http$Http$task(
							{
								fc: $elm$http$Http$emptyBody,
								dn: _List_Nil,
								cn: 'GET',
								eb: $elm$http$Http$stringResolver(
									$author$project$Prismic$handleJsonResponse($author$project$Prismic$decodeResponse)),
								eA: $elm$core$Maybe$Nothing,
								cQ: $author$project$Prismic$requestToUrl(request.G)
							})));
			}
		};
		return A2($elm$core$Task$andThen, doSubmit, requestTask);
	});
var $author$project$Api$Prismic$fetch = F3(
	function (query, decoder, prismic) {
		return A2(
			$author$project$Prismic$submit,
			decoder,
			A2(
				$author$project$Prismic$query,
				_List_fromArray(
					[
						A2($author$project$Prismic$at, 'document.type', query)
					]),
				A2(
					$author$project$Prismic$form,
					'everything',
					$author$project$Prismic$api(prismic))));
	});
var $author$project$Api$Prismic$fetchWithMsg = F4(
	function (msg, query, decoder, prismic) {
		return A2(
			$elm$core$Task$attempt,
			msg,
			A3($author$project$Api$Prismic$fetch, query, decoder, prismic));
	});
var $author$project$Api$Prismic$Types$CommunityDocument$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'community_document', $author$project$Api$Prismic$Types$CommunityDocument$decoder, model);
	});
var $author$project$Api$Prismic$Types$DigitalPage$FeatureSection = $elm$core$Basics$identity;
var $author$project$Api$Prismic$Types$Slice$FeatureSectionGroup = F6(
	function (visible, sectionTitle, sectionTextField, buttonVisible, buttonText, buttonLink) {
		return {az: buttonLink, aA: buttonText, fm: buttonVisible, hv: sectionTextField, eh: sectionTitle, r: visible};
	});
var $author$project$Prismic$Field$boolean = function (field) {
	if (field.$ === 10) {
		var x = field.a;
		return $elm$core$Result$Ok(x);
	} else {
		return $elm$core$Result$Err(
			'Expected a Boolean field, but got \'' + ($author$project$Prismic$Internal$fieldTypeToString(field) + '\'.'));
	}
};
var $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'button_link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Prismic$Group$required,
		'button_text',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Prismic$Group$required,
			'button_visible',
			$author$project$Prismic$Field$boolean,
			A3(
				$author$project$Prismic$Group$required,
				'section_text_field',
				$author$project$Prismic$Field$structuredText,
				A3(
					$author$project$Prismic$Group$required,
					'section_title',
					$author$project$Prismic$Field$text,
					A3(
						$author$project$Prismic$Group$required,
						'visible',
						$author$project$Prismic$Field$boolean,
						$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$FeatureSectionGroup)))))));
var $author$project$Api$Prismic$Types$Slice$FeatureSectionRepeatableGroup = F3(
	function (title, icon, textField) {
		return {ds: icon, x: textField, ie: title};
	});
var $author$project$Api$Prismic$Helper$groupMaybe = F2(
	function (str, dec) {
		return A3(
			$author$project$Prismic$Group$optional,
			str,
			A2($author$project$Prismic$map, $elm$core$Maybe$Just, dec),
			$elm$core$Maybe$Nothing);
	});
var $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionRepeatableGroup = A3(
	$author$project$Prismic$Group$required,
	'text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'icon',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Prismic$Group$required,
			'title',
			$author$project$Prismic$Field$text,
			$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$FeatureSectionRepeatableGroup))));
var $author$project$Api$Prismic$Types$DigitalPage$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			$elm$core$Basics$identity,
			A3($author$project$Prismic$Slice$slice, 'feature_section', $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionGroup, $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionRepeatableGroup))
		]));
var $author$project$Api$Prismic$Types$DigitalPage$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$DigitalPage$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$DigitalPage$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'digital_page', $author$project$Api$Prismic$Types$DigitalPage$decoder, model);
	});
var $author$project$Api$Prismic$Types$Event$Event = function (tag_line) {
	return function (title) {
		return function (info) {
			return function (lead) {
				return function (date_start) {
					return function (data_end) {
						return function (body) {
							return function (id) {
								return function (uid) {
									return function (created_at) {
										return function (image) {
											return function (is_community_event) {
												return {fc: body, fB: created_at, fF: data_end, fH: date_start, f7: id, cc: image, gi: info, gu: is_community_event, gz: lead, hW: tag_line, ie: title, ik: uid};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Prismic$Types$Event$Banner = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Prismic$Types$Event$FieldGallery = F2(
	function (title, images) {
		return {ge: images, ie: title};
	});
var $author$project$Api$Prismic$Types$Event$Gallery = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Prismic$Types$Event$Text = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Prismic$Types$Event$FieldBanner = F5(
	function (title, description, image, link, label) {
		return {fK: description, cc: image, gw: label, gB: link, ie: title};
	});
var $author$project$Api$Prismic$Types$Event$banner = A4(
	$author$project$Prismic$Group$optional,
	'button_label',
	A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Prismic$Group$optional,
		'button_link',
		A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$link),
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Prismic$Group$optional,
			'image_banner',
			A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$image),
			$elm$core$Maybe$Nothing,
			A4(
				$author$project$Prismic$Group$optional,
				'description',
				A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
				$elm$core$Maybe$Nothing,
				A4(
					$author$project$Prismic$Group$optional,
					'title_of_banner',
					A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
					$elm$core$Maybe$Nothing,
					$author$project$Prismic$decode($author$project$Api$Prismic$Types$Event$FieldBanner))))));
var $author$project$Api$Prismic$Types$Event$FieldImage = F2(
	function (captions, image) {
		return {fo: captions, cc: image};
	});
var $author$project$Api$Prismic$Types$Event$image = A4(
	$author$project$Prismic$Group$optional,
	'gallery_image',
	A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$image),
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Prismic$Group$optional,
		'image_captions',
		A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
		$elm$core$Maybe$Nothing,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Event$FieldImage)));
var $author$project$Api$Prismic$Types$Event$text = A4(
	$author$project$Prismic$Group$optional,
	'event_text',
	A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
	$elm$core$Maybe$Nothing,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$Event$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$Event$Text),
			A3(
				$author$project$Prismic$Slice$slice,
				'event_text',
				$author$project$Api$Prismic$Types$Event$text,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$Event$Banner),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_with_caption',
				$author$project$Api$Prismic$Types$Event$banner,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			function (_v0) {
				var title = _v0.a;
				var imgs = _v0.b;
				return $author$project$Api$Prismic$Types$Event$Gallery(
					A2($author$project$Api$Prismic$Types$Event$FieldGallery, title, imgs));
			},
			A3(
				$author$project$Prismic$Slice$slice,
				'image_gallery',
				A4(
					$author$project$Prismic$Group$optional,
					'name_of_the_gallery',
					A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$structuredText),
					$elm$core$Maybe$Nothing,
					$author$project$Prismic$decode($elm$core$Basics$identity)),
				$author$project$Api$Prismic$Types$Event$image))
		]));
var $author$project$Prismic$Field$date = function (field) {
	if (field.$ === 6) {
		var x = field.a;
		return $elm$core$Result$Ok(x);
	} else {
		return $elm$core$Result$Err(
			'Expected a Date field, but got \'' + ($author$project$Prismic$Internal$fieldTypeToString(field) + '\'.'));
	}
};
var $author$project$Prismic$Field$timestamp = function (field) {
	if (field.$ === 7) {
		var x = field.a;
		return $elm$core$Result$Ok(x);
	} else {
		return $elm$core$Result$Err(
			'Expected a Timestamp field, but got \'' + ($author$project$Prismic$Internal$fieldTypeToString(field) + '\'.'));
	}
};
var $author$project$Api$Prismic$Types$Event$decoder = A3(
	$author$project$Prismic$required,
	'is_community_event',
	$author$project$Prismic$Field$boolean,
	A3(
		$author$project$Api$Prismic$Helper$maybe,
		'image',
		$author$project$Prismic$Field$image,
		A3(
			$author$project$Prismic$required,
			'__first_publication_date',
			$author$project$Prismic$Field$timestamp,
			A4(
				$author$project$Prismic$optional,
				'__uid',
				A2($author$project$Prismic$map, $elm$core$Maybe$Just, $author$project$Prismic$Field$text),
				$elm$core$Maybe$Nothing,
				A3(
					$author$project$Prismic$required,
					'__id',
					$author$project$Prismic$Field$text,
					A3(
						$author$project$Prismic$sliceZone,
						'body',
						$author$project$Api$Prismic$Types$Event$bodySliceZone,
						A3(
							$author$project$Api$Prismic$Helper$maybe,
							'event_date_end',
							$author$project$Prismic$Field$date,
							A3(
								$author$project$Api$Prismic$Helper$maybe,
								'event_date_start',
								$author$project$Prismic$Field$date,
								A3(
									$author$project$Api$Prismic$Helper$maybe,
									'event_lead',
									$author$project$Prismic$Field$structuredText,
									A3(
										$author$project$Api$Prismic$Helper$maybe,
										'event_info',
										$author$project$Prismic$Field$structuredText,
										A3(
											$author$project$Api$Prismic$Helper$maybe,
											'event_title',
											$author$project$Prismic$Field$structuredText,
											A3(
												$author$project$Api$Prismic$Helper$maybe,
												'event_tag_line',
												$author$project$Prismic$Field$structuredText,
												$author$project$Prismic$decode($author$project$Api$Prismic$Types$Event$Event)))))))))))));
var $author$project$Api$Prismic$Types$Event$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'event', $author$project$Api$Prismic$Types$Event$decoder, model);
	});
var $author$project$Api$Prismic$Types$InternetCompanyPage$FeatureSection = $elm$core$Basics$identity;
var $author$project$Api$Prismic$Types$InternetCompanyPage$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			$elm$core$Basics$identity,
			A3($author$project$Prismic$Slice$slice, 'feature_section', $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionGroup, $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionRepeatableGroup))
		]));
var $author$project$Api$Prismic$Types$InternetCompanyPage$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$InternetCompanyPage$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$InternetCompanyPage$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'internet_company_page', $author$project$Api$Prismic$Types$InternetCompanyPage$decoder, model);
	});
var $author$project$Api$Prismic$Types$InternetInformationPage$Component = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Prismic$Types$InternetInformationPage$IntroSection = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Prismic$Types$InternetInformationPage$Left = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Prismic$Types$InternetInformationPage$Right = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Prismic$Types$InternetInformationPage$TextField = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Prismic$Types$InternetInformationPage$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$InternetInformationPage$IntroSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'intro_section',
				$author$project$Api$Prismic$Types$GenericPage$intro,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$InternetInformationPage$Component),
			A3(
				$author$project$Prismic$Slice$slice,
				'component_section',
				$author$project$Api$Prismic$Types$GenericPage$textImg(
					{bv: 'component_image', bJ: 'component_text'}),
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$InternetInformationPage$Left),
			A3(
				$author$project$Prismic$Slice$slice,
				'text-img_section',
				$author$project$Api$Prismic$Types$GenericPage$textImg(
					{bv: 'image_right', bJ: 'text_left'}),
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$InternetInformationPage$Right),
			A3(
				$author$project$Prismic$Slice$slice,
				'img-text_section',
				$author$project$Api$Prismic$Types$GenericPage$textImg(
					{bv: 'image_left', bJ: 'text_right'}),
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$InternetInformationPage$TextField),
			A3(
				$author$project$Prismic$Slice$slice,
				'text_field_section',
				A3(
					$author$project$Prismic$Group$required,
					'text_field',
					$author$project$Prismic$Field$structuredText,
					$author$project$Prismic$decode($elm$core$Basics$identity)),
				$author$project$Prismic$decode(0)))
		]));
var $author$project$Api$Prismic$Types$InternetInformationPage$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$InternetInformationPage$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$InternetInformationPage$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'internet_page', $author$project$Api$Prismic$Types$InternetInformationPage$decoder, model);
	});
var $author$project$Api$Prismic$Types$InternetPrivatePage$FeatureSection = $elm$core$Basics$identity;
var $author$project$Api$Prismic$Types$InternetPrivatePage$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			$elm$core$Basics$identity,
			A3($author$project$Prismic$Slice$slice, 'feature_section', $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionGroup, $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionRepeatableGroup))
		]));
var $author$project$Api$Prismic$Types$InternetPrivatePage$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$InternetPrivatePage$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$InternetPrivatePage$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'internet_private_page', $author$project$Api$Prismic$Types$InternetPrivatePage$decoder, model);
	});
var $author$project$Api$Prismic$Types$LandingPageDev$Banner = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$BannerSection2 = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$BannerSection3 = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$BannerSection4 = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$FaqSection = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$Feature3 = function (a) {
	return {$: 9, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$FeatureSection = function (a) {
	return {$: 7, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$FeatureSection2 = function (a) {
	return {$: 8, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$Hero = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$ImgTextSection = function (a) {
	return {$: 13, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$MembershipSection = function (a) {
	return {$: 15, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$NewsCardSection = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$SocialMediaSection = function (a) {
	return {$: 10, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$TestimonialSection = function (a) {
	return {$: 11, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$TestimonialSection2 = function (a) {
	return {$: 12, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageDev$TextImgSection = function (a) {
	return {$: 14, a: a};
};
var $author$project$Api$Prismic$Types$Slice$Banner2Group = F4(
	function (visible, text, buttonText, buttonLink) {
		return {az: buttonLink, aA: buttonText, eu: text, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeBanner2Group = A3(
	$author$project$Prismic$Group$required,
	'button_link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Prismic$Group$required,
		'button_text',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Prismic$Group$required,
			'text',
			$author$project$Prismic$Field$text,
			A3(
				$author$project$Prismic$Group$required,
				'visible',
				$author$project$Prismic$Field$boolean,
				$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$Banner2Group)))));
var $author$project$Api$Prismic$Types$Slice$Banner3Group = F5(
	function (visible, image, textField, buttonText, buttonLink) {
		return {az: buttonLink, aA: buttonText, cc: image, x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeBanner3Group = A3(
	$author$project$Prismic$Group$required,
	'button_link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Prismic$Group$required,
		'button_text',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Prismic$Group$required,
			'text_field',
			$author$project$Prismic$Field$structuredText,
			A3(
				$author$project$Api$Prismic$Helper$groupMaybe,
				'image',
				$author$project$Prismic$Field$image,
				A3(
					$author$project$Prismic$Group$required,
					'visible',
					$author$project$Prismic$Field$boolean,
					$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$Banner3Group))))));
var $author$project$Api$Prismic$Types$Slice$Banner4Group = F5(
	function (visible, image, textField, buttonText, buttonLink) {
		return {az: buttonLink, aA: buttonText, cc: image, x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeBanner4Group = A3(
	$author$project$Prismic$Group$required,
	'button_link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Prismic$Group$required,
		'button_text',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Prismic$Group$required,
			'text_field',
			$author$project$Prismic$Field$structuredText,
			A3(
				$author$project$Api$Prismic$Helper$groupMaybe,
				'image',
				$author$project$Prismic$Field$image,
				A3(
					$author$project$Prismic$Group$required,
					'visible',
					$author$project$Prismic$Field$boolean,
					$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$Banner4Group))))));
var $author$project$Api$Prismic$Types$Slice$BannerGroup = F4(
	function (visible, title, text, link) {
		return {gB: link, eu: text, ie: title, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeBannerGroup = A3(
	$author$project$Prismic$Group$required,
	'link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Prismic$Group$required,
		'text_field',
		$author$project$Prismic$Field$structuredText,
		A3(
			$author$project$Prismic$Group$required,
			'title',
			$author$project$Prismic$Field$text,
			A3(
				$author$project$Prismic$Group$required,
				'visible',
				$author$project$Prismic$Field$boolean,
				$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$BannerGroup)))));
var $author$project$Api$Prismic$Types$Slice$FaqGroup = F2(
	function (visible, sectionTitle) {
		return {eh: sectionTitle, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeFaqGroup = A3(
	$author$project$Prismic$Group$required,
	'section_title',
	$author$project$Prismic$Field$text,
	A3(
		$author$project$Prismic$Group$required,
		'visible',
		$author$project$Prismic$Field$boolean,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$FaqGroup)));
var $author$project$Api$Prismic$Types$Slice$FaqRepeatableGroup = F2(
	function (question, answer) {
		return {e_: answer, hf: question};
	});
var $author$project$Api$Prismic$Types$Slice$decodeFaqRepeatableGroup = A3(
	$author$project$Prismic$Group$required,
	'answer',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Prismic$Group$required,
		'question',
		$author$project$Prismic$Field$text,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$FaqRepeatableGroup)));
var $author$project$Api$Prismic$Types$Slice$Feature3Group = F7(
	function (buttonLink, buttonText, headline1, headline2, image, textField, visible) {
		return {az: buttonLink, aA: buttonText, f1: headline1, f2: headline2, cc: image, x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeFeature3Group = A3(
	$author$project$Prismic$Group$required,
	'visible',
	$author$project$Prismic$Field$boolean,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'text_field',
		$author$project$Prismic$Field$structuredText,
		A3(
			$author$project$Api$Prismic$Helper$groupMaybe,
			'image',
			$author$project$Prismic$Field$image,
			A3(
				$author$project$Api$Prismic$Helper$groupMaybe,
				'headline_2',
				$author$project$Prismic$Field$text,
				A3(
					$author$project$Api$Prismic$Helper$groupMaybe,
					'headline_1',
					$author$project$Prismic$Field$text,
					A3(
						$author$project$Api$Prismic$Helper$groupMaybe,
						'button_text',
						$author$project$Prismic$Field$text,
						A3(
							$author$project$Prismic$Group$required,
							'button_link',
							$author$project$Prismic$Field$link,
							$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$Feature3Group))))))));
var $author$project$Api$Prismic$Types$Slice$FeatureSection2Group = F2(
	function (visible, textField) {
		return {x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeFeatureSection2Group = A3(
	$author$project$Api$Prismic$Helper$groupMaybe,
	'text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Prismic$Group$required,
		'visible',
		$author$project$Prismic$Field$boolean,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$FeatureSection2Group)));
var $author$project$Api$Prismic$Types$Slice$FeatureSection2RepeatableGroup = F5(
	function (visible, icon, textField, linkText, link) {
		return {ds: icon, gB: link, gC: linkText, x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeFeatureSection2RepeatableGroup = A3(
	$author$project$Prismic$Group$required,
	'link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'link_text',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Api$Prismic$Helper$groupMaybe,
			'text_field',
			$author$project$Prismic$Field$structuredText,
			A3(
				$author$project$Api$Prismic$Helper$groupMaybe,
				'icon',
				$author$project$Prismic$Field$text,
				A3(
					$author$project$Prismic$Group$required,
					'visible',
					$author$project$Prismic$Field$boolean,
					$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$FeatureSection2RepeatableGroup))))));
var $author$project$Api$Prismic$Types$Slice$HeroGroup = function (visible) {
	return function (backgroundImage) {
		return function (eyebrowHeadline) {
			return function (title) {
				return function (textField) {
					return function (videoVisible) {
						return function (videoSrc) {
							return function (bannerVisible) {
								return function (bannerTextField) {
									return function (bannerImage) {
										return function (bannerLink) {
											return {e2: backgroundImage, e3: bannerImage, e4: bannerLink, e5: bannerTextField, e6: bannerVisible, fU: eyebrowHeadline, x: textField, ie: title, io: videoSrc, ip: videoVisible, r: visible};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Prismic$Types$Slice$decodeHeroGroup = A3(
	$author$project$Api$Prismic$Helper$groupMaybe,
	'banner_link',
	$author$project$Prismic$Field$text,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'banner_image',
		$author$project$Prismic$Field$image,
		A3(
			$author$project$Api$Prismic$Helper$groupMaybe,
			'banner_text_field',
			$author$project$Prismic$Field$structuredText,
			A3(
				$author$project$Prismic$Group$required,
				'banner_visible',
				$author$project$Prismic$Field$boolean,
				A3(
					$author$project$Api$Prismic$Helper$groupMaybe,
					'video_src',
					$author$project$Prismic$Field$text,
					A3(
						$author$project$Prismic$Group$required,
						'video_visible',
						$author$project$Prismic$Field$boolean,
						A3(
							$author$project$Api$Prismic$Helper$groupMaybe,
							'text_field',
							$author$project$Prismic$Field$structuredText,
							A3(
								$author$project$Prismic$Group$required,
								'title',
								$author$project$Prismic$Field$text,
								A3(
									$author$project$Prismic$Group$required,
									'eyebrow_headline',
									$author$project$Prismic$Field$structuredText,
									A3(
										$author$project$Api$Prismic$Helper$groupMaybe,
										'background_image',
										$author$project$Prismic$Field$image,
										A3(
											$author$project$Prismic$Group$required,
											'visible',
											$author$project$Prismic$Field$boolean,
											$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$HeroGroup))))))))))));
var $author$project$Api$Prismic$Types$Slice$ImgTextSectionGroup = F2(
	function (textRight, imageLeft) {
		return {gb: imageLeft, h7: textRight};
	});
var $author$project$Api$Prismic$Types$Slice$decodeImgTextSectionGroup = A3(
	$author$project$Api$Prismic$Helper$groupMaybe,
	'image_left',
	$author$project$Prismic$Field$image,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'text_right',
		$author$project$Prismic$Field$structuredText,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$ImgTextSectionGroup)));
var $author$project$Api$Prismic$Types$Slice$MembershipSectionGroup = F6(
	function (visible, numberOfMembers, numberOfMembersText, textField, buttonText, buttonLink) {
		return {az: buttonLink, aA: buttonText, gY: numberOfMembers, gZ: numberOfMembersText, x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeMembershipSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'button_link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'button_text',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Api$Prismic$Helper$groupMaybe,
			'text_field',
			$author$project$Prismic$Field$structuredText,
			A3(
				$author$project$Api$Prismic$Helper$groupMaybe,
				'number_of_members_text',
				$author$project$Prismic$Field$text,
				A3(
					$author$project$Api$Prismic$Helper$groupMaybe,
					'number_of_members',
					$author$project$Prismic$Field$text,
					A3(
						$author$project$Prismic$Group$required,
						'visible',
						$author$project$Prismic$Field$boolean,
						$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$MembershipSectionGroup)))))));
var $author$project$Api$Prismic$Types$Slice$NewsCardSectionGroup = F2(
	function (title, textField) {
		return {x: textField, ie: title};
	});
var $author$project$Api$Prismic$Types$Slice$decodeNewsCardSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Prismic$Group$required,
		'title',
		$author$project$Prismic$Field$text,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$NewsCardSectionGroup)));
var $author$project$Api$Prismic$Types$Slice$NewsCardSectionRepeatableGroup = F8(
	function (visible, tagline, title, description, linkText, link, imageSource, image) {
		return {fK: description, cc: image, gd: imageSource, gB: link, gC: linkText, hX: tagline, ie: title, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeNewsCardSectionRepeatableGroup = A3(
	$author$project$Api$Prismic$Helper$groupMaybe,
	'image',
	$author$project$Prismic$Field$image,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'image_source',
		$author$project$Prismic$Field$link,
		A3(
			$author$project$Prismic$Group$required,
			'link',
			$author$project$Prismic$Field$link,
			A3(
				$author$project$Prismic$Group$required,
				'link_text',
				$author$project$Prismic$Field$text,
				A3(
					$author$project$Api$Prismic$Helper$groupMaybe,
					'description',
					$author$project$Prismic$Field$text,
					A3(
						$author$project$Prismic$Group$required,
						'title',
						$author$project$Prismic$Field$text,
						A3(
							$author$project$Prismic$Group$required,
							'tagline',
							$author$project$Prismic$Field$text,
							A3(
								$author$project$Prismic$Group$required,
								'visible',
								$author$project$Prismic$Field$boolean,
								$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$NewsCardSectionRepeatableGroup)))))))));
var $author$project$Api$Prismic$Types$Slice$SocialMediaSectionGroup = function (visible) {
	return {r: visible};
};
var $author$project$Api$Prismic$Types$Slice$decodeSocialMediaSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'visible',
	$author$project$Prismic$Field$boolean,
	$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$SocialMediaSectionGroup));
var $author$project$Api$Prismic$Types$Slice$TestimonialSection2Group = F2(
	function (visible, textField) {
		return {x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeTestimonialSection2Group = A3(
	$author$project$Api$Prismic$Helper$groupMaybe,
	'text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Prismic$Group$required,
		'visible',
		$author$project$Prismic$Field$boolean,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$TestimonialSection2Group)));
var $author$project$Api$Prismic$Types$Slice$TestimonialSection2RepeatableGroup = F5(
	function (visible, image, name, shortDescription, textField) {
		return {cc: image, gU: name, hy: shortDescription, x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeTestimonialSection2RepeatableGroup = A3(
	$author$project$Api$Prismic$Helper$groupMaybe,
	'text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'short_description',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Api$Prismic$Helper$groupMaybe,
			'name',
			$author$project$Prismic$Field$text,
			A3(
				$author$project$Api$Prismic$Helper$groupMaybe,
				'image',
				$author$project$Prismic$Field$image,
				A3(
					$author$project$Prismic$Group$required,
					'visible',
					$author$project$Prismic$Field$boolean,
					$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$TestimonialSection2RepeatableGroup))))));
var $author$project$Api$Prismic$Types$Slice$TestimonialSectionGroup = function (visible) {
	return {r: visible};
};
var $author$project$Api$Prismic$Types$Slice$decodeTestomonialSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'visible',
	$author$project$Prismic$Field$boolean,
	$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$TestimonialSectionGroup));
var $author$project$Api$Prismic$Types$Slice$TextImgSectionGroup = F2(
	function (textLeft, imageRight) {
		return {gc: imageRight, h3: textLeft};
	});
var $author$project$Api$Prismic$Types$Slice$decodeTextImgSectionGroup = A3(
	$author$project$Api$Prismic$Helper$groupMaybe,
	'image_right',
	$author$project$Prismic$Field$image,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'text_left',
		$author$project$Prismic$Field$structuredText,
		$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$TextImgSectionGroup)));
var $author$project$Api$Prismic$Types$LandingPageDev$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageDev$NewsCardSection,
			A3($author$project$Prismic$Slice$slice, 'news_card_section', $author$project$Api$Prismic$Types$Slice$decodeNewsCardSectionGroup, $author$project$Api$Prismic$Types$Slice$decodeNewsCardSectionRepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$Banner),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner1',
				$author$project$Api$Prismic$Types$Slice$decodeBannerGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$BannerSection2),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_2',
				$author$project$Api$Prismic$Types$Slice$decodeBanner2Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$BannerSection3),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_3',
				$author$project$Api$Prismic$Types$Slice$decodeBanner3Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$BannerSection4),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_4',
				$author$project$Api$Prismic$Types$Slice$decodeBanner4Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageDev$FaqSection,
			A3($author$project$Prismic$Slice$slice, 'faq', $author$project$Api$Prismic$Types$Slice$decodeFaqGroup, $author$project$Api$Prismic$Types$Slice$decodeFaqRepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$Hero),
			A3(
				$author$project$Prismic$Slice$slice,
				'hero_section',
				$author$project$Api$Prismic$Types$Slice$decodeHeroGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageDev$FeatureSection,
			A3($author$project$Prismic$Slice$slice, 'feature_section', $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionGroup, $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionRepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageDev$FeatureSection2,
			A3($author$project$Prismic$Slice$slice, 'feature_section_2', $author$project$Api$Prismic$Types$Slice$decodeFeatureSection2Group, $author$project$Api$Prismic$Types$Slice$decodeFeatureSection2RepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$Feature3),
			A3(
				$author$project$Prismic$Slice$slice,
				'feature_3',
				$author$project$Api$Prismic$Types$Slice$decodeFeature3Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$SocialMediaSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'social_media_section',
				$author$project$Api$Prismic$Types$Slice$decodeSocialMediaSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$TestimonialSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'testimonial_section',
				$author$project$Api$Prismic$Types$Slice$decodeTestomonialSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageDev$TestimonialSection2,
			A3($author$project$Prismic$Slice$slice, 'testimonial_section_2', $author$project$Api$Prismic$Types$Slice$decodeTestimonialSection2Group, $author$project$Api$Prismic$Types$Slice$decodeTestimonialSection2RepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$ImgTextSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'img-text_section',
				$author$project$Api$Prismic$Types$Slice$decodeImgTextSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$TextImgSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'text-img_section',
				$author$project$Api$Prismic$Types$Slice$decodeTextImgSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageDev$MembershipSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'membership_section',
				$author$project$Api$Prismic$Types$Slice$decodeMembershipSectionGroup,
				$author$project$Prismic$decode(0)))
		]));
var $author$project$Api$Prismic$Types$LandingPageDev$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$LandingPageDev$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$LandingPageDev$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'landing_page_dev', $author$project$Api$Prismic$Types$LandingPageDev$decoder, model);
	});
var $author$project$Api$Prismic$Types$LandingPageV2$Banner = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$BannerSection2 = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$BannerSection3 = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$BannerSection4 = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$FaqSection = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$FeatureSection = function (a) {
	return {$: 7, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$Hero = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$ImageTextSection = function (a) {
	return {$: 10, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$MembershipSection = function (a) {
	return {$: 11, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$NewsCardSection = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$SocialMediaSection = function (a) {
	return {$: 8, a: a};
};
var $author$project$Api$Prismic$Types$LandingPageV2$TestimonialSection = function (a) {
	return {$: 9, a: a};
};
var $author$project$Api$Prismic$Types$Slice$ImageTextSectionGroup = F3(
	function (visible, image, textField) {
		return {cc: image, x: textField, r: visible};
	});
var $author$project$Api$Prismic$Types$Slice$decodeImageTextSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Api$Prismic$Helper$groupMaybe,
		'image',
		$author$project$Prismic$Field$image,
		A3(
			$author$project$Prismic$Group$required,
			'visible',
			$author$project$Prismic$Field$boolean,
			$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$ImageTextSectionGroup))));
var $author$project$Api$Prismic$Types$LandingPageV2$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageV2$NewsCardSection,
			A3($author$project$Prismic$Slice$slice, 'news_card_section', $author$project$Api$Prismic$Types$Slice$decodeNewsCardSectionGroup, $author$project$Api$Prismic$Types$Slice$decodeNewsCardSectionRepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$Banner),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner1',
				$author$project$Api$Prismic$Types$Slice$decodeBannerGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$BannerSection2),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_2',
				$author$project$Api$Prismic$Types$Slice$decodeBanner2Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$BannerSection3),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_3',
				$author$project$Api$Prismic$Types$Slice$decodeBanner3Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$BannerSection4),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_4',
				$author$project$Api$Prismic$Types$Slice$decodeBanner4Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageV2$FaqSection,
			A3($author$project$Prismic$Slice$slice, 'faq', $author$project$Api$Prismic$Types$Slice$decodeFaqGroup, $author$project$Api$Prismic$Types$Slice$decodeFaqRepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$Hero),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner',
				$author$project$Api$Prismic$Types$Slice$decodeHeroGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			$author$project$Api$Prismic$Types$LandingPageV2$FeatureSection,
			A3($author$project$Prismic$Slice$slice, 'feature_section', $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionGroup, $author$project$Api$Prismic$Types$Slice$decodeFeatureSectionRepeatableGroup)),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$SocialMediaSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'social_media_section',
				$author$project$Api$Prismic$Types$Slice$decodeSocialMediaSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$TestimonialSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'testimonial_section',
				$author$project$Api$Prismic$Types$Slice$decodeTestomonialSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$ImageTextSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'image_text_section',
				$author$project$Api$Prismic$Types$Slice$decodeImageTextSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$LandingPageV2$MembershipSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'membership_section',
				$author$project$Api$Prismic$Types$Slice$decodeMembershipSectionGroup,
				$author$project$Prismic$decode(0)))
		]));
var $author$project$Api$Prismic$Types$LandingPageV2$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$LandingPageV2$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$LandingPageV2$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'landing_page_v2', $author$project$Api$Prismic$Types$LandingPageV2$decoder, model);
	});
var $author$project$Api$Prismic$Types$MediaEcho$MediaEcho = F5(
	function (title, date, info, sourceLink, downloadLink) {
		return {fG: date, fN: downloadLink, gi: info, hF: sourceLink, ie: title};
	});
var $author$project$Api$Prismic$Types$MediaEcho$decoder = A3(
	$author$project$Api$Prismic$Helper$maybe,
	'download_link',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Api$Prismic$Helper$maybe,
		'source_link',
		$author$project$Prismic$Field$link,
		A3(
			$author$project$Prismic$required,
			'info',
			$author$project$Prismic$Field$text,
			A3(
				$author$project$Prismic$required,
				'date',
				$author$project$Prismic$Field$date,
				A3(
					$author$project$Prismic$required,
					'title',
					$author$project$Prismic$Field$text,
					$author$project$Prismic$decode($author$project$Api$Prismic$Types$MediaEcho$MediaEcho))))));
var $author$project$Api$Prismic$Types$MediaEcho$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'media_echo', $author$project$Api$Prismic$Types$MediaEcho$decoder, model);
	});
var $author$project$Api$Prismic$Types$MiaEngiadinaPartner$MiaEngiadinaPartner = F4(
	function (logo, imageAlignment, name, website) {
		return {f8: imageAlignment, gG: logo, gU: name, ir: website};
	});
var $author$project$Api$Prismic$Types$MiaEngiadinaPartner$decoder = A3(
	$author$project$Api$Prismic$Helper$maybe,
	'website',
	$author$project$Prismic$Field$link,
	A3(
		$author$project$Prismic$required,
		'name',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Prismic$required,
			'image_alignment',
			$author$project$Prismic$Field$boolean,
			A3(
				$author$project$Prismic$required,
				'logo',
				$author$project$Prismic$Field$image,
				$author$project$Prismic$decode($author$project$Api$Prismic$Types$MiaEngiadinaPartner$MiaEngiadinaPartner)))));
var $author$project$Api$Prismic$Types$MiaEngiadinaPartner$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'miaengiadina_partner', $author$project$Api$Prismic$Types$MiaEngiadinaPartner$decoder, model);
	});
var $author$project$Api$Prismic$Types$MintEngiadina$BannerSection2 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$Prismic$Types$MintEngiadina$BannerSection3 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Prismic$Types$MintEngiadina$BannerSection4 = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Prismic$Types$MintEngiadina$ImgTextSection = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$Prismic$Types$MintEngiadina$IntroSection = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Prismic$Types$MintEngiadina$TextFieldSection = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Prismic$Types$MintEngiadina$TextImgSection = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$Prismic$Types$Slice$IntroSectionGroup = F4(
	function (title, text, titleField, textField) {
		return {eu: text, x: textField, ie: title, $8: titleField};
	});
var $author$project$Api$Prismic$Types$Slice$decodeIntroSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'intro_text_field',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Prismic$Group$required,
		'intro_title_filed',
		$author$project$Prismic$Field$structuredText,
		A3(
			$author$project$Api$Prismic$Helper$groupMaybe,
			'intro_title',
			$author$project$Prismic$Field$structuredText,
			A3(
				$author$project$Api$Prismic$Helper$groupMaybe,
				'intro_title',
				$author$project$Prismic$Field$structuredText,
				$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$IntroSectionGroup)))));
var $author$project$Api$Prismic$Types$Slice$TextFieldSectionGroup = function (textField) {
	return {x: textField};
};
var $author$project$Api$Prismic$Types$Slice$decodeTextFieldSectionGroup = A3(
	$author$project$Prismic$Group$required,
	'text_field',
	$author$project$Prismic$Field$structuredText,
	$author$project$Prismic$decode($author$project$Api$Prismic$Types$Slice$TextFieldSectionGroup));
var $author$project$Api$Prismic$Types$MintEngiadina$bodySliceZone = $author$project$Prismic$Slice$oneOf(
	_List_fromArray(
		[
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$MintEngiadina$BannerSection2),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_2',
				$author$project$Api$Prismic$Types$Slice$decodeBanner2Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$MintEngiadina$BannerSection3),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_3',
				$author$project$Api$Prismic$Types$Slice$decodeBanner3Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$MintEngiadina$BannerSection4),
			A3(
				$author$project$Prismic$Slice$slice,
				'banner_section_4',
				$author$project$Api$Prismic$Types$Slice$decodeBanner4Group,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$MintEngiadina$TextFieldSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'text_field_section',
				$author$project$Api$Prismic$Types$Slice$decodeTextFieldSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$MintEngiadina$IntroSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'intro_section',
				$author$project$Api$Prismic$Types$Slice$decodeIntroSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$MintEngiadina$ImgTextSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'img-text_section',
				$author$project$Api$Prismic$Types$Slice$decodeImgTextSectionGroup,
				$author$project$Prismic$decode(0))),
			A2(
			$author$project$Prismic$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Api$Prismic$Types$MintEngiadina$TextImgSection),
			A3(
				$author$project$Prismic$Slice$slice,
				'text-img_section',
				$author$project$Api$Prismic$Types$Slice$decodeTextImgSectionGroup,
				$author$project$Prismic$decode(0)))
		]));
var $author$project$Api$Prismic$Types$MintEngiadina$decoder = A3(
	$author$project$Prismic$sliceZone,
	'body',
	$author$project$Api$Prismic$Types$MintEngiadina$bodySliceZone,
	$author$project$Prismic$decode($elm$core$Basics$identity));
var $author$project$Api$Prismic$Types$MintEngiadina$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'mint_engiadina_page', $author$project$Api$Prismic$Types$MintEngiadina$decoder, model);
	});
var $author$project$Api$Prismic$Types$TeamMember$TeamMember = F4(
	function (image, name, email, bio) {
		return {fa: bio, fQ: email, cc: image, gU: name};
	});
var $author$project$Api$Prismic$Types$TeamMember$decoder = A3(
	$author$project$Api$Prismic$Helper$maybe,
	'bio',
	$author$project$Prismic$Field$structuredText,
	A3(
		$author$project$Api$Prismic$Helper$maybe,
		'email',
		$author$project$Prismic$Field$text,
		A3(
			$author$project$Prismic$required,
			'name',
			$author$project$Prismic$Field$text,
			A3(
				$author$project$Prismic$required,
				'image',
				$author$project$Prismic$Field$image,
				$author$project$Prismic$decode($author$project$Api$Prismic$Types$TeamMember$TeamMember)))));
var $author$project$Api$Prismic$Types$TeamMember$fetch = F2(
	function (model, message) {
		return A4($author$project$Api$Prismic$fetchWithMsg, message, 'team_member', $author$project$Api$Prismic$Types$TeamMember$decoder, model);
	});
var $author$project$Api$Prismic$Types$fetchAll = function (model) {
	return $elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				A2($author$project$Api$Prismic$Types$Event$fetch, model.a, $author$project$Api$Prismic$Types$Event),
				A2($author$project$Api$Prismic$Types$LandingPageDev$fetch, model.a, $author$project$Api$Prismic$Types$LandingPageDev),
				A2($author$project$Api$Prismic$Types$LandingPageV2$fetch, model.a, $author$project$Api$Prismic$Types$LandingPageV2),
				A2($author$project$Api$Prismic$Types$InternetInformationPage$fetch, model.a, $author$project$Api$Prismic$Types$InternetInformationPage),
				A2($author$project$Api$Prismic$Types$InternetCompanyPage$fetch, model.a, $author$project$Api$Prismic$Types$InternetCompanyPage),
				A2($author$project$Api$Prismic$Types$InternetPrivatePage$fetch, model.a, $author$project$Api$Prismic$Types$InternetPrivatePage),
				A2($author$project$Api$Prismic$Types$DigitalPage$fetch, model.a, $author$project$Api$Prismic$Types$DigitalPage),
				A2($author$project$Api$Prismic$Types$MediaEcho$fetch, model.a, $author$project$Api$Prismic$Types$MediaEcho),
				A2($author$project$Api$Prismic$Types$TeamMember$fetch, model.a, $author$project$Api$Prismic$Types$TeamMember),
				A2($author$project$Api$Prismic$Types$CommunityDocument$fetch, model.a, $author$project$Api$Prismic$Types$CommunityDocument),
				A2($author$project$Api$Prismic$Types$MiaEngiadinaPartner$fetch, model.a, $author$project$Api$Prismic$Types$MiaEngiadinaPartner),
				A2($author$project$Api$Prismic$Types$MintEngiadina$fetch, model.a, $author$project$Api$Prismic$Types$MintEngiadina),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$AboutOrganisationPage, 'about_organisation_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$AboutPage, 'about_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$CommunityPage, 'community_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$CommunityPartnershipPage, 'community_partnership_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$CoworkingPage, 'coworking_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$CoworkingMountainHubsPage, 'coworking_mountain_hubs_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$InternetDownloadPage, 'internet_download_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$InternetPage, 'internet_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$ReferencePage, 'reference_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a),
				A4($author$project$Api$Prismic$fetchWithMsg, $author$project$Api$Prismic$Types$EducationPage, 'education_page', $author$project$Api$Prismic$Types$GenericPage$decoder, model.a)
			]));
};
var $author$project$Audience$EndUser = 1;
var $author$project$Audience$fromString = function (audience) {
	switch (audience) {
		case 'developer':
			return $elm$core$Maybe$Just(0);
		case 'enduser':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Ui$Scale$Md = 2;
var $author$project$Ui$Scale$Sm = 1;
var $author$project$Ui$Scale$Xs = 0;
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Ui$Scale$getScreenSize = function (screen) {
	return (screen.a7 <= 575) ? 0 : (((screen.a7 >= 576) && (screen.a7 <= 767)) ? 1 : (((screen.a7 >= 768) && (screen.a7 <= 991)) ? 2 : 3));
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {ae: frag, at: params, ab: unvisited, $9: value, ax: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.ab;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.$9);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.$9);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.dM),
					$elm$url$Url$Parser$prepareQuery(url.he),
					url.dl,
					$elm$core$Basics$identity)));
	});
var $author$project$Types$AnmeldungMountainHubScuolPage = {$: 13};
var $author$project$Types$ArbeitenInDenBergenPage = {$: 17};
var $author$project$Types$BewertungCoworking = {$: 18};
var $author$project$Types$BewertungInternetPage = {$: 16};
var $author$project$Types$BewertungMountainHubScuolPage = {$: 12};
var $author$project$Types$ContactPage = {$: 8};
var $author$project$Types$DigitalPage = {$: 2};
var $author$project$Types$GutscheinCoworkingPage = {$: 15};
var $author$project$Types$GvAnmeldungPage = {$: 11};
var $author$project$Types$MintEngiadinaPage = {$: 3};
var $author$project$Types$NewsPage = {$: 1};
var $author$project$Types$NewsletterPage = {$: 10};
var $author$project$Types$StatutenPage = {$: 9};
var $author$project$Types$WimmelbildPage = {$: 19};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.ax;
		var unvisited = _v0.ab;
		var params = _v0.at;
		var frag = _v0.ae;
		var value = _v0.$9;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.ax;
			var unvisited = _v1.ab;
			var params = _v1.at;
			var frag = _v1.ae;
			var value = _v1.$9;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $author$project$Types$AboutPage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Types$MediaPage = 1;
var $author$project$Types$MediaReleasesPage = 2;
var $author$project$Types$MyEngadinPartnerPage = 3;
var $author$project$Types$OrganisationPage = 4;
var $author$project$Types$TeamPage = 0;
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.ax;
		var unvisited = _v0.ab;
		var params = _v0.at;
		var frag = _v0.ae;
		var value = _v0.$9;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.ax;
			var unvisited = _v0.ab;
			var params = _v0.at;
			var frag = _v0.ae;
			var value = _v0.$9;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $author$project$Route$parseAboutRoute = function () {
	var root = $elm$url$Url$Parser$s('about');
	return $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Parser$map,
				function (str) {
					return $author$project$Types$AboutPage(
						function () {
							switch (str) {
								case 'team':
									return $elm$core$Maybe$Just(0);
								case 'media':
									return $elm$core$Maybe$Just(1);
								case 'media-releases':
									return $elm$core$Maybe$Just(2);
								case 'miaengiadina-partner':
									return $elm$core$Maybe$Just(3);
								case 'organisation':
									return $elm$core$Maybe$Just(4);
								default:
									return $elm$core$Maybe$Nothing;
							}
						}());
				},
				A2($elm$url$Url$Parser$slash, root, $elm$url$Url$Parser$string)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Types$AboutPage($elm$core$Maybe$Nothing),
				root)
			]));
}();
var $author$project$Types$CommunityPage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Types$EducationPage = {$: 2};
var $author$project$Types$EventsPage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$MembershipPage = {$: 0};
var $author$project$Types$PartnershipPage = {$: 1};
var $author$project$Route$parseCommunityRoute = function () {
	var root = $elm$url$Url$Parser$s('community');
	return $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Parser$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$Just,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Types$EventsPage,
						A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, $author$project$Types$CommunityPage))),
				A2(
					$elm$url$Url$Parser$slash,
					root,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('events'),
						$elm$url$Url$Parser$string))),
				A2(
				$elm$url$Url$Parser$map,
				function (str) {
					return $author$project$Types$CommunityPage(
						function () {
							switch (str) {
								case 'membership':
									return $elm$core$Maybe$Just($author$project$Types$MembershipPage);
								case 'partnership':
									return $elm$core$Maybe$Just($author$project$Types$PartnershipPage);
								case 'education':
									return $elm$core$Maybe$Just($author$project$Types$EducationPage);
								case 'events':
									return $elm$core$Maybe$Just(
										$author$project$Types$EventsPage($elm$core$Maybe$Nothing));
								default:
									return $elm$core$Maybe$Nothing;
							}
						}());
				},
				A2($elm$url$Url$Parser$slash, root, $elm$url$Url$Parser$string)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Types$CommunityPage($elm$core$Maybe$Nothing),
				root)
			]));
}();
var $author$project$Types$AccommodationsPage = 2;
var $author$project$Types$CoworkingPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Types$MountainHubPricingPage = 1;
var $author$project$Types$MountainHubsPage = 0;
var $author$project$Route$parseCoworkingRoute = function () {
	var root = $elm$url$Url$Parser$s('mountain-coworking');
	return $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Parser$map,
				function (str) {
					return $author$project$Types$CoworkingPage(
						function () {
							switch (str) {
								case 'mountain-hubs':
									return $elm$core$Maybe$Just(0);
								case 'mountain-hub-pricing':
									return $elm$core$Maybe$Just(1);
								case 'accommodations':
									return $elm$core$Maybe$Just(2);
								default:
									return $elm$core$Maybe$Nothing;
							}
						}());
				},
				A2($elm$url$Url$Parser$slash, root, $elm$url$Url$Parser$string)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Types$CoworkingPage($elm$core$Maybe$Nothing),
				root)
			]));
}();
var $author$project$Types$CompanySubscriptionPage = 1;
var $author$project$Types$DownloadPage = 4;
var $author$project$Types$InternetInformationPage = 3;
var $author$project$Types$InternetPage = function (a) {
	return {$: 4, a: a};
};
var $author$project$Types$PrivateCustomerSubscriptionPage = 0;
var $author$project$Types$ReferencePage = 2;
var $author$project$Route$parseInternetRoute = function () {
	var root = $elm$url$Url$Parser$s('internet');
	return $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Parser$map,
				function (str) {
					return $author$project$Types$InternetPage(
						function () {
							switch (str) {
								case 'private':
									return $elm$core$Maybe$Just(0);
								case 'company':
									return $elm$core$Maybe$Just(1);
								case 'referenzen':
									return $elm$core$Maybe$Just(2);
								case 'information':
									return $elm$core$Maybe$Just(3);
								case 'download':
									return $elm$core$Maybe$Just(4);
								default:
									return $elm$core$Maybe$Nothing;
							}
						}());
				},
				A2($elm$url$Url$Parser$slash, root, $elm$url$Url$Parser$string)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Types$InternetPage($elm$core$Maybe$Nothing),
				root)
			]));
}();
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$routes = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Types$LandingPage, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$NewsPage,
			$elm$url$Url$Parser$s('news')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$ContactPage,
			$elm$url$Url$Parser$s('contact')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$DigitalPage,
			$elm$url$Url$Parser$s('digital')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$MintEngiadinaPage,
			$elm$url$Url$Parser$s('mint')),
			$author$project$Route$parseInternetRoute,
			$author$project$Route$parseCoworkingRoute,
			$author$project$Route$parseCommunityRoute,
			$author$project$Route$parseAboutRoute,
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$StatutenPage,
			$elm$url$Url$Parser$s('statuten')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$NewsletterPage,
			$elm$url$Url$Parser$s('newsletter')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$GvAnmeldungPage,
			$elm$url$Url$Parser$s('gv-anmeldung')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$BewertungMountainHubScuolPage,
			$elm$url$Url$Parser$s('bewertung-mountain-hub-scuol')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$AnmeldungMountainHubScuolPage,
			$elm$url$Url$Parser$s('anmeldung-mountain-hub-scuol')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$GutscheinCoworkingPage,
			$elm$url$Url$Parser$s('gutschein-coworking')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$BewertungInternetPage,
			$elm$url$Url$Parser$s('bewertung-internet')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$ArbeitenInDenBergenPage,
			$elm$url$Url$Parser$s('arbeiten-in-den-bergen')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$BewertungCoworking,
			$elm$url$Url$Parser$s('bewertung-coworking')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$WimmelbildPage,
			$elm$url$Url$Parser$s('wimmelbild'))
		]));
var $author$project$Main$init = F3(
	function (flags, url, navKey) {
		var audience = A2(
			$elm$core$Maybe$withDefault,
			0,
			$author$project$Audience$fromString(flags.cZ));
		var model = function (m) {
			return _Utils_update(
				m,
				{
					gV: navKey,
					cz: flags.cz,
					ee: A2(
						$elm$core$Maybe$withDefault,
						m.ee,
						A2($elm$url$Url$Parser$parse, $author$project$Route$routes, url)),
					hq: $author$project$Ui$Scale$getScreenSize(flags.eg)
				});
		}(
			A2($author$project$Support$emptyModel, audience, navKey));
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Types$UpdatePrismic,
						$author$project$Api$Prismic$Types$fetchAll(model.a))
					])));
	});
var $author$project$Types$Blur = {$: 8};
var $author$project$Types$Resize = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$Scale$Screen = F2(
	function (width, height) {
		return {b9: height, a7: width};
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {dN: pids, er: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {b1: event, dy: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.dN,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.dy;
		var event = _v0.b1;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.er);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onMouseUp = A2($elm$browser$Browser$Events$on, 0, 'mouseup');
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Main$subscriptions = function (model) {
	return model.fb ? $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Types$Resize,
				$elm$browser$Browser$Events$onResize($author$project$Ui$Scale$Screen)),
				$elm$browser$Browser$Events$onMouseUp(
				$elm$json$Json$Decode$succeed($author$project$Types$Blur))
			])) : A2(
		$elm$core$Platform$Sub$map,
		$author$project$Types$Resize,
		$elm$browser$Browser$Events$onResize($author$project$Ui$Scale$Screen));
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Types$ResetViewportCb = function (a) {
	return {$: 6, a: a};
};
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Update$resetViewport = A2(
	$elm$core$Task$perform,
	$author$project$Types$ResetViewportCb,
	A2($elm$browser$Browser$Dom$setViewport, 0, 0));
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Route$routeToString = function (page) {
	switch (page.$) {
		case 0:
			return A2($elm$url$Url$Builder$absolute, _List_Nil, _List_Nil);
		case 1:
			return A2(
				$elm$url$Url$Builder$absolute,
				_List_fromArray(
					['news']),
				_List_Nil);
		case 8:
			return '/contact';
		case 2:
			return '/digital';
		case 3:
			return '/mint';
		case 4:
			var maybePage = page.a;
			var parts = A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				_List_Nil,
				function (p) {
					return $elm$core$List$singleton(
						function () {
							switch (p) {
								case 0:
									return 'private';
								case 1:
									return 'company';
								case 2:
									return 'referenzen';
								case 3:
									return 'information';
								default:
									return 'download';
							}
						}());
				},
				maybePage);
			return A2(
				$elm$url$Url$Builder$absolute,
				A2($elm$core$List$cons, 'internet', parts),
				_List_Nil);
		case 5:
			var maybePage = page.a;
			var parts = A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				_List_Nil,
				function (p) {
					return $elm$core$List$singleton(
						function () {
							switch (p) {
								case 0:
									return 'mountain-hubs';
								case 1:
									return 'mountain-hub-pricing';
								default:
									return 'accommodations';
							}
						}());
				},
				maybePage);
			return A2(
				$elm$url$Url$Builder$absolute,
				A2($elm$core$List$cons, 'mountain-coworking', parts),
				_List_Nil);
		case 6:
			var maybePage = page.a;
			var parts = A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				_List_Nil,
				function (p) {
					switch (p.$) {
						case 0:
							return _List_fromArray(
								['membership']);
						case 1:
							return _List_fromArray(
								['partnership']);
						case 2:
							return _List_fromArray(
								['education']);
						default:
							var maybeSlug = p.a;
							return A2(
								$elm$core$List$cons,
								'events',
								A3($elm_community$maybe_extra$Maybe$Extra$unwrap, _List_Nil, $elm$core$List$singleton, maybeSlug));
					}
				},
				maybePage);
			return A2(
				$elm$url$Url$Builder$absolute,
				A2($elm$core$List$cons, 'community', parts),
				_List_Nil);
		case 7:
			var maybePage = page.a;
			var parts = A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				_List_Nil,
				function (p) {
					return $elm$core$List$singleton(
						function () {
							switch (p) {
								case 0:
									return 'team';
								case 1:
									return 'media';
								case 2:
									return 'media-releases';
								case 3:
									return 'miaengiadina-partner';
								default:
									return 'organisation';
							}
						}());
				},
				maybePage);
			return A2(
				$elm$url$Url$Builder$absolute,
				A2($elm$core$List$cons, 'about', parts),
				_List_Nil);
		case 9:
			return '/statuten';
		case 10:
			return '/newsletter';
		case 11:
			return '/gv-anmeldung';
		case 12:
			return '/bewertung-mountain-hub-scuol';
		case 13:
			return '/anmeldung-mountain-hub-scuol';
		case 14:
			return '/anmeldung-innhub';
		case 15:
			return '/gutschein-coworking';
		case 16:
			return '/bewertung-internet';
		case 17:
			return '/arbeiten-in-den-bergen';
		case 18:
			return '/bewertung-coworking';
		default:
			return '/wimmelbild';
	}
};
var $author$project$Update$setPrivacyAgreed = _Platform_outgoingPort('setPrivacyAgreed', $elm$core$Basics$identity);
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.d5;
		switch (_v0.$) {
			case 0:
				return 'http://';
			case 1:
				return 'https://';
			default:
				var scheme = _v0.a;
				return scheme + '://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.dl,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.he,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.dO,
					_Utils_ap(http, url.dr)),
				url.dM)));
};
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $stoeffel$set_extra$Set$Extra$toggle = F2(
	function (elem, set) {
		return A2($elm$core$Set$member, elem, set) ? A2($elm$core$Set$remove, elem, set) : A2($elm$core$Set$insert, elem, set);
	});
var $author$project$Api$Prismic$Types$appendError = F2(
	function (err, model) {
		return _Utils_update(
			model,
			{
				bq: A2($elm$core$List$cons, err, model.bq)
			});
	});
var $author$project$Prismic$cache = F2(
	function (_v0, _v1) {
		var model1 = _v0;
		var model2 = _v1;
		return _Utils_update(
			model2,
			{
				ad: A2($elm$core$Dict$union, model2.ad, model1.ad)
			});
	});
var $author$project$Api$Prismic$Types$errorToString = function (err) {
	switch (err.$) {
		case 0:
			var str = err.a;
			return 'Form does not exist -> ' + str;
		case 1:
			var str = err.a;
			return 'Ref does not exist -> ' + str;
		case 2:
			var str = err.a;
			return 'Bookmark does not exist -> ' + str;
		case 5:
			var str = err.a;
			return 'Decode Document Error -> ' + str;
		case 3:
			return 'Fetch Api Error';
		default:
			return 'Submit Request Error';
	}
};
var $author$project$Api$Prismic$Types$handleResult = F4(
	function (result_, model, updateErr, updateOk) {
		if (!result_.$) {
			var _v1 = result_.a;
			var prismic = _v1.a;
			var result = _v1.b;
			return _Utils_Tuple2(
				A2(
					updateOk,
					_Utils_Tuple2(prismic, result),
					model),
				$elm$core$Platform$Cmd$none);
		} else {
			var info = result_.a;
			return _Utils_Tuple2(
				A2(
					updateErr,
					$author$project$Api$Prismic$Types$errorToString(info),
					model),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Api$Prismic$Types$updateDocuments = F2(
	function (transform, model) {
		return _Utils_update(
			model,
			{
				bo: transform(model.bo)
			});
	});
var $author$project$Api$Prismic$Types$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v1, m) {
							var prismic = _v1.a;
							var r = _v1.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bM: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 1:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v2, m) {
							var prismic = _v2.a;
							var r = _v2.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bN: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 2:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v3, m) {
							var prismic = _v3.a;
							var r = _v3.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bT: $elm$core$Maybe$Just(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 3:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v4, m) {
							var prismic = _v4.a;
							var r = _v4.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bU: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 4:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v5, m) {
							var prismic = _v5.a;
							var r = _v5.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bV: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 5:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v6, m) {
							var prismic = _v6.a;
							var r = _v6.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bY: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 6:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v7, m) {
							var prismic = _v7.a;
							var r = _v7.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bX: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 7:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v8, m) {
							var prismic = _v8.a;
							var r = _v8.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											bZ: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 8:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v9, m) {
							var prismic = _v9.a;
							var r = _v9.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											b0: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 9:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v10, m) {
							var prismic = _v10.a;
							var r = _v10.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											b1: $elm$core$Maybe$Just(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 10:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v11, m) {
							var prismic = _v11.a;
							var r = _v11.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											ce: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 11:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v12, m) {
							var prismic = _v12.a;
							var r = _v12.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cg: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 12:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v13, m) {
							var prismic = _v13.a;
							var r = _v13.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cf: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 14:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v14, m) {
							var prismic = _v14.a;
							var r = _v14.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											ch: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 13:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v15, m) {
							var prismic = _v15.a;
							var r = _v15.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cd: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 15:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v16, m) {
							var prismic = _v16.a;
							var r = _v16.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											ci: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 16:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v17, m) {
							var prismic = _v17.a;
							var r = _v17.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cj: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 17:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v18, m) {
							var prismic = _v18.a;
							var r = _v18.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cl: $elm$core$Maybe$Just(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 18:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v19, m) {
							var prismic = _v19.a;
							var r = _v19.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cB: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 19:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v20, m) {
							var prismic = _v20.a;
							var r = _v20.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cN: $elm$core$Maybe$Just(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			case 20:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v21, m) {
							var prismic = _v21.a;
							var r = _v21.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											co: $elm$core$Maybe$Just(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
			default:
				var result = msg.a;
				return A4(
					$author$project$Api$Prismic$Types$handleResult,
					result,
					model,
					$author$project$Api$Prismic$Types$appendError,
					F2(
						function (_v22, m) {
							var prismic = _v22.a;
							var r = _v22.b;
							return A2(
								$author$project$Api$Prismic$Types$updateDocuments,
								function (d) {
									return _Utils_update(
										d,
										{
											cq: $elm$core$List$head(r.f)
										});
								},
								_Utils_update(
									m,
									{
										a: A2($author$project$Prismic$cache, model.a, prismic)
									}));
						}));
		}
	});
var $author$project$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 3:
				var route = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$browser$Browser$Navigation$pushUrl,
						model.gV,
						$author$project$Route$routeToString(route)));
			case 5:
				return _Utils_Tuple2(model, $author$project$Update$resetViewport);
			case 6:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				var screen = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hq: $author$project$Ui$Scale$getScreenSize(screen)
						}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cz: $elm$core$Maybe$Just(bool)
						}),
					$author$project$Update$setPrivacyAgreed(
						$elm$json$Json$Encode$bool(bool)));
			case 0:
				var url = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ee: A2(
								$elm$core$Maybe$withDefault,
								model.ee,
								A2($elm$url$Url$Parser$parse, $author$project$Route$routes, url))
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var teamMemberKey = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							g5: A2($stoeffel$set_extra$Set$Extra$toggle, teamMemberKey, model.g5)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.gV,
							$elm$url$Url$toString(url)));
				} else {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(url));
				}
			case 7:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hY: id}),
					$elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(
					model.fb ? _Utils_update(
						model,
						{fb: false, fA: false, gR: false}) : model,
					$elm$core$Platform$Cmd$none);
			case 10:
				var index = msg.a;
				var oldTestimonials = model.hZ;
				var newTestimonials = _Utils_update(
					oldTestimonials,
					{ei: index});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hZ: newTestimonials}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fb: true, fA: true}),
					$elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fb: true, gR: true}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var subMsg = msg.a;
				var _v2 = A2($author$project$Api$Prismic$Types$update, subMsg, model.a);
				var prismic = _v2.a;
				var cmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a: prismic}),
					cmd);
			case 14:
				var ref = msg.a;
				var _v3 = _Utils_Tuple2(ref.dA, ref.ik);
				if (((_v3.a === 'homepage') && (!_v3.b.$)) && (_v3.b.a === 'homepage')) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ee: $author$project$Types$LandingPage}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{g6: !model.g6}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var route = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{g6: !model.g6}),
					A2(
						$elm$browser$Browser$Navigation$pushUrl,
						model.gV,
						$author$project$Route$routeToString(route)));
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{by: !model.by}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Types$OpenMenuMsg = {$: 15};
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $elm$core$String$toLower = _String_toLower;
var $elm$core$String$words = _String_words;
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.gU;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			$mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Element$Font$typeface = $mdgriffith$elm_ui$Internal$Model$Typeface;
var $author$project$Ui$Font$typeface = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$Font$typeface,
	A2($elm$core$Basics$composeR, $elm$core$List$singleton, $mdgriffith$elm_ui$Element$Font$family));
var $author$project$Ui$Font$blissProRegular = $author$project$Ui$Font$typeface('BlissPro Regular');
var $author$project$Types$SetPrivacyAgreed = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Bottom = 2;
var $mdgriffith$elm_ui$Element$alignBottom = $mdgriffith$elm_ui$Internal$Model$AlignY(2);
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var $mdgriffith$elm_ui$Internal$Model$asEl = 2;
var $mdgriffith$elm_ui$Internal$Style$classes = {eN: 'a', bO: 'atv', eP: 'ab', eQ: 'cx', eR: 'cy', eS: 'acb', eT: 'accx', eU: 'accy', eV: 'acr', cW: 'al', cX: 'ar', eW: 'at', bP: 'ah', bQ: 'av', e$: 's', e8: 'bh', e9: 'b', fd: 'w7', ff: 'bd', fg: 'bdt', bd: 'bn', fh: 'bs', be: 'cpe', fs: 'cp', ft: 'cpx', fu: 'cpy', S: 'c', bg: 'ctr', bk: 'cb', bl: 'ccx', T: 'ccy', aT: 'cl', bm: 'cr', fy: 'ct', fC: 'cptr', fD: 'ctxt', fZ: 'fcs', dj: 'focus-within', f_: 'fs', f$: 'g', b4: 'hbh', ca: 'hc', $7: 'he', cb: 'hf', dp: 'hfp', f4: 'hv', f9: 'ic', gg: 'fr', bx: 'lbl', gk: 'iml', gl: 'imlf', gm: 'imlp', gn: 'implw', go: 'it', gv: 'i', gB: 'lnk', aG: 'nb', dF: 'notxt', g0: 'ol', g1: 'or', as: 'oq', g8: 'oh', cv: 'pg', cw: 'p', g9: 'ppe', ec: 'ui', hn: 'r', hr: 'sb', hs: 'sbx', ht: 'sby', hu: 'sbt', hz: 'e', hA: 'cap', hG: 'sev', hP: 'sk', eu: 't', h_: 'tc', h$: 'w8', h0: 'w2', h1: 'w9', h2: 'tj', bI: 'tja', h3: 'tl', h4: 'w3', h5: 'w5', h6: 'w4', h7: 'tr', h8: 'w6', h9: 'w1', ia: 'tun', eD: 'ts', aw: 'clr', il: 'u', cR: 'wc', eK: 'we', cS: 'wf', eL: 'wfp', cT: 'wrp'};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.S);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f$);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cv);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cw);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hn);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hz);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var $mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.ho)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.fx)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.hH.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.hH.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.hn) + ('-' + ($elm$core$String$fromInt(pos.c7) + ('-' + ($elm$core$String$fromInt(pos.a7) + ('-' + $elm$core$String$fromInt(pos.b9)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.dv ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.aH.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.aH.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.fb) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.aI) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.aB))
				])));
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dj) + ':focus-within',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fe),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.e1),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										fb: shadow.fb,
										aB: shadow.aB,
										dv: false,
										aH: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.aH)),
										aI: shadow.aI
									}));
						},
						focus.hx),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					]))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + ':focus .focusable, ') + (($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + '.focusable:focus, ') + ('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + ' .focusable-thumb'))),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fe),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.e1),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										fb: shadow.fb,
										aB: shadow.aB,
										dv: false,
										aH: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.aH)),
										aI: shadow.aI
									}));
						},
						focus.hx),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					])))
		]);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Style$AllChildren = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = 3;
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = 2;
var $mdgriffith$elm_ui$Internal$Style$Self = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var $mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var $mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var $mdgriffith$elm_ui$Internal$Style$Top = 0;
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fy);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bk);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bm);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aT);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bl);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.T);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eW);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eP);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cX);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cW);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eQ);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eR);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b4),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hu),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eu),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cS),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ca),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cS),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eL),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cR),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = 0;
var $mdgriffith$elm_ui$Internal$Style$Behind = 5;
var $mdgriffith$elm_ui$Internal$Style$Below = 1;
var $mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var $mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var $mdgriffith$elm_ui$Internal$Style$Within = 4;
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _v0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hz),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f9))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-height', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cS),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ec),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gg),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aG),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aG),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hz),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eN),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cS),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e9),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g1),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g0),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gg),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e8),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cT),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dF),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fC),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fD),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g9),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.be),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aw),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.as),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.f4, $mdgriffith$elm_ui$Internal$Style$classes.aw)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.f4, $mdgriffith$elm_ui$Internal$Style$classes.as)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.fZ, $mdgriffith$elm_ui$Internal$Style$classes.aw)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.fZ, $mdgriffith$elm_ui$Internal$Style$classes.as)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.bO, $mdgriffith$elm_ui$Internal$Style$classes.aw)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.bO, $mdgriffith$elm_ui$Internal$Style$classes.as)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eD),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hr),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hs),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hn),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ht),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.S),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hz),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fs),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ft),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cR),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bd),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fg),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fh),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.go),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background', 'transparent'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'inherit')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hz),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hn),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eK),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gB),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dp),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cS),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bg),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eV,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eT,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eQ),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eT,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eQ),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eT,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eR),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.eT + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.eV + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.eT)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hG),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bx),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'baseline')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.S),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0px'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', 'min-content'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.$7),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cS),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eL),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cR),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eS,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eU,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eR),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eU,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eR),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.eU,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eR),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.eU + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.eS + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.eU)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bg),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hG),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f$),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$ + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.e$ + ($mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.e$))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.e$ + ($mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.e$))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gk),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background-color', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gn),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hz),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gm),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gl),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'transparent')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cw),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-wrap', 'break-word'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b4),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eu),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cw),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::after',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::before',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hz),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eK),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gg),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eN),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e9),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g1),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g0),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eu),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hn),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.S),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h9),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h0),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h4),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h6),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h5),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fd),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h$),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h1),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hP),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.il),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.il),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hP)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ia),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h2),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bI),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h_),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h7),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.e$ + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.e$ + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$inputTextReset = '\ninput[type="search"],\ninput[type="search"]::-webkit-search-decoration,\ninput[type="search"]::-webkit-search-cancel-button,\ninput[type="search"]::-webkit-search-results-button,\ninput[type="search"]::-webkit-search-results-decoration {\n  -webkit-appearance:none;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$sliderReset = '\ninput[type=range] {\n  -webkit-appearance: none; \n  background: transparent;\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$thumbReset = '\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$trackReset = '\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hn) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hn) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bg) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$inputTextReset + ($mdgriffith$elm_ui$Internal$Style$sliderReset + ($mdgriffith$elm_ui$Internal$Style$trackReset + ($mdgriffith$elm_ui$Internal$Style$thumbReset + $mdgriffith$elm_ui$Internal$Style$explainer)))))))))))))));
var $mdgriffith$elm_ui$Internal$Style$Intermediate = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {bf: closing, v: _List_Nil, X: _List_Nil, M: selector};
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								X: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.X)
							});
					case 3:
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								v: A2(
									$elm$core$List$cons,
									{bf: '\n}', v: _List_Nil, X: props, M: '@supports (' + (prop + (':' + (value + (') {' + parent.M))))},
									rendered.v)
							});
					case 5:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								v: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.M + (' + ' + selector), ''),
										adjRules),
									rendered.v)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								v: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.M + (' > ' + child), ''),
										childRules),
									rendered.v)
							});
					case 2:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								v: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.M + (' ' + child), ''),
										childRules),
									rendered.v)
							});
					case 4:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								v: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.M, descriptor),
											''),
										descriptorRules),
									rendered.v)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								v: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.M, ''),
										batched),
									rendered.v)
							});
				}
			});
		return A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.X;
		if (!_v2.b) {
			return '';
		} else {
			return rule.M + ('{' + (renderValues(rule.X) + (rule.bf + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.v)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $mdgriffith$elm_ui$Internal$Model$staticRoot = function (opts) {
	var _v0 = opts.gO;
	switch (_v0) {
		case 0:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'div',
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$virtual_dom$VirtualDom$node,
						'style',
						_List_Nil,
						_List_fromArray(
							[
								$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
							]))
					]));
		case 1:
			return $elm$virtual_dom$VirtualDom$text('');
		default:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'elm-ui-static-rules',
				_List_fromArray(
					[
						A2(
						$elm$virtual_dom$VirtualDom$property,
						'rules',
						$elm$json$Json$Encode$string($mdgriffith$elm_ui$Internal$Style$rules))
					]),
				_List_Nil);
	}
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.gU;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.eE);
	} else {
		return false;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$renderStyle = F4(
	function (options, maybePseudo, selector, props) {
		if (maybePseudo.$ === 1) {
			return _List_fromArray(
				[
					selector + ('{' + (A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$renderProps(false),
					'',
					props) + '\n}'))
				]);
		} else {
			var pseudo = maybePseudo.a;
			switch (pseudo) {
				case 1:
					var _v2 = options.f4;
					switch (_v2) {
						case 0:
							return _List_Nil;
						case 2:
							return _List_fromArray(
								[
									selector + ('-hv {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(true),
									'',
									props) + '\n}'))
								]);
						default:
							return _List_fromArray(
								[
									selector + ('-hv:hover {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(false),
									'',
									props) + '\n}'))
								]);
					}
				case 0:
					var renderedProps = A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props);
					return _List_fromArray(
						[
							selector + ('-fs:focus {' + (renderedProps + '\n}')),
							('.' + ($mdgriffith$elm_ui$Internal$Style$classes.e$ + (':focus ' + (selector + '-fs  {')))) + (renderedProps + '\n}'),
							(selector + '-fs:focus-within {') + (renderedProps + '\n}'),
							('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e$) + (' .focusable-thumb' + (selector + '-fs {')))) + (renderedProps + '\n}')
						]);
				default:
					return _List_fromArray(
						[
							selector + ('-act:active {' + (A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$renderProps(false),
							'',
							props) + '\n}'))
						]);
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.eE)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderStyleRule = F3(
	function (options, rule, maybePseudo) {
		switch (rule.$) {
			case 0:
				var selector = rule.a;
				var props = rule.b;
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, selector, props);
			case 13:
				var name = rule.a;
				var prop = rule.b;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
						]));
			case 12:
				var name = rule.a;
				var transparency = rule.b;
				var opacity = A2(
					$elm$core$Basics$max,
					0,
					A2($elm$core$Basics$min, 1, 1 - transparency));
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'opacity',
							$elm$core$String$fromFloat(opacity))
						]));
			case 2:
				var i = rule.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			case 1:
				var name = rule.a;
				var typefaces = rule.b;
				var features = A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
				var families = _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-family',
						A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-variant',
						A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
					]);
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, '.' + name, families);
			case 3:
				var _class = rule.a;
				var prop = rule.b;
				var val = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
						]));
			case 4:
				var _class = rule.a;
				var prop = rule.b;
				var color = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							prop,
							$mdgriffith$elm_ui$Internal$Model$formatColor(color))
						]));
			case 5:
				var cls = rule.a;
				var x = rule.b;
				var y = rule.c;
				var yPx = $elm$core$String$fromInt(y) + 'px';
				var xPx = $elm$core$String$fromInt(x) + 'px';
				var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.hz;
				var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.hn;
				var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.cT + row);
				var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.cX;
				var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.cw;
				var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.cv;
				var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.cW;
				var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
				var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
				var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.S;
				var _class = '.' + cls;
				var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.e$;
				return $elm$core$List$concat(
					_List_fromArray(
						[
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (row + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (wrappedRow + (' > ' + any)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (column + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_Utils_ap(_class, paragraph),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							'textarea' + (any + _class),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)')),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'height',
									'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::after'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-top',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::before'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-bottom',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								]))
						]));
			case 7:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'padding',
							$elm$core$String$fromFloat(top) + ('px ' + ($elm$core$String$fromFloat(right) + ('px ' + ($elm$core$String$fromFloat(bottom) + ('px ' + ($elm$core$String$fromFloat(left) + 'px')))))))
						]));
			case 6:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-width',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 8:
				var template = rule.a;
				var toGridLengthHelper = F3(
					function (minimum, maximum, x) {
						toGridLengthHelper:
						while (true) {
							switch (x.$) {
								case 0:
									var px = x.a;
									return $elm$core$String$fromInt(px) + 'px';
								case 1:
									var _v2 = _Utils_Tuple2(minimum, maximum);
									if (_v2.a.$ === 1) {
										if (_v2.b.$ === 1) {
											var _v3 = _v2.a;
											var _v4 = _v2.b;
											return 'max-content';
										} else {
											var _v6 = _v2.a;
											var maxSize = _v2.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v2.b.$ === 1) {
											var minSize = _v2.a.a;
											var _v5 = _v2.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
										} else {
											var minSize = _v2.a.a;
											var maxSize = _v2.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 2:
									var i = x.a;
									var _v7 = _Utils_Tuple2(minimum, maximum);
									if (_v7.a.$ === 1) {
										if (_v7.b.$ === 1) {
											var _v8 = _v7.a;
											var _v9 = _v7.b;
											return $elm$core$String$fromInt(i) + 'fr';
										} else {
											var _v11 = _v7.a;
											var maxSize = _v7.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v7.b.$ === 1) {
											var minSize = _v7.a.a;
											var _v10 = _v7.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
										} else {
											var minSize = _v7.a.a;
											var maxSize = _v7.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 3:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = $elm$core$Maybe$Just(m),
										$temp$maximum = maximum,
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
								default:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = minimum,
										$temp$maximum = $elm$core$Maybe$Just(m),
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
							}
						}
					});
				var toGridLength = function (x) {
					return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
				};
				var xSpacing = toGridLength(template.hH.a);
				var ySpacing = toGridLength(template.hH.b);
				var rows = function (x) {
					return 'grid-template-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.ho)));
				var msRows = function (x) {
					return '-ms-grid-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.fx)));
				var msColumns = function (x) {
					return '-ms-grid-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.fx)));
				var gapY = 'grid-row-gap:' + (toGridLength(template.hH.b) + ';');
				var gapX = 'grid-column-gap:' + (toGridLength(template.hH.a) + ';');
				var columns = function (x) {
					return 'grid-template-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.fx)));
				var _class = '.grid-rows-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.ho)) + ('-cols-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.fx)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.hH.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.hH.b)))))));
				var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msColumns + (msRows + '}')));
				return _List_fromArray(
					[base, supports]);
			case 9:
				var position = rule.a;
				var msPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'-ms-grid-row: ' + ($elm$core$String$fromInt(position.hn) + ';'),
							'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.b9) + ';'),
							'-ms-grid-column: ' + ($elm$core$String$fromInt(position.c7) + ';'),
							'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.a7) + ';')
						]));
				var modernPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'grid-row: ' + ($elm$core$String$fromInt(position.hn) + (' / ' + ($elm$core$String$fromInt(position.hn + position.b9) + ';'))),
							'grid-column: ' + ($elm$core$String$fromInt(position.c7) + (' / ' + ($elm$core$String$fromInt(position.c7 + position.a7) + ';')))
						]));
				var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.hn) + ('-' + ($elm$core$String$fromInt(position.c7) + ('-' + ($elm$core$String$fromInt(position.a7) + ('-' + $elm$core$String$fromInt(position.b9)))))));
				var modernGrid = _class + ('{' + (modernPosition + '}'));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msPosition + '}'));
				return _List_fromArray(
					[base, supports]);
			case 11:
				var _class = rule.a;
				var styles = rule.b;
				var renderPseudoRule = function (style) {
					return A3(
						$mdgriffith$elm_ui$Internal$Model$renderStyleRule,
						options,
						style,
						$elm$core$Maybe$Just(_class));
				};
				return A2($elm$core$List$concatMap, renderPseudoRule, styles);
			default:
				var transform = rule.a;
				var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
				var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				var _v12 = _Utils_Tuple2(_class, val);
				if ((!_v12.a.$) && (!_v12.b.$)) {
					var cls = _v12.a.a;
					var v = _v12.b.a;
					return A4(
						$mdgriffith$elm_ui$Internal$Model$renderStyle,
						options,
						maybePseudo,
						'.' + cls,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
							]));
				} else {
					return _List_Nil;
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$encodeStyles = F2(
	function (options, stylesheet) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (style) {
					var styled = A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing);
					return _Utils_Tuple2(
						$mdgriffith$elm_ui$Internal$Model$getStyleName(style),
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, styled));
				},
				stylesheet));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.eu + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.eu)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.hA, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.f_, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.hA + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.hA))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.hA + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.eu + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.hA + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.eu)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {b9: height / size, aI: size, eF: vertical};
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.fn, adjustment.e7, adjustment.fJ, adjustment.gI]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.fJ,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.e7,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.fn,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		fn: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		dm: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.b9)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.eF) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.aI) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _v2 = _with.eO;
						if (_v2.$ === 1) {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.dm;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.fn;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 1) {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var combine = F2(
			function (style, rendered) {
				return {
					bG: _Utils_ap(
						rendered.bG,
						A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing)),
					a5: function () {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v1.$ === 1) {
							return rendered.a5;
						} else {
							var topLevel = _v1.a;
							return A2($elm$core$List$cons, topLevel, rendered.a5);
						}
					}()
				};
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			combine,
			{bG: _List_Nil, a5: _List_Nil},
			stylesheet);
		var topLevel = _v0.a5;
		var rules = _v0.bG;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			$elm$core$String$concat(rules));
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		var _v0 = options.gO;
		switch (_v0) {
			case 0:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			case 1:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			default:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'elm-ui-rules',
					_List_fromArray(
						[
							A2(
							$elm$virtual_dom$VirtualDom$property,
							'rules',
							A2($mdgriffith$elm_ui$Internal$Model$encodeStyles, options, styleSheet))
						]),
					_List_Nil);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.fZ)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'static-stylesheet',
				$mdgriffith$elm_ui$Internal$Model$staticRoot(opts)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.fZ)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot(opts),
			A2($elm$core$List$cons, dynamicStyleSheet, children)) : A2($elm$core$List$cons, dynamicStyleSheet, children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$core$Bitwise$and = _Bitwise_and;
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hz))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.e$, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.bg, $mdgriffith$elm_ui$Internal$Style$classes.T, $mdgriffith$elm_ui$Internal$Style$classes.eV])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.e$, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.bg, $mdgriffith$elm_ui$Internal$Style$classes.T, $mdgriffith$elm_ui$Internal$Style$classes.eT])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.e$, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.bg, $mdgriffith$elm_ui$Internal$Style$classes.eU])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.e$, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.bg, $mdgriffith$elm_ui$Internal$Style$classes.eS])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $mdgriffith$elm_ui$Internal$Model$textElementClasses = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.eu + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.cR + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ca)))));
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFillClasses = $mdgriffith$elm_ui$Internal$Style$classes.e$ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.eu + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.cS + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cb)))));
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementFillClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.f6, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hT : _Utils_ap(styled.hT, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.f6, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hT : _Utils_ap(styled.hT, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.f6, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hT : _Utils_ap(styled.hT, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.f6, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hT : _Utils_ap(styled.hT, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.hT : _Utils_ap(rendered.hT, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.af,
						rendered.ar,
						rendered.ba,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.fr)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						f6: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.af,
							rendered.ar,
							rendered.ba,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.fr))),
						hT: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.hT : _Utils_ap(rendered.hT, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.af,
						rendered.ar,
						rendered.ba,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.fr)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						f6: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.af,
							rendered.ar,
							rendered.ba,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.fr))),
						hT: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.aG, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.eN]));
							case 1:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.aG, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.e9]));
							case 2:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.aG, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.g1]));
							case 3:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.aG, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.g0]));
							case 4:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.aG, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.gg]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.aG, $mdgriffith$elm_ui$Internal$Style$classes.hz, $mdgriffith$elm_ui$Internal$Style$classes.e8]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return $elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.f6, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.bP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cW);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.bP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cX);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.bP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eQ);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.bQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eW);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.bQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eP);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.bQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eR);
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.$7 + (' ' + name),
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.ca,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.cb,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.dp + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.e$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.S + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px !important');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.eK + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.cR,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.cS,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.eL + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.e$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.hn + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 1) {
					return {
						ba: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						fr: children,
						af: has,
						ar: node,
						hT: styles
					};
				} else {
					var _class = _v1.a;
					return {
						ba: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						fr: children,
						af: has,
						ar: node,
						hT: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.eK + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cR),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cS),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.eL + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.e$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.hn + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.$7 + (' ' + (name + (' ' + classes))),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.ca + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.cb + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dp + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.e$ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.S + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.hT);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $mdgriffith$elm_ui$Element$Input$onKeyLookup = function (lookup) {
	var decode = function (code) {
		var _v0 = lookup(code);
		if (_v0.$ === 1) {
			return $elm$json$Json$Decode$fail('No key matched');
		} else {
			var msg = _v0.a;
			return $elm$json$Json$Decode$succeed(msg);
		}
	};
	var isKey = A2(
		$elm$json$Json$Decode$andThen,
		decode,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2(
			$elm$html$Html$Events$preventDefaultOn,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				function (fired) {
					return _Utils_Tuple2(fired, true);
				},
				isKey)));
};
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.fC);
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $mdgriffith$elm_ui$Element$Input$space = ' ';
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.dK;
		var label = _v0.gw;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bl + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.T + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.hu + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dF)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onKeyLookup(
															function (code) {
																return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(msg) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(msg) : $elm$core$Maybe$Nothing);
															}),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Element$rgb255 = F3(
	function (red, green, blue) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, 1);
	});
var $author$project$Ui$Color$gray = A3($mdgriffith$elm_ui$Element$rgb255, 85, 89, 92);
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$padding = function (x) {
	var f = x;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			f,
			f,
			f,
			f));
};
var $mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left)))))));
	});
var $mdgriffith$elm_ui$Element$paddingEach = function (_v0) {
	var top = _v0.O;
	var right = _v0.L;
	var bottom = _v0.F;
	var left = _v0.H;
	if (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) {
		var topFloat = top;
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + $elm$core$String$fromInt(top),
				topFloat,
				topFloat,
				topFloat,
				topFloat));
	} else {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				A4($mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
				top,
				right,
				bottom,
				left));
	}
};
var $mdgriffith$elm_ui$Internal$Model$Paragraph = {$: 9};
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$paragraph = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asParagraph,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Paragraph),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$spacing(5),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $author$project$Ui$Color$red = A3($mdgriffith$elm_ui$Element$rgb255, 200, 16, 46);
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $author$project$Ui$Color$white = A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
var $author$project$View$cookieNotification = function (scale) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$alignBottom,
				function () {
				switch (scale) {
					case 0:
						return $mdgriffith$elm_ui$Element$paddingEach(
							{F: 60, H: 0, L: 0, O: 0});
					case 1:
						return $mdgriffith$elm_ui$Element$paddingEach(
							{F: 60, H: 0, L: 0, O: 0});
					case 2:
						return $mdgriffith$elm_ui$Element$paddingEach(
							{F: 0, H: 0, L: 0, O: 0});
					default:
						return $mdgriffith$elm_ui$Element$paddingEach(
							{F: 0, H: 0, L: 0, O: 0});
				}
			}()
			]),
		A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$mdgriffith$elm_ui$Element$padding(15),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					function () {
					switch (scale) {
						case 0:
							return $mdgriffith$elm_ui$Element$Font$size(16);
						case 1:
							return $mdgriffith$elm_ui$Element$Font$size(16);
						case 2:
							return $mdgriffith$elm_ui$Element$Font$size(18);
						default:
							return $mdgriffith$elm_ui$Element$Font$size(18);
					}
				}()
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Wir verwenden datenschutzfreundliche Technologie, um statistische Auswertungen der Seitennutzung zu erhalten.'),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{F: 0, H: 15, L: 0, O: 0}),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('Zustimmen'),
						dK: $elm$core$Maybe$Just(
							$author$project$Types$SetPrivacyAgreed(true))
					})
				])));
};
var $mdgriffith$elm_ui$Internal$Model$FocusStyleOption = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Element$focusStyle = $mdgriffith$elm_ui$Internal$Model$FocusStyleOption;
var $mdgriffith$elm_ui$Internal$Model$InFront = 4;
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$createNearby = F2(
	function (loc, element) {
		if (element.$ === 3) {
			return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
		} else {
			return A2($mdgriffith$elm_ui$Internal$Model$Nearby, loc, element);
		}
	});
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 4, element);
};
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var $mdgriffith$elm_ui$Internal$Model$Layout = 0;
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	e1: $elm$core$Maybe$Nothing,
	fe: $elm$core$Maybe$Nothing,
	hx: $elm$core$Maybe$Just(
		{
			fb: 0,
			aB: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			aH: _Utils_Tuple2(0, 0),
			aI: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _v4 = record.f4;
					if (_v4.$ === 1) {
						return _Utils_update(
							record,
							{
								f4: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _v5 = record.fZ;
					if (_v5.$ === 1) {
						return _Utils_update(
							record,
							{
								fZ: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.gO;
					if (_v6.$ === 1) {
						return _Utils_update(
							record,
							{
								gO: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			fZ: function () {
				var _v0 = record.fZ;
				if (_v0.$ === 1) {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			f4: function () {
				var _v1 = record.f4;
				if (_v1.$ === 1) {
					return 1;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			gO: function () {
				var _v2 = record.gO;
				if (_v2.$ === 1) {
					return 0;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{fZ: $elm$core$Maybe$Nothing, f4: $elm$core$Maybe$Nothing, gO: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.hT;
				var html = el.a.f6;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.gO;
			if (_v0 === 1) {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.ct;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.ec, $mdgriffith$elm_ui$Internal$Style$classes.e$, $mdgriffith$elm_ui$Internal$Style$classes.hz]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $mdgriffith$elm_ui$Element$Lazy$embed = function (x) {
	switch (x.$) {
		case 0:
			var html = x.a;
			return html;
		case 1:
			var styled = x.a;
			return styled.f6(
				A2(
					$mdgriffith$elm_ui$Internal$Model$OnlyDynamic,
					{
						fZ: {e1: $elm$core$Maybe$Nothing, fe: $elm$core$Maybe$Nothing, hx: $elm$core$Maybe$Nothing},
						f4: 1,
						gO: 0
					},
					styled.hT));
		case 2:
			var text = x.a;
			return $elm$core$Basics$always(
				$elm$virtual_dom$VirtualDom$text(text));
		default:
			return $elm$core$Basics$always(
				$elm$virtual_dom$VirtualDom$text(''));
	}
};
var $mdgriffith$elm_ui$Element$Lazy$apply1 = F2(
	function (fn, a) {
		return $mdgriffith$elm_ui$Element$Lazy$embed(
			fn(a));
	});
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $mdgriffith$elm_ui$Element$Lazy$lazy = F2(
	function (fn, a) {
		return $mdgriffith$elm_ui$Internal$Model$Unstyled(
			A3($elm$virtual_dom$VirtualDom$lazy3, $mdgriffith$elm_ui$Element$Lazy$apply1, fn, a));
	});
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Left = 0;
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX(0);
var $mdgriffith$elm_ui$Internal$Model$Top = 0;
var $mdgriffith$elm_ui$Element$alignTop = $mdgriffith$elm_ui$Internal$Model$AlignY(0);
var $mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX(1);
var $mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY(1);
var $mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.fy + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.aT)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $author$project$FontAwesome$Icon$Presentation = $elm$core$Basics$identity;
var $author$project$FontAwesome$Icon$present = function (icon) {
	return {ba: _List_Nil, ds: icon, f7: $elm$core$Maybe$Nothing, aZ: $elm$core$Maybe$Nothing, cH: 'img', ie: $elm$core$Maybe$Nothing, bK: _List_Nil};
};
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $author$project$FontAwesome$Transforms$Internal$add = F2(
	function (transform, combined) {
		switch (transform.$) {
			case 0:
				var direction = transform.a;
				var amount = function () {
					if (!direction.$) {
						var by = direction.a;
						return by;
					} else {
						var by = direction.a;
						return -by;
					}
				}();
				return _Utils_update(
					combined,
					{aI: combined.aI + amount});
			case 1:
				var direction = transform.a;
				var _v2 = function () {
					switch (direction.$) {
						case 0:
							var by = direction.a;
							return _Utils_Tuple2(0, -by);
						case 1:
							var by = direction.a;
							return _Utils_Tuple2(0, by);
						case 2:
							var by = direction.a;
							return _Utils_Tuple2(-by, 0);
						default:
							var by = direction.a;
							return _Utils_Tuple2(by, 0);
					}
				}();
				var x = _v2.a;
				var y = _v2.b;
				return _Utils_update(
					combined,
					{a8: combined.a8 + x, a9: combined.a9 + y});
			case 2:
				var rotation = transform.a;
				return _Utils_update(
					combined,
					{a3: combined.a3 + rotation});
			default:
				if (!transform.a) {
					var _v4 = transform.a;
					return _Utils_update(
						combined,
						{br: true});
				} else {
					var _v5 = transform.a;
					return _Utils_update(
						combined,
						{bs: true});
				}
		}
	});
var $author$project$FontAwesome$Transforms$Internal$baseSize = 16;
var $author$project$FontAwesome$Transforms$Internal$meaninglessTransform = {br: false, bs: false, a3: 0, aI: $author$project$FontAwesome$Transforms$Internal$baseSize, a8: 0, a9: 0};
var $author$project$FontAwesome$Transforms$Internal$combine = function (transforms) {
	return A3($elm$core$List$foldl, $author$project$FontAwesome$Transforms$Internal$add, $author$project$FontAwesome$Transforms$Internal$meaninglessTransform, transforms);
};
var $author$project$FontAwesome$Transforms$Internal$meaningfulTransform = function (transforms) {
	var combined = $author$project$FontAwesome$Transforms$Internal$combine(transforms);
	return _Utils_eq(combined, $author$project$FontAwesome$Transforms$Internal$meaninglessTransform) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(combined);
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $elm$svg$Svg$title = $elm$svg$Svg$trustedNode('title');
var $author$project$FontAwesome$Icon$titledContents = F3(
	function (titleId, contents, title) {
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$svg$Svg$title,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$id(titleId)
					]),
				_List_fromArray(
					[
						$elm$svg$Svg$text(title)
					])),
			contents);
	});
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $author$project$FontAwesome$Transforms$Internal$transformForSvg = F3(
	function (containerWidth, iconWidth, transform) {
		var path = 'translate(' + ($elm$core$String$fromFloat((iconWidth / 2) * (-1)) + ' -256)');
		var outer = 'translate(' + ($elm$core$String$fromFloat(containerWidth / 2) + ' 256)');
		var innerTranslate = 'translate(' + ($elm$core$String$fromFloat(transform.a8 * 32) + (',' + ($elm$core$String$fromFloat(transform.a9 * 32) + ') ')));
		var innerRotate = 'rotate(' + ($elm$core$String$fromFloat(transform.a3) + ' 0 0)');
		var flipY = transform.bs ? (-1) : 1;
		var scaleY = (transform.aI / $author$project$FontAwesome$Transforms$Internal$baseSize) * flipY;
		var flipX = transform.br ? (-1) : 1;
		var scaleX = (transform.aI / $author$project$FontAwesome$Transforms$Internal$baseSize) * flipX;
		var innerScale = 'scale(' + ($elm$core$String$fromFloat(scaleX) + (', ' + ($elm$core$String$fromFloat(scaleY) + ') ')));
		return {
			du: $elm$svg$Svg$Attributes$transform(
				_Utils_ap(
					innerTranslate,
					_Utils_ap(innerScale, innerRotate))),
			aZ: $elm$svg$Svg$Attributes$transform(outer),
			dM: $elm$svg$Svg$Attributes$transform(path)
		};
	});
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$FontAwesome$Icon$allSpace = _List_fromArray(
	[
		$elm$svg$Svg$Attributes$x('0'),
		$elm$svg$Svg$Attributes$y('0'),
		$elm$svg$Svg$Attributes$width('100%'),
		$elm$svg$Svg$Attributes$height('100%')
	]);
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $author$project$FontAwesome$Svg$Internal$corePath = F2(
	function (attrs, d) {
		return A2(
			$elm$svg$Svg$path,
			A2(
				$elm$core$List$cons,
				$elm$svg$Svg$Attributes$fill('currentColor'),
				A2(
					$elm$core$List$cons,
					$elm$svg$Svg$Attributes$d(d),
					attrs)),
			_List_Nil);
	});
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $author$project$FontAwesome$Svg$Internal$corePaths = F2(
	function (attrs, icon) {
		var _v0 = icon.ha;
		if (!_v0.b) {
			return A2($author$project$FontAwesome$Svg$Internal$corePath, attrs, '');
		} else {
			if (!_v0.b.b) {
				var only = _v0.a;
				return A2($author$project$FontAwesome$Svg$Internal$corePath, attrs, only);
			} else {
				var secondary = _v0.a;
				var _v1 = _v0.b;
				var primary = _v1.a;
				return A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('fa-group')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$FontAwesome$Svg$Internal$corePath,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('fa-secondary'),
								attrs),
							secondary),
							A2(
							$author$project$FontAwesome$Svg$Internal$corePath,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('fa-primary'),
								attrs),
							primary)
						]));
			}
		}
	});
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$mask = $elm$svg$Svg$trustedNode('mask');
var $elm$svg$Svg$Attributes$mask = _VirtualDom_attribute('mask');
var $elm$svg$Svg$Attributes$maskContentUnits = _VirtualDom_attribute('maskContentUnits');
var $elm$svg$Svg$Attributes$maskUnits = _VirtualDom_attribute('maskUnits');
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $author$project$FontAwesome$Icon$viewMaskedWithTransform = F4(
	function (id, transforms, inner, outer) {
		var maskInnerGroup = A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[transforms.du]),
			_List_fromArray(
				[
					A2(
					$author$project$FontAwesome$Svg$Internal$corePaths,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$fill('black'),
							transforms.dM
						]),
					inner)
				]));
		var maskId = 'mask-' + (inner.gU + ('-' + id));
		var maskTag = A2(
			$elm$svg$Svg$mask,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$id(maskId),
						$elm$svg$Svg$Attributes$maskUnits('userSpaceOnUse'),
						$elm$svg$Svg$Attributes$maskContentUnits('userSpaceOnUse')
					]),
				$author$project$FontAwesome$Icon$allSpace),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$rect,
					A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$fill('white'),
						$author$project$FontAwesome$Icon$allSpace),
					_List_Nil),
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[transforms.aZ]),
					_List_fromArray(
						[maskInnerGroup]))
				]));
		var clipId = 'clip-' + (outer.gU + ('-' + id));
		var defs = A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$clipPath,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$id(clipId)
						]),
					_List_fromArray(
						[
							A2($author$project$FontAwesome$Svg$Internal$corePaths, _List_Nil, outer)
						])),
					maskTag
				]));
		return _List_fromArray(
			[
				defs,
				A2(
				$elm$svg$Svg$rect,
				$elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								$elm$svg$Svg$Attributes$fill('currentColor'),
								$elm$svg$Svg$Attributes$clipPath('url(#' + (clipId + ')')),
								$elm$svg$Svg$Attributes$mask('url(#' + (maskId + ')'))
							]),
							$author$project$FontAwesome$Icon$allSpace
						])),
				_List_Nil)
			]);
	});
var $author$project$FontAwesome$Icon$viewWithTransform = F2(
	function (transforms, icon) {
		if (!transforms.$) {
			var ts = transforms.a;
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[ts.aZ]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[ts.du]),
						_List_fromArray(
							[
								A2(
								$author$project$FontAwesome$Svg$Internal$corePaths,
								_List_fromArray(
									[ts.dM]),
								icon)
							]))
					]));
		} else {
			return A2($author$project$FontAwesome$Svg$Internal$corePaths, _List_Nil, icon);
		}
	});
var $author$project$FontAwesome$Icon$internalView = function (_v0) {
	var icon = _v0.ds;
	var attributes = _v0.ba;
	var transforms = _v0.bK;
	var role = _v0.cH;
	var id = _v0.f7;
	var title = _v0.ie;
	var outer = _v0.aZ;
	var alwaysId = A2($elm$core$Maybe$withDefault, icon.gU, id);
	var titleId = alwaysId + '-title';
	var semantics = A2(
		$elm$core$Maybe$withDefault,
		A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always(
				A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', titleId)),
			title));
	var _v1 = A2(
		$elm$core$Maybe$withDefault,
		_Utils_Tuple2(icon.a7, icon.b9),
		A2(
			$elm$core$Maybe$map,
			function (o) {
				return _Utils_Tuple2(o.a7, o.b9);
			},
			outer));
	var width = _v1.a;
	var height = _v1.b;
	var classes = _List_fromArray(
		[
			'svg-inline--fa',
			'fa-' + icon.gU,
			'fa-w-' + $elm$core$String$fromInt(
			$elm$core$Basics$ceiling((width / height) * 16))
		]);
	var svgTransform = A2(
		$elm$core$Maybe$map,
		A2($author$project$FontAwesome$Transforms$Internal$transformForSvg, width, icon.a7),
		$author$project$FontAwesome$Transforms$Internal$meaningfulTransform(transforms));
	var contents = function () {
		var resolvedSvgTransform = A2(
			$elm$core$Maybe$withDefault,
			A3($author$project$FontAwesome$Transforms$Internal$transformForSvg, width, icon.a7, $author$project$FontAwesome$Transforms$Internal$meaninglessTransform),
			svgTransform);
		return A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				[
					A2($author$project$FontAwesome$Icon$viewWithTransform, svgTransform, icon)
				]),
			A2(
				$elm$core$Maybe$map,
				A3($author$project$FontAwesome$Icon$viewMaskedWithTransform, alwaysId, resolvedSvgTransform, icon),
				outer));
	}();
	var potentiallyTitledContents = A2(
		$elm$core$Maybe$withDefault,
		contents,
		A2(
			$elm$core$Maybe$map,
			A2($author$project$FontAwesome$Icon$titledContents, titleId, contents),
			title));
	return A2(
		$elm$svg$Svg$svg,
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', role),
						A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
						$elm$svg$Svg$Attributes$viewBox(
						'0 0 ' + ($elm$core$String$fromInt(width) + (' ' + $elm$core$String$fromInt(height)))),
						semantics
					]),
					A2($elm$core$List$map, $elm$svg$Svg$Attributes$class, classes),
					attributes
				])),
		potentiallyTitledContents);
};
var $author$project$FontAwesome$Icon$view = function (presentation) {
	return $author$project$FontAwesome$Icon$internalView(presentation);
};
var $author$project$FontAwesome$Icon$viewIcon = A2($elm$core$Basics$composeR, $author$project$FontAwesome$Icon$present, $author$project$FontAwesome$Icon$view);
var $author$project$Ui$Element$faIcon = A2(
	$elm$core$Basics$composeR,
	$author$project$FontAwesome$Icon$viewIcon,
	A2(
		$elm$core$Basics$composeR,
		$mdgriffith$elm_ui$Element$html,
		$mdgriffith$elm_ui$Element$el(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]))));
var $mdgriffith$elm_ui$Element$htmlAttribute = $mdgriffith$elm_ui$Internal$Model$Attr;
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Ui$Style$inlineStyle = F2(
	function (k, v) {
		return $mdgriffith$elm_ui$Element$htmlAttribute(
			A2($elm$html$Html$Attributes$style, k, v));
	});
var $mdgriffith$elm_ui$Internal$Model$Min = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$minimum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Min, i, l);
	});
var $author$project$FontAwesome$Icon$Icon = F5(
	function (prefix, name, width, height, paths) {
		return {b9: height, gU: name, ha: paths, hc: prefix, a7: width};
	});
var $author$project$FontAwesome$Regular$times = A5(
	$author$project$FontAwesome$Icon$Icon,
	'far',
	'times',
	320,
	512,
	_List_fromArray(
		['M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z']));
var $author$project$Ui$Component$Menu$view = function (config) {
	return config.gN ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Background$color(config.gL),
				A2($author$project$Ui$Style$inlineStyle, 'z-index', '2000'),
				$mdgriffith$elm_ui$Element$inFront(
				A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$alignLeft,
							$mdgriffith$elm_ui$Element$padding(20)
						]),
					{
						gw: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
							$author$project$Ui$Element$faIcon($author$project$FontAwesome$Regular$times)),
						dK: $elm$core$Maybe$Just(config.g6)
					}))
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						A2($mdgriffith$elm_ui$Element$minimum, 120, $mdgriffith$elm_ui$Element$fill)),
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				config.gM))) : $mdgriffith$elm_ui$Element$none;
};
var $author$project$Ui$Scale$isXsSm = function (s) {
	switch (s) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return false;
		default:
			return false;
	}
};
var $mdgriffith$elm_ui$Internal$Flag$overflow = $mdgriffith$elm_ui$Internal$Flag$flag(20);
var $mdgriffith$elm_ui$Element$scrollbarY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.ht);
var $mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var $mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		if (_Utils_eq(x, y)) {
			var f = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + $elm$core$String$fromInt(x),
					f,
					f,
					f,
					f));
		} else {
			var yFloat = y;
			var xFloat = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
					yFloat,
					xFloat,
					yFloat,
					xFloat));
		}
	});
var $author$project$Ui$Element$pageColumn = function (scale) {
	return $mdgriffith$elm_ui$Element$column(
		_Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			function () {
				switch (scale) {
					case 0:
						return _List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 20, 40),
								$mdgriffith$elm_ui$Element$spacing(50)
							]);
					case 1:
						return _List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 20, 40),
								$mdgriffith$elm_ui$Element$spacing(60)
							]);
					case 2:
						return _List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 60, 80),
								$mdgriffith$elm_ui$Element$spacing(70)
							]);
					default:
						return _List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 60, 80),
								$mdgriffith$elm_ui$Element$spacing(80)
							]);
				}
			}()));
};
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Typeform$view = function (id) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(500)),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$iframe,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src('https://fysitech.typeform.com/to/' + (id + '?typeform-embed=embed-widget&amp;embed-opacity=0&amp;typeform-embed-id=0lqgh')),
						A2($elm$html$Html$Attributes$attribute, 'data-qa', 'iframe'),
						A2($elm$html$Html$Attributes$style, 'border', '0px none'),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%'),
						A2($elm$html$Html$Attributes$attribute, 'frameborder', '0')
					]),
				_List_Nil)));
};
var $author$project$Page$bewertungInternetPage = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				$author$project$Typeform$view('lrXUwFsD')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var $mdgriffith$elm_ui$Internal$Model$asRow = 0;
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.aT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.T)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$spaceEvenly = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$spacing, $mdgriffith$elm_ui$Internal$Style$classes.hG);
var $author$project$Ui$Element$introSection = F3(
	function (scale, list1, list2) {
		return $author$project$Ui$Scale$isXsSm(scale) ? A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(30)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$spacing(14)
						]),
					list1),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					list2)
				])) : A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$spacing(14),
							$mdgriffith$elm_ui$Element$alignTop
						]),
					list1),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					list2)
				]));
	});
var $author$project$Ui$Font$blissProLight = $author$project$Ui$Font$typeface('BlissPro Light');
var $author$project$Ui$Element$introParagraph = function (scale) {
	return A2(
		$elm$core$Basics$composeR,
		$mdgriffith$elm_ui$Element$text,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$mdgriffith$elm_ui$Element$paragraph(
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight,
							$mdgriffith$elm_ui$Element$spacing(12)
						]),
					function () {
						switch (scale) {
							case 0:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(16)
									]);
							case 1:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(18)
									]);
							case 2:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(20)
									]);
							default:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(22)
									]);
						}
					}()))));
};
var $author$project$Page$Accommodations$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introParagraph, scale, 'Wählen Sie eine Unterkunft, welche Ihr Mountain Coworking zum vollendeten 24-Stunden-Erlebnis macht! Wählen Sie eine Möglichkeit, die zu Ihrem Budget passt! Die nachfolgenden Häuser bieten schnelles Internet von miaEngiadina.')
		]);
};
var $author$project$Ui$Element$introTitle = function (scale) {
	return A2(
		$elm$core$Basics$composeR,
		$mdgriffith$elm_ui$Element$text,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$mdgriffith$elm_ui$Element$paragraph(
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
							$author$project$Ui$Font$blissProLight
						]),
					function () {
						switch (scale) {
							case 0:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(40)
									]);
							case 1:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(46)
									]);
							case 2:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(50)
									]);
							default:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(54)
									]);
						}
					}()))));
};
var $author$project$Page$Accommodations$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Übernachten'),
			A2($author$project$Ui$Element$introTitle, scale, 'mit der Welt'),
			A2($author$project$Ui$Element$introTitle, scale, 'verbunden')
		]);
};
var $author$project$Page$Accommodations$Place = F4(
	function (name, location, img, url) {
		return {dt: img, dB: location, gU: name, cQ: url};
	});
var $author$project$Support$imagesCdnUrl = 'https://images.miaEngiadina.ch/';
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $author$project$Ui$Style$border = $mdgriffith$elm_ui$Element$Border$rounded(6);
var $mdgriffith$elm_ui$Element$clip = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.fs);
var $mdgriffith$elm_ui$Element$Background$image = function (src) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2($elm$virtual_dom$VirtualDom$style, 'background', 'url(\"' + (src + '\") center / cover no-repeat')));
};
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $mdgriffith$elm_ui$Internal$Model$boxShadowClass = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				shadow.dv ? 'box-inset' : 'box-',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.aH.a) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.aH.b) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.fb) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.aI) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.aB)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$shadows = $mdgriffith$elm_ui$Internal$Flag$flag(19);
var $mdgriffith$elm_ui$Element$Border$shadow = function (almostShade) {
	var shade = {fb: almostShade.fb, aB: almostShade.aB, dv: false, aH: almostShade.aH, aI: almostShade.aI};
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$shadows,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			$mdgriffith$elm_ui$Internal$Model$boxShadowClass(shade),
			'box-shadow',
			$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(shade)));
};
var $author$project$Ui$Style$tableShadow = $mdgriffith$elm_ui$Element$Border$shadow(
	{
		fb: 3,
		aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
		aH: _Utils_Tuple2(0, 0),
		aI: 1
	});
var $author$project$Ui$Element$imgCard = F2(
	function (src, elem) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$border,
					$author$project$Ui$Style$tableShadow,
					$mdgriffith$elm_ui$Element$clip
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(192)),
							$mdgriffith$elm_ui$Element$Background$image(src)
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$padding(10)
						]),
					elem)
				]));
	});
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $mdgriffith$elm_ui$Element$newTabLink = F2(
	function (attrs, _v0) {
		var url = _v0.cQ;
		var label = _v0.gw;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Attributes$target('_blank')),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bl + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.T + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gB)))),
									attrs)))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $author$project$Helpers$whenJust = $elm_community$maybe_extra$Maybe$Extra$unwrap($mdgriffith$elm_ui$Element$none);
var $author$project$Page$Accommodations$viewPlace = F3(
	function (scale, subHeader, p) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$newTabLink,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				{
					gw: A2(
						$author$project$Ui$Element$imgCard,
						$author$project$Support$imagesCdnUrl + ('accommodations/' + p.dt),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$size(24),
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(p.gU)
										])),
									A2(
									$author$project$Helpers$whenJust,
									$author$project$Ui$Element$introParagraph(scale),
									subHeader),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$size(16),
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
										]),
									$mdgriffith$elm_ui$Element$text(p.dB))
								]))),
					cQ: p.cQ
				}));
	});
var $author$project$Page$Accommodations$viewRow = function (scale) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			A2($author$project$Page$Accommodations$viewPlace, scale, $elm$core$Maybe$Nothing)),
		$mdgriffith$elm_ui$Element$row(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(25),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				])));
};
var $author$project$Page$Accommodations$row1 = function (scale) {
	return A2(
		$author$project$Page$Accommodations$viewRow,
		scale,
		_List_fromArray(
			[
				A4($author$project$Page$Accommodations$Place, 'Ferienpark Tulai, Familie Carl', 'Scuol', '7.jpeg', 'https://www.ferienwohnungen-tulai-scuol.com'),
				A4($author$project$Page$Accommodations$Place, 'Hotel Belvédère', 'Scuol', '3.jpeg', 'https://www.belvedere-scuol.ch')
			]));
};
var $author$project$Page$Accommodations$row2 = function (scale) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(25),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Page$Accommodations$viewPlace,
				scale,
				$elm$core$Maybe$Just('Einige Wohnungen davon bereits mit schnellem Internet.'),
				A4($author$project$Page$Accommodations$Place, 'Ferienwohnungen von Engadin Booking', 'Scuol', '8.jpeg', 'https://engadin-booking.ch/')),
				A3(
				$author$project$Page$Accommodations$viewPlace,
				scale,
				$elm$core$Maybe$Nothing,
				A4($author$project$Page$Accommodations$Place, 'Hotel Arnica', 'Scuol', '2.jpeg', 'https://www.arnica-scuol.ch')),
				A3(
				$author$project$Page$Accommodations$viewPlace,
				scale,
				$elm$core$Maybe$Nothing,
				A4($author$project$Page$Accommodations$Place, 'Jugendherberge', 'Scuol', '4.jpeg', 'https://www.youthhostel.ch/de/hostels/scuol/'))
			]));
};
var $author$project$Page$Accommodations$row3 = function (scale) {
	return A2(
		$author$project$Page$Accommodations$viewRow,
		scale,
		_List_fromArray(
			[
				A4($author$project$Page$Accommodations$Place, 'Hotel Paradies', 'Ftan', '5.jpeg', 'https://www.paradieshotel.ch'),
				A4($author$project$Page$Accommodations$Place, 'Hotel Traube', 'Scuol', '6.jpeg', 'http://traube.ch')
			]));
};
var $author$project$Page$Accommodations$row4 = function (scale) {
	return A2(
		$author$project$Page$Accommodations$viewRow,
		scale,
		_List_fromArray(
			[
				A4($author$project$Page$Accommodations$Place, 'Hotel Altana', 'Scuol', '1.jpeg', 'http://www.altana.ch'),
				A4($author$project$Page$Accommodations$Place, 'Saloon San Jon', 'Scuol', '9.jpeg', 'https://www.sanjon.ch'),
				A4($author$project$Page$Accommodations$Place, 'Camping Cul', 'Zernez', '10.jpeg', 'https://camping-cul.com/')
			]));
};
var $author$project$Page$Accommodations$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$Accommodations$introSectionTitle(scale),
				$author$project$Page$Accommodations$introSectionText(scale)),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(25),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$author$project$Page$Accommodations$row1(scale),
						$author$project$Page$Accommodations$row2(scale),
						$author$project$Page$Accommodations$row3(scale),
						$author$project$Page$Accommodations$row4(scale)
					]))
			]));
};
var $author$project$Page$AnmeldungInnHub$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				$author$project$Typeform$view('VZLpACbg')
			]));
};
var $author$project$Page$AnmeldungMountainHubScuol$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				$author$project$Typeform$view('VwB6yNOD')
			]));
};
var $author$project$Page$BewertungMountainHubScuol$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				$author$project$Typeform$view('bSV7expG')
			]));
};
var $author$project$Ui$Font$blissProBold = $author$project$Ui$Font$typeface('BlissPro Bold');
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.fd);
var $mdgriffith$elm_ui$Element$Font$italic = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gv);
var $author$project$Support$default = function () {
	var headingDefaults = _List_fromArray(
		[
			$author$project$Ui$Font$blissProBold,
			A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
		]);
	return {
		fk: $mdgriffith$elm_ui$Element$text('–'),
		de: _List_fromArray(
			[$mdgriffith$elm_ui$Element$Font$italic]),
		aV: _Utils_ap(
			headingDefaults,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$size(30)
				])),
		b5: _Utils_ap(
			headingDefaults,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$size(24)
				])),
		bu: _Utils_ap(
			headingDefaults,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					$mdgriffith$elm_ui$Element$Font$size(24)
				])),
		b6: _Utils_ap(
			headingDefaults,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					$mdgriffith$elm_ui$Element$Font$size(20)
				])),
		b7: _Utils_ap(
			headingDefaults,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					$mdgriffith$elm_ui$Element$Font$size(20)
				])),
		b8: _Utils_ap(
			headingDefaults,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					$mdgriffith$elm_ui$Element$Font$size(20)
				])),
		cw: _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$size(16),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Ui$Font$blissProRegular
			]),
		hR: _List_fromArray(
			[$mdgriffith$elm_ui$Element$Font$bold])
	};
}();
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $mdgriffith$elm_ui$Element$image = F2(
	function (attrs, _v0) {
		var src = _v0.eq;
		var description = _v0.fK;
		var imageAttributes = A2(
			$elm$core$List$filter,
			function (a) {
				switch (a.$) {
					case 7:
						return true;
					case 8:
						return true;
					default:
						return false;
				}
			},
			attrs);
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.f9),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('img'),
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$src(src)),
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$alt(description))
								]),
							imageAttributes),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil))
					])));
	});
var $author$project$Support$imageAsHtml = F2(
	function (scale, imageView) {
		return A2(
			$mdgriffith$elm_ui$Element$image,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			{
				fK: A2($elm$core$Maybe$withDefault, '', imageView.eY),
				eq: imageView.cQ
			});
	});
var $mdgriffith$elm_ui$Element$link = F2(
	function (attrs, _v0) {
		var url = _v0.cQ;
		var label = _v0.gw;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bl + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.T + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gB)))),
								attrs))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $elm$core$List$sortBy = _List_sortBy;
var $mdgriffith$elm_ui$Element$Font$underline = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.il);
var $author$project$Support$blockAsHtml = F4(
	function (scale, style, el, field) {
		var spanEl = function (span) {
			var _v2 = span.hI;
			switch (_v2.$) {
				case 0:
					return $mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$italic]));
				case 1:
					return $mdgriffith$elm_ui$Element$el(
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]));
				default:
					switch (_v2.a.$) {
						case 1:
							var _v3 = _v2.a;
							var target = _v3.a;
							var hlink = _v3.b;
							var linkFunction = function () {
								if (!target.$) {
									return $mdgriffith$elm_ui$Element$newTabLink;
								} else {
									return $mdgriffith$elm_ui$Element$link;
								}
							}();
							return function (x) {
								return A2(
									linkFunction,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$underline]),
									{gw: x, cQ: hlink});
							};
						case 0:
							var _v5 = _v2.a;
							var linkedDoc = _v5.a;
							var isBroken = _v5.b;
							return $mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$underline]));
						default:
							var fileRef = _v2.a.a;
							return function (x) {
								return A2(
									$mdgriffith$elm_ui$Element$link,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$underline]),
									{gw: x, cQ: fileRef.cQ});
							};
					}
			}
		};
		var foldFn = F2(
			function (span, _v1) {
				var childs = _v1.a;
				var index = _v1.b;
				var middle = A3($elm$core$String$slice, span.cL, span.df, field.eu);
				var beginning = A3($elm$core$String$slice, index, span.cL, field.eu);
				return _Utils_Tuple2(
					_Utils_ap(
						childs,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(beginning),
								A2(
								spanEl,
								span,
								$mdgriffith$elm_ui$Element$text(middle))
							])),
					span.df);
			});
		return el(
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$elm$html$Html$Attributes$class,
								A2($elm$core$Basics$composeR, $mdgriffith$elm_ui$Element$htmlAttribute, $elm$core$List$singleton)),
							field.gw)),
					function (_v0) {
						var childs = _v0.a;
						var index = _v0.b;
						return _Utils_ap(
							childs,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(
									A2($elm$core$String$dropLeft, index, field.eu))
								]));
					}(
						A3(
							$elm$core$List$foldl,
							foldFn,
							_Utils_Tuple2(_List_Nil, 0),
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.cL;
								},
								field.hJ))))
				]));
	});
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.aH, s.hn, s.c7, s.eq);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.c)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.aH, newOffset) < 0,
			0,
			{c7: newCol, c: s.c, d: s.d, aH: newOffset, hn: newRow, eq: s.eq});
	};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$commentString = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $hecrj$html_parser$Html$Parser$comment = A2($elm$parser$Parser$map, $hecrj$html_parser$Html$Parser$Comment, $hecrj$html_parser$Html$Parser$commentString);
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {c7: s.c7 + (newOffset - s.aH), c: s.c, d: s.d, aH: newOffset, hn: s.hn, eq: s.eq};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.aH, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.eq);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.hn, s.c7 - (floatOffset + s.aH), invalid, s.c));
		} else {
			if (_Utils_eq(s.aH, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.aH, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.aH, floatOffset, s.eq));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.aH, s.eq)) {
			var zeroOffset = s.aH + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.eq) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.gp,
				c.dq,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.eq),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.eq) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.gp,
				c.dG,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.eq),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.eq) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.gp,
				c.c$,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.eq),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.gp,
				c.dg,
				c.dw,
				c.di,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.gp,
				c.dg,
				c.dw,
				c.di,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.aH, s.eq),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				c$: $elm$core$Result$Err(invalid),
				dg: expecting,
				di: $elm$core$Result$Err(invalid),
				dq: $elm$core$Result$Err(invalid),
				dw: $elm$core$Result$Ok($elm$core$Basics$identity),
				gp: invalid,
				dG: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Support$parseHtml = function (code) {
	return A2(
		$elm$core$Result$withDefault,
		A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text(code)
				])),
		A2(
			$elm$core$Result$map,
			A2(
				$elm$core$Basics$composeR,
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom,
				A2(
					$elm$core$Basics$composeR,
					$elm$html$Html$div(_List_Nil),
					$mdgriffith$elm_ui$Element$html)),
			$hecrj$html_parser$Html$Parser$run(code)));
};
var $author$project$Support$embedAsHtml = function (embed) {
	return $author$project$Support$parseHtml(
		function () {
			if (!embed.$) {
				var props = embed.a;
				return props.f6;
			} else {
				var props = embed.a;
				return props.f6;
			}
		}());
};
var $mdgriffith$elm_ui$Element$modular = F3(
	function (normal, ratio, rescale) {
		return (!rescale) ? normal : ((rescale < 0) ? (normal * A2($elm$core$Basics$pow, ratio, rescale)) : (normal * A2($elm$core$Basics$pow, ratio, rescale - 1)));
	});
var $author$project$Ui$Scale$toInt = function (s) {
	switch (s) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		default:
			return 4;
	}
};
var $author$project$Ui$Scale$factor = F2(
	function (start, step) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Ui$Scale$toInt,
			A2(
				$elm$core$Basics$composeR,
				A2($mdgriffith$elm_ui$Element$modular, start, step),
				$elm$core$Basics$round));
	});
var $author$project$Ui$Style$spacing = A2(
	$elm$core$Basics$composeR,
	A2($author$project$Ui$Scale$factor, 12, 1.2),
	$mdgriffith$elm_ui$Element$spacing);
var $author$project$Support$structuredTextBlockAsHtml = F3(
	function (scale, style, field) {
		switch (field.$) {
			case 8:
				var simage = field.a;
				return A2($author$project$Support$imageAsHtml, scale, simage);
			case 9:
				var embed = field.a;
				return $author$project$Support$embedAsHtml(embed);
			case 0:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(style.aV),
					block);
			case 1:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(style.b5),
					block);
			case 2:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(style.bu),
					block);
			case 3:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(style.b6),
					block);
			case 4:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(style.b7),
					block);
			case 5:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(style.b8),
					block);
			case 6:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(style.cw),
					block);
			case 7:
				var blocks = field.a;
				var bullet = function (child) {
					return A2(
						$mdgriffith$elm_ui$Element$row,
						A2(
							$elm$core$List$cons,
							$author$project$Ui$Style$spacing(scale),
							style.cw),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignTop]),
								$mdgriffith$elm_ui$Element$text('•')),
								A2($mdgriffith$elm_ui$Element$paragraph, _List_Nil, child)
							]));
				};
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A3($author$project$Support$blockAsHtml, scale, style, bullet),
						blocks));
			case 11:
				var blocks = field.a;
				var bullet = F2(
					function (index, child) {
						return A2(
							$mdgriffith$elm_ui$Element$row,
							A2(
								$elm$core$List$cons,
								$author$project$Ui$Style$spacing(scale),
								style.cw),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignTop]),
									$mdgriffith$elm_ui$Element$text(
										$elm$core$String$fromInt(index + 1) + '.')),
									A2($mdgriffith$elm_ui$Element$paragraph, _List_Nil, child)
								]));
					});
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_Nil,
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, block) {
								return A4(
									$author$project$Support$blockAsHtml,
									scale,
									style,
									bullet(i),
									block);
							}),
						blocks));
			default:
				var block = field.a;
				return A4(
					$author$project$Support$blockAsHtml,
					scale,
					style,
					$mdgriffith$elm_ui$Element$paragraph(_List_Nil),
					block);
		}
	});
var $author$project$Support$structuredTextAsHtml = F3(
	function (scale, style, _v0) {
		var blocks = _v0;
		var styling = A2($elm$core$Maybe$withDefault, $author$project$Support$default, style);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$map,
				A2($author$project$Support$structuredTextBlockAsHtml, scale, styling),
				blocks));
	});
var $author$project$Ui$Element$textImgComponent = F4(
	function (scale, imageCopyright, textEl, imgEl) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				function () {
					switch (scale) {
						case 0:
							return _List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 0)
								]);
						case 1:
							return _List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 0)
								]);
						case 2:
							return _List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 0)
								]);
						default:
							return _List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 68, 0)
								]);
					}
				}()),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									A2($mdgriffith$elm_ui$Element$maximum, 1000, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$clip,
									$author$project$Ui$Style$border
								]),
							imgEl),
							function () {
							if (!imageCopyright.$) {
								var copyright = imageCopyright.a;
								return A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$size(10),
											$author$project$Ui$Font$blissProLight,
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(copyright)
										]));
							} else {
								return $mdgriffith$elm_ui$Element$none;
							}
						}()
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					textEl)
				]));
	});
var $author$project$Ui$Style$textImgParagraph = function (scale) {
	return _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$alignTop,
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
			$mdgriffith$elm_ui$Element$Font$size(20),
			$mdgriffith$elm_ui$Element$paddingEach(
			{F: 10, H: 0, L: 0, O: 0})
		]);
};
var $author$project$Ui$Style$textImgTitle = function (scale) {
	return _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$paddingEach(
			{F: 10, H: 0, L: 0, O: 0}),
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
			$mdgriffith$elm_ui$Element$Font$size(24),
			$author$project$Ui$Font$blissProBold
		]);
};
var $author$project$Page$View$componentSection = F2(
	function (scale, data) {
		return A4(
			$author$project$Ui$Element$textImgComponent,
			scale,
			data.ga,
			A3(
				$author$project$Support$structuredTextAsHtml,
				scale,
				$elm$core$Maybe$Just(
					_Utils_update(
						$author$project$Support$default,
						{
							aV: $author$project$Ui$Style$textImgTitle(scale),
							cw: $author$project$Ui$Style$textImgParagraph(scale)
						})),
				data.eu),
			A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.dC;
						},
						$author$project$Support$imageAsHtml(scale)),
					data.cc)));
	});
var $author$project$Ui$Style$introParagraph = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$alignLeft,
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$author$project$Ui$Font$blissProLight
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(16)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(22)
						]);
			}
		}());
};
var $author$project$Ui$Element$introSection2 = F3(
	function (scale, list1, list2) {
		return $author$project$Ui$Scale$isXsSm(scale) ? A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(30)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(14)
						]),
					list1),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					list2)
				])) : A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(14)
						]),
					list1),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					list2)
				]));
	});
var $author$project$Ui$Style$introTitle = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
				$author$project$Ui$Font$blissProLight
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(40)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(46)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(50)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(54)
						]);
			}
		}());
};
var $author$project$Page$View$introSection = F2(
	function (scale, data) {
		return A3(
			$author$project$Ui$Element$introSection2,
			scale,
			A3(
				$author$project$Support$structuredTextAsHtml,
				scale,
				$elm$core$Maybe$Just(
					_Utils_update(
						$author$project$Support$default,
						{
							aV: $author$project$Ui$Style$introTitle(scale)
						})),
				data.ig),
			A3(
				$author$project$Support$structuredTextAsHtml,
				scale,
				$elm$core$Maybe$Just(
					_Utils_update(
						$author$project$Support$default,
						{
							cw: _Utils_ap(
								$author$project$Support$default.cw,
								$author$project$Ui$Style$introParagraph(scale))
						})),
				data.ib));
	});
var $mdgriffith$elm_ui$Element$scrollbarX = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.hs);
var $author$project$Ui$Element$orgCard = $mdgriffith$elm_ui$Element$column(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$spacing(20),
			$mdgriffith$elm_ui$Element$padding(20),
			$mdgriffith$elm_ui$Element$scrollbarX,
			$author$project$Ui$Style$border,
			$author$project$Ui$Style$tableShadow
		]));
var $author$project$Page$View$organisationChart = F2(
	function (scale, data) {
		var style = _Utils_update(
			$author$project$Support$default,
			{
				fk: $mdgriffith$elm_ui$Element$none,
				cw: _Utils_ap(
					$author$project$Support$default.cw,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20)
						]))
			});
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$author$project$Ui$Element$orgCard(
				_List_fromArray(
					[
						function () {
						var _v0 = data.g7;
						if (!_v0.$) {
							var text = _v0.a;
							return A3(
								$author$project$Support$structuredTextAsHtml,
								scale,
								$elm$core$Maybe$Just(style),
								text);
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}(),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$alignLeft,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spaceEvenly,
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								A2(
									$author$project$Support$structuredTextAsHtml,
									scale,
									$elm$core$Maybe$Just(
										_Utils_update(
											style,
											{
												hR: _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
													])
											}))),
								$mdgriffith$elm_ui$Element$el(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$alignTop
										]))),
							data.fx))
					])));
	});
var $author$project$Ui$Element$textImgLeft = F3(
	function (scale, textEl, imgEl) {
		var style3 = _Utils_ap(
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$clip, $author$project$Ui$Style$border]),
			function () {
				switch (scale) {
					case 0:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					case 1:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					case 2:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					default:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$px(550)),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
				}
			}());
		var style2 = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$spacing(20)
			]);
		var style1 = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(30)
			]);
		return $author$project$Ui$Scale$isXsSm(scale) ? A2(
			$mdgriffith$elm_ui$Element$column,
			style1,
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2($mdgriffith$elm_ui$Element$el, style3, imgEl)),
					A2($mdgriffith$elm_ui$Element$el, style2, textEl)
				])) : A2(
			$mdgriffith$elm_ui$Element$row,
			style1,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$el, style2, textEl),
					A2($mdgriffith$elm_ui$Element$el, style3, imgEl)
				]));
	});
var $author$project$Page$View$textImgLeftSection = F2(
	function (scale, data) {
		return A3(
			$author$project$Ui$Element$textImgLeft,
			scale,
			A3(
				$author$project$Support$structuredTextAsHtml,
				scale,
				$elm$core$Maybe$Just(
					_Utils_update(
						$author$project$Support$default,
						{
							aV: $author$project$Ui$Style$textImgTitle(scale),
							cw: $author$project$Ui$Style$textImgParagraph(scale)
						})),
				data.eu),
			A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.dC;
						},
						$author$project$Support$imageAsHtml(scale)),
					data.cc)));
	});
var $author$project$Ui$Element$textImgRight = F3(
	function (scale, textEl, imgEl) {
		var style3 = _Utils_ap(
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$clip, $author$project$Ui$Style$border]),
			function () {
				switch (scale) {
					case 0:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					case 1:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					case 2:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					default:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$px(550)),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
				}
			}());
		var style2 = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$spacing(20)
			]);
		var style1 = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(30)
			]);
		return $author$project$Ui$Scale$isXsSm(scale) ? A2(
			$mdgriffith$elm_ui$Element$column,
			style1,
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2($mdgriffith$elm_ui$Element$el, style3, imgEl)),
					A2($mdgriffith$elm_ui$Element$el, style2, textEl)
				])) : A2(
			$mdgriffith$elm_ui$Element$row,
			style1,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$el, style3, imgEl),
					A2($mdgriffith$elm_ui$Element$el, style2, textEl)
				]));
	});
var $author$project$Page$View$textImgRightSection = F2(
	function (scale, data) {
		return A3(
			$author$project$Ui$Element$textImgRight,
			scale,
			A3(
				$author$project$Support$structuredTextAsHtml,
				scale,
				$elm$core$Maybe$Just(
					_Utils_update(
						$author$project$Support$default,
						{
							aV: $author$project$Ui$Style$textImgTitle(scale),
							cw: $author$project$Ui$Style$textImgParagraph(scale)
						})),
				data.eu),
			A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.dC;
						},
						$author$project$Support$imageAsHtml(scale)),
					data.cc)));
	});
var $author$project$Page$View$viewBody = F2(
	function (scale, body) {
		switch (body.$) {
			case 0:
				var data = body.a;
				return A2($author$project$Page$View$introSection, scale, data);
			case 1:
				var data = body.a;
				return A2($author$project$Page$View$textImgRightSection, scale, data);
			case 2:
				var data = body.a;
				return A2($author$project$Page$View$textImgLeftSection, scale, data);
			case 3:
				var data = body.a;
				return A2($author$project$Page$View$componentSection, scale, data);
			case 4:
				var data = body.a;
				return A3(
					$author$project$Support$structuredTextAsHtml,
					scale,
					$elm$core$Maybe$Just(
						_Utils_update(
							$author$project$Support$default,
							{
								aV: $author$project$Ui$Style$textImgTitle(scale),
								cw: $author$project$Ui$Style$textImgParagraph(scale)
							})),
					data);
			default:
				var data = body.a;
				return A2($author$project$Page$View$organisationChart, scale, data);
		}
	});
var $author$project$Page$View$viewContent = F2(
	function (scale, content) {
		return A2(
			$author$project$Ui$Element$pageColumn,
			scale,
			A2(
				$elm$core$List$map,
				$author$project$Page$View$viewBody(scale),
				content));
	});
var $author$project$Page$View$viewLoading = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$Ui$Font$blissProLight,
						$mdgriffith$elm_ui$Element$Font$size(20)
					]),
				$mdgriffith$elm_ui$Element$text('Loading'))
			]));
};
var $author$project$Page$View$view = F2(
	function (scale, content) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$View$viewLoading(scale),
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$View$viewContent(scale),
				content));
	});
var $author$project$Page$Community$viewCommunityDocument = function (document) {
	var body = A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(15)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(24),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
						$author$project$Ui$Font$blissProRegular
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(document.ie)
					])),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
						$mdgriffith$elm_ui$Element$Font$size(16)
					]),
				$mdgriffith$elm_ui$Element$text(
					A2($elm$core$Maybe$withDefault, 'Zum Dokument', document.gC)))
			]));
	var _v0 = document.gB;
	if ((!_v0.$) && (_v0.a.$ === 2)) {
		var fileLink = _v0.a.a;
		return A2(
			$mdgriffith$elm_ui$Element$newTabLink,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 550, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$padding(15),
					$author$project$Ui$Style$border,
					$author$project$Ui$Style$tableShadow
				]),
			{gw: body, cQ: fileLink.cQ});
	} else {
		return body;
	}
};
var $author$project$Page$Community$view = F2(
	function (scale, documents) {
		return A2(
			$author$project$Ui$Element$pageColumn,
			scale,
			_List_fromArray(
				[
					A2($author$project$Page$View$view, scale, documents.bU),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$spacing(20),
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20)
						]),
					A2(
						$elm$core$List$map,
						$author$project$Page$Community$viewCommunityDocument,
						A2($elm$core$Maybe$withDefault, _List_Nil, documents.bT)))
				]));
	});
var $author$project$Ui$Style$eventInfo = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$author$project$Ui$Font$blissProBold,
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(16),
							$mdgriffith$elm_ui$Element$paddingEach(
							{F: 10, H: 0, L: 0, O: 0})
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18),
							$mdgriffith$elm_ui$Element$paddingEach(
							{F: 10, H: 0, L: 0, O: 0})
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20),
							$mdgriffith$elm_ui$Element$paddingEach(
							{F: 20, H: 0, L: 0, O: 0})
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(22),
							$mdgriffith$elm_ui$Element$paddingEach(
							{F: 20, H: 0, L: 0, O: 0})
						]);
			}
		}());
};
var $author$project$Ui$Style$eventLead = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$author$project$Ui$Font$blissProRegular,
				$mdgriffith$elm_ui$Element$spacing(10),
				$mdgriffith$elm_ui$Element$paddingEach(
				{F: 10, H: 0, L: 0, O: 0})
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(22)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(24)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(26)
						]);
			}
		}());
};
var $author$project$Ui$Font$colorForLargeHeading = $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red);
var $author$project$Ui$Font$h1Color = $author$project$Ui$Font$colorForLargeHeading;
var $author$project$Ui$Font$h1FontSize = function (scale) {
	return $mdgriffith$elm_ui$Element$Font$size(
		function () {
			switch (scale) {
				case 0:
					return 40;
				case 1:
					return 46;
				case 2:
					return 50;
				default:
					return 54;
			}
		}());
};
var $author$project$Ui$Font$h1Padding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 20, H: 0, L: 0, O: 20});
var $author$project$Ui$Font$h1Spacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$h1Typeface = $author$project$Ui$Font$blissProLight;
var $author$project$Ui$Font$h1 = function (scale) {
	return _List_fromArray(
		[
			$author$project$Ui$Font$h1Typeface,
			$author$project$Ui$Font$h1FontSize(scale),
			$author$project$Ui$Font$h1Spacing,
			$author$project$Ui$Font$h1Padding,
			$author$project$Ui$Font$h1Color
		]);
};
var $author$project$Ui$Style$eventBodyParagraph = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$author$project$Ui$Font$blissProRegular,
				$mdgriffith$elm_ui$Element$spacing(10),
				$mdgriffith$elm_ui$Element$paddingEach(
				{F: 10, H: 0, L: 0, O: 10})
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20)
						]);
			}
		}());
};
var $author$project$Ui$Font$colorForHeading = $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray);
var $author$project$Ui$Font$h2Color = $author$project$Ui$Font$colorForHeading;
var $author$project$Ui$Font$h2FontSize = function (scale) {
	return $mdgriffith$elm_ui$Element$Font$size(
		function () {
			switch (scale) {
				case 0:
					return 22;
				case 1:
					return 22;
				case 2:
					return 26;
				default:
					return 26;
			}
		}());
};
var $author$project$Ui$Font$h2Padding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 15, H: 0, L: 0, O: 15});
var $author$project$Ui$Font$h2Spacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$h2Typeface = $author$project$Ui$Font$blissProBold;
var $author$project$Ui$Font$h2 = function (scale) {
	return _List_fromArray(
		[
			$author$project$Ui$Font$h2Typeface,
			$author$project$Ui$Font$h2FontSize(scale),
			$author$project$Ui$Font$h2Spacing,
			$author$project$Ui$Font$h2Padding,
			$author$project$Ui$Font$h2Color
		]);
};
var $author$project$Ui$Font$h3Color = $author$project$Ui$Font$colorForHeading;
var $author$project$Ui$Font$h3FontSize = function (scale) {
	return $mdgriffith$elm_ui$Element$Font$size(
		function () {
			switch (scale) {
				case 0:
					return 20;
				case 1:
					return 20;
				case 2:
					return 24;
				default:
					return 24;
			}
		}());
};
var $author$project$Ui$Font$h3Padding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 10, H: 0, L: 0, O: 10});
var $author$project$Ui$Font$h3Spacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$h3Typeface = $author$project$Ui$Font$blissProBold;
var $author$project$Ui$Font$h3 = function (scale) {
	return _List_fromArray(
		[
			$author$project$Ui$Font$h3Typeface,
			$author$project$Ui$Font$h3FontSize(scale),
			$author$project$Ui$Font$h3Spacing,
			$author$project$Ui$Font$h3Padding,
			$author$project$Ui$Font$h3Color
		]);
};
var $author$project$Page$Community$Events$viewBody = F2(
	function (scale, body) {
		switch (body.$) {
			case 0:
				if (!body.a.$) {
					var text = body.a.a;
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(20)
							]),
						$elm$core$List$singleton(
							A3(
								$author$project$Support$structuredTextAsHtml,
								scale,
								$elm$core$Maybe$Just(
									_Utils_update(
										$author$project$Support$default,
										{
											aV: $author$project$Ui$Font$h1(scale),
											b5: $author$project$Ui$Font$h2(scale),
											bu: $author$project$Ui$Font$h3(scale),
											cw: $author$project$Ui$Style$eventBodyParagraph(scale)
										})),
								text)));
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			case 1:
				var banner = body.a;
				return $mdgriffith$elm_ui$Element$none;
			default:
				var gallery = body.a;
				return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$Page$Community$Events$viewEvent = F2(
	function (scale, event) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(400)),
							$mdgriffith$elm_ui$Element$Background$image(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.dC;
										},
										function ($) {
											return $.cQ;
										}),
									event.cc)))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$padding(30)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							$elm$core$List$singleton(
								A2(
									$elm$core$Maybe$withDefault,
									$mdgriffith$elm_ui$Element$none,
									A2(
										$elm$core$Maybe$map,
										A2(
											$author$project$Support$structuredTextAsHtml,
											scale,
											$elm$core$Maybe$Just(
												_Utils_update(
													$author$project$Support$default,
													{
														aV: $author$project$Ui$Font$h1(scale)
													}))),
										event.ie)))),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							$elm$core$List$singleton(
								A2(
									$elm$core$Maybe$withDefault,
									$mdgriffith$elm_ui$Element$none,
									A2(
										$elm$core$Maybe$map,
										A2(
											$author$project$Support$structuredTextAsHtml,
											scale,
											$elm$core$Maybe$Just(
												_Utils_update(
													$author$project$Support$default,
													{
														aV: $author$project$Ui$Style$eventInfo(scale),
														b5: $author$project$Ui$Style$eventInfo(scale),
														bu: $author$project$Ui$Style$eventInfo(scale),
														cw: $author$project$Ui$Style$eventInfo(scale)
													}))),
										event.gi)))),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							$elm$core$List$singleton(
								A2(
									$elm$core$Maybe$withDefault,
									$mdgriffith$elm_ui$Element$none,
									A2(
										$elm$core$Maybe$map,
										A2(
											$author$project$Support$structuredTextAsHtml,
											scale,
											$elm$core$Maybe$Just(
												_Utils_update(
													$author$project$Support$default,
													{
														aV: $author$project$Ui$Style$eventLead(scale),
														b5: $author$project$Ui$Style$eventLead(scale),
														bu: $author$project$Ui$Style$eventLead(scale),
														cw: $author$project$Ui$Style$eventLead(scale)
													}))),
										event.gz)))),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(20),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
								]),
							A2(
								$elm$core$List$map,
								$author$project$Page$Community$Events$viewBody(scale),
								event.fc))
						]))
				]));
	});
var $author$project$Types$NavigateTo = function (a) {
	return {$: 3, a: a};
};
var $author$project$Ui$Color$blue = A3($mdgriffith$elm_ui$Element$rgb255, 15, 76, 129);
var $author$project$Ui$Element$linkFillMaxWidth = F2(
	function (msg, elem) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			{
				gw: elem,
				dK: $elm$core$Maybe$Just(msg)
			});
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.cL, posixMinutes) < 0) {
					return posixMinutes + era.aH;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $author$project$Page$Community$Events$formatClockTime = F2(
	function (timezone, posix) {
		var minute = A2($elm$time$Time$toMinute, timezone, posix);
		var hour = A2($elm$time$Time$toHour, timezone, posix);
		return $elm$core$String$fromInt(hour) + (':' + (((minute < 10) ? '0' : '') + $elm$core$String$fromInt(minute)));
	});
var $elm$time$Time$Jan = 0;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {c9: d, dE: m, eM: y};
			}
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		cu: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		eM: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.eM, 0, date.cu);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.c9;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.dE;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.cu;
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		eI: 1 + (((rd - week1Day1) / 7) | 0),
		eJ: wy,
		is: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.eI;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.eJ;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.gQ(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.gP(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.gQ(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.fI(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.iu(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.iu(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.iu(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.it(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.iu(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.iu(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.aH, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		c9: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		dE: month,
		eM: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).c9;
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dE;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).eM;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $author$project$Page$Community$Events$monthToStringLong = function (month) {
	switch (month) {
		case 0:
			return 'Januar';
		case 1:
			return 'Februar';
		case 2:
			return 'März';
		case 3:
			return 'April';
		case 4:
			return 'Mai';
		case 5:
			return 'Juni';
		case 6:
			return 'Juli';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'Oktober';
		case 10:
			return 'November';
		default:
			return 'Dezember';
	}
};
var $author$project$Page$Community$Events$monthToString = A2(
	$elm$core$Basics$composeR,
	$author$project$Page$Community$Events$monthToStringLong,
	$elm$core$String$left(3));
var $author$project$Page$Community$Events$weekdayToStringLong = function (day) {
	switch (day) {
		case 6:
			return 'Sonntag';
		case 0:
			return 'Montag';
		case 1:
			return 'Dienstag';
		case 2:
			return 'Mittwoch';
		case 3:
			return 'Donnerstag';
		case 4:
			return 'Freitag';
		default:
			return 'Samstag';
	}
};
var $author$project$Page$Community$Events$weekdayToString = A2(
	$elm$core$Basics$composeR,
	$author$project$Page$Community$Events$weekdayToStringLong,
	$elm$core$String$left(2));
var $author$project$Page$Community$Events$german = {
	fI: function (x) {
		return $elm$core$String$fromInt(x) + '.';
	},
	gP: $author$project$Page$Community$Events$monthToStringLong,
	gQ: $author$project$Page$Community$Events$monthToString,
	it: $author$project$Page$Community$Events$weekdayToStringLong,
	iu: $author$project$Page$Community$Events$weekdayToString
};
var $author$project$Page$Community$Events$format = F2(
	function (timezone, posix) {
		var minute = A2($elm$time$Time$toMinute, timezone, posix);
		var hour = A2($elm$time$Time$toHour, timezone, posix);
		var date = A2($justinmimbs$date$Date$fromPosix, timezone, posix);
		return _Utils_ap(
			A3($justinmimbs$date$Date$formatWithLanguage, $author$project$Page$Community$Events$german, 'EEEE, d MMMM y', date),
			((!hour) && (!minute)) ? '' : (' (' + (A2($author$project$Page$Community$Events$formatClockTime, timezone, posix) + ')')));
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$Community$Events$time = F3(
	function (start, end, created) {
		var _v0 = _Utils_Tuple2(start, end);
		if (!_v0.a.$) {
			if (_v0.b.$ === 1) {
				var s = _v0.a.a;
				var _v1 = _v0.b;
				return A2($author$project$Page$Community$Events$format, $elm$time$Time$utc, s);
			} else {
				var s = _v0.a.a;
				var e = _v0.b.a;
				return (_Utils_eq(
					A2($elm$time$Time$toDay, $elm$time$Time$utc, s),
					A2($elm$time$Time$toDay, $elm$time$Time$utc, e)) && (_Utils_eq(
					A2($elm$time$Time$toMonth, $elm$time$Time$utc, s),
					A2($elm$time$Time$toMonth, $elm$time$Time$utc, e)) && _Utils_eq(
					A2($elm$time$Time$toYear, $elm$time$Time$utc, s),
					A2($elm$time$Time$toYear, $elm$time$Time$utc, e)))) ? _Utils_ap(
					A2($author$project$Page$Community$Events$format, $elm$time$Time$utc, s),
					function () {
						var hours = A2($author$project$Page$Community$Events$formatClockTime, $elm$time$Time$utc, e);
						return (hours === '(0:00)') ? '' : (' -- ' + hours);
					}()) : (A2($author$project$Page$Community$Events$format, $elm$time$Time$utc, s) + ('( -- ' + (A2($author$project$Page$Community$Events$format, $elm$time$Time$utc, s) + ')')));
			}
		} else {
			return '';
		}
	});
var $author$project$Ui$Color$yellow = A3($mdgriffith$elm_ui$Element$rgb255, 255, 218, 41);
var $author$project$Page$Community$Events$viewC = F2(
	function (scale, ev) {
		return A2(
			$author$project$Ui$Element$linkFillMaxWidth,
			$author$project$Types$NavigateTo(
				$author$project$Types$CommunityPage(
					$elm$core$Maybe$Just(
						$author$project$Types$EventsPage(ev.ik)))),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill) : $mdgriffith$elm_ui$Element$width(
						A2($mdgriffith$elm_ui$Element$minimum, 320, $mdgriffith$elm_ui$Element$fill)),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$author$project$Ui$Style$border,
						ev.gu ? $mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$blue) : $mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white)
					]),
				A2(
					$author$project$Ui$Element$imgCard,
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.dC;
								},
								function ($) {
									return $.cQ;
								}),
							ev.cc)),
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(15),
								$mdgriffith$elm_ui$Element$padding(15)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(5)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										$elm$core$List$singleton(
											A2(
												$elm$core$Maybe$withDefault,
												$mdgriffith$elm_ui$Element$none,
												A2(
													$elm$core$Maybe$map,
													A2(
														$author$project$Support$structuredTextAsHtml,
														scale,
														$elm$core$Maybe$Just(
															_Utils_update(
																$author$project$Support$default,
																{
																	bu: _List_fromArray(
																		[
																			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
																			ev.gu ? $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$yellow) : $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
																			$author$project$Ui$Font$blissProLight,
																			$mdgriffith$elm_ui$Element$Font$size(16)
																		])
																}))),
													ev.hW)))),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										$elm$core$List$singleton(
											A2(
												$elm$core$Maybe$withDefault,
												$mdgriffith$elm_ui$Element$none,
												A2(
													$elm$core$Maybe$map,
													A2(
														$author$project$Support$structuredTextAsHtml,
														scale,
														$elm$core$Maybe$Just(
															_Utils_update(
																$author$project$Support$default,
																{
																	aV: _Utils_ap(
																		_List_fromArray(
																			[
																				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
																				ev.gu ? $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white) : $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
																				$author$project$Ui$Font$blissProRegular
																			]),
																		function () {
																			switch (scale) {
																				case 0:
																					return _List_fromArray(
																						[
																							$mdgriffith$elm_ui$Element$Font$size(16),
																							$mdgriffith$elm_ui$Element$spacing(4)
																						]);
																				case 1:
																					return _List_fromArray(
																						[
																							$mdgriffith$elm_ui$Element$Font$size(18),
																							$mdgriffith$elm_ui$Element$spacing(6)
																						]);
																				case 2:
																					return _List_fromArray(
																						[
																							$mdgriffith$elm_ui$Element$Font$size(20),
																							$mdgriffith$elm_ui$Element$spacing(8)
																						]);
																				default:
																					return _List_fromArray(
																						[
																							$mdgriffith$elm_ui$Element$Font$size(22),
																							$mdgriffith$elm_ui$Element$spacing(10)
																						]);
																			}
																		}())
																}))),
													ev.ie))))
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(16),
										ev.gu ? $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white) : $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
									]),
								$elm$core$List$singleton(
									$mdgriffith$elm_ui$Element$text(
										A3($author$project$Page$Community$Events$time, ev.fH, ev.fF, ev.fB)))),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignBottom,
										$mdgriffith$elm_ui$Element$Font$size(16),
										ev.gu ? $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$yellow) : $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('Mehr erfahren')
									]))
							])))));
	});
var $mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, _v0) {
				var pad = _v0.a;
				var spacing = _v0.b;
				return _Utils_Tuple2(
					function () {
						if (!pad.$) {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 7)) {
								var _v3 = attr.b;
								var name = _v3.a;
								var t = _v3.b;
								var r = _v3.c;
								var b = _v3.d;
								var l = _v3.e;
								return $elm$core$Maybe$Just(
									A5($mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (!spacing.$) {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v6 = attr.b;
								var name = _v6.a;
								var x = _v6.b;
								var y = _v6.c;
								return $elm$core$Maybe$Just(
									A3($mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		attrs);
};
var $mdgriffith$elm_ui$Internal$Model$paddingNameFloat = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(top) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(right) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(bottom) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(left)))))));
	});
var $mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _v0 = $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _v0.a;
		var spaced = _v0.b;
		if (spaced.$ === 1) {
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asRow,
				$mdgriffith$elm_ui$Internal$Model$div,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.aT + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.T + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cT)))),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							attrs))),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _v2 = spaced.a;
			var spaceName = _v2.a;
			var x = _v2.b;
			var y = _v2.c;
			var newPadding = function () {
				if (!padded.$) {
					var _v5 = padded.a;
					var name = _v5.a;
					var t = _v5.b;
					var r = _v5.c;
					var b = _v5.d;
					var l = _v5.e;
					if ((_Utils_cmp(r, x / 2) > -1) && (_Utils_cmp(b, y / 2) > -1)) {
						var newTop = t - (y / 2);
						var newRight = r - (x / 2);
						var newLeft = l - (x / 2);
						var newBottom = b - (y / 2);
						return $elm$core$Maybe$Just(
							A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, newRight, newBottom, newLeft),
									newTop,
									newRight,
									newBottom,
									newLeft)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			if (!newPadding.$) {
				var pad = newPadding.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asRow,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.aT + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.T + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cT)))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asRow,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.aT + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.T + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cT)))),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												$elm$html$Html$Attributes$style,
												'margin',
												$elm$core$String$fromFloat(halfY) + ('px' + (' ' + ($elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													$elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + ($elm$core$String$fromInt(x) + 'px)'))),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														$elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))),
												A2(
													$elm$core$List$cons,
													A2(
														$mdgriffith$elm_ui$Internal$Model$StyleClass,
														$mdgriffith$elm_ui$Internal$Flag$spacing,
														A3($mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var $author$project$Page$Community$Events$viewEvents = function (scale) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$withDefault(_List_Nil),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map(
				$author$project$Page$Community$Events$viewC(scale)),
			function () {
				switch (scale) {
					case 0:
						return $mdgriffith$elm_ui$Element$column(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$spacing(20),
									A2($mdgriffith$elm_ui$Element$paddingXY, 20, 40)
								]));
					case 1:
						return $mdgriffith$elm_ui$Element$column(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$spacing(20),
									A2($mdgriffith$elm_ui$Element$paddingXY, 20, 40)
								]));
					case 2:
						return $mdgriffith$elm_ui$Element$wrappedRow(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$spacing(20),
									A2($mdgriffith$elm_ui$Element$paddingXY, 60, 80)
								]));
					default:
						return $mdgriffith$elm_ui$Element$wrappedRow(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$spacing(20),
									A2($mdgriffith$elm_ui$Element$paddingXY, 60, 80)
								]));
				}
			}()));
};
var $author$project$Page$Community$Events$view = F3(
	function (events, scale, maybeId) {
		if (!maybeId.$) {
			var uid = maybeId.a;
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				A2($author$project$Page$Community$Events$viewEvents, scale, events),
				$author$project$Page$Community$Events$viewEvent(scale),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.ik;
							},
							$elm$core$Basics$eq(
								$elm$core$Maybe$Just(uid))),
						A2($elm$core$Maybe$withDefault, _List_Nil, events))));
		} else {
			return A2($author$project$Page$Community$Events$viewEvents, scale, events);
		}
	});
var $author$project$Page$Community$Membership$Offer = F4(
	function (a, b, c, d) {
		return {cU: a, c_: b, c1: c, c8: d};
	});
var $author$project$Page$Community$Membership$box = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$text,
	$mdgriffith$elm_ui$Element$el(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$padding(5),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red)
			])));
var $author$project$Ui$Style$cellSpacing = $mdgriffith$elm_ui$Element$spacing(2);
var $author$project$Ui$Text$Text = $elm$core$Basics$identity;
var $author$project$Ui$Color$grayDark = A3($mdgriffith$elm_ui$Element$rgb255, 55, 58, 54);
var $author$project$Ui$Color$font = $author$project$Ui$Color$grayDark;
var $author$project$Ui$Font$headlineSmallFontSize = $mdgriffith$elm_ui$Element$Font$size(24);
var $author$project$Ui$Font$headlineSmallPadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 14, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$headlineSmallSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$headlineTypeface = $author$project$Ui$Font$blissProBold;
var $author$project$Ui$Text$headlineSmall = function (text) {
	return {
		ba: _List_Nil,
		i: $author$project$Ui$Color$font,
		m: $author$project$Ui$Font$headlineSmallFontSize,
		bB: $author$project$Ui$Font$headlineSmallPadding,
		hH: $author$project$Ui$Font$headlineSmallSpacing,
		eu: text,
		l: _Utils_Tuple2(false, $elm$core$Maybe$Nothing),
		p: $author$project$Ui$Font$headlineTypeface,
		q: false
	};
};
var $author$project$Ui$Color$lightGray = A3($mdgriffith$elm_ui$Element$rgb255, 245, 245, 245);
var $mdgriffith$elm_ui$Element$InternalColumn = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridPosition = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridTemplateStyle = function (a) {
	return {$: 8, a: a};
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $mdgriffith$elm_ui$Internal$Model$AsGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$asGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$getSpacing = F2(
	function (attrs, _default) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3(
				$elm$core$List$foldr,
				F2(
					function (attr, acc) {
						if (!acc.$) {
							var x = acc.a;
							return $elm$core$Maybe$Just(x);
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v2 = attr.b;
								var x = _v2.b;
								var y = _v2.c;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}),
				$elm$core$Maybe$Nothing,
				attrs));
	});
var $mdgriffith$elm_ui$Internal$Flag$gridPosition = $mdgriffith$elm_ui$Internal$Flag$flag(35);
var $mdgriffith$elm_ui$Internal$Flag$gridTemplate = $mdgriffith$elm_ui$Internal$Flag$flag(34);
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $mdgriffith$elm_ui$Element$tableHelper = F2(
	function (attrs, config) {
		var onGrid = F3(
			function (rowLevel, columnLevel, elem) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$StyleClass,
							$mdgriffith$elm_ui$Internal$Flag$gridPosition,
							$mdgriffith$elm_ui$Internal$Model$GridPosition(
								{c7: columnLevel, b9: 1, hn: rowLevel, a7: 1}))
						]),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[elem])));
			});
		var columnWidth = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.a7;
			} else {
				var colConfig = col.a;
				return colConfig.a7;
			}
		};
		var columnHeader = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.bt;
			} else {
				var colConfig = col.a;
				return colConfig.bt;
			}
		};
		var maybeHeaders = function (headers) {
			return A2(
				$elm$core$List$all,
				$elm$core$Basics$eq($mdgriffith$elm_ui$Internal$Model$Empty),
				headers) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (col, header) {
							return A3(onGrid, 1, col + 1, header);
						}),
					headers));
		}(
			A2($elm$core$List$map, columnHeader, config.fx));
		var add = F3(
			function (cell, columnConfig, cursor) {
				if (!columnConfig.$) {
					var col = columnConfig.a;
					return _Utils_update(
						cursor,
						{
							S: cursor.S + 1,
							P: A2(
								$elm$core$List$cons,
								A3(
									onGrid,
									cursor.hn,
									cursor.S,
									A2(
										col.iq,
										_Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? (cursor.hn - 1) : (cursor.hn - 2),
										cell)),
								cursor.P)
						});
				} else {
					var col = columnConfig.a;
					return {
						S: cursor.S + 1,
						P: A2(
							$elm$core$List$cons,
							A3(
								onGrid,
								cursor.hn,
								cursor.S,
								col.iq(cell)),
							cursor.P),
						hn: cursor.hn
					};
				}
			});
		var build = F3(
			function (columns, rowData, cursor) {
				var newCursor = A3(
					$elm$core$List$foldl,
					add(rowData),
					cursor,
					columns);
				return {S: 1, P: newCursor.P, hn: cursor.hn + 1};
			});
		var children = A3(
			$elm$core$List$foldl,
			build(config.fx),
			{
				S: 1,
				P: _List_Nil,
				hn: _Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? 1 : 2
			},
			config.fE);
		var _v0 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attrs,
			_Utils_Tuple2(0, 0));
		var sX = _v0.a;
		var sY = _v0.b;
		var template = A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$gridTemplate,
			$mdgriffith$elm_ui$Internal$Model$GridTemplateStyle(
				{
					fx: A2($elm$core$List$map, columnWidth, config.fx),
					ho: A2(
						$elm$core$List$repeat,
						$elm$core$List$length(config.fE),
						$mdgriffith$elm_ui$Internal$Model$Content),
					hH: _Utils_Tuple2(
						$mdgriffith$elm_ui$Element$px(sX),
						$mdgriffith$elm_ui$Element$px(sY))
				}));
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asGrid,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($elm$core$List$cons, template, attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				function () {
					if (maybeHeaders.$ === 1) {
						return children.P;
					} else {
						var renderedHeaders = maybeHeaders.a;
						return _Utils_ap(
							renderedHeaders,
							$elm$core$List$reverse(children.P));
					}
				}()));
	});
var $mdgriffith$elm_ui$Element$table = F2(
	function (attrs, config) {
		return A2(
			$mdgriffith$elm_ui$Element$tableHelper,
			attrs,
			{
				fx: A2($elm$core$List$map, $mdgriffith$elm_ui$Element$InternalColumn, config.fx),
				fE: config.fE
			});
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Ui$Text$view = function (_v0) {
	var config = _v0;
	var unsafeHtml = function (str) {
		var _v1 = $hecrj$html_parser$Html$Parser$run(str);
		if (!_v1.$) {
			var nodes = _v1.a;
			return $mdgriffith$elm_ui$Element$html(
				A2(
					$elm$html$Html$div,
					_List_Nil,
					$hecrj$html_parser$Html$Parser$Util$toVirtualDom(nodes)));
		} else {
			return $mdgriffith$elm_ui$Element$text(str);
		}
	};
	var numberOfLines = config.l.b;
	var isUnsafeHtml = config.q;
	var isEllipsis = config.l.a;
	return isEllipsis ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[config.bB]),
		A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_Utils_ap(
				config.ba,
				_List_fromArray(
					[
						config.m,
						config.hH,
						config.p,
						$mdgriffith$elm_ui$Element$Font$color(config.i),
						A2($author$project$Ui$Style$inlineStyle, 'box-sizing', 'content-box'),
						A2($author$project$Ui$Style$inlineStyle, 'display', '-webkit-box'),
						A2($author$project$Ui$Style$inlineStyle, 'flex-basis', 'auto'),
						A2($author$project$Ui$Style$inlineStyle, 'overflow', 'hidden'),
						A2($author$project$Ui$Style$inlineStyle, 'text-overflow', 'ellipsis'),
						A2($author$project$Ui$Style$inlineStyle, '-webkit-box-orient', 'vertical'),
						A2(
						$author$project$Ui$Style$inlineStyle,
						'-webkit-line-clamp',
						$elm$core$String$fromInt(
							A2($elm$core$Maybe$withDefault, 1, numberOfLines)))
					])),
			A2(
				$elm$core$List$cons,
				isUnsafeHtml ? unsafeHtml(config.eu) : $mdgriffith$elm_ui$Element$text(config.eu),
				$elm$core$List$concat(
					A2(
						$elm$core$List$repeat,
						A2($elm$core$Maybe$withDefault, 1, numberOfLines),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$html(
								A2($elm$html$Html$br, _List_Nil, _List_Nil)),
								$mdgriffith$elm_ui$Element$text('')
							])))))) : A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				config.m,
				config.bB,
				config.hH,
				config.p,
				$mdgriffith$elm_ui$Element$Font$color(config.i)
			]),
		_List_fromArray(
			[
				isUnsafeHtml ? unsafeHtml(config.eu) : $mdgriffith$elm_ui$Element$text(config.eu)
			]));
};
var $author$project$Page$Community$Membership$elmUiTable = A2(
	$mdgriffith$elm_ui$Element$column,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$spacing(10)
		]),
	_List_fromArray(
		[
			$author$project$Ui$Text$view(
			$author$project$Ui$Text$headlineSmall('Mitgliedertarife')),
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$table,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$scrollbarX,
						$author$project$Ui$Style$cellSpacing,
						$author$project$Ui$Style$tableShadow
					]),
				{
					fx: _List_fromArray(
						[
							{
							bt: $author$project$Page$Community$Membership$box(''),
							iq: A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.cU;
								},
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										$mdgriffith$elm_ui$Element$paragraph(
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$alignTop,
													$mdgriffith$elm_ui$Element$padding(5),
													$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
													$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$gray)
												]))))),
							a7: $mdgriffith$elm_ui$Element$shrink
						},
							{
							bt: $author$project$Page$Community$Membership$box('Einzel'),
							iq: A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.c_;
								},
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										$mdgriffith$elm_ui$Element$paragraph(
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$padding(5),
													$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
													$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$lightGray)
												]))))),
							a7: $mdgriffith$elm_ui$Element$shrink
						},
							{
							bt: $author$project$Page$Community$Membership$box('Familie'),
							iq: A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.c1;
								},
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										$mdgriffith$elm_ui$Element$paragraph(
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$padding(5),
													$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
													$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$lightGray)
												]))))),
							a7: $mdgriffith$elm_ui$Element$fill
						},
							{
							bt: $author$project$Page$Community$Membership$box('Firma/Verein'),
							iq: A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.c8;
								},
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										$mdgriffith$elm_ui$Element$paragraph(
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$padding(5),
													$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
													$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$lightGray)
												]))))),
							a7: $mdgriffith$elm_ui$Element$fill
						}
						]),
					fE: _List_fromArray(
						[
							A4($author$project$Page$Community$Membership$Offer, 'Jahresbeitrag', 'CHF 80.–', '+ CHF 40.–', 'CHF 500.–'),
							A4($author$project$Page$Community$Membership$Offer, 'Bedingungen', 'Persönliche Mitgliedschaft', 'Basierend auf Einzelmitgliedschaft. Gesamtrechnung via Hauptkontakt.', 'Bis zu 10 Personen, namentlich aufgelistet, weitere CHF 40.– pro Person. Gesamtrechnung via Firma/Verein.')
						])
				}))
		]));
var $author$project$Ui$Style$introLinkStyle = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
				$author$project$Ui$Font$blissProLight,
				$mdgriffith$elm_ui$Element$spacing(12)
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(16)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(22)
						]);
			}
		}());
};
var $author$project$Page$Community$Membership$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introParagraph, scale, 'Nutzen Sie die Möglichkeit, sich über miaEngiadina im Engadin zu vernetzen und treten Sie der miaEngiadina Community bei. Als Mitglied profitieren Sie von zahlreichen Vorteilen in den Coworking Spaces sowie bei unseren Abonnementen. Zudem können Sie die Coworking Spaces an vier Halbtagen pro Monat kostenlos benutzen.')
		]);
};
var $author$project$Page$Community$Membership$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Jetzt'),
			A2($author$project$Ui$Element$introTitle, scale, 'Mitglied'),
			A2($author$project$Ui$Element$introTitle, scale, 'werden')
		]);
};
var $author$project$Page$Community$Membership$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$Community$Membership$introSectionTitle(scale),
				$author$project$Page$Community$Membership$introSectionText(scale)),
				$author$project$Page$Community$Membership$elmUiTable,
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(40)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Ui$Text$view(
								$author$project$Ui$Text$headlineSmall('Persönliche Mitgliedschaft')),
								A2(
								$mdgriffith$elm_ui$Element$link,
								$author$project$Ui$Style$introLinkStyle(scale),
								{
									gw: $mdgriffith$elm_ui$Element$text('→ Einzelmitgliedschaft anmelden'),
									cQ: 'https://mein.fairgate.ch/miaengiadina/register/MjcxMA=='
								})
							])),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(5)
							]),
						_List_fromArray(
							[
								$author$project$Ui$Text$view(
								$author$project$Ui$Text$headlineSmall('Familienmitgliedschaft')),
								A2(
								$mdgriffith$elm_ui$Element$link,
								$author$project$Ui$Style$introLinkStyle(scale),
								{
									gw: $mdgriffith$elm_ui$Element$text('→ Familien-Hauptkontakt anmelden'),
									cQ: 'https://mein.fairgate.ch/miaengiadina/register/Njg1Nw=='
								}),
								A2(
								$mdgriffith$elm_ui$Element$link,
								$author$project$Ui$Style$introLinkStyle(scale),
								{
									gw: $mdgriffith$elm_ui$Element$text('→ Familienmitglieder anmelden'),
									cQ: 'https://mein.fairgate.ch/miaengiadina/register/Njg2MQ=='
								})
							])),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(5)
							]),
						_List_fromArray(
							[
								$author$project$Ui$Text$view(
								$author$project$Ui$Text$headlineSmall('Firma/Verein Mitgliedschaft')),
								A2(
								$mdgriffith$elm_ui$Element$link,
								$author$project$Ui$Style$introLinkStyle(scale),
								{
									gw: $mdgriffith$elm_ui$Element$text('→ Firma/Verein anmelden'),
									cQ: 'https://mein.fairgate.ch/miaengiadina/register/Njk2NA=='
								}),
								A2(
								$mdgriffith$elm_ui$Element$link,
								$author$project$Ui$Style$introLinkStyle(scale),
								{
									gw: $mdgriffith$elm_ui$Element$text('→ Firmenmitarbeitende/Vereinsmitglieder anmelden'),
									cQ: 'https://mein.fairgate.ch/miaengiadina/register/NjU0NA=='
								})
							]))
					]))
			]));
};
var $author$project$Ui$Element$textTitleGray = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$text,
	$mdgriffith$elm_ui$Element$el(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$mdgriffith$elm_ui$Element$Font$size(24),
				$author$project$Ui$Font$blissProBold
			])));
var $author$project$Page$Contact$contactSection = A2(
	$mdgriffith$elm_ui$Element$column,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$spacing(40)
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Element$textTitleGray('Allgemeine Auskünfte und Reservierungen'),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$link,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$underline,
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Font$blissProLight
								]),
							{
								gw: $mdgriffith$elm_ui$Element$text('081 508 05 05'),
								cQ: 'tel:' + '081 508 05 05'
							}),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Font$blissProLight
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Mo-Fr 8:30-12:00, 13:30-17:00')
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('info@miaEngiadina.ch'),
						cQ: 'mailto:' + 'info@miaEngiadina.ch'
					})
				])),
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Element$textTitleGray('Technische Unterstützung für Privatkunden'),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$link,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$underline,
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Font$blissProLight
								]),
							{
								gw: $mdgriffith$elm_ui$Element$text('081 508 05 06'),
								cQ: 'tel:' + '081 508 05 06'
							}),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Font$blissProLight
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Mo-Fr 8:30-12:00, 13:30-17:00')
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('support@miaEngiadina.ch'),
						cQ: 'mailto:' + 'support@miaEngiadina.ch'
					})
				])),
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Element$textTitleGray('Technische Unterstützung für Geschäftskunden'),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$link,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$underline,
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Font$blissProLight
								]),
							{
								gw: $mdgriffith$elm_ui$Element$text('081 508 05 07'),
								cQ: 'tel:' + '081 508 05 07'
							}),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Font$blissProLight
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Mo-Fr 8:30-12:00, 13:30-17:00')
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('support@miaEngiadina.ch'),
						cQ: 'mailto:' + 'support@miaEngiadina.ch'
					})
				])),
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Element$textTitleGray('Medienanfragen, Anfragen für Referate und Sponsoring'),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('media@miaEngiadina.ch'),
						cQ: 'mailto:' + 'media@miaEngiadina.ch'
					})
				])),
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Element$textTitleGray('Anschrift'),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('miaEngiadina')
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Stradun 400A')
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('7550 Scuol')
								]))
						]))
				])),
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Element$textTitleGray('Datenschutz'),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('Datenschutzerklärung mia Engiadina Marketing SA'),
						cQ: 'https://prismic-io.s3.amazonaws.com/miaengiadina/cc1da678-cbfe-4933-bfa0-315000567b86_2308119+Datenschutzerkl%C3%A4rung+MEMSA.pdf'
					}),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('Datenschutzerklärung mia Engiadina Network SA'),
						cQ: 'https://prismic-io.s3.amazonaws.com/miaengiadina/c4089d8d-d854-49a9-a2ce-9968b116c831_2308119+Datenschutzerkl%C3%A4rung+MENSA.pdf'
					}),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('Datenschutzerklärung mia Engiadina Verein / Community\n'),
						cQ: 'https://prismic-io.s3.amazonaws.com/miaengiadina/7d708ea2-f617-4e75-97bb-2f92bb28ca54_2308119+Datenschutzerkl%C3%A4rung+Community.pdf'
					})
				]))
		]));
var $author$project$Page$Contact$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introParagraph, scale, 'Gerne geben wir Ihnen detaillierte Auskunft zu miaEngiadina. Über Fragen oder Anregungen freuen wir uns.')
		]);
};
var $author$project$Page$Contact$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Kontakt')
		]);
};
var $author$project$FontAwesome$Brands$facebookF = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fab',
	'facebook-f',
	320,
	512,
	_List_fromArray(
		['M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z']));
var $author$project$Ui$Element$iconBtn = F2(
	function (url, icon_) {
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(30)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(30))
				]),
			{
				gw: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$centerX]),
					$author$project$Ui$Element$faIcon(icon_)),
				cQ: url
			});
	});
var $author$project$FontAwesome$Brands$instagram = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fab',
	'instagram',
	448,
	512,
	_List_fromArray(
		['M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z']));
var $author$project$FontAwesome$Brands$linkedinIn = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fab',
	'linkedin-in',
	448,
	512,
	_List_fromArray(
		['M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z']));
var $author$project$FontAwesome$Brands$twitter = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fab',
	'twitter',
	512,
	512,
	_List_fromArray(
		['M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z']));
var $author$project$Ui$Element$socialMedia = function (scale) {
	var responsiveStyle = function () {
		switch (scale) {
			case 0:
				return _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(30),
						$mdgriffith$elm_ui$Element$spacing(32),
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20)
					]);
			case 1:
				return _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(46),
						$mdgriffith$elm_ui$Element$spacing(80),
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 40)
					]);
			case 2:
				return _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(54),
						$mdgriffith$elm_ui$Element$spacing(100),
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 40)
					]);
			default:
				return _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(58),
						$mdgriffith$elm_ui$Element$spacing(120),
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 50)
					]);
		}
	}();
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
				]),
			responsiveStyle),
		_List_fromArray(
			[
				A2($author$project$Ui$Element$iconBtn, 'https://www.facebook.com/miaEngiadina/', $author$project$FontAwesome$Brands$facebookF),
				A2($author$project$Ui$Element$iconBtn, 'https://www.linkedin.com/company/mia-engiadina', $author$project$FontAwesome$Brands$linkedinIn),
				A2($author$project$Ui$Element$iconBtn, 'https://www.instagram.com/miaEngiadina/', $author$project$FontAwesome$Brands$instagram),
				A2($author$project$Ui$Element$iconBtn, 'https://twitter.com/miaEngiadina', $author$project$FontAwesome$Brands$twitter)
			]));
};
var $author$project$Page$Contact$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$Contact$introSectionTitle(scale),
				$author$project$Page$Contact$introSectionText(scale)),
				$author$project$Page$Contact$contactSection,
				$author$project$Ui$Element$socialMedia(scale)
			]));
};
var $author$project$Ui$Style$pageMaximumWidth = function (scale) {
	return $author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill) : $mdgriffith$elm_ui$Element$width(
		A2($mdgriffith$elm_ui$Element$maximum, 1600, $mdgriffith$elm_ui$Element$fill));
};
var $author$project$Ui$Element$landingPageColumn = function (scale) {
	return $mdgriffith$elm_ui$Element$column(
		_List_fromArray(
			[
				$author$project$Ui$Style$pageMaximumWidth(scale),
				$mdgriffith$elm_ui$Element$centerX
			]));
};
var $author$project$Ui$Font$colorForBody = $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray);
var $author$project$Ui$Font$body1Color = $author$project$Ui$Font$colorForBody;
var $author$project$Ui$Font$body1FontSize = function (scale) {
	return $mdgriffith$elm_ui$Element$Font$size(
		function () {
			switch (scale) {
				case 0:
					return 18;
				case 1:
					return 18;
				case 2:
					return 20;
				default:
					return 20;
			}
		}());
};
var $author$project$Ui$Font$body1Padding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 10, H: 0, L: 0, O: 10});
var $author$project$Ui$Font$body1Spacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$body1Typeface = $author$project$Ui$Font$blissProRegular;
var $author$project$Ui$Font$body1 = function (scale) {
	return _List_fromArray(
		[
			$author$project$Ui$Font$body1Typeface,
			$author$project$Ui$Font$body1FontSize(scale),
			$author$project$Ui$Font$body1Spacing,
			$author$project$Ui$Font$body1Padding,
			$author$project$Ui$Font$body1Color
		]);
};
var $mdgriffith$elm_ui$Internal$Flag$fontAlignment = $mdgriffith$elm_ui$Internal$Flag$flag(12);
var $mdgriffith$elm_ui$Element$Font$center = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.h_);
var $author$project$Ui$Font$body2Color = $author$project$Ui$Font$colorForBody;
var $author$project$Ui$Font$body2FontSize = function (scale) {
	return $mdgriffith$elm_ui$Element$Font$size(
		function () {
			switch (scale) {
				case 0:
					return 16;
				case 1:
					return 16;
				case 2:
					return 18;
				default:
					return 18;
			}
		}());
};
var $author$project$Ui$Font$body2Padding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 5, H: 0, L: 0, O: 5});
var $author$project$Ui$Font$body2Spacing = $mdgriffith$elm_ui$Element$spacing(5);
var $author$project$Ui$Font$body2Typeface = $author$project$Ui$Font$blissProRegular;
var $author$project$Ui$Font$body2 = function (scale) {
	return _List_fromArray(
		[
			$author$project$Ui$Font$body2Typeface,
			$author$project$Ui$Font$body2FontSize(scale),
			$author$project$Ui$Font$body2Spacing,
			$author$project$Ui$Font$body2Padding,
			$author$project$Ui$Font$body2Color
		]);
};
var $author$project$Page$Digital$featureBoxes = F2(
	function (scale, featureSectionRepeatableGroup) {
		return _Utils_Tuple2(
			1,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[
								$author$project$Ui$Font$h2Spacing,
								$author$project$Ui$Font$h2FontSize(scale),
								$author$project$Ui$Font$h2Typeface
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(featureSectionRepeatableGroup.ie)
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						$author$project$Ui$Font$body2(scale),
						_List_fromArray(
							[
								A3(
								$author$project$Support$structuredTextAsHtml,
								scale,
								$elm$core$Maybe$Just(
									_Utils_update(
										$author$project$Support$default,
										{
											cw: $author$project$Ui$Font$body2(scale)
										})),
								featureSectionRepeatableGroup.x)
							]))
					])));
	});
var $author$project$Ui$Style$pageSectionPadding = function (scale) {
	return $author$project$Ui$Scale$isXsSm(scale) ? A2($mdgriffith$elm_ui$Element$paddingXY, 20, 40) : A2($mdgriffith$elm_ui$Element$paddingXY, 60, 80);
};
var $author$project$Ui$Element$landingPageSectionElement = function (scale) {
	return $mdgriffith$elm_ui$Element$el(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$centerX,
				$author$project$Ui$Style$pageSectionPadding(scale)
			]));
};
var $mdgriffith$elm_ui$Element$fillPortion = $mdgriffith$elm_ui$Internal$Model$Fill;
var $author$project$Ui$Component$Matrix$view = F2(
	function (config, list) {
		var fn = F2(
			function (_v1, _v2) {
				var sizeElement = _v1.a;
				var el_ = _v1.b;
				var cur = _v2.a;
				var row_ = _v2.b;
				var col_ = _v2.c;
				return (cur <= 0) ? _Utils_Tuple3(
					config.gK - sizeElement,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(sizeElement))
								]),
							el_)
						]),
					A2(
						$elm$core$List$append,
						col_,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										config.hH
									]),
								row_)
							]))) : _Utils_Tuple3(
					cur - sizeElement,
					A2(
						$elm$core$List$append,
						row_,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(sizeElement))
									]),
								el_)
							])),
					col_);
			});
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					config.hH
				]),
			function (_v0) {
				var restSize = _v0.a;
				var row_ = _v0.b;
				var col_ = _v0.c;
				return $elm$core$List$isEmpty(row_) ? col_ : (config.fY ? A2(
					$elm$core$List$append,
					col_,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									config.hH
								]),
							row_)
						])) : A2(
					$elm$core$List$append,
					col_,
					$elm$core$List$singleton(
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									config.hH
								]),
							A2(
								$elm$core$List$append,
								row_,
								A2(
									$elm$core$List$repeat,
									restSize,
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$fillPortion(1))
											]),
										$mdgriffith$elm_ui$Element$none)))))));
			}(
				A3(
					$elm$core$List$foldl,
					fn,
					_Utils_Tuple3(config.gK, _List_Nil, _List_Nil),
					list)));
	});
var $author$project$Page$Digital$viewFeatureSection = F2(
	function (scale, _v0) {
		var featureSectionGroup = _v0.a;
		var featureSectionRepeatableGroup = _v0.b;
		return (!featureSectionGroup.r) ? $mdgriffith$elm_ui$Element$none : A2(
			$author$project$Ui$Element$landingPageSectionElement,
			scale,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(60)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$spacing(10)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
										$author$project$Ui$Font$h1Spacing,
										$author$project$Ui$Font$h1FontSize(scale),
										$author$project$Ui$Font$h1Typeface,
										$mdgriffith$elm_ui$Element$Font$center
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(featureSectionGroup.eh)
									])),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
										$mdgriffith$elm_ui$Element$Font$center
									]),
								A3(
									$author$project$Support$structuredTextAsHtml,
									scale,
									$elm$core$Maybe$Just(
										_Utils_update(
											$author$project$Support$default,
											{
												aV: $author$project$Ui$Font$h1(scale),
												b5: $author$project$Ui$Font$h2(scale),
												bu: $author$project$Ui$Font$h3(scale),
												cw: $author$project$Ui$Font$body1(scale)
											})),
									featureSectionGroup.hv))
							])),
						A2(
						$author$project$Ui$Component$Matrix$view,
						{
							fY: true,
							gK: function () {
								switch (scale) {
									case 0:
										return 1;
									case 1:
										return 1;
									case 2:
										return 4;
									default:
										return 4;
								}
							}(),
							hH: $author$project$Ui$Style$spacing(scale)
						},
						A2(
							$elm$core$List$map,
							$author$project$Page$Digital$featureBoxes(scale),
							featureSectionRepeatableGroup))
					])));
	});
var $author$project$Page$Digital$viewBody = F2(
	function (scale, content) {
		var data = content;
		return A2($author$project$Page$Digital$viewFeatureSection, scale, data);
	});
var $author$project$Page$Digital$viewContent = F2(
	function (scale, content) {
		return A2(
			$author$project$Ui$Element$landingPageColumn,
			scale,
			A2(
				$elm$core$List$map,
				$author$project$Page$Digital$viewBody(scale),
				content));
	});
var $author$project$Page$Digital$view = F2(
	function (scale, content) {
		return A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_ui$Element$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$Digital$viewContent(scale),
				content));
	});
var $author$project$Page$GutscheinCoworking$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				$author$project$Typeform$view('mGGEd1my')
			]));
};
var $author$project$Page$Gv$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introParagraph, scale, '')
		]);
};
var $author$project$Page$Gv$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Anmeldung zur'),
			A2($author$project$Ui$Element$introTitle, scale, 'Generalversammlung'),
			A2($author$project$Ui$Element$introTitle, scale, '12.09.2020')
		]);
};
var $author$project$Page$Gv$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$Gv$introSectionTitle(scale),
				$author$project$Page$Gv$introSectionText(scale)),
				$author$project$Typeform$view('ZPCS6jP7')
			]));
};
var $author$project$Ui$Font$bodyLargeFontSize = $mdgriffith$elm_ui$Element$Font$size(18);
var $author$project$Ui$Font$bodyLargePadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 12, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$bodyLargeSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$bodyTypeface = $author$project$Ui$Font$blissProRegular;
var $author$project$Ui$Text$bodyLarge = function (text) {
	return {
		ba: _List_Nil,
		i: $author$project$Ui$Color$font,
		m: $author$project$Ui$Font$bodyLargeFontSize,
		bB: $author$project$Ui$Font$bodyLargePadding,
		hH: $author$project$Ui$Font$bodyLargeSpacing,
		eu: text,
		l: _Utils_Tuple2(false, $elm$core$Maybe$Nothing),
		p: $author$project$Ui$Font$bodyTypeface,
		q: false
	};
};
var $author$project$Ui$Font$displayLargeFontSize = $mdgriffith$elm_ui$Element$Font$size(57);
var $author$project$Ui$Font$displaySmallFontSize = $mdgriffith$elm_ui$Element$Font$size(36);
var $author$project$Ui$Button$Button = $elm$core$Basics$identity;
var $author$project$Ui$Style$shadow = $mdgriffith$elm_ui$Element$Border$shadow(
	{
		fb: 3,
		aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
		aH: _Utils_Tuple2(0, 0),
		aI: 1
	});
var $author$project$Ui$Button$elevated = function (text) {
	return {
		aR: $author$project$Ui$Color$white,
		bh: $author$project$Ui$Style$border,
		bi: $author$project$Ui$Style$shadow,
		aS: $author$project$Ui$Color$white,
		bj: 0,
		b9: $mdgriffith$elm_ui$Element$height(
			$mdgriffith$elm_ui$Element$px(40)),
		aY: $author$project$Ui$Color$grayDark,
		bB: $mdgriffith$elm_ui$Element$paddingEach(
			{F: 10, H: 20, L: 20, O: 10}),
		eu: text,
		cQ: '',
		a7: $mdgriffith$elm_ui$Element$width(
			A2($mdgriffith$elm_ui$Element$minimum, 80, $mdgriffith$elm_ui$Element$fill))
	};
};
var $author$project$Ui$Element$link = F3(
	function (url, attributes, label) {
		return A2(
			$mdgriffith$elm_ui$Element$link,
			attributes,
			{gw: label, cQ: url});
	});
var $mdgriffith$elm_ui$Internal$Model$AsTextColumn = 5;
var $mdgriffith$elm_ui$Internal$Model$asTextColumn = 5;
var $mdgriffith$elm_ui$Element$textColumn = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asTextColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width(
					A2(
						$mdgriffith$elm_ui$Element$maximum,
						750,
						A2($mdgriffith$elm_ui$Element$minimum, 500, $mdgriffith$elm_ui$Element$fill))),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Flag$borderColor = $mdgriffith$elm_ui$Internal$Flag$flag(28);
var $mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var $author$project$Ui$Font$labelLargeFontSize = $mdgriffith$elm_ui$Element$Font$size(14);
var $author$project$Ui$Font$labelLargeSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$labelTypeface = $author$project$Ui$Font$blissProRegular;
var $author$project$Ui$Style$uppercase = $mdgriffith$elm_ui$Element$htmlAttribute(
	A2($elm$html$Html$Attributes$style, 'text-transform', 'uppercase'));
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $author$project$Ui$Button$view = function (_v0) {
	var config = _v0;
	return A2(
		$mdgriffith$elm_ui$Element$link,
		_List_fromArray(
			[
				config.bh,
				config.bi,
				config.b9,
				config.a7,
				$mdgriffith$elm_ui$Element$Background$color(config.aR),
				$mdgriffith$elm_ui$Element$Border$color(config.aS),
				$mdgriffith$elm_ui$Element$Border$width(config.bj)
			]),
		{
			gw: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							config.bB,
							$mdgriffith$elm_ui$Element$Font$color(config.aY),
							$author$project$Ui$Font$labelLargeFontSize,
							$author$project$Ui$Font$labelLargeSpacing,
							$author$project$Ui$Font$labelTypeface,
							$author$project$Ui$Style$uppercase
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(config.eu)
						]))),
			cQ: config.cQ
		});
};
var $author$project$Ui$Button$withContainerColor = F2(
	function (containerColor, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{aR: containerColor});
	});
var $author$project$Ui$Button$withOnContainerColor = F2(
	function (onContainerColor, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{aY: onContainerColor});
	});
var $author$project$Page$Section$Feature4$view = F2(
	function (scale, config) {
		var button2 = A3(
			$author$project$Ui$Element$link,
			config.fl,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$author$project$Ui$Button$view(
				$author$project$Ui$Button$elevated('MEHR INFORMATIONEN')));
		var button1 = A3(
			$author$project$Ui$Element$link,
			'https://www.miaengiadina.ch/contact',
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$author$project$Ui$Button$view(
				A2(
					$author$project$Ui$Button$withOnContainerColor,
					$author$project$Ui$Color$white,
					A2(
						$author$project$Ui$Button$withContainerColor,
						$author$project$Ui$Color$gray,
						$author$project$Ui$Button$elevated('KONTAKT')))));
		var buttonBlock = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[button1, button2]));
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$pageSectionPadding(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(60)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$spacing(20)
							]),
						_List_fromArray(
							[
								$author$project$Ui$Scale$isXsSm(scale) ? A2(
								$mdgriffith$elm_ui$Element$textColumn,
								_List_fromArray(
									[
										$author$project$Ui$Font$displaySmallFontSize,
										$mdgriffith$elm_ui$Element$spacing(10),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$font)
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('Internet')
												]))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('da miaEngiadina')
												])))
									])) : A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$author$project$Ui$Font$displayLargeFontSize,
										$mdgriffith$elm_ui$Element$spacing(10)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$font)
											]),
										$mdgriffith$elm_ui$Element$text('Internet ')),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
											]),
										$mdgriffith$elm_ui$Element$text('da miaEngiadina'))
									])),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill)),
										$mdgriffith$elm_ui$Element$Font$center,
										$mdgriffith$elm_ui$Element$centerX
									]),
								$author$project$Ui$Text$view(
									$author$project$Ui$Text$bodyLarge('miaEngiadina holt mit Glasfasertechnologie die Welt ins Engadin und öffnet gleichzeitig dem Engadin die Welt. So baut miaEngiadina ein leistungsstarkes Glasfasernetz auf und unterstützt die digitale Transformation im Engadin.'))),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								buttonBlock)
							]))
					])));
	});
var $author$project$Ui$Font$bodyColor = $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray);
var $author$project$Ui$Font$bodyLarge = _List_fromArray(
	[$author$project$Ui$Font$bodyColor, $author$project$Ui$Font$bodyTypeface, $author$project$Ui$Font$bodyLargeFontSize, $author$project$Ui$Font$bodyLargeSpacing, $author$project$Ui$Font$bodyLargePadding]);
var $author$project$Ui$Font$button1Color = $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white);
var $author$project$Ui$Font$buttonFontSize = function (scale) {
	return $mdgriffith$elm_ui$Element$Font$size(
		function () {
			switch (scale) {
				case 0:
					return 16;
				case 1:
					return 16;
				case 2:
					return 16;
				default:
					return 16;
			}
		}());
};
var $author$project$Ui$Font$buttonPadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 15, H: 15, L: 15, O: 15});
var $author$project$Ui$Font$buttonSpacing = $mdgriffith$elm_ui$Element$spacing(5);
var $author$project$Ui$Font$buttonTypeface = _List_fromArray(
	[
		$author$project$Ui$Font$blissProRegular,
		$mdgriffith$elm_ui$Element$htmlAttribute(
		A2($elm$html$Html$Attributes$style, 'text-transform', 'uppercase'))
	]);
var $author$project$Ui$Font$button1 = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$author$project$Ui$Font$buttonFontSize(scale),
				$author$project$Ui$Font$buttonSpacing,
				$author$project$Ui$Font$buttonPadding,
				$author$project$Ui$Font$button1Color,
				$mdgriffith$elm_ui$Element$centerX
			]),
		$author$project$Ui$Font$buttonTypeface);
};
var $author$project$Ui$Font$displayColor = $mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$grayDark);
var $author$project$Ui$Font$displayLargePadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 28, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$displayLargeSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$displayTypeface = $author$project$Ui$Font$blissProLight;
var $author$project$Ui$Font$displayLarge = _List_fromArray(
	[$author$project$Ui$Font$displayColor, $author$project$Ui$Font$displayTypeface, $author$project$Ui$Font$displayLargeFontSize, $author$project$Ui$Font$displayLargeSpacing, $author$project$Ui$Font$displayLargePadding]);
var $author$project$Ui$Text$displayLarge = function (text) {
	return {
		ba: _List_Nil,
		i: $author$project$Ui$Color$font,
		m: $author$project$Ui$Font$displayLargeFontSize,
		bB: $author$project$Ui$Font$displayLargePadding,
		hH: $author$project$Ui$Font$displayLargeSpacing,
		eu: text,
		l: _Utils_Tuple2(false, $elm$core$Maybe$Nothing),
		p: $author$project$Ui$Font$displayTypeface,
		q: false
	};
};
var $author$project$Ui$Font$displayMediumFontSize = $mdgriffith$elm_ui$Element$Font$size(45);
var $author$project$Ui$Font$displayMediumPadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 22, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$displayMediumSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$displayMedium = _List_fromArray(
	[$author$project$Ui$Font$displayColor, $author$project$Ui$Font$displayTypeface, $author$project$Ui$Font$displayMediumFontSize, $author$project$Ui$Font$displayMediumSpacing, $author$project$Ui$Font$displayMediumPadding]);
var $author$project$Ui$Font$displaySmallPadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 18, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$displaySmallSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Font$displaySmall = _List_fromArray(
	[$author$project$Ui$Font$displayColor, $author$project$Ui$Font$displayTypeface, $author$project$Ui$Font$displaySmallFontSize, $author$project$Ui$Font$displaySmallSpacing, $author$project$Ui$Font$displaySmallPadding]);
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Ui$Element$icon = function (iconName) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$centerX]),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'class', iconName)
					]),
				_List_Nil)));
};
var $author$project$Page$Section$featureBoxes = F2(
	function (scale, featureSectionRepeatableGroup) {
		var icon = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$Font$size(50),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$blue)
				]),
			$author$project$Ui$Element$icon(
				A2($elm$core$Maybe$withDefault, '', featureSectionRepeatableGroup.ds)));
		return _Utils_Tuple2(
			1,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(20)
							]),
						_List_fromArray(
							[
								icon,
								$author$project$Ui$Text$view(
								$author$project$Ui$Text$headlineSmall(featureSectionRepeatableGroup.ie))
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								A3(
								$author$project$Support$structuredTextAsHtml,
								scale,
								$elm$core$Maybe$Just(
									_Utils_update(
										$author$project$Support$default,
										{
											cw: _List_fromArray(
												[$author$project$Ui$Font$bodyColor, $author$project$Ui$Font$bodyTypeface, $author$project$Ui$Font$bodyLargeFontSize, $author$project$Ui$Font$bodyLargeSpacing])
										})),
								featureSectionRepeatableGroup.x)
							]))
					])));
	});
var $author$project$Page$Section$viewFeature = F2(
	function (scale, _v0) {
		var featureSectionGroup = _v0.a;
		var featureSectionRepeatableGroup = _v0.b;
		var buttonText = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$minimum, 200, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
					$author$project$Ui$Style$shadow,
					$author$project$Ui$Style$border,
					$mdgriffith$elm_ui$Element$clip
				]),
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(10),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
							$mdgriffith$elm_ui$Element$Font$center
						]),
					$author$project$Ui$Font$button1(scale)),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(featureSectionGroup.aA)
					])));
		var button = function () {
			if (!featureSectionGroup.fm) {
				return $mdgriffith$elm_ui$Element$none;
			} else {
				var _v2 = featureSectionGroup.az;
				if (_v2.$ === 1) {
					var target = _v2.a;
					var url = _v2.b;
					var linkFunction = function () {
						if (!target.$) {
							return $mdgriffith$elm_ui$Element$newTabLink;
						} else {
							return $mdgriffith$elm_ui$Element$link;
						}
					}();
					return A2(
						linkFunction,
						_List_Nil,
						{gw: buttonText, cQ: url});
				} else {
					return buttonText;
				}
			}
		}();
		return (!featureSectionGroup.r) ? $mdgriffith$elm_ui$Element$none : A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$pageSectionPadding(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(60)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$spacing(20)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$center, $mdgriffith$elm_ui$Element$centerX]),
								$author$project$Ui$Text$view(
									$author$project$Ui$Text$displayLarge(featureSectionGroup.eh))),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill)),
										$mdgriffith$elm_ui$Element$Font$center
									]),
								A3(
									$author$project$Support$structuredTextAsHtml,
									scale,
									$elm$core$Maybe$Just(
										_Utils_update(
											$author$project$Support$default,
											{aV: $author$project$Ui$Font$displayLarge, b5: $author$project$Ui$Font$displayMedium, bu: $author$project$Ui$Font$displaySmall, cw: $author$project$Ui$Font$bodyLarge})),
									featureSectionGroup.hv)),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								button)
							])),
						A2(
						$author$project$Ui$Component$Matrix$view,
						{
							fY: true,
							gK: function () {
								switch (scale) {
									case 0:
										return 1;
									case 1:
										return 1;
									case 2:
										return 4;
									default:
										return 4;
								}
							}(),
							hH: $author$project$Ui$Style$spacing(scale)
						},
						A2(
							$elm$core$List$map,
							$author$project$Page$Section$featureBoxes(scale),
							featureSectionRepeatableGroup))
					])));
	});
var $author$project$Page$Internet$Company$viewBody = F2(
	function (scale, content) {
		var data = content;
		return A2($author$project$Page$Section$viewFeature, scale, data);
	});
var $author$project$Page$Section$InternetCompanyPricing$dataForCard1 = {
	aO: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '250 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: '24/7 Störungsannahme'},
			{ds: 'fa-solid fa-check', eu: '1 Arbeitstag Störungsbehebung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Vorteile miaEngiadina Mitgliedschaft'}
		]),
	aP: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '250 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: '24/7 Störungsannahme'},
			{ds: 'fa-solid fa-check', eu: '1 Arbeitstag Störungsbehebung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'}
		]),
	aQ: 'IM ABO ENTHALTEN',
	ag: {au: '95', bC: 'CHF / Monat', bD: '110'},
	aU: 'miaEngiadina Internet',
	ds: 'fa-solid fa-s',
	U: false
};
var $author$project$Page$Section$InternetCompanyPricing$dataForCard2 = {
	aO: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '600 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: '24/7 Störungsannahme'},
			{ds: 'fa-solid fa-check', eu: '1 Arbeitstag Störungsbehebung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Vorteile miaEngiadina Mitgliedschaft'}
		]),
	aP: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '600 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: '24/7 Störungsannahme'},
			{ds: 'fa-solid fa-check', eu: '1 Arbeitstag Störungsbehebung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'}
		]),
	aQ: 'IM ABO ENTHALTEN',
	ag: {au: '140', bC: 'CHF / Monat', bD: '155'},
	aU: 'miaEngiadina Internet',
	ds: 'fa-solid fa-m',
	U: true
};
var $author$project$Page$Section$InternetCompanyPricing$dataForCard3 = {
	aO: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '800 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: '24/7 Störungsannahme'},
			{ds: 'fa-solid fa-check', eu: '1 Arbeitstag Störungsbehebung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Vorteile miaEngiadina Mitgliedschaft'}
		]),
	aP: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '800 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: '24/7 Störungsannahme'},
			{ds: 'fa-solid fa-check', eu: '1 Arbeitstag Störungsbehebung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'}
		]),
	aQ: 'IM ABO ENTHALTEN',
	ag: {au: '260', bC: 'CHF / Monat', bD: '290'},
	aU: 'miaEngiadina Internet',
	ds: 'fa-solid fa-l',
	U: false
};
var $author$project$Page$Section$InternetCompanyPricing$dataForCards = _List_fromArray(
	[$author$project$Page$Section$InternetCompanyPricing$dataForCard1, $author$project$Page$Section$InternetCompanyPricing$dataForCard2, $author$project$Page$Section$InternetCompanyPricing$dataForCard3]);
var $author$project$Types$InternetPricingSwitched = {$: 17};
var $author$project$Ui$Font$bodySmallFontSize = $mdgriffith$elm_ui$Element$Font$size(14);
var $author$project$Ui$Font$bodySmallPadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 8, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$bodySmallSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Text$bodySmall = function (text) {
	return {
		ba: _List_Nil,
		i: $author$project$Ui$Color$font,
		m: $author$project$Ui$Font$bodySmallFontSize,
		bB: $author$project$Ui$Font$bodySmallPadding,
		hH: $author$project$Ui$Font$bodySmallSpacing,
		eu: text,
		l: _Utils_Tuple2(false, $elm$core$Maybe$Nothing),
		p: $author$project$Ui$Font$bodyTypeface,
		q: false
	};
};
var $author$project$Ui$Font$headlineLargeFontSize = $mdgriffith$elm_ui$Element$Font$size(32);
var $author$project$Ui$Font$headlineLargePadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 18, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$headlineLargeSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Text$headlineLarge = function (text) {
	return {
		ba: _List_Nil,
		i: $author$project$Ui$Color$font,
		m: $author$project$Ui$Font$headlineLargeFontSize,
		bB: $author$project$Ui$Font$headlineLargePadding,
		hH: $author$project$Ui$Font$headlineLargeSpacing,
		eu: text,
		l: _Utils_Tuple2(false, $elm$core$Maybe$Nothing),
		p: $author$project$Ui$Font$headlineTypeface,
		q: false
	};
};
var $mdgriffith$elm_ui$Internal$Model$Right = 2;
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX(2);
var $author$project$Ui$Icon$Icon = $elm$core$Basics$identity;
var $author$project$Ui$Font$bodyMediumFontSize = $mdgriffith$elm_ui$Element$Font$size(16);
var $author$project$Ui$Icon$bodyMedium = function (text) {
	return {ba: _List_Nil, s: text, n: $author$project$Ui$Color$font, t: $author$project$Ui$Font$bodyMediumFontSize};
};
var $author$project$Ui$Font$bodyMediumPadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 10, H: 0, L: 0, O: 0});
var $author$project$Ui$Font$bodyMediumSpacing = $mdgriffith$elm_ui$Element$spacing(10);
var $author$project$Ui$Text$bodyMedium = function (text) {
	return {
		ba: _List_Nil,
		i: $author$project$Ui$Color$font,
		m: $author$project$Ui$Font$bodyMediumFontSize,
		bB: $author$project$Ui$Font$bodyMediumPadding,
		hH: $author$project$Ui$Font$bodyMediumSpacing,
		eu: text,
		l: _Utils_Tuple2(false, $elm$core$Maybe$Nothing),
		p: $author$project$Ui$Font$bodyTypeface,
		q: false
	};
};
var $author$project$Ui$Font$labelLargePadding = $mdgriffith$elm_ui$Element$paddingEach(
	{F: 10, H: 0, L: 0, O: 0});
var $author$project$Ui$Text$labelLarge = function (text) {
	return {
		ba: _List_Nil,
		i: $author$project$Ui$Color$font,
		m: $author$project$Ui$Font$labelLargeFontSize,
		bB: $author$project$Ui$Font$labelLargePadding,
		hH: $author$project$Ui$Font$labelLargeSpacing,
		eu: text,
		l: _Utils_Tuple2(false, $elm$core$Maybe$Nothing),
		p: $author$project$Ui$Font$labelTypeface,
		q: false
	};
};
var $author$project$Ui$Font$titleMediumFontSize = $mdgriffith$elm_ui$Element$Font$size(16);
var $author$project$Ui$Icon$titleMedium = function (text) {
	return {ba: _List_Nil, s: text, n: $author$project$Ui$Color$font, t: $author$project$Ui$Font$titleMediumFontSize};
};
var $author$project$Ui$Icon$view = function (_v0) {
	var config = _v0;
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_Utils_ap(
			config.ba,
			_List_fromArray(
				[
					config.t,
					$mdgriffith$elm_ui$Element$Font$color(config.n)
				])),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'class', config.s)
					]),
				_List_Nil)));
};
var $author$project$Ui$Icon$withAttributes = F2(
	function (attributes, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{ba: attributes});
	});
var $author$project$Ui$Text$withFontColor = F2(
	function (color, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{i: color});
	});
var $author$project$Ui$Icon$withIconColor = F2(
	function (iconColor, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{n: iconColor});
	});
var $author$project$Page$Section$InternetCompanyPricing$viewCard = F2(
	function (model, config) {
		var styleColor = config.U ? $author$project$Ui$Color$red : $author$project$Ui$Color$blue;
		var iconBlock = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(50)),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(50)),
					$mdgriffith$elm_ui$Element$Background$color(styleColor),
					$author$project$Ui$Style$border,
					$mdgriffith$elm_ui$Element$centerX
				]),
			$author$project$Ui$Icon$view(
				A2(
					$author$project$Ui$Icon$withIconColor,
					$author$project$Ui$Color$white,
					A2(
						$author$project$Ui$Icon$withAttributes,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
						$author$project$Ui$Icon$titleMedium(config.ds)))));
		var headerBlock = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(30)
				]),
			_List_fromArray(
				[
					iconBlock,
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$author$project$Ui$Text$view(
						$author$project$Ui$Text$bodyLarge(config.aU))),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$alignBottom]),
							$author$project$Ui$Text$view(
								$author$project$Ui$Text$headlineLarge(
									model.by ? config.ag.bD : config.ag.au))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignLeft,
									$mdgriffith$elm_ui$Element$alignBottom,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$paddingEach(
									{F: 8, H: 0, L: 0, O: 0})
								]),
							$author$project$Ui$Text$view(
								$author$project$Ui$Text$bodyLarge(config.ag.bC)))
						])),
					A3(
					$author$project$Ui$Element$link,
					'https://fysitech.typeform.com/internetGK',
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					$author$project$Ui$Button$view(
						A2(
							$author$project$Ui$Button$withOnContainerColor,
							$author$project$Ui$Color$white,
							A2(
								$author$project$Ui$Button$withContainerColor,
								styleColor,
								$author$project$Ui$Button$elevated('BESTELLEN')))))
				]));
		var bodyContent = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$map,
				function (content) {
					return A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								$author$project$Ui$Icon$view(
								A2(
									$author$project$Ui$Icon$withIconColor,
									styleColor,
									A2(
										$author$project$Ui$Icon$withAttributes,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$alignTop]),
										$author$project$Ui$Icon$bodyMedium(content.ds)))),
								$author$project$Ui$Text$view(
								$author$project$Ui$Text$bodyMedium(content.eu))
							]));
				},
				model.by ? config.aP : config.aO));
		var bodyBlock = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(30)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Text$view(
					A2(
						$author$project$Ui$Text$withFontColor,
						styleColor,
						$author$project$Ui$Text$bodyLarge(config.aQ))),
					bodyContent
				]));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					config.U ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$height(
							A2($mdgriffith$elm_ui$Element$minimum, 20, $mdgriffith$elm_ui$Element$fill))
						]),
					$author$project$Ui$Text$view(
						A2(
							$author$project$Ui$Text$withFontColor,
							$author$project$Ui$Color$red,
							$author$project$Ui$Text$labelLarge('TOPSELLER')))) : A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							A2($mdgriffith$elm_ui$Element$minimum, 20, $mdgriffith$elm_ui$Element$fill))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$author$project$Ui$Style$border,
							config.U ? $mdgriffith$elm_ui$Element$Border$width(1) : $mdgriffith$elm_ui$Element$Border$width(2),
							config.U ? $mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$red) : $mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$lightGray)
						]),
					_List_fromArray(
						[
							headerBlock,
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$lightGray),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Border$width(1)
								]),
							$mdgriffith$elm_ui$Element$none),
							bodyBlock
						]))
				]));
	});
var $author$project$Ui$Text$withPadding = F2(
	function (padding, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{bB: padding});
	});
var $author$project$Page$Section$InternetCompanyPricing$view = F2(
	function (model, config) {
		var switchHeadingBlock = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			$author$project$Ui$Text$view(
				$author$project$Ui$Text$headlineLarge('Geschäftskunden')));
		var switchButton2 = A2(
			$mdgriffith$elm_ui$Element$Input$button,
			model.by ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$border,
					$author$project$Ui$Style$shadow
				]) : _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			{
				gw: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
					$author$project$Ui$Text$view(
						A2(
							$author$project$Ui$Text$withFontColor,
							$author$project$Ui$Color$blue,
							A2(
								$author$project$Ui$Text$withPadding,
								$mdgriffith$elm_ui$Element$padding(0),
								$author$project$Ui$Text$bodyLarge(config.bw.bH.ew))))),
				dK: $elm$core$Maybe$Just($author$project$Types$InternetPricingSwitched)
			});
		var switchButton1 = A2(
			$mdgriffith$elm_ui$Element$Input$button,
			model.by ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]) : _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$border,
					$author$project$Ui$Style$shadow
				]),
			{
				gw: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
					$author$project$Ui$Text$view(
						A2(
							$author$project$Ui$Text$withFontColor,
							$author$project$Ui$Color$blue,
							A2(
								$author$project$Ui$Text$withPadding,
								$mdgriffith$elm_ui$Element$padding(0),
								$author$project$Ui$Text$bodyLarge(config.bw.bH.ev))))),
				dK: $elm$core$Maybe$Just($author$project$Types$InternetPricingSwitched)
			});
		var scale = model.hq;
		var switchButtonBlock = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(52)),
					$author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill) : $mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(400)),
					$mdgriffith$elm_ui$Element$padding(6),
					$mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$lightGray),
					$author$project$Ui$Style$border
				]),
			_List_fromArray(
				[switchButton1, switchButton2]));
		var switchInformationText = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill) : $mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(400)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[$author$project$Ui$Font$bodySmallFontSize, $author$project$Ui$Font$bodyColor, $author$project$Ui$Font$bodyTypeface]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Für CHF 80 / Jahr wirst Du miaEngiadina Mitglied und profitierst von vielen Vorteilen. '),
						A3(
						$author$project$Ui$Element$link,
						'https://www.miaengiadina.ch/community/membership',
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$author$project$Ui$Font$bodySmallFontSize,
									$author$project$Ui$Font$bodyColor,
									$author$project$Ui$Font$bodyTypeface,
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Mehr dazu hier')
								])))
					])));
		var switchBlock = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[switchHeadingBlock, switchButtonBlock, switchInformationText]));
		var informationBlock = $author$project$Ui$Text$view(
			$author$project$Ui$Text$bodySmall(config.bw.cK));
		var cards = A2(
			$elm$core$List$map,
			function (card) {
				return A2($author$project$Page$Section$InternetCompanyPricing$viewCard, model, card);
			},
			config.c3);
		var cardsBlock = ($author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$column(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(20)
				])) : $mdgriffith$elm_ui$Element$row(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(20)
				])))(cards);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$spacing(20),
					$author$project$Ui$Style$pageSectionPadding(scale),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(60)
						]),
					_List_fromArray(
						[switchBlock, cardsBlock])),
					informationBlock
				]));
	});
var $author$project$Page$Section$InternetCompanyPricing$viewIntern = function (model) {
	return A2(
		$author$project$Page$Section$InternetCompanyPricing$view,
		model,
		{
			c3: $author$project$Page$Section$InternetCompanyPricing$dataForCards,
			bw: {
				cK: '*für Services, welche nicht über das Glasfasernetz von miaEngiadina geliefert werden, gelten die Angaben als Maximalwerte',
				bH: {ev: 'Mitgliedschaft', ew: 'Ohne'}
			}
		});
};
var $author$project$Page$Internet$Company$viewContent = F2(
	function (model, content) {
		return A2(
			$author$project$Ui$Element$landingPageColumn,
			model.hq,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$author$project$Page$Section$Feature4$view,
						model.hq,
						{fl: 'https://prismic-io.s3.amazonaws.com/miaengiadina/5e04e28c-b501-4498-9efc-6dd4fc015a9f_miaEngiadina_flyer_April+2022_GK+WEBForm.pdf'}),
						$author$project$Page$Section$InternetCompanyPricing$viewIntern(model)
					]),
				A2(
					$elm$core$List$map,
					$author$project$Page$Internet$Company$viewBody(model.hq),
					content)));
	});
var $author$project$FontAwesome$Attributes$pulse = $elm$svg$Svg$Attributes$class('fa-pulse');
var $author$project$FontAwesome$Regular$spinner = A5(
	$author$project$FontAwesome$Icon$Icon,
	'far',
	'spinner',
	512,
	512,
	_List_fromArray(
		['M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z']));
var $author$project$Page$Helper$viewLoading = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$centerY,
			$mdgriffith$elm_ui$Element$padding(100)
		]),
	A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$htmlAttribute($author$project$FontAwesome$Attributes$pulse)
			]),
		$author$project$Ui$Element$faIcon($author$project$FontAwesome$Regular$spinner)));
var $author$project$Page$Internet$Company$view = F2(
	function (model, content) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$Helper$viewLoading,
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$Internet$Company$viewContent(model),
				content));
	});
var $author$project$Page$Internet$Information$viewBody = F2(
	function (scale, content) {
		switch (content.$) {
			case 0:
				var data = content.a;
				return A2($author$project$Page$View$introSection, scale, data);
			case 1:
				var data = content.a;
				return A2($author$project$Page$View$textImgRightSection, scale, data);
			case 2:
				var data = content.a;
				return A2($author$project$Page$View$textImgLeftSection, scale, data);
			case 3:
				var data = content.a;
				return A2($author$project$Page$View$componentSection, scale, data);
			default:
				return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$Page$Internet$Information$viewContent = F2(
	function (scale, content) {
		return A2(
			$author$project$Ui$Element$pageColumn,
			scale,
			A2(
				$elm$core$List$map,
				$author$project$Page$Internet$Information$viewBody(scale),
				content));
	});
var $author$project$Page$Internet$Information$view = F2(
	function (scale, content) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$Helper$viewLoading,
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$Internet$Information$viewContent(scale),
				content));
	});
var $author$project$Page$Internet$Private$viewBody = F2(
	function (scale, content) {
		var data = content;
		return A2($author$project$Page$Section$viewFeature, scale, data);
	});
var $author$project$Page$Section$InternetPricing$dataForCard1 = {
	aO: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '100 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Vorteile miaEngiadina Mitgliedschaft'}
		]),
	aP: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '100 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'}
		]),
	aQ: 'IM ABO ENTHALTEN',
	ag: {au: '50', bC: 'CHF / Monat', bD: '55'},
	aU: 'miaEngiadina Internet',
	ds: 'fa-solid fa-s',
	U: false
};
var $author$project$Page$Section$InternetPricing$dataForCard2 = {
	aO: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '250 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Vorteile miaEngiadina Mitgliedschaft'}
		]),
	aP: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '250 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'}
		]),
	aQ: 'IM ABO ENTHALTEN',
	ag: {au: '75', bC: 'CHF / Monat', bD: '85'},
	aU: 'miaEngiadina Internet',
	ds: 'fa-solid fa-m',
	U: true
};
var $author$project$Page$Section$InternetPricing$dataForCard3 = {
	aO: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '600 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Vorteile miaEngiadina Mitgliedschaft'}
		]),
	aP: _List_fromArray(
		[
			{ds: 'fa-solid fa-check', eu: '600 Mbit/s*'},
			{ds: 'fa-solid fa-check', eu: 'WLAN Router'},
			{ds: 'fa-solid fa-check', eu: 'Kombi mit Zusatzangeboten'},
			{ds: 'fa-solid fa-check', eu: 'Persönliche Betreuung'}
		]),
	aQ: 'IM ABO ENTHALTEN',
	ag: {au: '140', bC: 'CHF / Monat', bD: '155'},
	aU: 'miaEngiadina Internet',
	ds: 'fa-solid fa-l',
	U: false
};
var $author$project$Page$Section$InternetPricing$dataForCards = _List_fromArray(
	[$author$project$Page$Section$InternetPricing$dataForCard1, $author$project$Page$Section$InternetPricing$dataForCard2, $author$project$Page$Section$InternetPricing$dataForCard3]);
var $author$project$Page$Section$InternetPricing$viewCard = F2(
	function (model, config) {
		var styleColor = config.U ? $author$project$Ui$Color$red : $author$project$Ui$Color$blue;
		var iconBlock = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(50)),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(50)),
					$mdgriffith$elm_ui$Element$Background$color(styleColor),
					$author$project$Ui$Style$border,
					$mdgriffith$elm_ui$Element$centerX
				]),
			$author$project$Ui$Icon$view(
				A2(
					$author$project$Ui$Icon$withIconColor,
					$author$project$Ui$Color$white,
					A2(
						$author$project$Ui$Icon$withAttributes,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
						$author$project$Ui$Icon$titleMedium(config.ds)))));
		var headerBlock = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(30)
				]),
			_List_fromArray(
				[
					iconBlock,
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$author$project$Ui$Text$view(
						$author$project$Ui$Text$bodyLarge(config.aU))),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$alignBottom]),
							$author$project$Ui$Text$view(
								$author$project$Ui$Text$headlineLarge(
									model.by ? config.ag.bD : config.ag.au))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignLeft,
									$mdgriffith$elm_ui$Element$alignBottom,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$paddingEach(
									{F: 8, H: 0, L: 0, O: 0})
								]),
							$author$project$Ui$Text$view(
								$author$project$Ui$Text$bodyLarge(config.ag.bC)))
						])),
					A3(
					$author$project$Ui$Element$link,
					'https://form.typeform.com/to/g6wFkWC8',
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					$author$project$Ui$Button$view(
						A2(
							$author$project$Ui$Button$withOnContainerColor,
							$author$project$Ui$Color$white,
							A2(
								$author$project$Ui$Button$withContainerColor,
								styleColor,
								$author$project$Ui$Button$elevated('BESTELLEN')))))
				]));
		var bodyContent = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$map,
				function (content) {
					return A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								$author$project$Ui$Icon$view(
								A2(
									$author$project$Ui$Icon$withIconColor,
									styleColor,
									A2(
										$author$project$Ui$Icon$withAttributes,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$alignTop]),
										$author$project$Ui$Icon$bodyMedium(content.ds)))),
								$author$project$Ui$Text$view(
								$author$project$Ui$Text$bodyMedium(content.eu))
							]));
				},
				model.by ? config.aP : config.aO));
		var bodyBlock = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(30)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Text$view(
					A2(
						$author$project$Ui$Text$withFontColor,
						styleColor,
						$author$project$Ui$Text$bodyLarge(config.aQ))),
					bodyContent
				]));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					config.U ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$height(
							A2($mdgriffith$elm_ui$Element$minimum, 20, $mdgriffith$elm_ui$Element$fill))
						]),
					$author$project$Ui$Text$view(
						A2(
							$author$project$Ui$Text$withFontColor,
							$author$project$Ui$Color$red,
							$author$project$Ui$Text$labelLarge('TOPSELLER')))) : A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							A2($mdgriffith$elm_ui$Element$minimum, 20, $mdgriffith$elm_ui$Element$fill))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$author$project$Ui$Style$border,
							config.U ? $mdgriffith$elm_ui$Element$Border$width(1) : $mdgriffith$elm_ui$Element$Border$width(2),
							config.U ? $mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$red) : $mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$lightGray)
						]),
					_List_fromArray(
						[
							headerBlock,
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$lightGray),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Border$width(1)
								]),
							$mdgriffith$elm_ui$Element$none),
							bodyBlock
						]))
				]));
	});
var $author$project$Page$Section$InternetPricing$view = F2(
	function (model, config) {
		var switchHeadingBlock = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			$author$project$Ui$Text$view(
				$author$project$Ui$Text$headlineLarge('Privatkunden')));
		var switchButton2 = A2(
			$mdgriffith$elm_ui$Element$Input$button,
			model.by ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$border,
					$author$project$Ui$Style$shadow
				]) : _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			{
				gw: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
					$author$project$Ui$Text$view(
						A2(
							$author$project$Ui$Text$withFontColor,
							$author$project$Ui$Color$blue,
							A2(
								$author$project$Ui$Text$withPadding,
								$mdgriffith$elm_ui$Element$padding(0),
								$author$project$Ui$Text$bodyLarge(config.bw.bH.ew))))),
				dK: $elm$core$Maybe$Just($author$project$Types$InternetPricingSwitched)
			});
		var switchButton1 = A2(
			$mdgriffith$elm_ui$Element$Input$button,
			model.by ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]) : _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$border,
					$author$project$Ui$Style$shadow
				]),
			{
				gw: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
					$author$project$Ui$Text$view(
						A2(
							$author$project$Ui$Text$withFontColor,
							$author$project$Ui$Color$blue,
							A2(
								$author$project$Ui$Text$withPadding,
								$mdgriffith$elm_ui$Element$padding(0),
								$author$project$Ui$Text$bodyLarge(config.bw.bH.ev))))),
				dK: $elm$core$Maybe$Just($author$project$Types$InternetPricingSwitched)
			});
		var scale = model.hq;
		var switchButtonBlock = A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(52)),
					$author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill) : $mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(400)),
					$mdgriffith$elm_ui$Element$padding(6),
					$mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$lightGray),
					$author$project$Ui$Style$border
				]),
			_List_fromArray(
				[switchButton1, switchButton2]));
		var switchInformationText = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill) : $mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(400)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[$author$project$Ui$Font$bodySmallFontSize, $author$project$Ui$Font$bodyColor, $author$project$Ui$Font$bodyTypeface]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Für CHF 80 / Jahr wirst Du miaEngiadina Mitglied und profitierst von vielen Vorteilen. '),
						A3(
						$author$project$Ui$Element$link,
						'https://www.miaengiadina.ch/community/membership',
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$author$project$Ui$Font$bodySmallFontSize,
									$author$project$Ui$Font$bodyColor,
									$author$project$Ui$Font$bodyTypeface,
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Mehr dazu hier')
								])))
					])));
		var switchBlock = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[switchHeadingBlock, switchButtonBlock, switchInformationText]));
		var informationBlock = $author$project$Ui$Text$view(
			$author$project$Ui$Text$bodySmall(config.bw.cK));
		var cards = A2(
			$elm$core$List$map,
			function (card) {
				return A2($author$project$Page$Section$InternetPricing$viewCard, model, card);
			},
			config.c3);
		var cardsBlock = ($author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$column(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(20)
				])) : $mdgriffith$elm_ui$Element$row(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(20)
				])))(cards);
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$spacing(20),
					$author$project$Ui$Style$pageSectionPadding(scale),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(60)
						]),
					_List_fromArray(
						[switchBlock, cardsBlock])),
					informationBlock
				]));
	});
var $author$project$Page$Section$InternetPricing$viewIntern = function (model) {
	return A2(
		$author$project$Page$Section$InternetPricing$view,
		model,
		{
			c3: $author$project$Page$Section$InternetPricing$dataForCards,
			bw: {
				cK: '*für Services, welche nicht über das Glasfasernetz von miaEngiadina geliefert werden, gelten die Angaben als Maximalwerte',
				bH: {ev: 'Mitgliedschaft', ew: 'Ohne'}
			}
		});
};
var $author$project$Page$Internet$Private$viewContent = F2(
	function (model, content) {
		return A2(
			$author$project$Ui$Element$landingPageColumn,
			model.hq,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$author$project$Page$Section$Feature4$view,
						model.hq,
						{fl: 'https://prismic-io.s3.amazonaws.com/miaengiadina/4d14075a-ae08-4b49-a8f2-573d4bb494a9_miaEngiadina_Internet_Privatkunden_TV.pdf'}),
						$author$project$Page$Section$InternetPricing$viewIntern(model)
					]),
				A2(
					$elm$core$List$map,
					$author$project$Page$Internet$Private$viewBody(model.hq),
					content)));
	});
var $author$project$Page$Internet$Private$view = F2(
	function (model, content) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$Helper$viewLoading,
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$Internet$Private$viewContent(model),
				content));
	});
var $mdgriffith$elm_ui$Internal$Model$Hover = 1;
var $mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$hover = $mdgriffith$elm_ui$Internal$Flag$flag(33);
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 1:
				var styled = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						f6: F2(
							function (add, context) {
								return A2(
									$elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.f6, add, context));
							}),
						hT: styled.hT
					});
			case 0:
				var html = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						$elm$core$Basics$composeL,
						$elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 2:
				var str = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return $mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2($mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, $elm$core$Basics$never, style);
};
var $mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _v0) {
		var styles = _v0.a;
		var trans = _v0.b;
		var _v1 = $mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_v1.$) {
			case 4:
				var style = _v1.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, style, styles),
					trans);
			case 10:
				var flag = _v1.a;
				var component = _v1.b;
				return _Utils_Tuple2(
					styles,
					A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _v0 = A3(
		$elm$core$List$foldl,
		$mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, $mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _v0.a;
	var transform = _v0.b;
	return A2(
		$elm$core$List$cons,
		$mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var $mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			1,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $elm$html$Html$Attributes$download = function (fileName) {
	return A2($elm$html$Html$Attributes$stringProperty, 'download', fileName);
};
var $mdgriffith$elm_ui$Element$downloadAs = F2(
	function (attrs, _v0) {
		var url = _v0.cQ;
		var filename = _v0.fW;
		var label = _v0.gw;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$download(filename)),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bl),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.T),
									attrs)))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $author$project$Support$resolveLink = F2(
	function (style_, l) {
		switch (l.$) {
			case 0:
				var linkedDoc = l.a;
				var isBroken = l.b;
				var _v1 = linkedDoc.dA;
				if (_v1 === 'event') {
					return function (label) {
						return A2(
							$mdgriffith$elm_ui$Element$Input$button,
							style_,
							{
								gw: label,
								dK: $elm$core$Maybe$Just(
									$author$project$Types$NavigateTo(
										$author$project$Types$CommunityPage(
											$elm$core$Maybe$Just(
												$author$project$Types$EventsPage(linkedDoc.ik)))))
							});
					};
				} else {
					return $elm$core$Basics$identity;
				}
			case 1:
				var target = l.a;
				var url = l.b;
				var linkFunction = function () {
					if (!target.$) {
						return $mdgriffith$elm_ui$Element$newTabLink;
					} else {
						return $mdgriffith$elm_ui$Element$link;
					}
				}();
				return function (label) {
					return A2(
						linkFunction,
						style_,
						{gw: label, cQ: url});
				};
			default:
				var file = l.a;
				return function (label) {
					return A2(
						$mdgriffith$elm_ui$Element$downloadAs,
						style_,
						{fW: file.gU, gw: label, cQ: file.cQ});
				};
		}
	});
var $author$project$Page$Section$viewBanner = F2(
	function (scale, data) {
		return (!data.r) ? $mdgriffith$elm_ui$Element$none : A2(
			$author$project$Ui$Element$landingPageSectionElement,
			scale,
			A3(
				$author$project$Support$resolveLink,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$padding(40),
						$mdgriffith$elm_ui$Element$Border$rounded(12),
						$mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Border$shadow(
								{
									fb: 12,
									aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
									aH: _Utils_Tuple2(0, 0),
									aI: 3
								})
							]))
					]),
				data.gB,
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(60)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
									$author$project$Ui$Font$h1Spacing,
									$author$project$Ui$Font$h1FontSize(scale),
									$author$project$Ui$Font$h1Typeface
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(data.ie)
								])),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										A3(
										$author$project$Support$structuredTextAsHtml,
										scale,
										$elm$core$Maybe$Just(
											_Utils_update(
												$author$project$Support$default,
												{
													aV: _List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
															$author$project$Ui$Font$h1Padding,
															$author$project$Ui$Font$h1Spacing,
															$author$project$Ui$Font$h1FontSize(scale),
															$author$project$Ui$Font$h1Typeface
														]),
													b5: _List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
															$author$project$Ui$Font$h2Padding,
															$author$project$Ui$Font$h2Spacing,
															$author$project$Ui$Font$h2FontSize(scale),
															$author$project$Ui$Font$h2Typeface
														]),
													bu: _List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
															$author$project$Ui$Font$h3Padding,
															$author$project$Ui$Font$h3Spacing,
															$author$project$Ui$Font$h3FontSize(scale),
															$author$project$Ui$Font$h3Typeface
														]),
													cw: _List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
															$author$project$Ui$Font$body1Typeface,
															$author$project$Ui$Font$body1FontSize(scale),
															$author$project$Ui$Font$body1Spacing
														])
												})),
										data.eu)
									])))
						]))));
	});
var $author$project$Ui$Color$green = A3($mdgriffith$elm_ui$Element$rgb255, 35, 102, 97);
var $author$project$Ui$Style$pageSectionPaddingY = function (scale) {
	return $author$project$Ui$Scale$isXsSm(scale) ? A2($mdgriffith$elm_ui$Element$paddingXY, 0, 40) : A2($mdgriffith$elm_ui$Element$paddingXY, 0, 80);
};
var $mdgriffith$elm_ui$Element$rgba255 = F4(
	function (red, green, blue, a) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, a);
	});
var $author$project$Page$Section$viewBanner2 = F2(
	function (scale, data) {
		var buttonText = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
					$mdgriffith$elm_ui$Element$centerX
				]),
			$mdgriffith$elm_ui$Element$text(data.aA));
		var button = function () {
			var _v0 = data.az;
			if (_v0.$ === 1) {
				var target = _v0.a;
				var url = _v0.b;
				var linkFunction = function () {
					if (!target.$) {
						return $mdgriffith$elm_ui$Element$newTabLink;
					} else {
						return $mdgriffith$elm_ui$Element$link;
					}
				}();
				return A2(
					linkFunction,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
							$author$project$Ui$Style$border,
							$author$project$Ui$Style$shadow
						]),
					{gw: buttonText, cQ: url});
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(300)),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
							$author$project$Ui$Style$border
						]),
					buttonText);
			}
		}();
		var desktop = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPaddingY(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(240)),
						$mdgriffith$elm_ui$Element$Background$color(
						A4($mdgriffith$elm_ui$Element$rgba255, 38, 91, 99, 0.4))
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(60),
							A2($mdgriffith$elm_ui$Element$paddingXY, 120, 0),
							$mdgriffith$elm_ui$Element$centerY
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$green),
									$author$project$Ui$Font$h2Spacing,
									$mdgriffith$elm_ui$Element$Font$size(60),
									$author$project$Ui$Font$h2Typeface
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(data.eu)
								])),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight]),
							button)
						]))));
		var mobile = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPaddingY(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(200)),
						$mdgriffith$elm_ui$Element$Background$color(
						A4($mdgriffith$elm_ui$Element$rgba255, 38, 91, 99, 0.4))
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(20),
							A2($mdgriffith$elm_ui$Element$paddingXY, 20, 20)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$green),
									$author$project$Ui$Font$h2Spacing,
									$author$project$Ui$Font$h2Typeface,
									$mdgriffith$elm_ui$Element$Font$size(40),
									$mdgriffith$elm_ui$Element$alignLeft
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(data.eu)
								])),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignLeft]),
							button)
						]))));
		return (!data.r) ? $mdgriffith$elm_ui$Element$none : ($author$project$Ui$Scale$isXsSm(scale) ? mobile : desktop);
	});
var $mdgriffith$elm_ui$Internal$Model$Behind = 5;
var $mdgriffith$elm_ui$Element$behindContent = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 5, element);
};
var $author$project$Ui$Component$Pixel$view = function (config) {
	var pixel = A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(config.a0)),
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(config.a0)),
				$mdgriffith$elm_ui$Element$Background$color(config.a_)
			]),
		$mdgriffith$elm_ui$Element$none);
	var pixelRow = A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(config.a1)
			]),
		A2($elm$core$List$repeat, config.a$, pixel));
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(config.a1),
					$mdgriffith$elm_ui$Element$clip
				]),
			A2($elm$core$List$repeat, config.a$, pixelRow)));
};
var $author$project$Page$Section$viewBanner3 = F2(
	function (scale, data) {
		var textField = A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$author$project$Support$structuredTextAsHtml,
					scale,
					$elm$core$Maybe$Just(
						_Utils_update(
							$author$project$Support$default,
							{
								aV: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$h1Padding,
										$author$project$Ui$Font$h1Spacing,
										$author$project$Ui$Font$h1FontSize(scale),
										$author$project$Ui$Font$h1Typeface
									]),
								b5: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$h2Padding,
										$author$project$Ui$Font$h2Spacing,
										$author$project$Ui$Font$h2FontSize(scale),
										$author$project$Ui$Font$h2Typeface
									]),
								bu: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$h3Padding,
										$author$project$Ui$Font$h3Spacing,
										$author$project$Ui$Font$h3FontSize(scale),
										$author$project$Ui$Font$h3Typeface
									]),
								cw: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$body1Typeface,
										$author$project$Ui$Font$body1FontSize(scale),
										$author$project$Ui$Font$body1Spacing
									])
							})),
					data.x)
				]));
		var image = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 460, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$Border$shadow(
					{
						fb: 25,
						aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
						aH: _Utils_Tuple2(0, 10),
						aI: 4
					}),
					$mdgriffith$elm_ui$Element$Border$rounded(12),
					$mdgriffith$elm_ui$Element$clip
				]),
			A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.dC;
						},
						$author$project$Support$imageAsHtml(scale)),
					data.cc)));
		var buttonText = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
					$mdgriffith$elm_ui$Element$centerX
				]),
			$mdgriffith$elm_ui$Element$text(data.aA));
		var button = function () {
			var _v0 = data.az;
			if (_v0.$ === 1) {
				var target = _v0.a;
				var url = _v0.b;
				var linkFunction = function () {
					if (!target.$) {
						return $mdgriffith$elm_ui$Element$newTabLink;
					} else {
						return $mdgriffith$elm_ui$Element$link;
					}
				}();
				return A2(
					linkFunction,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
							$author$project$Ui$Style$border,
							$author$project$Ui$Style$shadow
						]),
					{gw: buttonText, cQ: url});
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(300)),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
							$author$project$Ui$Style$border
						]),
					buttonText);
			}
		}();
		var desktop = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPaddingY(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(2))
										]),
									$mdgriffith$elm_ui$Element$none),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(8)),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$blue),
											$mdgriffith$elm_ui$Element$Border$rounded(12),
											$mdgriffith$elm_ui$Element$inFront(
											A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height(
														$mdgriffith$elm_ui$Element$px(200)),
														$mdgriffith$elm_ui$Element$alignBottom,
														$mdgriffith$elm_ui$Element$alignLeft
													]),
												$author$project$Ui$Component$Pixel$view(
													{a_: $author$project$Ui$Color$white, a$: 18, a0: 3, a1: 15}))),
											$mdgriffith$elm_ui$Element$inFront(
											A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$px(120)),
														$mdgriffith$elm_ui$Element$alignTop,
														$mdgriffith$elm_ui$Element$alignRight
													]),
												$author$project$Ui$Component$Pixel$view(
													{a_: $author$project$Ui$Color$white, a$: 18, a0: 3, a1: 15})))
										]),
									$mdgriffith$elm_ui$Element$none),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1))
										]),
									$mdgriffith$elm_ui$Element$none)
								])))
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(80),
							$mdgriffith$elm_ui$Element$alignLeft,
							A2($mdgriffith$elm_ui$Element$paddingXY, 120, 0)
						]),
					_List_fromArray(
						[
							image,
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(20),
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 100)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill)),
											$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$blue)
										]),
									textField),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									button)
								]))
						]))));
		var mobile = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPaddingY(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$blue)
								]),
							$mdgriffith$elm_ui$Element$none))
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(60),
							A2($mdgriffith$elm_ui$Element$paddingXY, 20, 60)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							image),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									textField),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									button)
								]))
						]))));
		return (!data.r) ? $mdgriffith$elm_ui$Element$none : ($author$project$Ui$Scale$isXsSm(scale) ? mobile : desktop);
	});
var $author$project$Page$Section$viewBanner4 = F2(
	function (scale, data) {
		var textField = A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			_List_fromArray(
				[
					A3(
					$author$project$Support$structuredTextAsHtml,
					scale,
					$elm$core$Maybe$Just(
						_Utils_update(
							$author$project$Support$default,
							{
								aV: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$h1Padding,
										$author$project$Ui$Font$h1Spacing,
										$author$project$Ui$Font$h1FontSize(scale),
										$author$project$Ui$Font$h1Typeface
									]),
								b5: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$h2Padding,
										$author$project$Ui$Font$h2Spacing,
										$author$project$Ui$Font$h2FontSize(scale),
										$author$project$Ui$Font$h2Typeface
									]),
								bu: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$h3Padding,
										$author$project$Ui$Font$h3Spacing,
										$author$project$Ui$Font$h3FontSize(scale),
										$author$project$Ui$Font$h3Typeface
									]),
								cw: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										$author$project$Ui$Font$body1Typeface,
										$author$project$Ui$Font$body1FontSize(scale),
										$author$project$Ui$Font$body1Spacing
									])
							})),
					data.x)
				]));
		var image = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$Border$shadow(
					{
						fb: 25,
						aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
						aH: _Utils_Tuple2(0, 10),
						aI: 4
					}),
					$mdgriffith$elm_ui$Element$Border$rounded(12),
					$mdgriffith$elm_ui$Element$clip
				]),
			A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$none,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.dC;
						},
						$author$project$Support$imageAsHtml(scale)),
					data.cc)));
		var buttonText = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
					$mdgriffith$elm_ui$Element$centerX
				]),
			$mdgriffith$elm_ui$Element$text(data.aA));
		var button = function () {
			var _v0 = data.az;
			if (_v0.$ === 1) {
				var target = _v0.a;
				var url = _v0.b;
				var linkFunction = function () {
					if (!target.$) {
						return $mdgriffith$elm_ui$Element$newTabLink;
					} else {
						return $mdgriffith$elm_ui$Element$link;
					}
				}();
				return A2(
					linkFunction,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
							$author$project$Ui$Style$border,
							$author$project$Ui$Style$shadow
						]),
					{gw: buttonText, cQ: url});
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(300)),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
							$author$project$Ui$Style$border
						]),
					buttonText);
			}
		}();
		var desktop = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPaddingY(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									A2($mdgriffith$elm_ui$Element$maximum, 300, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$Background$color(
									A4($mdgriffith$elm_ui$Element$rgba255, 200, 16, 46, 0.4))
								]),
							$mdgriffith$elm_ui$Element$none))
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(60),
							$mdgriffith$elm_ui$Element$alignLeft,
							A2($mdgriffith$elm_ui$Element$paddingXY, 120, 0)
						]),
					_List_fromArray(
						[
							image,
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill))
										]),
									textField),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									button)
								]))
						]))));
		var mobile = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPaddingY(scale)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$Background$color(
									A4($mdgriffith$elm_ui$Element$rgba255, 200, 16, 46, 0.4))
								]),
							$mdgriffith$elm_ui$Element$none))
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(60),
							A2($mdgriffith$elm_ui$Element$paddingXY, 20, 60)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							image),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									textField),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									button)
								]))
						]))));
		return (!data.r) ? $mdgriffith$elm_ui$Element$none : ($author$project$Ui$Scale$isXsSm(scale) ? mobile : desktop);
	});
var $author$project$Page$Section$faqList = F2(
	function (scale, faqRepeatableGroup) {
		return _Utils_Tuple2(
			1,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						$author$project$Ui$Font$h3(scale),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(faqRepeatableGroup.hf)
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								A3(
								$author$project$Support$structuredTextAsHtml,
								scale,
								$elm$core$Maybe$Just(
									_Utils_update(
										$author$project$Support$default,
										{
											cw: $author$project$Ui$Font$body2(scale)
										})),
								faqRepeatableGroup.e_)
							]))
					])));
	});
var $author$project$Page$Section$viewFaq = F2(
	function (scale, _v0) {
		var faqGroup = _v0.a;
		var faqRepeatableGroup = _v0.b;
		return (!faqGroup.r) ? $mdgriffith$elm_ui$Element$none : A2(
			$author$project$Ui$Element$landingPageSectionElement,
			scale,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						$author$project$Ui$Font$h1(scale),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(faqGroup.eh)
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(4)),
								$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$lightGray)
							]),
						$mdgriffith$elm_ui$Element$none),
						A2(
						$author$project$Ui$Component$Matrix$view,
						{
							fY: true,
							gK: function () {
								switch (scale) {
									case 0:
										return 1;
									case 1:
										return 1;
									case 2:
										return 2;
									default:
										return 2;
								}
							}(),
							hH: $mdgriffith$elm_ui$Element$spacing(40)
						},
						A2(
							$elm$core$List$map,
							$author$project$Page$Section$faqList(scale),
							faqRepeatableGroup))
					])));
	});
var $author$project$Ui$Font$typefaceForHeading = $author$project$Ui$Font$blissProBold;
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Page$Section$youTubeElement = function (videoSrc) {
	return $mdgriffith$elm_ui$Element$html(
		A2(
			$elm$html$Html$iframe,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					$elm$html$Html$Attributes$src(videoSrc),
					$elm$html$Html$Attributes$title('miaEngiadina Youtube'),
					A2($elm$html$Html$Attributes$attribute, 'frameborder', '0'),
					A2($elm$html$Html$Attributes$attribute, 'allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'),
					A2($elm$html$Html$Attributes$attribute, 'allowfullscreen', '')
				]),
			_List_Nil));
};
var $author$project$Page$Section$viewHero = F2(
	function (scale, heroGroup) {
		var heading = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$alignLeft]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white)
						]),
					A3(
						$author$project$Support$structuredTextAsHtml,
						scale,
						$elm$core$Maybe$Just(
							_Utils_update(
								$author$project$Support$default,
								{
									aV: _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
											$author$project$Ui$Font$h1Padding,
											$author$project$Ui$Font$h1Spacing,
											$author$project$Ui$Font$h1FontSize(scale),
											$author$project$Ui$Font$h1Typeface
										]),
									b5: _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
											$author$project$Ui$Font$h2Padding,
											$author$project$Ui$Font$h2Spacing,
											$author$project$Ui$Font$h2FontSize(scale),
											$author$project$Ui$Font$h2Typeface
										]),
									bu: _List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
											$author$project$Ui$Font$h3Padding,
											$author$project$Ui$Font$h3Spacing,
											$author$project$Ui$Font$h3FontSize(scale),
											$author$project$Ui$Font$h3Typeface
										]),
									cw: _Utils_ap(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
												$author$project$Ui$Font$typefaceForHeading
											]),
										$author$project$Ui$Scale$isXsSm(scale) ? _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$size(46)
											]) : _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$size(64),
												$mdgriffith$elm_ui$Element$spacing(10)
											]))
								})),
						heroGroup.fU)),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$spacing(10) : $mdgriffith$elm_ui$Element$spacing(40)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_Utils_ap(
								_List_fromArray(
									[
										$author$project$Ui$Font$typefaceForHeading,
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$yellow)
									]),
								$author$project$Ui$Scale$isXsSm(scale) ? _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(50)
									]) : _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(90),
										$mdgriffith$elm_ui$Element$spacing(10)
									])),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(heroGroup.ie)
								])),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill))
								]),
							A2(
								$elm$core$Maybe$withDefault,
								$mdgriffith$elm_ui$Element$none,
								A2(
									$elm$core$Maybe$map,
									A2(
										$author$project$Support$structuredTextAsHtml,
										scale,
										$elm$core$Maybe$Just(
											_Utils_update(
												$author$project$Support$default,
												{
													cw: _List_fromArray(
														[
															$author$project$Ui$Font$body1Typeface,
															$author$project$Ui$Font$body1FontSize(scale),
															$mdgriffith$elm_ui$Element$spacing(10),
															$mdgriffith$elm_ui$Element$paddingEach(
															{F: 10, H: 0, L: 0, O: 10}),
															$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
															$mdgriffith$elm_ui$Element$Font$size(
															function () {
																switch (scale) {
																	case 0:
																		return 22;
																	case 1:
																		return 22;
																	case 2:
																		return 24;
																	default:
																		return 24;
																}
															}())
														])
												}))),
									heroGroup.x)))
						]))
				]));
		var bannerTextField = A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_ui$Element$none,
			A2(
				$elm$core$Maybe$map,
				A2(
					$author$project$Support$structuredTextAsHtml,
					scale,
					$elm$core$Maybe$Just(
						_Utils_update(
							$author$project$Support$default,
							{
								aV: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
										$author$project$Ui$Font$h1Spacing,
										$author$project$Ui$Font$h1FontSize(scale),
										$author$project$Ui$Font$h1Typeface,
										$mdgriffith$elm_ui$Element$paddingEach(
										{F: 10, H: 0, L: 0, O: 0})
									]),
								b5: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
										$author$project$Ui$Font$h2Spacing,
										$author$project$Ui$Font$h2FontSize(scale),
										$author$project$Ui$Font$h2Typeface,
										$mdgriffith$elm_ui$Element$paddingEach(
										{F: 10, H: 0, L: 0, O: 0})
									]),
								bu: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
										$author$project$Ui$Font$h3Spacing,
										$author$project$Ui$Font$h3FontSize(scale),
										$author$project$Ui$Font$h3Typeface
									]),
								cw: _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
										$author$project$Ui$Font$body1Typeface,
										$author$project$Ui$Font$body1FontSize(scale),
										$mdgriffith$elm_ui$Element$spacing(5),
										$mdgriffith$elm_ui$Element$paddingEach(
										{F: 5, H: 0, L: 0, O: 0})
									])
							}))),
				heroGroup.e5));
		var bannerImage = function () {
			var _v1 = heroGroup.e3;
			if (!_v1.$) {
				var main = _v1.a.dC;
				return $mdgriffith$elm_ui$Element$Background$image(main.cQ);
			} else {
				return $mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$gray);
			}
		}();
		var mobile = function () {
			var video = (!heroGroup.ip) ? $mdgriffith$elm_ui$Element$none : A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 100)
					]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$centerX,
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10)
						]),
					A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $mdgriffith$elm_ui$Element$none, $author$project$Page$Section$youTubeElement, heroGroup.io)));
			var headingArea = A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(0))
							]),
						$mdgriffith$elm_ui$Element$none),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(5)),
								$mdgriffith$elm_ui$Element$alignBottom,
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 40)
							]),
						heading)
					]));
			var banner = (!heroGroup.e6) ? $mdgriffith$elm_ui$Element$none : A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$paddingEach(
						{F: 0, H: 0, L: 0, O: 90})
					]),
				A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 300, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(240)),
							$mdgriffith$elm_ui$Element$centerX
						]),
					{
						gw: A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									A2($mdgriffith$elm_ui$Element$maximum, 300, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(240)),
									$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
									$mdgriffith$elm_ui$Element$Border$shadow(
									{
										fb: 18,
										aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
										aH: _Utils_Tuple2(16, 16),
										aI: 16
									}),
									$mdgriffith$elm_ui$Element$Border$rounded(12),
									$mdgriffith$elm_ui$Element$clip,
									$mdgriffith$elm_ui$Element$centerX
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$height(
											$mdgriffith$elm_ui$Element$px(100)),
											$mdgriffith$elm_ui$Element$padding(10)
										]),
									bannerTextField),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$height(
											$mdgriffith$elm_ui$Element$px(140)),
											$mdgriffith$elm_ui$Element$alignBottom,
											bannerImage
										]),
									$mdgriffith$elm_ui$Element$none)
								])),
						cQ: A2($elm$core$Maybe$withDefault, '', heroGroup.e4)
					}));
			return (!heroGroup.r) ? $mdgriffith$elm_ui$Element$none : A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(800)),
							$mdgriffith$elm_ui$Element$behindContent(
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(440)),
										$mdgriffith$elm_ui$Element$Background$color(
										A4($mdgriffith$elm_ui$Element$rgba255, 38, 91, 99, 0.5)),
										$mdgriffith$elm_ui$Element$alignBottom
									]),
								$mdgriffith$elm_ui$Element$none)),
							$mdgriffith$elm_ui$Element$behindContent(
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(600)),
										$mdgriffith$elm_ui$Element$alignBottom
									]),
								$author$project$Ui$Component$Pixel$view(
									{a_: $author$project$Ui$Color$green, a$: 20, a0: 4, a1: 20}))),
							$mdgriffith$elm_ui$Element$behindContent(
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(240)),
										$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$green),
										$mdgriffith$elm_ui$Element$alignBottom
									]),
								$mdgriffith$elm_ui$Element$none))
						]),
					_List_fromArray(
						[banner, video, headingArea])));
		}();
		var backgroundImageSection = function () {
			var _v0 = heroGroup.e2;
			if (!_v0.$) {
				var main = _v0.a.dC;
				return $mdgriffith$elm_ui$Element$Background$image(main.cQ);
			} else {
				return $mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$gray);
			}
		}();
		var desktop = (!heroGroup.r) ? $mdgriffith$elm_ui$Element$none : A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(800)),
						backgroundImageSection,
						$mdgriffith$elm_ui$Element$inFront(
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(540)),
									$mdgriffith$elm_ui$Element$alignBottom
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(2)),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Background$color(
											A4($mdgriffith$elm_ui$Element$rgba255, 38, 91, 99, 0.5))
										]),
									$mdgriffith$elm_ui$Element$none),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1))
										]),
									$mdgriffith$elm_ui$Element$none)
								]))),
						$mdgriffith$elm_ui$Element$inFront(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(640)),
									$mdgriffith$elm_ui$Element$alignBottom
								]),
							$author$project$Ui$Component$Pixel$view(
								{a_: $author$project$Ui$Color$green, a$: 20, a0: 4, a1: 20}))),
						$mdgriffith$elm_ui$Element$inFront(
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(240)),
									$mdgriffith$elm_ui$Element$alignBottom
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1))
										]),
									$mdgriffith$elm_ui$Element$none),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(6)),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$green)
										]),
									$mdgriffith$elm_ui$Element$none)
								]))),
						$mdgriffith$elm_ui$Element$inFront(
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1))
										]),
									$mdgriffith$elm_ui$Element$none),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(5)),
											$mdgriffith$elm_ui$Element$alignBottom,
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 50)
										]),
									heading)
								]))),
						(!heroGroup.ip) ? $mdgriffith$elm_ui$Element$inFront($mdgriffith$elm_ui$Element$none) : $mdgriffith$elm_ui$Element$inFront(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$padding(60)
								]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(500)),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(255)),
										$mdgriffith$elm_ui$Element$alignRight
									]),
								A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $mdgriffith$elm_ui$Element$none, $author$project$Page$Section$youTubeElement, heroGroup.io)))),
						(!heroGroup.e6) ? $mdgriffith$elm_ui$Element$inFront($mdgriffith$elm_ui$Element$none) : $mdgriffith$elm_ui$Element$inFront(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$paddingEach(
									{F: 0, H: 0, L: 0, O: 40})
								]),
							A2(
								$mdgriffith$elm_ui$Element$link,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								{
									gw: A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(200)),
												$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
												$mdgriffith$elm_ui$Element$Border$shadow(
												{
													fb: 18,
													aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
													aH: _Utils_Tuple2(16, 16),
													aI: 16
												}),
												$mdgriffith$elm_ui$Element$Border$rounded(12),
												$mdgriffith$elm_ui$Element$clip
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$fillPortion(5)),
														$mdgriffith$elm_ui$Element$alignTop,
														$mdgriffith$elm_ui$Element$paddingEach(
														{F: 0, H: 10, L: 10, O: 20})
													]),
												bannerTextField),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$px(400)),
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														bannerImage
													]),
												$mdgriffith$elm_ui$Element$none)
											])),
									cQ: A2($elm$core$Maybe$withDefault, '', heroGroup.e4)
								})))
					]),
				$mdgriffith$elm_ui$Element$none));
		return $author$project$Ui$Scale$isXsSm(scale) ? mobile : desktop;
	});
var $author$project$Page$Section$viewImageText = F2(
	function (scale, data) {
		return (!data.r) ? $mdgriffith$elm_ui$Element$none : A2(
			$author$project$Ui$Element$landingPageSectionElement,
			scale,
			A3(
				$author$project$Ui$Element$textImgRight,
				scale,
				A3(
					$author$project$Support$structuredTextAsHtml,
					scale,
					$elm$core$Maybe$Just(
						_Utils_update(
							$author$project$Support$default,
							{
								aV: $author$project$Ui$Font$h1(scale),
								b5: $author$project$Ui$Font$h2(scale),
								bu: $author$project$Ui$Font$h3(scale),
								cw: $author$project$Ui$Font$body1(scale)
							})),
					data.x),
				A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.dC;
							},
							$author$project$Support$imageAsHtml(scale)),
						data.cc))));
	});
var $elm$html$Html$Attributes$autoplay = $elm$html$Html$Attributes$boolProperty('autoplay');
var $elm$html$Html$Attributes$loop = $elm$html$Html$Attributes$boolProperty('loop');
var $author$project$FontAwesome$Duotone$users = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fad',
	'users',
	640,
	512,
	_List_fromArray(
		['M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z', 'M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z']));
var $author$project$Page$Section$viewMembership = F2(
	function (scale, data) {
		var textField = A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$author$project$Support$structuredTextAsHtml,
							scale,
							$elm$core$Maybe$Just(
								_Utils_update(
									$author$project$Support$default,
									{
										aV: _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
												$author$project$Ui$Font$h1Padding,
												$author$project$Ui$Font$h1Spacing,
												$author$project$Ui$Font$h1FontSize(scale),
												$author$project$Ui$Font$h1Typeface
											]),
										b5: _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
												$author$project$Ui$Font$h2Padding,
												$author$project$Ui$Font$h2Spacing,
												$author$project$Ui$Font$h2FontSize(scale),
												$author$project$Ui$Font$h2Typeface
											]),
										bu: _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
												$author$project$Ui$Font$h3Padding,
												$author$project$Ui$Font$h3Spacing,
												$author$project$Ui$Font$h3FontSize(scale),
												$author$project$Ui$Font$h3Typeface
											]),
										cw: _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
												$author$project$Ui$Font$body1Typeface,
												$author$project$Ui$Font$body1FontSize(scale),
												$author$project$Ui$Font$body1Spacing
											])
									}))),
						data.x))
				]));
		var numberOfMembersForMobile = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$minimum, 240, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$height(
					A2($mdgriffith$elm_ui$Element$minimum, 240, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$Border$width(4),
					$mdgriffith$elm_ui$Element$Border$rounded(240),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$white)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$Font$size(40)
								]),
							$author$project$Ui$Element$faIcon($author$project$FontAwesome$Duotone$users)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
									$mdgriffith$elm_ui$Element$Font$size(60),
									$author$project$Ui$Font$h2Typeface,
									$mdgriffith$elm_ui$Element$centerX
								]),
							$mdgriffith$elm_ui$Element$text(
								A2($elm$core$Maybe$withDefault, '', data.gY))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
									$author$project$Ui$Font$body2FontSize(scale),
									$author$project$Ui$Font$body2Typeface,
									$mdgriffith$elm_ui$Element$centerX
								]),
							$mdgriffith$elm_ui$Element$text(
								A2($elm$core$Maybe$withDefault, '', data.gZ)))
						]))));
		var numberOfMembersForDesktop = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2(
						$mdgriffith$elm_ui$Element$minimum,
						400,
						A2($mdgriffith$elm_ui$Element$maximum, 400, $mdgriffith$elm_ui$Element$fill))),
					$mdgriffith$elm_ui$Element$height(
					A2(
						$mdgriffith$elm_ui$Element$minimum,
						400,
						A2($mdgriffith$elm_ui$Element$maximum, 400, $mdgriffith$elm_ui$Element$fill))),
					$mdgriffith$elm_ui$Element$Border$width(4),
					$mdgriffith$elm_ui$Element$Border$rounded(400),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Ui$Color$white)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
							$mdgriffith$elm_ui$Element$centerX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$Font$size(60)
								]),
							$author$project$Ui$Element$faIcon($author$project$FontAwesome$Duotone$users)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
									$mdgriffith$elm_ui$Element$Font$size(90),
									$author$project$Ui$Font$h2Typeface,
									$mdgriffith$elm_ui$Element$centerX
								]),
							$mdgriffith$elm_ui$Element$text(
								A2($elm$core$Maybe$withDefault, '', data.gY))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
									$author$project$Ui$Font$body2FontSize(scale),
									$author$project$Ui$Font$body2Typeface,
									$mdgriffith$elm_ui$Element$centerX
								]),
							$mdgriffith$elm_ui$Element$text(
								A2($elm$core$Maybe$withDefault, '', data.gZ)))
						]))));
		var fireworks = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			$mdgriffith$elm_ui$Element$html(
				A3(
					$elm$html$Html$node,
					'lottie-player',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('https://assets7.lottiefiles.com/packages/lf20_jEMHbp.json'),
							A2($elm$html$Html$Attributes$attribute, 'background', 'transparent'),
							A2($elm$html$Html$Attributes$attribute, 'speed', '1'),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'height', '100%'),
							$elm$html$Html$Attributes$loop(false),
							$elm$html$Html$Attributes$autoplay(true)
						]),
					_List_Nil)));
		var buttonText = A2(
			$mdgriffith$elm_ui$Element$el,
			$author$project$Ui$Font$button1(scale),
			$mdgriffith$elm_ui$Element$text(
				A2($elm$core$Maybe$withDefault, '', data.aA)));
		var button = function () {
			var _v0 = data.az;
			if (_v0.$ === 1) {
				var target = _v0.a;
				var url = _v0.b;
				var linkFunction = function () {
					if (!target.$) {
						return $mdgriffith$elm_ui$Element$newTabLink;
					} else {
						return $mdgriffith$elm_ui$Element$link;
					}
				}();
				return A2(
					linkFunction,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
							$author$project$Ui$Style$border,
							$author$project$Ui$Style$shadow
						]),
					{gw: buttonText, cQ: url});
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(300)),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
							$author$project$Ui$Style$border
						]),
					buttonText);
			}
		}();
		var desktop = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									A2($mdgriffith$elm_ui$Element$maximum, 900, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$Background$color(
									A4($mdgriffith$elm_ui$Element$rgba255, 15, 76, 129, 1))
								]),
							$mdgriffith$elm_ui$Element$none))
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(100),
							$mdgriffith$elm_ui$Element$centerX,
							A2($mdgriffith$elm_ui$Element$paddingXY, 120, 60),
							$mdgriffith$elm_ui$Element$behindContent(fireworks)
						]),
					_List_fromArray(
						[
							numberOfMembersForDesktop,
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											A2($mdgriffith$elm_ui$Element$maximum, 400, $mdgriffith$elm_ui$Element$fill))
										]),
									textField),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									button)
								]))
						]))));
		var mobile = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$behindContent(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height(
									A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
									$mdgriffith$elm_ui$Element$Background$color(
									A4($mdgriffith$elm_ui$Element$rgba255, 15, 76, 129, 1))
								]),
							$mdgriffith$elm_ui$Element$none)),
						$mdgriffith$elm_ui$Element$inFront(fireworks)
					]),
				A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(60),
							A2($mdgriffith$elm_ui$Element$paddingXY, 20, 60)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							numberOfMembersForMobile),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									textField),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft]),
									button)
								]))
						]))));
		return (!data.r) ? $mdgriffith$elm_ui$Element$none : ($author$project$Ui$Scale$isXsSm(scale) ? mobile : desktop);
	});
var $author$project$Ui$Element$cardLandingPage = F5(
	function (scale, tagline, title, linkText, imgSrc) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$clip,
					$author$project$Ui$Style$border,
					$author$project$Ui$Style$shadow
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(180)),
							$mdgriffith$elm_ui$Element$Background$image(imgSrc)
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(15),
							$mdgriffith$elm_ui$Element$padding(15)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(5)
								]),
							_List_fromArray(
								[tagline, title])),
							linkText
						]))
				]));
	});
var $author$project$Ui$Style$cardLinkText = function (scale) {
	return _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
			$author$project$Ui$Font$blissProLight,
			$mdgriffith$elm_ui$Element$Font$size(
			function () {
				switch (scale) {
					case 0:
						return 12;
					case 1:
						return 14;
					case 2:
						return 16;
					default:
						return 18;
				}
			}())
		]);
};
var $author$project$Ui$Style$cardTagline = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
				$author$project$Ui$Font$blissProLight
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(12)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(14)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(16)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18)
						]);
			}
		}());
};
var $author$project$Ui$Style$cardTitle = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$author$project$Ui$Font$blissProRegular
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(16),
							$mdgriffith$elm_ui$Element$spacing(4)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18),
							$mdgriffith$elm_ui$Element$spacing(6)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20),
							$mdgriffith$elm_ui$Element$spacing(8)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(22),
							$mdgriffith$elm_ui$Element$spacing(10)
						]);
			}
		}());
};
var $author$project$Prismic$Field$getWebLink = function (weblink) {
	if (weblink.$ === 1) {
		var url = weblink.b;
		return $elm$core$Maybe$Just(url);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Section$newsCard = F2(
	function (scale, card) {
		return _Utils_Tuple2(
			1,
			A3(
				$author$project$Support$resolveLink,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				card.gB,
				A5(
					$author$project$Ui$Element$cardLandingPage,
					scale,
					A2(
						$mdgriffith$elm_ui$Element$paragraph,
						$author$project$Ui$Style$cardTagline(scale),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(card.hX)
							])),
					A2(
						$mdgriffith$elm_ui$Element$paragraph,
						$author$project$Ui$Style$cardTitle(scale),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(card.ie)
							])),
					A2(
						$mdgriffith$elm_ui$Element$paragraph,
						$author$project$Ui$Style$cardLinkText(scale),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(card.gC)
							])),
					A2(
						$elm$core$Maybe$withDefault,
						'',
						function () {
							var _v0 = _Utils_Tuple2(card.gd, card.cc);
							if (!_v0.a.$) {
								var image_source = _v0.a.a;
								return $author$project$Prismic$Field$getWebLink(image_source);
							} else {
								if (!_v0.b.$) {
									var image = _v0.b.a;
									return $elm$core$Maybe$Just(image.dC.cQ);
								} else {
									return $elm$core$Maybe$Nothing;
								}
							}
						}()))));
	});
var $author$project$Page$Section$viewNewsCard = F2(
	function (scale, _v0) {
		var newsCardSectionGroup = _v0.a;
		var newsCardSectionRepeatableGroup = _v0.b;
		return A2(
			$author$project$Ui$Element$landingPageSectionElement,
			scale,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(60)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$spacing(10)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
										$author$project$Ui$Font$h1Spacing,
										$author$project$Ui$Font$h1FontSize(scale),
										$author$project$Ui$Font$h1Typeface,
										$mdgriffith$elm_ui$Element$Font$center
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(newsCardSectionGroup.ie)
									])),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										A2($mdgriffith$elm_ui$Element$maximum, 800, $mdgriffith$elm_ui$Element$fill)),
										$mdgriffith$elm_ui$Element$Font$center
									]),
								A3(
									$author$project$Support$structuredTextAsHtml,
									scale,
									$elm$core$Maybe$Just(
										_Utils_update(
											$author$project$Support$default,
											{
												aV: $author$project$Ui$Font$h1(scale),
												b5: $author$project$Ui$Font$h2(scale),
												bu: $author$project$Ui$Font$h3(scale),
												cw: $author$project$Ui$Font$body1(scale)
											})),
									newsCardSectionGroup.x))
							])),
						A2(
						$author$project$Ui$Component$Matrix$view,
						{
							fY: true,
							gK: $author$project$Ui$Scale$toInt(scale),
							hH: $author$project$Ui$Style$spacing(scale)
						},
						A2(
							$elm$core$List$map,
							$author$project$Page$Section$newsCard(scale),
							A2(
								$elm$core$List$filter,
								function ($) {
									return $.r;
								},
								newsCardSectionRepeatableGroup)))
					])));
	});
var $author$project$Ui$Element$iconLink = F2(
	function (url, icon_) {
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(30)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(30))
				]),
			{
				gw: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$centerX]),
					$author$project$Ui$Element$icon(icon_)),
				cQ: url
			});
	});
var $author$project$Icon$tiktok = 'fab fa-tiktok';
var $author$project$Icon$youtube = 'fab fa-youtube';
var $author$project$Page$Section$viewSocialMedia = F2(
	function (scale, socialMediaSectionGroup) {
		var responsiveStyle = function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20),
							$mdgriffith$elm_ui$Element$spacing(20)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(40),
							$mdgriffith$elm_ui$Element$spacing(40)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(60),
							$mdgriffith$elm_ui$Element$spacing(100)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(80),
							$mdgriffith$elm_ui$Element$spacing(120)
						]);
			}
		}();
		return (!socialMediaSectionGroup.r) ? $mdgriffith$elm_ui$Element$none : A2(
			$author$project$Ui$Element$landingPageSectionElement,
			scale,
			A2(
				$mdgriffith$elm_ui$Element$row,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
						]),
					responsiveStyle),
				_List_fromArray(
					[
						A2($author$project$Ui$Element$iconBtn, 'https://www.facebook.com/miaEngiadina/', $author$project$FontAwesome$Brands$facebookF),
						A2($author$project$Ui$Element$iconBtn, 'https://www.linkedin.com/company/mia-engiadina', $author$project$FontAwesome$Brands$linkedinIn),
						A2($author$project$Ui$Element$iconBtn, 'https://www.instagram.com/miaEngiadina/', $author$project$FontAwesome$Brands$instagram),
						A2($author$project$Ui$Element$iconBtn, 'https://twitter.com/miaEngiadina', $author$project$FontAwesome$Brands$twitter),
						A2($author$project$Ui$Element$iconLink, 'https://www.youtube.com/channel/UC9JRrABQLsw0fskomITMxrA', $author$project$Icon$youtube),
						A2($author$project$Ui$Element$iconLink, 'https://vm.tiktok.com/ZM89d4FYY/', $author$project$Icon$tiktok)
					])));
	});
var $author$project$Types$SelectTestimonial = function (a) {
	return {$: 10, a: a};
};
var $author$project$Ui$Style$componentMdStyle = function (scale) {
	return _Utils_ap(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
				$author$project$Ui$Font$blissProLight,
				$mdgriffith$elm_ui$Element$spacing(12)
			]),
		function () {
			switch (scale) {
				case 0:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(16)
						]);
				case 1:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18)
						]);
				case 2:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(20)
						]);
				default:
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(22)
						]);
			}
		}());
};
var $author$project$Ui$Element$externalLink = F2(
	function (url, elem) {
		return A2(
			$mdgriffith$elm_ui$Element$newTabLink,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			{gw: elem, cQ: url});
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Elm$JsArray$indexedMap = _JsArray_indexedMap;
var $elm$core$Array$indexedMap = F2(
	function (func, _v0) {
		var len = _v0.a;
		var tree = _v0.c;
		var tail = _v0.d;
		var initialBuilder = {
			o: _List_Nil,
			g: 0,
			k: A3(
				$elm$core$Elm$JsArray$indexedMap,
				func,
				$elm$core$Array$tailIndex(len),
				tail)
		};
		var helper = F2(
			function (node, builder) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, builder, subTree);
				} else {
					var leaf = node.a;
					var offset = builder.g * $elm$core$Array$branchFactor;
					var mappedLeaf = $elm$core$Array$Leaf(
						A3($elm$core$Elm$JsArray$indexedMap, func, offset, leaf));
					return {
						o: A2($elm$core$List$cons, mappedLeaf, builder.o),
						g: builder.g + 1,
						k: builder.k
					};
				}
			});
		return A2(
			$elm$core$Array$builderToArray,
			true,
			A3($elm$core$Elm$JsArray$foldl, helper, initialBuilder, tree));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$problemToString = function (problem) {
	switch (problem.$) {
		case 0:
			var string = problem.a;
			return 'Expecting ' + string;
		case 1:
			return 'Expecting int';
		case 2:
			return 'Expecting hex';
		case 3:
			return 'Expecting octal';
		case 4:
			return 'Expecting binary';
		case 5:
			return 'Expecting float';
		case 6:
			return 'Expecting number';
		case 7:
			return 'Expecting variable';
		case 8:
			var string = problem.a;
			return 'Expecting symbol ' + string;
		case 9:
			var string = problem.a;
			return 'Expecting keyword ' + string;
		case 10:
			return 'Expecting keyword end';
		case 11:
			return 'Unexpected char';
		case 12:
			var problemDescription = problem.a;
			return problemDescription;
		default:
			return 'Bad repeat';
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString = function (deadEnd) {
	return 'Problem at row ' + ($elm$core$String$fromInt(deadEnd.hn) + ('\n' + $dillonkearns$elm_markdown$Markdown$Parser$problemToString(deadEnd.hd)));
};
var $author$project$Md$deadEndsToString = function (deadEnds) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, deadEnds));
};
var $dillonkearns$elm_markdown$Markdown$Block$Body = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Parser$Body = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Parser$CodeBlock = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Html = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Parser$Html = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$Markdown$Parser$UnparsedInlines = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$Parser$BlankLine = {$: 6};
var $dillonkearns$elm_markdown$Markdown$Parser$blankLine = A2(
	$elm$parser$Parser$Advanced$map,
	function (_v0) {
		return $dillonkearns$elm_markdown$Markdown$Parser$BlankLine;
	},
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$token(
			A2(
				$elm$parser$Parser$Advanced$Token,
				'\n',
				$elm$parser$Parser$Expecting('\n'))),
		$elm$parser$Parser$Advanced$chompWhile(
			function (c) {
				return c === '\n';
			})));
var $dillonkearns$elm_markdown$Markdown$Parser$combine = function (list) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (parser, listParser) {
				return A2(
					$elm$parser$Parser$Advanced$andThen,
					function (soFar) {
						return A2(
							$elm$parser$Parser$Advanced$map,
							function (a) {
								return A2($elm$core$List$cons, a, soFar);
							},
							parser);
					},
					listParser);
			}),
		$elm$parser$Parser$Advanced$succeed(_List_Nil),
		list);
};
var $dillonkearns$elm_markdown$XmlParser$Element = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_markdown$XmlParser$Text = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$XmlParser$Attribute = F2(
	function (name, value) {
		return {gU: name, $9: value};
	});
var $elm$parser$Parser$Advanced$Located = F3(
	function (row, col, context) {
		return {c7: col, c: context, hn: row};
	});
var $elm$parser$Parser$Advanced$changeContext = F2(
	function (newContext, s) {
		return {c7: s.c7, c: newContext, d: s.d, aH: s.aH, hn: s.hn, eq: s.eq};
	});
var $elm$parser$Parser$Advanced$inContext = F2(
	function (context, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(
				A2(
					$elm$parser$Parser$Advanced$changeContext,
					A2(
						$elm$core$List$cons,
						A3($elm$parser$Parser$Advanced$Located, s0.hn, s0.c7, context),
						s0.c),
					s0));
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					a,
					A2($elm$parser$Parser$Advanced$changeContext, s0.c, s1));
			} else {
				var step = _v1;
				return step;
			}
		};
	});
var $dillonkearns$elm_markdown$XmlParser$isWhitespace = function (c) {
	return (c === ' ') || ((c === '\u000D') || ((c === '\n') || (c === '\t')));
};
var $elm$parser$Parser$BadRepeat = {$: 13};
var $dillonkearns$elm_markdown$XmlParser$keep = F2(
	function (count, predicate) {
		var n = count;
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (str) {
				return (_Utils_cmp(
					n,
					$elm$core$String$length(str)) < 1) ? $elm$parser$Parser$Advanced$succeed(str) : $elm$parser$Parser$Advanced$problem($elm$parser$Parser$BadRepeat);
			},
			$elm$parser$Parser$Advanced$getChompedString(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(0),
					$elm$parser$Parser$Advanced$chompWhile(predicate))));
	});
var $dillonkearns$elm_markdown$XmlParser$AtLeast = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$XmlParser$oneOrMore = 1;
var $dillonkearns$elm_markdown$XmlParser$attributeName = A2(
	$elm$parser$Parser$Advanced$inContext,
	'attributeName',
	A2(
		$dillonkearns$elm_markdown$XmlParser$keep,
		$dillonkearns$elm_markdown$XmlParser$oneOrMore,
		function (c) {
			return (!$dillonkearns$elm_markdown$XmlParser$isWhitespace(c)) && ((c !== '/') && ((c !== '<') && ((c !== '>') && ((c !== '\"') && ((c !== '\'') && (c !== '='))))));
		}));
var $dillonkearns$elm_markdown$XmlParser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $dillonkearns$elm_markdown$XmlParser$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('quot', '\"')
		]));
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $dillonkearns$elm_markdown$XmlParser$decodeEscape = function (s) {
	return A2($elm$core$String$startsWith, '#x', s) ? A2(
		$elm$core$Result$mapError,
		$elm$parser$Parser$Problem,
		A2(
			$elm$core$Result$map,
			$elm$core$Char$fromCode,
			$rtfeldman$elm_hex$Hex$fromString(
				A2($elm$core$String$dropLeft, 2, s)))) : (A2($elm$core$String$startsWith, '#', s) ? A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('Invalid escaped charactor: ' + s),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Char$fromCode,
			$elm$core$String$toInt(
				A2($elm$core$String$dropLeft, 1, s)))) : A2(
		$elm$core$Result$fromMaybe,
		$elm$parser$Parser$Problem('No entity named \"&' + (s + ';\" found.')),
		A2($elm$core$Dict$get, s, $dillonkearns$elm_markdown$XmlParser$entities)));
};
var $dillonkearns$elm_markdown$XmlParser$fail = function (str) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(str));
};
var $dillonkearns$elm_markdown$XmlParser$escapedChar = function (end_) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'escapedChar',
		A2(
			$elm$parser$Parser$Advanced$andThen,
			function (s) {
				return $elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$andThen,
							function (_v0) {
								var _v1 = $dillonkearns$elm_markdown$XmlParser$decodeEscape(s);
								if (!_v1.$) {
									var c = _v1.a;
									return $elm$parser$Parser$Advanced$succeed(c);
								} else {
									var e = _v1.a;
									return $elm$parser$Parser$Advanced$problem(e);
								}
							},
							$dillonkearns$elm_markdown$XmlParser$symbol(';')),
							$dillonkearns$elm_markdown$XmlParser$fail('Entities must end_ with \";\": &' + s)
						]));
			},
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$dillonkearns$elm_markdown$XmlParser$symbol('&')),
				A2(
					$dillonkearns$elm_markdown$XmlParser$keep,
					$dillonkearns$elm_markdown$XmlParser$oneOrMore,
					function (c) {
						return (!_Utils_eq(c, end_)) && (c !== ';');
					}))));
};
var $dillonkearns$elm_markdown$XmlParser$zeroOrMore = 0;
var $dillonkearns$elm_markdown$XmlParser$textString = function (end_) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'textString',
		A2(
			$elm$parser$Parser$Advanced$andThen,
			function (s) {
				return $elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$keeper,
								$elm$parser$Parser$Advanced$succeed(
									F2(
										function (c, t) {
											return _Utils_ap(
												s,
												A2($elm$core$String$cons, c, t));
										})),
								$dillonkearns$elm_markdown$XmlParser$escapedChar(end_)),
							$elm$parser$Parser$Advanced$lazy(
								function (_v0) {
									return $dillonkearns$elm_markdown$XmlParser$textString(end_);
								})),
							$elm$parser$Parser$Advanced$succeed(s)
						]));
			},
			A2(
				$dillonkearns$elm_markdown$XmlParser$keep,
				$dillonkearns$elm_markdown$XmlParser$zeroOrMore,
				function (c) {
					return (!_Utils_eq(c, end_)) && (c !== '&');
				})));
};
var $dillonkearns$elm_markdown$XmlParser$attributeValue = A2(
	$elm$parser$Parser$Advanced$inContext,
	'attributeValue',
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$dillonkearns$elm_markdown$XmlParser$symbol('\"')),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$XmlParser$textString('\"'),
					$dillonkearns$elm_markdown$XmlParser$symbol('\"'))),
				A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					$dillonkearns$elm_markdown$XmlParser$symbol('\'')),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$dillonkearns$elm_markdown$XmlParser$textString('\''),
					$dillonkearns$elm_markdown$XmlParser$symbol('\'')))
			])));
var $dillonkearns$elm_markdown$XmlParser$ignore = F2(
	function (count, predicate) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			function (_v0) {
				return 0;
			},
			A2($dillonkearns$elm_markdown$XmlParser$keep, count, predicate));
	});
var $dillonkearns$elm_markdown$XmlParser$whiteSpace = A2($dillonkearns$elm_markdown$XmlParser$ignore, $dillonkearns$elm_markdown$XmlParser$zeroOrMore, $dillonkearns$elm_markdown$XmlParser$isWhitespace);
var $dillonkearns$elm_markdown$XmlParser$attribute = A2(
	$elm$parser$Parser$Advanced$inContext,
	'attribute',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$XmlParser$Attribute),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2($elm$parser$Parser$Advanced$ignorer, $dillonkearns$elm_markdown$XmlParser$attributeName, $dillonkearns$elm_markdown$XmlParser$whiteSpace),
					$dillonkearns$elm_markdown$XmlParser$symbol('=')),
				$dillonkearns$elm_markdown$XmlParser$whiteSpace)),
		$dillonkearns$elm_markdown$XmlParser$attributeValue));
var $dillonkearns$elm_markdown$XmlParser$attributes = function (keys) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'attributes',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$andThen,
					function (attr) {
						return A2($elm$core$Set$member, attr.gU, keys) ? $dillonkearns$elm_markdown$XmlParser$fail('attribute ' + (attr.gU + ' is duplicated')) : A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed(
									$elm$core$List$cons(attr)),
								$dillonkearns$elm_markdown$XmlParser$whiteSpace),
							$dillonkearns$elm_markdown$XmlParser$attributes(
								A2($elm$core$Set$insert, attr.gU, keys)));
					},
					$dillonkearns$elm_markdown$XmlParser$attribute),
					$elm$parser$Parser$Advanced$succeed(_List_Nil)
				])));
};
var $dillonkearns$elm_markdown$XmlParser$tagName = A2(
	$elm$parser$Parser$Advanced$inContext,
	'tagName',
	A2(
		$dillonkearns$elm_markdown$XmlParser$keep,
		$dillonkearns$elm_markdown$XmlParser$oneOrMore,
		function (c) {
			return (!$dillonkearns$elm_markdown$XmlParser$isWhitespace(c)) && ((c !== '/') && ((c !== '<') && ((c !== '>') && ((c !== '\"') && ((c !== '\'') && (c !== '='))))));
		}));
var $dillonkearns$elm_markdown$XmlParser$closingTag = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'closingTag',
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$elm$parser$Parser$Advanced$succeed(0),
							$dillonkearns$elm_markdown$XmlParser$symbol('</')),
						$dillonkearns$elm_markdown$XmlParser$whiteSpace),
					A2(
						$elm$parser$Parser$Advanced$andThen,
						function (endTagName) {
							return _Utils_eq(startTagName, endTagName) ? $elm$parser$Parser$Advanced$succeed(0) : $dillonkearns$elm_markdown$XmlParser$fail('tag name mismatch: ' + (startTagName + (' and ' + endTagName)));
						},
						$dillonkearns$elm_markdown$XmlParser$tagName)),
				$dillonkearns$elm_markdown$XmlParser$whiteSpace),
			$dillonkearns$elm_markdown$XmlParser$symbol('>')));
};
function $dillonkearns$elm_markdown$XmlParser$cyclic$cdataContent() {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'cdataContent',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(''),
					$dillonkearns$elm_markdown$XmlParser$symbol(']]>')),
					A2(
					$elm$parser$Parser$Advanced$andThen,
					function (_v0) {
						return A2(
							$elm$parser$Parser$Advanced$map,
							function (tail) {
								return ']]' + tail;
							},
							$dillonkearns$elm_markdown$XmlParser$cyclic$cdataContent());
					},
					$dillonkearns$elm_markdown$XmlParser$symbol(']]')),
					A2(
					$elm$parser$Parser$Advanced$andThen,
					function (_v1) {
						return A2(
							$elm$parser$Parser$Advanced$map,
							function (tail) {
								return ']' + tail;
							},
							$dillonkearns$elm_markdown$XmlParser$cyclic$cdataContent());
					},
					$dillonkearns$elm_markdown$XmlParser$symbol(']')),
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed($elm$core$Basics$append),
						A2(
							$dillonkearns$elm_markdown$XmlParser$keep,
							$dillonkearns$elm_markdown$XmlParser$zeroOrMore,
							function (c) {
								return c !== ']';
							})),
					$elm$parser$Parser$Advanced$lazy(
						function (_v2) {
							return $dillonkearns$elm_markdown$XmlParser$cyclic$cdataContent();
						}))
				])));
}
var $dillonkearns$elm_markdown$XmlParser$cdataContent = $dillonkearns$elm_markdown$XmlParser$cyclic$cdataContent();
$dillonkearns$elm_markdown$XmlParser$cyclic$cdataContent = function () {
	return $dillonkearns$elm_markdown$XmlParser$cdataContent;
};
var $dillonkearns$elm_markdown$XmlParser$cdata = A2(
	$elm$parser$Parser$Advanced$inContext,
	'cdata',
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$dillonkearns$elm_markdown$XmlParser$symbol('<![CDATA[')),
		$dillonkearns$elm_markdown$XmlParser$cdataContent));
var $dillonkearns$elm_markdown$XmlParser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $dillonkearns$elm_markdown$XmlParser$comment = A2(
	$elm$parser$Parser$Advanced$ignorer,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed(0),
			$elm$parser$Parser$Advanced$token(
				$dillonkearns$elm_markdown$XmlParser$toToken('<!--'))),
		$elm$parser$Parser$Advanced$chompUntil(
			$dillonkearns$elm_markdown$XmlParser$toToken('-->'))),
	$elm$parser$Parser$Advanced$token(
		$dillonkearns$elm_markdown$XmlParser$toToken('-->')));
function $dillonkearns$elm_markdown$XmlParser$cyclic$textNodeString() {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'textNodeString',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed(
							F2(
								function (s, maybeString) {
									return $elm$core$Maybe$Just(
										_Utils_ap(
											s,
											A2($elm$core$Maybe$withDefault, '', maybeString)));
								})),
						A2(
							$dillonkearns$elm_markdown$XmlParser$keep,
							$dillonkearns$elm_markdown$XmlParser$oneOrMore,
							function (c) {
								return (c !== '<') && (c !== '&');
							})),
					$elm$parser$Parser$Advanced$lazy(
						function (_v0) {
							return $dillonkearns$elm_markdown$XmlParser$cyclic$textNodeString();
						})),
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed(
							F2(
								function (c, maybeString) {
									return $elm$core$Maybe$Just(
										A2(
											$elm$core$String$cons,
											c,
											A2($elm$core$Maybe$withDefault, '', maybeString)));
								})),
						$dillonkearns$elm_markdown$XmlParser$escapedChar('<')),
					$elm$parser$Parser$Advanced$lazy(
						function (_v1) {
							return $dillonkearns$elm_markdown$XmlParser$cyclic$textNodeString();
						})),
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed(
							F2(
								function (s, maybeString) {
									var str = _Utils_ap(
										s,
										A2($elm$core$Maybe$withDefault, '', maybeString));
									return (str !== '') ? $elm$core$Maybe$Just(str) : $elm$core$Maybe$Nothing;
								})),
						$dillonkearns$elm_markdown$XmlParser$cdata),
					$elm$parser$Parser$Advanced$lazy(
						function (_v2) {
							return $dillonkearns$elm_markdown$XmlParser$cyclic$textNodeString();
						})),
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(
							function (maybeString) {
								var str = A2($elm$core$Maybe$withDefault, '', maybeString);
								return (str !== '') ? $elm$core$Maybe$Just(str) : $elm$core$Maybe$Nothing;
							}),
						$dillonkearns$elm_markdown$XmlParser$comment),
					$elm$parser$Parser$Advanced$lazy(
						function (_v3) {
							return $dillonkearns$elm_markdown$XmlParser$cyclic$textNodeString();
						})),
					$elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing)
				])));
}
var $dillonkearns$elm_markdown$XmlParser$textNodeString = $dillonkearns$elm_markdown$XmlParser$cyclic$textNodeString();
$dillonkearns$elm_markdown$XmlParser$cyclic$textNodeString = function () {
	return $dillonkearns$elm_markdown$XmlParser$textNodeString;
};
var $dillonkearns$elm_markdown$XmlParser$children = function (startTagName) {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'children',
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(_List_Nil),
					$dillonkearns$elm_markdown$XmlParser$closingTag(startTagName)),
					A2(
					$elm$parser$Parser$Advanced$andThen,
					function (maybeString) {
						if (!maybeString.$) {
							var s = maybeString.a;
							return A2(
								$elm$parser$Parser$Advanced$keeper,
								$elm$parser$Parser$Advanced$succeed(
									function (rest) {
										return A2(
											$elm$core$List$cons,
											$dillonkearns$elm_markdown$XmlParser$Text(s),
											rest);
									}),
								$dillonkearns$elm_markdown$XmlParser$children(startTagName));
						} else {
							return A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed(_List_Nil),
								$dillonkearns$elm_markdown$XmlParser$closingTag(startTagName));
						}
					},
					$dillonkearns$elm_markdown$XmlParser$textNodeString),
					$elm$parser$Parser$Advanced$lazy(
					function (_v2) {
						return A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$keeper,
								$elm$parser$Parser$Advanced$succeed($elm$core$List$cons),
								$dillonkearns$elm_markdown$XmlParser$cyclic$element()),
							$dillonkearns$elm_markdown$XmlParser$children(startTagName));
					})
				])));
};
function $dillonkearns$elm_markdown$XmlParser$cyclic$element() {
	return A2(
		$elm$parser$Parser$Advanced$inContext,
		'element',
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$dillonkearns$elm_markdown$XmlParser$symbol('<')),
			A2(
				$elm$parser$Parser$Advanced$andThen,
				function (startTagName) {
					return A2(
						$elm$parser$Parser$Advanced$keeper,
						A2(
							$elm$parser$Parser$Advanced$keeper,
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$elm$parser$Parser$Advanced$succeed(
									$dillonkearns$elm_markdown$XmlParser$Element(startTagName)),
								$dillonkearns$elm_markdown$XmlParser$whiteSpace),
							A2(
								$elm$parser$Parser$Advanced$ignorer,
								$dillonkearns$elm_markdown$XmlParser$attributes($elm$core$Set$empty),
								$dillonkearns$elm_markdown$XmlParser$whiteSpace)),
						$elm$parser$Parser$Advanced$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$Advanced$ignorer,
									$elm$parser$Parser$Advanced$succeed(_List_Nil),
									$dillonkearns$elm_markdown$XmlParser$symbol('/>')),
									A2(
									$elm$parser$Parser$Advanced$keeper,
									A2(
										$elm$parser$Parser$Advanced$ignorer,
										$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
										$dillonkearns$elm_markdown$XmlParser$symbol('>')),
									$elm$parser$Parser$Advanced$lazy(
										function (_v0) {
											return $dillonkearns$elm_markdown$XmlParser$children(startTagName);
										}))
								])));
				},
				$dillonkearns$elm_markdown$XmlParser$tagName)));
}
var $dillonkearns$elm_markdown$XmlParser$element = $dillonkearns$elm_markdown$XmlParser$cyclic$element();
$dillonkearns$elm_markdown$XmlParser$cyclic$element = function () {
	return $dillonkearns$elm_markdown$XmlParser$element;
};
var $dillonkearns$elm_markdown$Markdown$Parser$Heading = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompUntilEndOr = function (str) {
	return function (s) {
		var _v0 = A5(_Parser_findSubString, str, s.aH, s.hn, s.c7, s.eq);
		var newOffset = _v0.a;
		var newRow = _v0.b;
		var newCol = _v0.c;
		var adjustedOffset = (newOffset < 0) ? $elm$core$String$length(s.eq) : newOffset;
		return A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.aH, adjustedOffset) < 0,
			0,
			{c7: newCol, c: s.c, d: s.d, aH: adjustedOffset, hn: newRow, eq: s.eq});
	};
};
var $elm$core$String$endsWith = _String_endsWith;
var $elm$core$String$trimRight = _String_trimRight;
var $dillonkearns$elm_markdown$Markdown$Parser$dropTrailingHashes = function (headingString) {
	return A2($elm$core$String$endsWith, '#', headingString) ? $dillonkearns$elm_markdown$Markdown$Parser$dropTrailingHashes(
		$elm$core$String$trimRight(
			A2($elm$core$String$dropRight, 1, headingString))) : headingString;
};
var $dillonkearns$elm_markdown$Markdown$Parser$heading = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$Parser$Heading),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'#',
					$elm$parser$Parser$Expecting('#')))),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$andThen,
				function (additionalHashes) {
					var level = $elm$core$String$length(additionalHashes) + 1;
					return (level >= 7) ? $elm$parser$Parser$Advanced$problem(
						$elm$parser$Parser$Expecting('heading with < 7 #\'s')) : $elm$parser$Parser$Advanced$succeed(level);
				},
				$elm$parser$Parser$Advanced$getChompedString(
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(0),
						$elm$parser$Parser$Advanced$chompWhile(
							function (c) {
								return c === '#';
							})))),
			$elm$parser$Parser$Advanced$chompWhile(
				function (c) {
					return c === ' ';
				}))),
	A2(
		$elm$parser$Parser$Advanced$andThen,
		function (headingText) {
			return $elm$parser$Parser$Advanced$succeed(
				$dillonkearns$elm_markdown$Markdown$Parser$dropTrailingHashes(headingText));
		},
		$elm$parser$Parser$Advanced$getChompedString(
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(0),
				$elm$parser$Parser$Advanced$chompUntilEndOr('\n')))));
var $dillonkearns$elm_markdown$Markdown$Parser$ListBlock = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$List$listMarkerParser = function () {
	var markerOption = function (marker) {
		return $elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					marker,
					$elm$parser$Parser$ExpectingSymbol(marker))));
	};
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				markerOption('-'),
				markerOption('+'),
				markerOption('*')
			]));
}();
var $dillonkearns$elm_markdown$Parser$Extra$oneOrMore = function (condition) {
	return A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$chompIf,
			condition,
			$elm$parser$Parser$Problem('Expected one or more character')),
		$elm$parser$Parser$Advanced$chompWhile(condition));
};
var $dillonkearns$elm_markdown$Markdown$List$openingItemParser = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		$elm$parser$Parser$Advanced$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$backtrackable($dillonkearns$elm_markdown$Markdown$List$listMarkerParser),
			$dillonkearns$elm_markdown$Parser$Extra$oneOrMore(
				function (c) {
					return c === ' ';
				}))),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
		$elm$parser$Parser$Advanced$symbol(
			A2(
				$elm$parser$Parser$Advanced$Token,
				'\n',
				$elm$parser$Parser$ExpectingSymbol('\n')))));
var $dillonkearns$elm_markdown$Markdown$List$singleItemParser = function (listMarker) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$Advanced$backtrackable(
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							listMarker,
							$elm$parser$Parser$ExpectingSymbol(listMarker))),
					$dillonkearns$elm_markdown$Parser$Extra$oneOrMore(
						function (c) {
							return c === ' ';
						})))),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'\n',
					$elm$parser$Parser$ExpectingSymbol('\n')))));
};
var $dillonkearns$elm_markdown$Markdown$List$statementsHelp = F3(
	function (listMarker, firstItem, revStmts) {
		return $elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$keeper,
						A2(
							$elm$parser$Parser$Advanced$keeper,
							$elm$parser$Parser$Advanced$succeed(
								F3(
									function (offsetBefore, stmt, offsetAfter) {
										return $elm$parser$Parser$Advanced$Loop(
											A2($elm$core$List$cons, stmt, revStmts));
									})),
							$elm$parser$Parser$Advanced$getOffset),
						$dillonkearns$elm_markdown$Markdown$List$singleItemParser(listMarker)),
					$elm$parser$Parser$Advanced$getOffset),
					A2(
					$elm$parser$Parser$Advanced$map,
					function (_v0) {
						return $elm$parser$Parser$Advanced$Done(
							A2(
								$elm$core$List$cons,
								firstItem,
								$elm$core$List$reverse(revStmts)));
					},
					$elm$parser$Parser$Advanced$succeed(0))
				]));
	});
var $dillonkearns$elm_markdown$Markdown$List$parser = A2(
	$elm$parser$Parser$Advanced$andThen,
	function (_v0) {
		var listMarker = _v0.a;
		var firstItem = _v0.b;
		return A2(
			$elm$parser$Parser$Advanced$loop,
			_List_Nil,
			A2($dillonkearns$elm_markdown$Markdown$List$statementsHelp, listMarker, firstItem));
	},
	$dillonkearns$elm_markdown$Markdown$List$openingItemParser);
var $dillonkearns$elm_markdown$Markdown$Parser$listBlock = A2(
	$elm$parser$Parser$Advanced$map,
	$dillonkearns$elm_markdown$Markdown$Parser$ListBlock,
	A2(
		$elm$parser$Parser$Advanced$map,
		$elm$core$List$map($elm$core$Basics$identity),
		$dillonkearns$elm_markdown$Markdown$List$parser));
var $dillonkearns$elm_markdown$Markdown$Block$CodeBlock = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Heading = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_markdown$Markdown$Block$ListBlock = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$ThematicBreak = {$: 5};
var $dillonkearns$elm_markdown$Markdown$Parser$just = function (value) {
	return $elm$parser$Parser$Advanced$succeed(
		$elm$core$Maybe$Just(value));
};
var $dillonkearns$elm_markdown$Markdown$Inlines$isUninteresting = function (_char) {
	return (_char !== '*') && ((_char !== '`') && ((_char !== '[') && (_char !== '!')));
};
var $dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenAllFailed = F2(
	function (_v0, string) {
		var currStyle = _v0.a;
		var revStyledStrings = _v0.b;
		return $elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple3(
				currStyle,
				revStyledStrings,
				$elm$core$Maybe$Just(string)));
	});
var $dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundBold = F2(
	function (_v0, string) {
		var currStyle = _v0.a;
		var revStyledStrings = _v0.b;
		return $elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple3(
				_Utils_update(
					currStyle,
					{gq: !currStyle.gq}),
				A2(
					$elm$core$List$cons,
					{hQ: string, hS: currStyle},
					revStyledStrings),
				$elm$core$Maybe$Nothing));
	});
var $dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundCode = F2(
	function (_v0, string) {
		var currStyle = _v0.a;
		var revStyledStrings = _v0.b;
		return $elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple3(
				_Utils_update(
					currStyle,
					{gr: !currStyle.gr}),
				A2(
					$elm$core$List$cons,
					{hQ: string, hS: currStyle},
					revStyledStrings),
				$elm$core$Maybe$Nothing));
	});
var $dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundItalic = F2(
	function (_v0, string) {
		var currStyle = _v0.a;
		var revStyledStrings = _v0.b;
		return $elm$parser$Parser$Advanced$Loop(
			_Utils_Tuple3(
				_Utils_update(
					currStyle,
					{gs: !currStyle.gs}),
				A2(
					$elm$core$List$cons,
					{hQ: string, hS: currStyle},
					revStyledStrings),
				$elm$core$Maybe$Nothing));
	});
var $dillonkearns$elm_markdown$Markdown$Block$Image = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Block$Link = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundLink = F3(
	function (link, _v0, string) {
		var currStyle = _v0.a;
		var revStyledStrings = _v0.b;
		if (!link.$) {
			var record = link.a;
			return $elm$parser$Parser$Advanced$Loop(
				_Utils_Tuple3(
					currStyle,
					A2(
						$elm$core$List$cons,
						{
							hQ: record.fK,
							hS: _Utils_update(
								currStyle,
								{
									gB: $elm$core$Maybe$Just(
										{
											fL: $dillonkearns$elm_markdown$Markdown$Block$Link(record.fL),
											ie: record.ie
										})
								})
						},
						A2(
							$elm$core$List$cons,
							{hQ: string, hS: currStyle},
							revStyledStrings)),
					$elm$core$Maybe$Nothing));
		} else {
			var record = link.a;
			return $elm$parser$Parser$Advanced$Loop(
				_Utils_Tuple3(
					currStyle,
					A2(
						$elm$core$List$cons,
						{
							hQ: record.eY,
							hS: _Utils_update(
								currStyle,
								{
									gB: $elm$core$Maybe$Just(
										{
											fL: $dillonkearns$elm_markdown$Markdown$Block$Image(record.eq),
											ie: $elm$core$Maybe$Nothing
										})
								})
						},
						A2(
							$elm$core$List$cons,
							{hQ: string, hS: currStyle},
							revStyledStrings)),
					$elm$core$Maybe$Nothing));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundNothing = F2(
	function (_v0, string) {
		var currStyle = _v0.a;
		var revStyledStrings = _v0.b;
		return $elm$parser$Parser$Advanced$Done(
			A2(
				$elm$core$List$filter,
				function (thing) {
					return thing.hQ !== '';
				},
				$elm$core$List$reverse(
					A2(
						$elm$core$List$cons,
						{hQ: string, hS: currStyle},
						revStyledStrings))));
	});
var $dillonkearns$elm_markdown$Markdown$Link$Image = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Link$Link = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_markdown$Markdown$Link$linkDestination = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'<',
						$elm$parser$Parser$ExpectingSymbol('<')))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntil(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'>',
							$elm$parser$Parser$ExpectingSymbol('>')))),
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'>',
						$elm$parser$Parser$ExpectingSymbol('>'))))),
			A2(
			$elm$parser$Parser$Advanced$keeper,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntil(
					A2(
						$elm$parser$Parser$Advanced$Token,
						')',
						$elm$parser$Parser$ExpectingSymbol(')')))))
		]));
var $dillonkearns$elm_markdown$Markdown$Link$parser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(
						F2(
							function (alt, src) {
								return $dillonkearns$elm_markdown$Markdown$Link$Image(
									{eY: alt, eq: src});
							})),
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'![',
							$elm$parser$Parser$ExpectingSymbol('[')))),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$getChompedString(
							$elm$parser$Parser$Advanced$chompUntil(
								A2(
									$elm$parser$Parser$Advanced$Token,
									']',
									$elm$parser$Parser$ExpectingSymbol(']')))),
						$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								']',
								$elm$parser$Parser$ExpectingSymbol(']')))),
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'(',
							$elm$parser$Parser$ExpectingSymbol('('))))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntil(
						A2(
							$elm$parser$Parser$Advanced$Token,
							')',
							$elm$parser$Parser$ExpectingSymbol(')')))),
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						')',
						$elm$parser$Parser$ExpectingSymbol(')'))))),
			A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed(
						F2(
							function (description, destination) {
								return $dillonkearns$elm_markdown$Markdown$Link$Link(
									{fK: description, fL: destination, ie: $elm$core$Maybe$Nothing});
							})),
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'[',
							$elm$parser$Parser$ExpectingSymbol('[')))),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$getChompedString(
							$elm$parser$Parser$Advanced$chompUntil(
								A2(
									$elm$parser$Parser$Advanced$Token,
									']',
									$elm$parser$Parser$ExpectingSymbol(']')))),
						$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								']',
								$elm$parser$Parser$ExpectingSymbol(']')))),
					$elm$parser$Parser$Advanced$symbol(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'(',
							$elm$parser$Parser$ExpectingSymbol('('))))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$dillonkearns$elm_markdown$Markdown$Link$linkDestination,
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						')',
						$elm$parser$Parser$ExpectingSymbol(')')))))
		]));
var $dillonkearns$elm_markdown$Markdown$Inlines$parseHelp = function (state) {
	var allFailed = state.c;
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		function (chompedString) {
			return $elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$Advanced$map,
						function (link) {
							return A3($dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundLink, link, state, chompedString);
						},
						$dillonkearns$elm_markdown$Markdown$Link$parser),
						A2(
						$elm$parser$Parser$Advanced$map,
						function (_v0) {
							return A2($dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundCode, state, chompedString);
						},
						$elm$parser$Parser$Advanced$token(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'`',
								$elm$parser$Parser$Expecting('`')))),
						A2(
						$elm$parser$Parser$Advanced$map,
						function (_v1) {
							return A2($dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundBold, state, chompedString);
						},
						$elm$parser$Parser$Advanced$token(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'**',
								$elm$parser$Parser$Expecting('**')))),
						A2(
						$elm$parser$Parser$Advanced$map,
						function (_v2) {
							return A2($dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundItalic, state, chompedString);
						},
						$elm$parser$Parser$Advanced$token(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'*',
								$elm$parser$Parser$Expecting('*')))),
						A2(
						$elm$parser$Parser$Advanced$keeper,
						$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$elm$parser$Parser$Advanced$succeed(
								A2($dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenFoundNothing, state, chompedString)),
							$elm$parser$Parser$Advanced$end(
								$elm$parser$Parser$Expecting('End of inlines')))),
						$elm$parser$Parser$Advanced$succeed(
						A2($dillonkearns$elm_markdown$Markdown$Inlines$nextStepWhenAllFailed, state, chompedString))
					]));
		},
		function () {
			if (allFailed.$ === 1) {
				return $elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompWhile($dillonkearns$elm_markdown$Markdown$Inlines$isUninteresting));
			} else {
				var unhanldedString = allFailed.a;
				return A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(
						function (chomped) {
							return _Utils_ap(unhanldedString, chomped);
						}),
					$elm$parser$Parser$Advanced$getChompedString(
						A2(
							$elm$parser$Parser$Advanced$chompIf,
							function (_v4) {
								return true;
							},
							$elm$parser$Parser$Expecting('*'))));
			}
		}());
};
var $dillonkearns$elm_markdown$Markdown$Inlines$parse = A2(
	$elm$parser$Parser$Advanced$loop,
	_Utils_Tuple3(
		{gq: false, gr: false, gs: false, gB: $elm$core$Maybe$Nothing},
		_List_Nil,
		$elm$core$Maybe$Nothing),
	$dillonkearns$elm_markdown$Markdown$Inlines$parseHelp);
var $dillonkearns$elm_markdown$Markdown$Parser$parseRawInline = F2(
	function (wrap, _v0) {
		var unparsedInlines = _v0;
		var _v1 = A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$Inlines$parse, unparsedInlines);
		if (!_v1.$) {
			var styledLine = _v1.a;
			return $elm$parser$Parser$Advanced$succeed(
				wrap(styledLine));
		} else {
			var error = _v1.a;
			return $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Expecting(
					A2(
						$elm$core$String$join,
						'\n',
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error))));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseInlines = function (rawBlock) {
	switch (rawBlock.$) {
		case 0:
			var level = rawBlock.a;
			var unparsedInlines = rawBlock.b;
			var _v1 = A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$Inlines$parse, unparsedInlines);
			if (!_v1.$) {
				var styledLine = _v1.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$just(
					A2($dillonkearns$elm_markdown$Markdown$Block$Heading, level, styledLine));
			} else {
				var error = _v1.a;
				return $elm$parser$Parser$Advanced$problem(
					$elm$parser$Parser$Expecting(
						A2(
							$elm$core$String$join,
							'\n',
							A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error))));
			}
		case 1:
			var unparsedInlines = rawBlock.a;
			var _v2 = A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$Inlines$parse, unparsedInlines);
			if (!_v2.$) {
				var styledLine = _v2.a;
				return $dillonkearns$elm_markdown$Markdown$Parser$just(
					$dillonkearns$elm_markdown$Markdown$Block$Body(styledLine));
			} else {
				var error = _v2.a;
				return $elm$parser$Parser$Advanced$problem(
					$elm$parser$Parser$Expecting(
						A2(
							$elm$core$String$join,
							'\n',
							A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error))));
			}
		case 2:
			var tagName = rawBlock.a;
			var attributes = rawBlock.b;
			var children = rawBlock.c;
			return $dillonkearns$elm_markdown$Markdown$Parser$just(
				A3($dillonkearns$elm_markdown$Markdown$Block$Html, tagName, attributes, children));
		case 3:
			var unparsedInlines = rawBlock.a;
			return A2(
				$elm$parser$Parser$Advanced$map,
				$elm$core$Maybe$Just,
				A2(
					$elm$parser$Parser$Advanced$map,
					$dillonkearns$elm_markdown$Markdown$Block$ListBlock,
					$dillonkearns$elm_markdown$Markdown$Parser$combine(
						$elm$core$List$reverse(
							A2(
								$elm$core$List$map,
								$dillonkearns$elm_markdown$Markdown$Parser$parseRawInline($elm$core$Basics$identity),
								unparsedInlines)))));
		case 4:
			var codeBlock = rawBlock.a;
			return $dillonkearns$elm_markdown$Markdown$Parser$just(
				$dillonkearns$elm_markdown$Markdown$Block$CodeBlock(codeBlock));
		case 5:
			return $dillonkearns$elm_markdown$Markdown$Parser$just($dillonkearns$elm_markdown$Markdown$Block$ThematicBreak);
		default:
			return $elm$parser$Parser$Advanced$succeed($elm$core$Maybe$Nothing);
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$combineBlocks = F2(
	function (rawBlock, soFar) {
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (parsedBlocks) {
				return A2(
					$elm$parser$Parser$Advanced$map,
					function (maybeNewParsedBlock) {
						if (!maybeNewParsedBlock.$) {
							var newParsedBlock = maybeNewParsedBlock.a;
							return A2($elm$core$List$cons, newParsedBlock, parsedBlocks);
						} else {
							return parsedBlocks;
						}
					},
					$dillonkearns$elm_markdown$Markdown$Parser$parseInlines(rawBlock));
			},
			soFar);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines = function (rawBlocks) {
	return A3(
		$elm$core$List$foldl,
		$dillonkearns$elm_markdown$Markdown$Parser$combineBlocks,
		$elm$parser$Parser$Advanced$succeed(_List_Nil),
		rawBlocks);
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$indentedBlock = A2(
	$elm$parser$Parser$Advanced$keeper,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$succeed(
			function (body) {
				return {fc: body, ck: $elm$core$Maybe$Nothing};
			}),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'    ',
						$elm$parser$Parser$ExpectingSymbol('Indentation'))),
					$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'\t',
						$elm$parser$Parser$ExpectingSymbol('Indentation')))
				]))),
	$elm$parser$Parser$Advanced$getChompedString(
		$elm$parser$Parser$Advanced$chompUntilEndOr('\n')));
var $dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp = function (delimeter) {
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(
					F2(
						function (language, body) {
							return {
								fc: body,
								ck: (language === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(language)
							};
						})),
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						delimeter,
						$elm$parser$Parser$ExpectingSymbol(delimeter)))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$getChompedString(
					$elm$parser$Parser$Advanced$chompUntil(
						A2(
							$elm$parser$Parser$Advanced$Token,
							'\n',
							$elm$parser$Parser$Problem('Expecting newline')))),
				$elm$parser$Parser$Advanced$symbol(
					A2(
						$elm$parser$Parser$Advanced$Token,
						'\n',
						$elm$parser$Parser$ExpectingSymbol('\n'))))),
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$getChompedString(
				$elm$parser$Parser$Advanced$chompUntilEndOr('\n' + delimeter)),
			$elm$parser$Parser$Advanced$symbol(
				A2(
					$elm$parser$Parser$Advanced$Token,
					'\n' + delimeter,
					$elm$parser$Parser$ExpectingSymbol(delimeter)))));
};
var $dillonkearns$elm_markdown$Markdown$CodeBlock$parser = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp('```'),
			$dillonkearns$elm_markdown$Markdown$CodeBlock$parserHelp('~~~'),
			$dillonkearns$elm_markdown$Markdown$CodeBlock$indentedBlock
		]));
var $dillonkearns$elm_markdown$Markdown$Parser$plainLine = A2(
	$elm$parser$Parser$Advanced$keeper,
	$elm$parser$Parser$Advanced$succeed(
		function (rawLine) {
			return $dillonkearns$elm_markdown$Markdown$Parser$Body(rawLine);
		}),
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		$elm$parser$Parser$Advanced$getChompedString(
			$elm$parser$Parser$Advanced$chompUntilEndOr('\n')),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$chompIf,
					function (c) {
						return c === '\n';
					},
					$elm$parser$Parser$Expecting('A single non-newline char.')),
					$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('End'))
				]))));
var $dillonkearns$elm_markdown$Markdown$Parser$ThematicBreak = {$: 5};
var $dillonkearns$elm_markdown$Markdown$Parser$spaceOrTab = function (c) {
	return (c === ' ') || (c === '\t');
};
var $dillonkearns$elm_markdown$Parser$Extra$zeroOrMore = function (condition) {
	return $elm$parser$Parser$Advanced$chompWhile(condition);
};
var $dillonkearns$elm_markdown$Markdown$Parser$thematicBreak = A2(
	$elm$parser$Parser$Advanced$ignorer,
	A2(
		$elm$parser$Parser$Advanced$ignorer,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed($dillonkearns$elm_markdown$Markdown$Parser$ThematicBreak),
				$elm$parser$Parser$Advanced$oneOf(
					_List_fromArray(
						[
							$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'   ',
								$elm$parser$Parser$Problem('Expecting 3 spaces'))),
							$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'  ',
								$elm$parser$Parser$Problem('Expecting 2 spaces'))),
							$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								' ',
								$elm$parser$Parser$Problem('Expecting space'))),
							$elm$parser$Parser$Advanced$succeed(0)
						]))),
			$elm$parser$Parser$Advanced$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'---',
								$elm$parser$Parser$Expecting('---'))),
						$elm$parser$Parser$Advanced$chompWhile(
							function (c) {
								return c === '-';
							})),
						A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'***',
								$elm$parser$Parser$Expecting('***'))),
						$elm$parser$Parser$Advanced$chompWhile(
							function (c) {
								return c === '*';
							})),
						A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$symbol(
							A2(
								$elm$parser$Parser$Advanced$Token,
								'___',
								$elm$parser$Parser$Expecting('___'))),
						$elm$parser$Parser$Advanced$chompWhile(
							function (c) {
								return c === '_';
							}))
					]))),
		$dillonkearns$elm_markdown$Parser$Extra$zeroOrMore($dillonkearns$elm_markdown$Markdown$Parser$spaceOrTab)),
	$elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				$elm$parser$Parser$Advanced$end(
				$elm$parser$Parser$Problem('Expecting end')),
				A2(
				$elm$parser$Parser$Advanced$chompIf,
				function (c) {
					return c === '\n';
				},
				$elm$parser$Parser$Problem('Expecting newline'))
			])));
var $elm$core$String$trim = _String_trim;
var $dillonkearns$elm_markdown$Markdown$Parser$childToParser = function (node) {
	if (!node.$) {
		var tag = node.a;
		var attributes = node.b;
		var children = node.c;
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (childrenAsBlocks) {
				return $elm$parser$Parser$Advanced$succeed(
					_List_fromArray(
						[
							A3($dillonkearns$elm_markdown$Markdown$Block$Html, tag, attributes, childrenAsBlocks)
						]));
			},
			$dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksParser(children));
	} else {
		var innerText = node.a;
		var _v5 = A2(
			$elm$parser$Parser$Advanced$run,
			$dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2(),
			innerText);
		if (!_v5.$) {
			var value = _v5.a;
			return $elm$parser$Parser$Advanced$succeed(value);
		} else {
			var error = _v5.a;
			return $elm$parser$Parser$Advanced$problem(
				$elm$parser$Parser$Expecting(
					A2(
						$elm$core$String$join,
						'\n',
						A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error))));
		}
	}
};
var $dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksParser = function (children) {
	return A2(
		$elm$parser$Parser$Advanced$map,
		$elm$core$List$concat,
		$dillonkearns$elm_markdown$Markdown$Parser$combine(
			A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$childToParser, children)));
};
var $dillonkearns$elm_markdown$Markdown$Parser$statementsHelp2 = function (revStmts) {
	var keepLooping = function (parser) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			function (stmts) {
				var _v2 = _Utils_Tuple2(stmts, revStmts);
				if (((_v2.a.$ === 1) && _v2.b.b) && (_v2.b.a.$ === 1)) {
					var body1 = _v2.a.a;
					var _v3 = _v2.b;
					var body2 = _v3.a.a;
					var rest = _v3.b;
					var body2Trimmed = $elm$core$String$trim(body2);
					var body1Trimmed = $elm$core$String$trim(body1);
					return ((body1Trimmed === '') || (body2Trimmed === '')) ? $elm$parser$Parser$Advanced$Loop(
						A2(
							$elm$core$List$cons,
							$dillonkearns$elm_markdown$Markdown$Parser$Body(
								_Utils_ap(body2Trimmed, body1Trimmed)),
							rest)) : $elm$parser$Parser$Advanced$Loop(
						A2(
							$elm$core$List$cons,
							$dillonkearns$elm_markdown$Markdown$Parser$Body(body2Trimmed + (' ' + body1Trimmed)),
							rest));
				} else {
					return $elm$parser$Parser$Advanced$Loop(
						A2($elm$core$List$cons, stmts, revStmts));
				}
			},
			parser);
	};
	return $elm$parser$Parser$Advanced$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$Advanced$map,
				function (_v1) {
					return $elm$parser$Parser$Advanced$Done(revStmts);
				},
				$elm$parser$Parser$Advanced$end(
					$elm$parser$Parser$Expecting('End'))),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$blankLine),
				keepLooping(
				A2($elm$parser$Parser$Advanced$map, $dillonkearns$elm_markdown$Markdown$Parser$CodeBlock, $dillonkearns$elm_markdown$Markdown$CodeBlock$parser)),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$thematicBreak),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$listBlock),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$heading),
				keepLooping(
				$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser()),
				keepLooping($dillonkearns$elm_markdown$Markdown$Parser$plainLine),
				$elm$parser$Parser$Advanced$succeed(
				$elm$parser$Parser$Advanced$Done(revStmts))
			]));
};
var $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode = function (parser) {
	return A2(
		$elm$parser$Parser$Advanced$andThen,
		function (xmlNode) {
			if (xmlNode.$ === 1) {
				var innerText = xmlNode.a;
				return $elm$parser$Parser$Advanced$succeed(
					$dillonkearns$elm_markdown$Markdown$Parser$Body(innerText));
			} else {
				var tag = xmlNode.a;
				var attributes = xmlNode.b;
				var children = xmlNode.c;
				return A2(
					$elm$parser$Parser$Advanced$andThen,
					function (parsedChildren) {
						return $elm$parser$Parser$Advanced$succeed(
							A3($dillonkearns$elm_markdown$Markdown$Parser$Html, tag, attributes, parsedChildren));
					},
					$dillonkearns$elm_markdown$Markdown$Parser$nodesToBlocksParser(children));
			}
		},
		parser);
};
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser() {
	return $dillonkearns$elm_markdown$Markdown$Parser$xmlNodeToHtmlNode($dillonkearns$elm_markdown$XmlParser$element);
}
function $dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2() {
	return A2(
		$elm$parser$Parser$Advanced$map,
		$elm$core$List$filter(
			function (item) {
				return !_Utils_eq(
					item,
					$dillonkearns$elm_markdown$Markdown$Block$Body(_List_Nil));
			}),
		A2(
			$elm$parser$Parser$Advanced$andThen,
			$dillonkearns$elm_markdown$Markdown$Parser$parseAllInlines,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2($elm$parser$Parser$Advanced$loop, _List_Nil, $dillonkearns$elm_markdown$Markdown$Parser$statementsHelp2),
				$elm$parser$Parser$Advanced$succeed($elm$parser$Parser$Advanced$end))));
}
var $dillonkearns$elm_markdown$Markdown$Parser$htmlParser = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$htmlParser = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$htmlParser;
};
var $dillonkearns$elm_markdown$Markdown$Parser$multiParser2 = $dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2();
$dillonkearns$elm_markdown$Markdown$Parser$cyclic$multiParser2 = function () {
	return $dillonkearns$elm_markdown$Markdown$Parser$multiParser2;
};
var $dillonkearns$elm_markdown$Markdown$Parser$parse = function (input) {
	return A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$Parser$multiParser2, input);
};
var $dillonkearns$elm_markdown$Markdown$Parser$combineResults = A2(
	$elm$core$List$foldr,
	$elm$core$Result$map2($elm$core$List$cons),
	$elm$core$Result$Ok(_List_Nil));
var $dillonkearns$elm_markdown$Markdown$Parser$renderHtml = F5(
	function (tagName, attributes, children, _v0, renderedChildren) {
		var htmlRenderer = _v0;
		return A2(
			$elm$core$Result$andThen,
			function (okChildren) {
				return A2(
					$elm$core$Result$map,
					function (myRenderer) {
						return myRenderer(okChildren);
					},
					A3(htmlRenderer, tagName, attributes, children));
			},
			$dillonkearns$elm_markdown$Markdown$Parser$combineResults(renderedChildren));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$foldThing = F3(
	function (renderer, _v0, soFar) {
		var style = _v0.hS;
		var string = _v0.hQ;
		var _v1 = style.gB;
		if (!_v1.$) {
			var link = _v1.a;
			var _v2 = link.fL;
			if (_v2.$ === 1) {
				var destination = _v2.a;
				var _v3 = A2($elm$parser$Parser$Advanced$run, $dillonkearns$elm_markdown$Markdown$Inlines$parse, string);
				if (!_v3.$) {
					var styledLine = _v3.a;
					return A2(
						$elm$core$List$cons,
						A2(
							$elm$core$Result$andThen,
							function (children) {
								return A2(
									renderer.gB,
									{fL: destination, ie: link.ie},
									children);
							},
							A2($dillonkearns$elm_markdown$Markdown$Parser$renderStyled, renderer, styledLine)),
						soFar);
				} else {
					var error = _v3.a;
					return _Utils_ap(
						A2(
							$elm$core$List$map,
							$elm$core$Result$Err,
							A2($elm$core$List$map, $dillonkearns$elm_markdown$Markdown$Parser$deadEndToString, error)),
						soFar);
				}
			} else {
				var src = _v2.a;
				return A2(
					$elm$core$List$cons,
					A2(
						renderer.cc,
						{eq: src},
						string),
					soFar);
			}
		} else {
			return style.gq ? A2(
				$elm$core$List$cons,
				$elm$core$Result$Ok(
					renderer.fd(string)),
				soFar) : (style.gs ? A2(
				$elm$core$List$cons,
				$elm$core$Result$Ok(
					renderer.gv(string)),
				soFar) : (style.gr ? A2(
				$elm$core$List$cons,
				$elm$core$Result$Ok(
					renderer.fv(string)),
				soFar) : A2(
				$elm$core$List$cons,
				$elm$core$Result$Ok(
					renderer.hb(string)),
				soFar)));
		}
	});
var $dillonkearns$elm_markdown$Markdown$Parser$renderStyled = F2(
	function (renderer, styledStrings) {
		return $dillonkearns$elm_markdown$Markdown$Parser$combineResults(
			A3(
				$elm$core$List$foldr,
				$dillonkearns$elm_markdown$Markdown$Parser$foldThing(renderer),
				_List_Nil,
				styledStrings));
	});
var $dillonkearns$elm_markdown$Markdown$Inlines$toString = function (list) {
	return A2(
		$elm$core$String$join,
		'-',
		A2(
			$elm$core$List$map,
			function ($) {
				return $.hQ;
			},
			list));
};
var $dillonkearns$elm_markdown$Markdown$Parser$renderHelper = F2(
	function (renderer, blocks) {
		return A2(
			$elm$core$List$map,
			function (block) {
				switch (block.$) {
					case 0:
						var level = block.a;
						var content = block.b;
						return A2(
							$elm$core$Result$map,
							function (children) {
								return renderer.f0(
									{
										fr: children,
										gA: level,
										hh: $dillonkearns$elm_markdown$Markdown$Inlines$toString(content)
									});
							},
							A2($dillonkearns$elm_markdown$Markdown$Parser$renderStyled, renderer, content));
					case 1:
						var content = block.a;
						return A2(
							$elm$core$Result$map,
							renderer.hg,
							A2($dillonkearns$elm_markdown$Markdown$Parser$renderStyled, renderer, content));
					case 2:
						var tag = block.a;
						var attributes = block.b;
						var children = block.c;
						return A4($dillonkearns$elm_markdown$Markdown$Parser$renderHtmlNode, renderer, tag, attributes, children);
					case 3:
						var items = block.a;
						return A2(
							$elm$core$Result$map,
							renderer.gF,
							A2(
								$elm$core$Result$map,
								$elm$core$List$map(renderer.hg),
								$dillonkearns$elm_markdown$Markdown$Parser$combineResults(
									A2(
										$elm$core$List$map,
										$dillonkearns$elm_markdown$Markdown$Parser$renderStyled(renderer),
										items))));
					case 4:
						var codeBlock = block.a;
						return $elm$core$Result$Ok(
							renderer.fw(codeBlock));
					default:
						return $elm$core$Result$Ok(renderer.ic);
				}
			},
			blocks);
	});
var $dillonkearns$elm_markdown$Markdown$Parser$renderHtmlNode = F4(
	function (renderer, tag, attributes, children) {
		return A5(
			$dillonkearns$elm_markdown$Markdown$Parser$renderHtml,
			tag,
			attributes,
			children,
			renderer.f6,
			A2($dillonkearns$elm_markdown$Markdown$Parser$renderHelper, renderer, children));
	});
var $dillonkearns$elm_markdown$Markdown$Parser$render = F2(
	function (renderer, ast) {
		return $dillonkearns$elm_markdown$Markdown$Parser$combineResults(
			A2($dillonkearns$elm_markdown$Markdown$Parser$renderHelper, renderer, ast));
	});
var $mdgriffith$elm_ui$Internal$Model$ImportFont = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Font$external = function (_v0) {
	var url = _v0.cQ;
	var name = _v0.gU;
	return A2($mdgriffith$elm_ui$Internal$Model$ImportFont, name, url);
};
var $author$project$Md$code = function (snippet) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color(
				A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.04)),
				$mdgriffith$elm_ui$Element$Border$rounded(2),
				A2($mdgriffith$elm_ui$Element$paddingXY, 5, 3),
				$mdgriffith$elm_ui$Element$Font$family(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$external(
						{gU: 'Source Code Pro', cQ: 'https://fonts.googleapis.com/css?family=Source+Code+Pro'})
					]))
			]),
		$mdgriffith$elm_ui$Element$text(snippet));
};
var $author$project$Md$codeBlock = function (details) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color(
				A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.03)),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				A2($elm$html$Html$Attributes$style, 'white-space', 'pre')),
				$mdgriffith$elm_ui$Element$padding(20),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$family(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$external(
						{gU: 'Source Code Pro', cQ: 'https://fonts.googleapis.com/css?family=Source+Code+Pro'})
					]))
			]),
		$mdgriffith$elm_ui$Element$text(details.fc));
};
var $mdgriffith$elm_ui$Internal$Model$Heading = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_ui$Element$Region$heading = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Describe, $mdgriffith$elm_ui$Internal$Model$Heading);
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Md$rawTextToId = function (rawText) {
	return A3(
		$elm$core$String$replace,
		' ',
		'',
		$elm$core$String$toLower(rawText));
};
var $author$project$Md$heading = function (_v0) {
	var level = _v0.gA;
	var rawText = _v0.hh;
	var children = _v0.fr;
	return A2(
		$mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$size(
				function () {
					switch (level) {
						case 1:
							return 36;
						case 2:
							return 24;
						default:
							return 20;
					}
				}()),
				$author$project$Ui$Font$blissProBold,
				$mdgriffith$elm_ui$Element$Region$heading(level),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				A2(
					$elm$html$Html$Attributes$attribute,
					'name',
					$author$project$Md$rawTextToId(rawText))),
				$mdgriffith$elm_ui$Element$htmlAttribute(
				$elm$html$Html$Attributes$id(
					$author$project$Md$rawTextToId(rawText)))
			]),
		children);
};
var $dillonkearns$elm_markdown$Markdown$HtmlRenderer$HtmlRenderer = $elm$core$Basics$identity;
var $dillonkearns$elm_markdown$Markdown$Html$resultOr = F2(
	function (ra, rb) {
		if (ra.$ === 1) {
			var singleError = ra.a;
			if (!rb.$) {
				var okValue = rb.a;
				return $elm$core$Result$Ok(okValue);
			} else {
				var errorsSoFar = rb.a;
				return $elm$core$Result$Err(
					A2($elm$core$List$cons, singleError, errorsSoFar));
			}
		} else {
			var okValue = ra.a;
			return $elm$core$Result$Ok(okValue);
		}
	});
var $dillonkearns$elm_markdown$Markdown$Html$attributesToString = function (attributes) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$map,
			function (_v0) {
				var name = _v0.gU;
				var value = _v0.$9;
				return name + ('=\"' + (value + '\"'));
			},
			attributes));
};
var $dillonkearns$elm_markdown$Markdown$Html$tagToString = F2(
	function (tagName, attributes) {
		return $elm$core$List$isEmpty(attributes) ? ('<' + (tagName + '>')) : ('<' + (tagName + (' ' + ($dillonkearns$elm_markdown$Markdown$Html$attributesToString(attributes) + '>'))));
	});
var $dillonkearns$elm_markdown$Markdown$Html$oneOf = function (decoders) {
	var unwrappedDecoders = A2(
		$elm$core$List$map,
		function (_v1) {
			var rawDecoder = _v1;
			return rawDecoder;
		},
		decoders);
	return function (rawDecoder) {
		return F3(
			function (tagName, attributes, innerBlocks) {
				return A2(
					$elm$core$Result$mapError,
					function (errors) {
						if (!errors.b) {
							return 'Ran into a oneOf with no possibilities!';
						} else {
							if (!errors.b.b) {
								var singleError = errors.a;
								return 'Problem with the given value:\n\n' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\n' + (singleError + '\n')));
							} else {
								return 'oneOf failed parsing this value:\n    ' + (A2($dillonkearns$elm_markdown$Markdown$Html$tagToString, tagName, attributes) + ('\n\nParsing failed in the following 2 ways:\n\n\n' + (A2(
									$elm$core$String$join,
									'\n\n',
									A2(
										$elm$core$List$indexedMap,
										F2(
											function (index, error) {
												return '(' + ($elm$core$String$fromInt(index + 1) + (') ' + error));
											}),
										errors)) + '\n')));
							}
						}
					},
					A3(rawDecoder, tagName, attributes, innerBlocks));
			});
	}(
		A3(
			$elm$core$List$foldl,
			F2(
				function (decoder, soFar) {
					return F3(
						function (tagName, attributes, children) {
							return A2(
								$dillonkearns$elm_markdown$Markdown$Html$resultOr,
								A3(decoder, tagName, attributes, children),
								A3(soFar, tagName, attributes, children));
						});
				}),
			F3(
				function (tagName, attributes, children) {
					return $elm$core$Result$Err(_List_Nil);
				}),
			unwrappedDecoders));
};
var $author$project$Md$renderer = {
	fd: function (content) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[$author$project$Ui$Font$blissProBold]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text(content)
				]));
	},
	fv: $author$project$Md$code,
	fw: $author$project$Md$codeBlock,
	f0: $author$project$Md$heading,
	f6: $dillonkearns$elm_markdown$Markdown$Html$oneOf(_List_Nil),
	cc: F2(
		function (image, body) {
			return $elm$core$Result$Ok(
				A2(
					$mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					{fK: body, eq: image.eq}));
		}),
	gv: function (content) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$Font$italic]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text(content)
				]));
	},
	gB: F2(
		function (_v0, body) {
			var title = _v0.ie;
			var destination = _v0.fL;
			return $elm$core$Result$Ok(
				A2(
					$mdgriffith$elm_ui$Element$newTabLink,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$htmlAttribute(
							A2($elm$html$Html$Attributes$style, 'display', 'inline-flex'))
						]),
					{
						gw: A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
								]),
							body),
						cQ: destination
					}));
		}),
	gF: function (items) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(15)
				]),
			A2(
				$elm$core$List$map,
				function (itemBlocks) {
					return A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(5)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$alignTop]),
								$mdgriffith$elm_ui$Element$text('•')),
								itemBlocks
							]));
				},
				items));
	},
	hb: $mdgriffith$elm_ui$Element$text,
	hg: $mdgriffith$elm_ui$Element$paragraph(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(12)
			])),
	ic: $mdgriffith$elm_ui$Element$none
};
var $author$project$Md$markdown = F3(
	function (scale, attrList, md) {
		var _v0 = A2(
			$elm$core$Result$andThen,
			function (ast) {
				return A2($dillonkearns$elm_markdown$Markdown$Parser$render, $author$project$Md$renderer, ast);
			},
			A2(
				$elm$core$Result$mapError,
				$author$project$Md$deadEndsToString,
				$dillonkearns$elm_markdown$Markdown$Parser$parse(md)));
		if (!_v0.$) {
			var rendered = _v0.a;
			return A2($mdgriffith$elm_ui$Element$column, attrList, rendered);
		} else {
			var errors = _v0.a;
			return $mdgriffith$elm_ui$Element$text(errors);
		}
	});
var $author$project$FontAwesome$Solid$playCircle = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'play-circle',
	512,
	512,
	_List_fromArray(
		['M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z']));
var $author$project$FontAwesome$Solid$quoteRight = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'quote-right',
	512,
	512,
	_List_fromArray(
		['M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z']));
var $author$project$Page$Section$viewTestimonials = F3(
	function (scale, testimonialModel, featureSectionGroup) {
		var style1 = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$spacing(30),
				$author$project$Ui$Style$pageSectionPadding(scale)
			]);
		var renderButton = F3(
			function (selectedIndex, index, testimonial) {
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$padding(10),
								$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
								$mdgriffith$elm_ui$Element$Font$size(25)
							]),
						_Utils_eq(selectedIndex, index) ? _List_fromArray(
							[$author$project$Ui$Style$border, $author$project$Ui$Style$shadow]) : _List_Nil),
					{
						gw: $mdgriffith$elm_ui$Element$text(testimonial._),
						dK: $elm$core$Maybe$Just(
							$author$project$Types$SelectTestimonial(index))
					});
			});
		var _v0 = A2($elm$core$Array$get, testimonialModel.ei, testimonialModel.cY);
		if (_v0.$ === 1) {
			return A2($mdgriffith$elm_ui$Element$column, style1, _List_Nil);
		} else {
			var currentTestimonial = _v0.a;
			return (scale === 3) ? ((!featureSectionGroup.r) ? $mdgriffith$elm_ui$Element$none : A2(
				$mdgriffith$elm_ui$Element$column,
				style1,
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(50)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(360)),
										$mdgriffith$elm_ui$Element$Background$image(currentTestimonial.cc),
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
										A2($mdgriffith$elm_ui$Element$paddingXY, 35, 20),
										$author$project$Ui$Style$border,
										$author$project$Ui$Style$tableShadow,
										$mdgriffith$elm_ui$Element$Font$size(35)
									]),
								A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(5),
											$mdgriffith$elm_ui$Element$alignBottom
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Ui$Element$externalLink,
											currentTestimonial.ac,
											A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$size(70)
													]),
												$author$project$Ui$Element$faIcon($author$project$FontAwesome$Solid$playCircle))),
											$mdgriffith$elm_ui$Element$text(currentTestimonial._),
											$mdgriffith$elm_ui$Element$text(currentTestimonial.aa)
										]))),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1)),
										$mdgriffith$elm_ui$Element$spacing(30)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_Nil,
										A2($author$project$Ui$Element$introTitle, scale, testimonialModel.ie)),
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(240)),
												$mdgriffith$elm_ui$Element$spacing(20)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$alignTop,
														$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
														$mdgriffith$elm_ui$Element$Font$size(40)
													]),
												$author$project$Ui$Element$faIcon($author$project$FontAwesome$Solid$quoteRight)),
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
														$mdgriffith$elm_ui$Element$Font$size(20)
													]),
												_List_fromArray(
													[
														A3(
														$author$project$Md$markdown,
														scale,
														$author$project$Ui$Style$componentMdStyle(scale),
														currentTestimonial.Y)
													]))
											]))
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$scrollbarX,
									$mdgriffith$elm_ui$Element$padding(10),
									$author$project$Ui$Style$spacing(scale)
								]),
							$elm$core$Array$toList(
								A2(
									$elm$core$Array$indexedMap,
									renderButton(testimonialModel.ei),
									testimonialModel.cY))))
					]))) : A2($mdgriffith$elm_ui$Element$column, style1, _List_Nil);
		}
	});
var $author$project$Page$Landing$View$viewBody = F3(
	function (scale, testimonials, content) {
		switch (content.$) {
			case 0:
				var data = content.a;
				return A2($author$project$Page$Section$viewNewsCard, scale, data);
			case 1:
				var data = content.a;
				return A2($author$project$Page$Section$viewBanner, scale, data);
			case 2:
				var data = content.a;
				return A2($author$project$Page$Section$viewBanner2, scale, data);
			case 3:
				var data = content.a;
				return A2($author$project$Page$Section$viewBanner3, scale, data);
			case 4:
				var data = content.a;
				return A2($author$project$Page$Section$viewBanner4, scale, data);
			case 5:
				var data = content.a;
				return A2($author$project$Page$Section$viewFaq, scale, data);
			case 6:
				var data = content.a;
				return A2($author$project$Page$Section$viewHero, scale, data);
			case 7:
				var data = content.a;
				return A2($author$project$Page$Section$viewFeature, scale, data);
			case 8:
				var data = content.a;
				return A2($author$project$Page$Section$viewSocialMedia, scale, data);
			case 9:
				var data = content.a;
				return A3($author$project$Page$Section$viewTestimonials, scale, testimonials, data);
			case 10:
				var data = content.a;
				return A2($author$project$Page$Section$viewImageText, scale, data);
			default:
				var data = content.a;
				return A2($author$project$Page$Section$viewMembership, scale, data);
		}
	});
var $author$project$Page$Landing$View$viewContent = F3(
	function (scale, testimonials, content) {
		return A2(
			$author$project$Ui$Element$landingPageColumn,
			scale,
			A2(
				$elm$core$List$map,
				A2($author$project$Page$Landing$View$viewBody, scale, testimonials),
				content));
	});
var $author$project$Page$Landing$View$view = F3(
	function (scale, testimonials, content) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$Helper$viewLoading,
			A2(
				$elm$core$Maybe$map,
				A2($author$project$Page$Landing$View$viewContent, scale, testimonials),
				content));
	});
var $author$project$Page$Media$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introParagraph, scale, 'Verfolgen Sie die wichtigsten Meilensteine von miaEngiadina anhand der Medienmitteilungen! Beziehen Sie bei Bedarf Logo und Bildmaterial von miaEngiadina! Oder nehmen Sie mit dem Media Desk Kontakt auf!'),
			A2(
			$mdgriffith$elm_ui$Element$Input$button,
			$author$project$Ui$Style$introLinkStyle(scale),
			{
				gw: $mdgriffith$elm_ui$Element$text('Zu den Medienmitteilungen'),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$NavigateTo(
						$author$project$Types$AboutPage(
							$elm$core$Maybe$Just(2))))
			}),
			A2(
			$mdgriffith$elm_ui$Element$Input$button,
			$author$project$Ui$Style$introLinkStyle(scale),
			{
				gw: $mdgriffith$elm_ui$Element$text('Zum Media Desk'),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$NavigateTo($author$project$Types$ContactPage))
			})
		]);
};
var $author$project$Page$Media$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Medien')
		]);
};
var $author$project$Page$Media$mediaechIntroSection = function (scale) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 32)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(14),
						$mdgriffith$elm_ui$Element$alignTop
					]),
				_List_fromArray(
					[
						A2($author$project$Ui$Element$introTitle, scale, 'Medienecho')
					])),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2($author$project$Ui$Element$introParagraph, scale, 'Die Vision von miaEngiadina hat von Beginn an reges Medieninteresse hervorgerufen. Nachfolgend finden Sie eine Auswahl zur Berichterstattung.')
					]))
			]));
};
var $author$project$Ui$Style$highlightRed = $mdgriffith$elm_ui$Element$mouseOver(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
		]));
var $author$project$Ui$Element$mediaEchoCard = $mdgriffith$elm_ui$Element$column(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$padding(20),
			$author$project$Ui$Style$border,
			$author$project$Ui$Style$tableShadow
		]));
var $author$project$Page$Media$cardMedia = function (content) {
	var maybeWithLink = function () {
		var _v1 = content.hF;
		if ((!_v1.$) && (_v1.a.$ === 1)) {
			var _v2 = _v1.a;
			var target = _v2.a;
			var url = _v2.b;
			var linkFunction = function () {
				if (!target.$) {
					return $mdgriffith$elm_ui$Element$newTabLink;
				} else {
					return $mdgriffith$elm_ui$Element$link;
				}
			}();
			return A2(
				linkFunction,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Font$size(16),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
						$author$project$Ui$Font$blissProLight,
						$author$project$Ui$Style$highlightRed
					]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Link'),
					cQ: url
				});
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	}();
	var maybeWithDowload = function () {
		var _v0 = content.fN;
		if ((!_v0.$) && (_v0.a.$ === 2)) {
			var fileLink = _v0.a.a;
			return A2(
				$mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Font$size(16),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
						$author$project$Ui$Font$blissProLight,
						$author$project$Ui$Style$highlightRed
					]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Download'),
					cQ: fileLink.cQ
				});
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	}();
	return $author$project$Ui$Element$mediaEchoCard(
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$alignTop,
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(5)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$Font$size(20),
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
										$author$project$Ui$Font$blissProLight
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(content.gi)
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$author$project$Ui$Font$blissProBold,
										$mdgriffith$elm_ui$Element$Font$size(24),
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(content.ie)
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[maybeWithDowload, maybeWithLink]))
					]))
			]));
};
var $author$project$Page$Media$mediaecho = F2(
	function (cmsMedia, scale) {
		return A2(
			$author$project$Ui$Component$Matrix$view,
			{
				fY: false,
				gK: function () {
					switch (scale) {
						case 0:
							return 1;
						case 1:
							return 2;
						case 2:
							return 2;
						default:
							return 3;
					}
				}(),
				hH: $author$project$Ui$Style$spacing(scale)
			},
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$pair(1),
				A2(
					$elm$core$List$map,
					$author$project$Page$Media$cardMedia,
					A2(
						$elm$core$List$sortBy,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.fG;
							},
							A2($elm$core$Basics$composeR, $elm$time$Time$posixToMillis, $elm$core$Basics$negate)),
						A2($elm$core$Maybe$withDefault, _List_Nil, cmsMedia)))));
	});
var $author$project$Page$Media$view = F2(
	function (cmsMedia, scale) {
		return A2(
			$author$project$Ui$Element$pageColumn,
			scale,
			_List_fromArray(
				[
					A3(
					$author$project$Ui$Element$introSection,
					scale,
					$author$project$Page$Media$introSectionTitle(scale),
					$author$project$Page$Media$introSectionText(scale)),
					$author$project$Page$Media$mediaechIntroSection(scale),
					A2($author$project$Page$Media$mediaecho, cmsMedia, scale)
				]));
	});
var $author$project$Page$MediaReleases$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Medienmitteilungen')
		]);
};
var $author$project$Ui$Element$cardWrap = $mdgriffith$elm_ui$Element$el(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$author$project$Ui$Style$tableShadow,
			$mdgriffith$elm_ui$Element$padding(20),
			$author$project$Ui$Style$border,
			$mdgriffith$elm_ui$Element$scrollbarX
		]));
var $author$project$Ui$Font$typefaceForBody = $author$project$Ui$Font$blissProRegular;
var $author$project$Page$MediaReleases$cardMediaRelease = function (mr) {
	var linkStyle = _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$author$project$Ui$Font$blissProLight,
			$mdgriffith$elm_ui$Element$Font$size(16),
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
			$author$project$Ui$Style$highlightRed
		]);
	return $author$project$Ui$Element$cardWrap(
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(20)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$author$project$Ui$Font$blissProBold,
									$mdgriffith$elm_ui$Element$Font$size(24),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(mr.ie)
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$author$project$Ui$Font$typefaceForBody,
									$mdgriffith$elm_ui$Element$Font$size(20),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(mr.fK)
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(6)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$link,
							linkStyle,
							{
								gw: $mdgriffith$elm_ui$Element$text(mr.an),
								cQ: mr.I
							}),
							A2(
							$mdgriffith$elm_ui$Element$link,
							linkStyle,
							{
								gw: $mdgriffith$elm_ui$Element$text(mr.ao),
								cQ: mr.I
							}),
							A2(
							$mdgriffith$elm_ui$Element$link,
							linkStyle,
							{
								gw: $mdgriffith$elm_ui$Element$text(mr.ap),
								cQ: mr.I
							}),
							A2(
							$mdgriffith$elm_ui$Element$link,
							linkStyle,
							{
								gw: $mdgriffith$elm_ui$Element$text(mr.aq),
								cQ: mr.I
							})
						]))
				])));
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $author$project$Support$contentCdnUrl = 'https://content.miaEngiadina.ch/';
var $author$project$Page$MediaReleases$mr150129 = {fK: 'Ftan, 29. Januar 2015 – Im Rahmen eines feierlichen Anlasses wurde heute gemeinsam mit Vertretern aus Wirtschaft, Wissenschaft und Politik die «miaEngiadina Charta» zur Förderung der UNO Sustainable Development Goals (SDG) und zur nachhaltigen Stärkung der Region unterzeichnet.', I: $author$project$Support$contentCdnUrl + 'press/mia_Engiadina_Medienmitteilung_Unterzeichnung_miaEngiadina_Charta.pdf', aD: $author$project$Support$contentCdnUrl + 'docs/mia_Engiadina_Konzept_Your-first-Third-Place.pdf', aE: $author$project$Support$contentCdnUrl + 'docs/mia_Engiadina_Charta.pdf', aF: '', an: 'Download Medienmitteilung (.pdf)', ao: 'Download miaEngiadina Konzept (.pdf)', ap: 'Download miaEngiadina Charta (.pdf)', aq: '', ie: 'Unterzeichnung der «miaEngiadina Charta»'};
var $author$project$Page$MediaReleases$mr160818 = {fK: 'Am 18. August 2016 findet in Scuol die Openaxs FTTH-Conference statt. Ausrichter sind Openaxs, miaEngiadina und der Handels- und Gewerbeverein des Unterengadins (HGV). Ziel ist es, insbesondere den lokalen KMUs und Politikern, Vorteile und Nutzen eines flächendeckenden Glasfasernetzes, wie es miaEngiadina errichten will, näher zu bringen.', I: 'https://myengadinasssets.blob.core.windows.net/docs/Medienmitteilung_Openaxs_FTTH_Conference_18082016.pdf', aD: 'https://www.youtube.com/watch?v=fYW28cpcfe4&feature=youtu.be', aE: 'http://www.openaxs.ch/de/ftth-conference/referate/', aF: '', an: 'Download Medienmitteilung (.pdf)', ao: 'Kurzfilm des Anlasses', ap: 'Referate der Konferenz', aq: '', ie: 'Openaxs-FTTH-Conference in Scuol'};
var $author$project$Page$MediaReleases$mr190125de = {fK: 'La Punt Chamues-ch, 25.01.2019. miaEngiadina\'s initiative, the «InnHub La Punt», is beginning to take shape. The privately-funded new centre for innovation that will comprise work and seminar spaces, sports facilities, retail shops, a restaurant as well as an underground car park enjoys the support of the canton, the district as well as the town. The world-famous architect Lord Norman Foster has been commissioned for the design.', I: $author$project$Support$contentCdnUrl + 'press/mia_Engiadina_Press_Release_InnHub_La_Punt_EN.pdf', aD: $author$project$Support$contentCdnUrl + 'press-media/mia_Engiadina_InnHub_LaPunt_2834_FP680417.jpg', aE: $author$project$Support$contentCdnUrl + 'press-media/mia_Engiadina_InnHub_LaPunt_2834_FP680418.jpg', aF: $author$project$Support$contentCdnUrl + 'press-media/mia_Engiadina_InnHub_LaPunt_2834_FP680419.jpg', an: 'Download Media Release (.pdf)', ao: 'Picture 1: Exterior view from main street, © Foster + Partners', ap: 'Picture 2: Interior view of the indoor auditorium, © Foster + Partners', aq: 'Picture 3: South courtyard and outdoor cafeteria, © Foster + Partners', ie: 'A new centre for La Punt Chamues-ch'};
var $author$project$Page$MediaReleases$mr190125en = {fK: 'La Punt Chamues-ch, 25.1.2019. Der von miaEngiadina initiierte «InnHub La Punt» nimmt konkrete Formen an. Das neue Zentrum mit Arbeits-, Seminar- und Sportmöglichkeiten, Detailhandel, einem Restaurant und einer Tiefgarage wird privatwirtschaftlich finanziert und von Kanton, Region und Gemeinde unterstützt. Als Architekt zeichnet der weltbekannte Lord Norman Foster.', I: $author$project$Support$contentCdnUrl + 'press/mia_Engiadina_Press_Release_InnHub_La_Punt_DE.pdf', aD: $author$project$Support$contentCdnUrl + 'press-media/mia_Engiadina_InnHub_LaPunt_2834_FP680417.jpg', aE: $author$project$Support$contentCdnUrl + 'press-media/mia_Engiadina_InnHub_LaPunt_2834_FP680418.jpg', aF: $author$project$Support$contentCdnUrl + 'press-media/mia_Engiadina_InnHub_LaPunt_2834_FP680419.jpg', an: 'Download Medienmitteilung (.pdf)', ao: 'Bild 1: Exterior view from main street, © Foster + Partners', ap: 'Bild 2: Interior view of the indoor auditorium, © Foster + Partners', aq: 'Bild 3: South courtyard and outdoor cafeteria, © Foster + Partners', ie: 'Ein neues Zentrum für La Punt Chamues-ch'};
var $author$project$Page$MediaReleases$mr190710 = {fK: 'Scuol, 10.7.2019. An der Festa sül Stradun vom 20. Juli 2019 öffnet der neue Mountain Hub von miaEngiadina in Scuol erstmals seine Türe. Die Bevölkerung ist zur Besichtigung und zum gemütlichen Beisammensein eingeladen.', I: $author$project$Support$contentCdnUrl + 'media-releases/190710-Medienmitteilung-miaEngiadina.pdf', aD: $author$project$Support$contentCdnUrl + 'media-resources/1906-miaEngiadina-Stradun-400A-Scuol.jpeg', aE: '', aF: '', an: 'Download Medienmitteilung (.pdf)', ao: 'Bild 1: Der neue Mountain Hub befindet sich im 1. Stock am Stradun in Scuol ©miaEngiadina/Mayk Wendt', ap: '', aq: '', ie: 'miaEngiadina eröffnet den neuen Mountain Hub Scuol'};
var $author$project$Page$MediaReleases$mr190722 = {fK: 'Scuol, 22. Juli 2019. Am Nationalfeiertag führt miaEngiadina wiederum den beliebten Orientierungslauf durch, dieses Jahr mit einer speziellen Bahn zum 650-Jahre-Jubiläum der Scuoler Mineralquellen.', I: $author$project$Support$contentCdnUrl + 'media-resources/1907-miaEngiadina-Medienmitteilung-1August-OL.pdf', aD: $author$project$Support$contentCdnUrl + 'media-resources/1907-Bild1.jpeg', aE: $author$project$Support$contentCdnUrl + 'media-resources/1907-Bild2.jpeg', aF: $author$project$Support$contentCdnUrl + 'media-resources/1907-Bild3.jpeg', an: 'Download Medienmitteilung (.pdf)', ao: 'Bild 1: Kinder und Erwachsene können mitmachen. ©miaEngiadina', ap: 'Bild 2: Nach Ardez 2018 führt der OL 2019 durch die Gassen von Scuol. ©miaEngiadina', aq: 'Bild 3: OL-Weltmeisterin Julia Jakob hat fünf Bahnen vorbereitet. ©miaEngiadina', ie: '1. August-OL - Die Gassen und Quellen von Scuol entdecken'};
var $author$project$Page$MediaReleases$mediaReleaseList = _List_fromArray(
	[$author$project$Page$MediaReleases$mr190722, $author$project$Page$MediaReleases$mr190710, $author$project$Page$MediaReleases$mr150129, $author$project$Page$MediaReleases$mr190125de, $author$project$Page$MediaReleases$mr190125en, $author$project$Page$MediaReleases$mr160818]);
var $author$project$Page$MediaReleases$mediaReleases = A2(
	$mdgriffith$elm_ui$Element$column,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$spacing(20),
			$mdgriffith$elm_ui$Element$centerX
		]),
	A2(
		$elm$core$List$map,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($author$project$Page$MediaReleases$cardMediaRelease),
			$mdgriffith$elm_ui$Element$row(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(20)
					]))),
		A2($elm_community$list_extra$List$Extra$greedyGroupsOf, 1, $author$project$Page$MediaReleases$mediaReleaseList)));
var $author$project$Page$MediaReleases$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$MediaReleases$introSectionTitle(scale),
				_List_Nil),
				$author$project$Page$MediaReleases$mediaReleases
			]));
};
var $author$project$Page$Section$viewImgText = F2(
	function (scale, data) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPadding(scale)
				]),
			A3(
				$author$project$Ui$Element$textImgRight,
				scale,
				A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$author$project$Support$structuredTextAsHtml,
							scale,
							$elm$core$Maybe$Just(
								_Utils_update(
									$author$project$Support$default,
									{
										aV: $author$project$Ui$Font$h1(scale),
										b5: $author$project$Ui$Font$h2(scale),
										bu: $author$project$Ui$Font$h3(scale),
										cw: $author$project$Ui$Font$body1(scale)
									}))),
						data.h7)),
				A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.dC;
							},
							$author$project$Support$imageAsHtml(scale)),
						data.gb))));
	});
var $author$project$Page$Section$viewIntro = F2(
	function (scale, data) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPadding(scale)
				]),
			A3(
				$author$project$Ui$Element$introSection2,
				scale,
				A3(
					$author$project$Support$structuredTextAsHtml,
					scale,
					$elm$core$Maybe$Just(
						_Utils_update(
							$author$project$Support$default,
							{
								aV: $author$project$Ui$Style$introTitle(scale)
							})),
					data.$8),
				A3(
					$author$project$Support$structuredTextAsHtml,
					scale,
					$elm$core$Maybe$Just(
						_Utils_update(
							$author$project$Support$default,
							{
								cw: _Utils_ap(
									$author$project$Support$default.cw,
									$author$project$Ui$Style$introParagraph(scale))
							})),
					data.x)));
	});
var $author$project$Page$Section$viewTextField = F2(
	function (scale, data) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPadding(scale)
				]),
			A3(
				$author$project$Support$structuredTextAsHtml,
				scale,
				$elm$core$Maybe$Just(
					_Utils_update(
						$author$project$Support$default,
						{
							aV: $author$project$Ui$Font$h1(scale),
							b5: $author$project$Ui$Font$h2(scale),
							bu: $author$project$Ui$Font$h3(scale),
							cw: $author$project$Ui$Font$body1(scale)
						})),
				data.x));
	});
var $author$project$Page$Section$viewTextImg = F2(
	function (scale, data) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$author$project$Ui$Style$pageSectionPadding(scale)
				]),
			A3(
				$author$project$Ui$Element$textImgLeft,
				scale,
				A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$author$project$Support$structuredTextAsHtml,
							scale,
							$elm$core$Maybe$Just(
								_Utils_update(
									$author$project$Support$default,
									{
										aV: $author$project$Ui$Font$h1(scale),
										b5: $author$project$Ui$Font$h2(scale),
										bu: $author$project$Ui$Font$h3(scale),
										cw: $author$project$Ui$Font$body1(scale)
									}))),
						data.h3)),
				A2(
					$elm$core$Maybe$withDefault,
					$mdgriffith$elm_ui$Element$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.dC;
							},
							$author$project$Support$imageAsHtml(scale)),
						data.gc))));
	});
var $author$project$Page$MintEngiadina$viewBody = F2(
	function (scale, content) {
		switch (content.$) {
			case 0:
				var data = content.a;
				return A2($author$project$Page$Section$viewBanner2, scale, data);
			case 1:
				var data = content.a;
				return A2($author$project$Page$Section$viewBanner3, scale, data);
			case 2:
				var data = content.a;
				return A2($author$project$Page$Section$viewBanner4, scale, data);
			case 3:
				var data = content.a;
				return A2($author$project$Page$Section$viewIntro, scale, data);
			case 4:
				var data = content.a;
				return A2($author$project$Page$Section$viewTextField, scale, data);
			case 5:
				var data = content.a;
				return A2($author$project$Page$Section$viewImgText, scale, data);
			default:
				var data = content.a;
				return A2($author$project$Page$Section$viewTextImg, scale, data);
		}
	});
var $author$project$Page$MintEngiadina$viewContent = F2(
	function (scale, content) {
		return A2(
			$author$project$Ui$Element$landingPageColumn,
			scale,
			A2(
				$elm$core$List$map,
				$author$project$Page$MintEngiadina$viewBody(scale),
				content));
	});
var $author$project$Page$MintEngiadina$view = F2(
	function (scale, content) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$Helper$viewLoading,
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$MintEngiadina$viewContent(scale),
				content));
	});
var $author$project$Ui$Element$list = function (listText) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(5)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignTop]),
				$mdgriffith$elm_ui$Element$text('•')),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(listText)
					]))
			]));
};
var $author$project$Page$MountainHubPricing$cellCoworkingOffer = F3(
	function (scale, cow, imageLeft) {
		var style3 = _Utils_ap(
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$clip, $author$project$Ui$Style$border]),
			function () {
				switch (scale) {
					case 0:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					case 1:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					case 2:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
					default:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$px(500)),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]);
				}
			}());
		var style2c = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
					$author$project$Ui$Font$blissProRegular
				]),
			function () {
				switch (scale) {
					case 0:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(14)
							]);
					case 1:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16)
							]);
					case 2:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16)
							]);
					default:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(18)
							]);
				}
			}());
		var style2b = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					$author$project$Ui$Font$blissProLight
				]),
			function () {
				switch (scale) {
					case 0:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(14)
							]);
					case 1:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16)
							]);
					case 2:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16)
							]);
					default:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(18)
							]);
				}
			}());
		var style2a = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
				$mdgriffith$elm_ui$Element$Font$size(24),
				$author$project$Ui$Font$blissProBold
			]);
		var style2 = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$centerY,
					$mdgriffith$elm_ui$Element$spacing(15)
				]),
			function () {
				switch (scale) {
					case 0:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]);
					case 1:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]);
					case 2:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill))
							]);
					default:
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								A2(
									$mdgriffith$elm_ui$Element$maximum,
									400,
									A2($mdgriffith$elm_ui$Element$minimum, 400, $mdgriffith$elm_ui$Element$fill)))
							]);
				}
			}());
		var style1 = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$spacing(30)
			]);
		var paragraphElement = A2(
			$mdgriffith$elm_ui$Element$column,
			style2,
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					style2a,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(cow.ie)
						])),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							style2b,
							A2($author$project$Helpers$whenJust, $author$project$Ui$Element$list, cow.ai)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							style2b,
							A2($author$project$Helpers$whenJust, $author$project$Ui$Element$list, cow.aj)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							style2b,
							A2($author$project$Helpers$whenJust, $author$project$Ui$Element$list, cow.ak)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							style2b,
							A2($author$project$Helpers$whenJust, $author$project$Ui$Element$list, cow.al)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							style2b,
							A2($author$project$Helpers$whenJust, $author$project$Ui$Element$list, cow.am))
						])),
					A3(
					$elm$core$Basics$composeR,
					$author$project$Helpers$whenJust($mdgriffith$elm_ui$Element$text),
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$mdgriffith$elm_ui$Element$paragraph(style2c)),
					cow.au)
				]));
		var imageElement = A2(
			$mdgriffith$elm_ui$Element$el,
			style3,
			A2(
				$mdgriffith$elm_ui$Element$image,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$centerX
					]),
				{fK: '', eq: cow.ah}));
		return $author$project$Ui$Scale$isXsSm(scale) ? A2(
			$mdgriffith$elm_ui$Element$column,
			style1,
			_List_fromArray(
				[imageElement, paragraphElement])) : (imageLeft ? A2(
			$mdgriffith$elm_ui$Element$row,
			style1,
			_List_fromArray(
				[imageElement, paragraphElement])) : A2(
			$mdgriffith$elm_ui$Element$row,
			style1,
			_List_fromArray(
				[paragraphElement, imageElement])));
	});
var $author$project$Page$MountainHubPricing$coworkingFlexikarte = {
	ah: $author$project$Support$imagesCdnUrl + 'mountainhubs/offer7.jpg',
	ai: $elm$core$Maybe$Just('10 Tage / 20 Halbtage, unbefristet gültig'),
	aj: $elm$core$Maybe$Just('Mo-Fr: 08.00 Uhr bis 17.00 Uhr (12.00 Uhr - 13.00 Uhr unbetreut)'),
	ak: $elm$core$Maybe$Just('WLAN, Drucker, Lounge und Relaxzone'),
	al: $elm$core$Maybe$Just('Kaffee und Tee gegen Bezahlung'),
	am: $elm$core$Maybe$Just('Nur mit Mitgliedschaft'),
	au: $elm$core$Maybe$Just('CHF 270'),
	ie: '10er Karte'
};
var $author$project$Page$MountainHubPricing$coworkingMitgliedschaft = {
	ah: $author$project$Support$imagesCdnUrl + 'mountainhubs/offer1.jpg',
	ai: $elm$core$Maybe$Just('4x halbe Tage im Monat kostenlos'),
	aj: $elm$core$Maybe$Just('Mo-Fr: 08.00 Uhr bis 17.00 Uhr (12.00 Uhr - 13.00 Uhr unbetreut)'),
	ak: $elm$core$Maybe$Just('WLAN, Drucker, Lounge und Relaxzone'),
	al: $elm$core$Maybe$Just('Kaffee und Tee gegen Bezahlung'),
	am: $elm$core$Maybe$Nothing,
	au: $elm$core$Maybe$Just('CHF 80.00 / Jahr'),
	ie: 'Mitgliedschaft'
};
var $author$project$Page$MountainHubPricing$coworkingMonatskarte = {
	ah: $author$project$Support$imagesCdnUrl + 'mountainhubs/offer6.jpg',
	ai: $elm$core$Maybe$Just('WLAN, Drucker, Lounge und Relaxzone'),
	aj: $elm$core$Maybe$Just('Kaffee und Tee gegen Bezahlung'),
	ak: $elm$core$Maybe$Just('7/24h Zugang und Schliessfach inklusive'),
	al: $elm$core$Maybe$Just('Nur mit Mitgliedschaft'),
	am: $elm$core$Maybe$Just('CHF 430 / Monat'),
	au: $elm$core$Maybe$Nothing,
	ie: 'Monatskarte'
};
var $author$project$Page$MountainHubPricing$coworkingSitzungszimmer = {
	ah: $author$project$Support$imagesCdnUrl + 'mountainhubs/offer4.jpg',
	ai: $elm$core$Maybe$Just('Bis 8 Personen'),
	aj: $elm$core$Maybe$Just('Drucker (Fair Use) '),
	ak: $elm$core$Maybe$Just('Kaffee & Tee (Fair Use)'),
	al: $elm$core$Maybe$Just('Ausstattung: Surface Hub, Flipchart'),
	am: $elm$core$Maybe$Just('ohne Mitgliedschaft CHF 60.- / Std + CHF 420.- / Tag'),
	au: $elm$core$Maybe$Just('CHF 40.- / Std + CHF 280.- / Tag'),
	ie: 'Sitzungszimmer'
};
var $author$project$Page$MountainHubPricing$coworkingTageskarte = {
	ah: $author$project$Support$imagesCdnUrl + 'mountainhubs/offer2.jpg',
	ai: $elm$core$Maybe$Just('Ein Wochentag zwischen: 08.00 Uhr bis 17.00 Uhr (12.00 Uhr - 13.00 Uhr unbetreut)'),
	aj: $elm$core$Maybe$Just('WLAN, Drucker, Lounge und Relaxzone'),
	ak: $elm$core$Maybe$Just('Kaffee und Tee gegen Bezahlung'),
	al: $elm$core$Maybe$Nothing,
	am: $elm$core$Maybe$Nothing,
	au: $elm$core$Maybe$Just('Mitglied CHF 30 / nicht Mitglied CHF 43'),
	ie: 'Tageskarte'
};
var $author$project$Page$MountainHubPricing$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introParagraph, scale, 'Wir bieten Ihnen Raum für einen Arbeitsplatz, für Ihre Sitzungen, Workshops, Kurse sowie auch für ein Apéro. Gerne stellen wir Ihnen ein individuelles Angebot zusammen.')
		]);
};
var $author$project$Page$MountainHubPricing$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Angebote'),
			A2($author$project$Ui$Element$introTitle, scale, 'Coworking')
		]);
};
var $author$project$Page$MountainHubPricing$view = function (model) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		model.hq,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				model.hq,
				$author$project$Page$MountainHubPricing$introSectionTitle(model.hq),
				$author$project$Page$MountainHubPricing$introSectionText(model.hq)),
				A3($author$project$Page$MountainHubPricing$cellCoworkingOffer, model.hq, $author$project$Page$MountainHubPricing$coworkingMitgliedschaft, false),
				A3($author$project$Page$MountainHubPricing$cellCoworkingOffer, model.hq, $author$project$Page$MountainHubPricing$coworkingTageskarte, true),
				A3($author$project$Page$MountainHubPricing$cellCoworkingOffer, model.hq, $author$project$Page$MountainHubPricing$coworkingFlexikarte, false),
				A3($author$project$Page$MountainHubPricing$cellCoworkingOffer, model.hq, $author$project$Page$MountainHubPricing$coworkingMonatskarte, true),
				A3($author$project$Page$MountainHubPricing$cellCoworkingOffer, model.hq, $author$project$Page$MountainHubPricing$coworkingSitzungszimmer, false),
				$author$project$Typeform$view('kzwum3')
			]));
};
var $author$project$Page$MyEngadinPartner$grundungsPartnerList = _List_fromArray(
	['Bezzola Denoth AG, Scuol', 'Caprez Ingenieure AG, Scuol', 'Center da sandà Engiadina Bassa, Scuol', 'EE-Energia Engiadina, Scuol', 'Energie 360° AG, Zürich', 'Engadin Booking, Scuol', 'Gemeinden Zernez, Scuol, Valsot und Samnaun', 'Graubündner Kantonalbank, Chur', 'Hotel Paradies, Ftan', 'Handels- und Gewerbeverein Unterengadin', 'I-Community, St. Moritz', 'Informatica AG, Ilanz', 'Microsoft Schweiz GmbH, Wallisellen', 'Monday Coffee AG, Urdorf', 'Net-Design AG, Luzern', 'Rhätische Bahn, Chur', 'Sunrise Communication AG, Zürich', '5to9, Brütten', 'Academia Engiadina, Samedan', 'Alpine Co-Working, Zürich', 'Belvédère Hotels, Scuol', 'Bergbahnen Scuol AG', 'Cadonau büro d’architectura, Ramosch', 'Central-Garage Denoth, Scuol', 'Cla Mosca Flowmanagement, Scuol', 'Curdin Müller, Strada', 'Electra Buin, Scuol', 'Gammeter Media, St. Moritz', 'Go2nova AG, Altstätten SG', 'Hatecke, Scuol', 'Hochalpines Institut Ftan', 'Hotel Central, Valchava', 'Hotel Helvetia AG, Müstair', 'Innoveritas, Wettswil a. A.', 'Laurent AG, Ramosch', 'Lyceum Alpinum Zuoz', 'Pädkon, Ramosch', 'Piguetweb, Sent', 'Priska Cavelti Marketing Kommunikationsberatung, Küssnacht', 'Producta Marketing GmbH, Müstair', 'Quo AG, Zürich', 'Radiotelevisiun Rumantscha, Chur', 'Reitstall und Saloon San Jon, Scuol', 'Resgia Koch, Ramosch', 'Rubcom GmbH, Sent', 'Schweizerischer Nationalpark, Zernez', 'Simply Science, Zürich', 'Strimer architects AG, Ardez', 'Tschenett AG, Müstair', 'Urban Hive, Zürich', 'Witzig AG, Frauenfeld', 'Kanton Graubünden, Amt für Wirtschaft und Tourismus', 'Schweizerische Eidgenossenschaft, SECO', 'Universität St. Gallen']);
var $author$project$Page$MyEngadinPartner$introSectionTitle1 = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'miaEngiadina'),
			A2($author$project$Ui$Element$introTitle, scale, 'Partner')
		]);
};
var $author$project$Page$MyEngadinPartner$introSectionTitle2 = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'miaEngiadina'),
			A2($author$project$Ui$Element$introTitle, scale, 'Gründungspartner')
		]);
};
var $author$project$Page$MyEngadinPartner$partnerCard = F2(
	function (scale, partner) {
		var partnerCardContent = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					A2($mdgriffith$elm_ui$Element$minimum, 200, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$padding(20),
					$author$project$Ui$Style$tableShadow,
					$author$project$Ui$Style$border
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							partner.f8 ? $mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$maximum, 100, $mdgriffith$elm_ui$Element$fill)) : $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
						]),
					A2($author$project$Support$imageAsHtml, scale, partner.gG.dC))));
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			function () {
				var _v0 = partner.ir;
				if ((!_v0.$) && (_v0.a.$ === 1)) {
					var _v1 = _v0.a;
					var target = _v1.a;
					var url = _v1.b;
					var linkFunction = function () {
						if (!target.$) {
							return $mdgriffith$elm_ui$Element$newTabLink;
						} else {
							return $mdgriffith$elm_ui$Element$link;
						}
					}();
					return A2(
						linkFunction,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
							]),
						{gw: partnerCardContent, cQ: url});
				} else {
					return partnerCardContent;
				}
			}());
	});
var $author$project$Page$MyEngadinPartner$partnerList = F2(
	function (scale, partners) {
		return A2(
			$author$project$Ui$Component$Matrix$view,
			{
				fY: false,
				gK: function () {
					switch (scale) {
						case 0:
							return 1;
						case 1:
							return 2;
						case 2:
							return 2;
						default:
							return 3;
					}
				}(),
				hH: $author$project$Ui$Style$spacing(scale)
			},
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$pair(1),
				A2(
					$elm$core$List$map,
					$author$project$Page$MyEngadinPartner$partnerCard(scale),
					A2($elm$core$Maybe$withDefault, _List_Nil, partners))));
	});
var $author$project$Page$MyEngadinPartner$view = F2(
	function (scale, partners) {
		return A2(
			$author$project$Ui$Element$pageColumn,
			scale,
			_List_fromArray(
				[
					A3(
					$author$project$Ui$Element$introSection,
					scale,
					$author$project$Page$MyEngadinPartner$introSectionTitle1(scale),
					_List_Nil),
					A2($author$project$Page$MyEngadinPartner$partnerList, scale, partners),
					A3(
					$author$project$Ui$Element$introSection,
					scale,
					$author$project$Page$MyEngadinPartner$introSectionTitle2(scale),
					_List_Nil),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeR,
							$mdgriffith$elm_ui$Element$text,
							$mdgriffith$elm_ui$Element$el(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
										$author$project$Ui$Font$blissProLight,
										$mdgriffith$elm_ui$Element$Font$size(32)
									]))),
						$author$project$Page$MyEngadinPartner$grundungsPartnerList))
				]));
	});
var $author$project$Page$News$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'miaEngiadina'),
			A2($author$project$Ui$Element$introTitle, scale, 'News')
		]);
};
var $author$project$Page$News$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$News$introSectionTitle(scale),
				_List_Nil)
			]));
};
var $author$project$Page$Newsletter$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introParagraph, scale, 'Erhalten Sie aktuelle Informationen zu Veranstaltungen und Neuigkeiten rund ums Thema miaEngiadina.')
		]);
};
var $author$project$Page$Newsletter$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'miaEngiadina'),
			A2($author$project$Ui$Element$introTitle, scale, 'Newsletter')
		]);
};
var $author$project$Page$Newsletter$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$Newsletter$introSectionTitle(scale),
				$author$project$Page$Newsletter$introSectionText(scale)),
				$author$project$Typeform$view('tU7IMn')
			]));
};
var $author$project$Page$PrivateCustomerSubscription$internetPrivate = {dH: 'L', dI: 'M', dJ: 'S', dS: '155.00¹', dT: '145.00²', dU: '140.00³', dV: '130.00⁴', dW: '85.00¹', dX: '75.00²', dY: '75.00³', dZ: '65.00⁴', d_: '55.00¹', d$: '43.00²', d0: '50.00³', d1: '40.00⁴', en: '600/600 Mbit/s*', eo: '250/250 Mbit/s*', ep: '60/60 Mbit/s*'};
var $author$project$Ui$Style$cell = $elm$core$Basics$append(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$padding(5),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
		]));
var $author$project$Ui$Style$cellLightGray = $elm$core$Basics$append(
	$author$project$Ui$Style$cell(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$lightGray),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
			])));
var $author$project$Ui$Style$cellRed = $elm$core$Basics$append(
	$author$project$Ui$Style$cell(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white)
			])));
var $author$project$Ui$Element$internetTableColumn = $mdgriffith$elm_ui$Element$column(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$spacing(10)
		]));
var $author$project$Ui$Element$internetTableTitle = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$text,
	$mdgriffith$elm_ui$Element$el(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
				$mdgriffith$elm_ui$Element$Font$size(24),
				$author$project$Ui$Font$typefaceForBody
			])));
var $author$project$Ui$Element$textForUnderTable = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$text,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$singleton,
		$mdgriffith$elm_ui$Element$paragraph(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					$author$project$Ui$Font$blissProLight,
					$mdgriffith$elm_ui$Element$Font$size(16)
				]))));
var $author$project$Page$PrivateCustomerSubscription$internetTable = function (i) {
	return $author$project$Ui$Element$internetTableColumn(
		_List_fromArray(
			[
				$author$project$Ui$Element$internetTableTitle('Internet'),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$author$project$Ui$Style$cellSpacing,
							$author$project$Ui$Style$tableShadow,
							$mdgriffith$elm_ui$Element$scrollbarX
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									A2(
										$mdgriffith$elm_ui$Element$minimum,
										30,
										A2($mdgriffith$elm_ui$Element$maximum, 30, $mdgriffith$elm_ui$Element$fill))),
									$author$project$Ui$Style$cellSpacing
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									$author$project$Ui$Style$cellRed(_List_Nil),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
										$mdgriffith$elm_ui$Element$text(i.dH))),
									A2(
									$mdgriffith$elm_ui$Element$el,
									$author$project$Ui$Style$cellRed(_List_Nil),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
										$mdgriffith$elm_ui$Element$text(i.dI))),
									A2(
									$mdgriffith$elm_ui$Element$el,
									$author$project$Ui$Style$cellRed(_List_Nil),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
										$mdgriffith$elm_ui$Element$text(i.dJ)))
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$author$project$Ui$Style$cellSpacing
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									$author$project$Ui$Style$cellLightGray(_List_Nil),
									$mdgriffith$elm_ui$Element$text(i.en)),
									A2(
									$mdgriffith$elm_ui$Element$el,
									$author$project$Ui$Style$cellLightGray(_List_Nil),
									$mdgriffith$elm_ui$Element$text(i.eo)),
									A2(
									$mdgriffith$elm_ui$Element$el,
									$author$project$Ui$Style$cellLightGray(_List_Nil),
									$mdgriffith$elm_ui$Element$text(i.ep))
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$author$project$Ui$Style$cellSpacing
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$row,
									$author$project$Ui$Style$cellLightGray(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(20)
											])),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('CHF'),
											$mdgriffith$elm_ui$Element$text(i.dV),
											$mdgriffith$elm_ui$Element$text(i.dU),
											$mdgriffith$elm_ui$Element$text(i.dT),
											$mdgriffith$elm_ui$Element$text(i.dS)
										])),
									A2(
									$mdgriffith$elm_ui$Element$row,
									$author$project$Ui$Style$cellLightGray(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(20)
											])),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('CHF'),
											$mdgriffith$elm_ui$Element$text(i.dZ),
											$mdgriffith$elm_ui$Element$text(i.dY),
											$mdgriffith$elm_ui$Element$text(i.dX),
											$mdgriffith$elm_ui$Element$text(i.dW)
										])),
									A2(
									$mdgriffith$elm_ui$Element$row,
									$author$project$Ui$Style$cellLightGray(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(20)
											])),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('CHF'),
											$mdgriffith$elm_ui$Element$text(i.d1),
											$mdgriffith$elm_ui$Element$text(i.d0),
											$mdgriffith$elm_ui$Element$text(i.d$),
											$mdgriffith$elm_ui$Element$text(i.d_)
										]))
								]))
						]))),
				$author$project$Ui$Element$textForUnderTable('*Ftan: M = 250/25 Mbit/s und S = 60/20 Mbit/s, kein L'),
				$author$project$Ui$Element$textForUnderTable('¹Basisangebot | ²Zweitwohnungsbesitzer/innen | ³miaEngiadina Mitglieder | ⁴Zweitwohnungsbesitzer und Mitglieder')
			]));
};
var $author$project$Page$PrivateCustomerSubscription$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(25)
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Md$markdown,
					scale,
					$author$project$Ui$Style$componentMdStyle(scale),
					'Stellen Sie Ihr eigenes Paket zusammen! Wählen Sie Ihr bevorzugtes Internet-Abo und fügen Sie anschliessend TV und Telefon nach Bedarf hinzu! Die nachfolgenden Preise gelten für einen Glasfaseranschluss pro Monat inkl. MWST.'),
					A3(
					$author$project$Md$markdown,
					scale,
					$author$project$Ui$Style$componentMdStyle(scale),
					'Ist ihr Quartier bereits durch Glasfasern erschlossen? Senden Sie uns ein [Mail](mailto:info@miaEngiadina.ch).'),
					A3(
					$author$project$Md$markdown,
					scale,
					$author$project$Ui$Style$componentMdStyle(scale),
					'Haben Sie weitere Fragen oder Probleme im Digitalisierungsbereich? Wir unterstützen Sie gerne. miaEngiadina Kundinnen und Kunden erhalten einige Beratungsstunden kostenlos.'),
					A2(
					$mdgriffith$elm_ui$Element$link,
					$author$project$Ui$Style$introLinkStyle(scale),
					{
						gw: $mdgriffith$elm_ui$Element$text('Flyer - Privatkunden'),
						cQ: $author$project$Support$contentCdnUrl + 'docs/miaEngiadina_Flyer_PK.pdf'
					})
				]))
		]);
};
var $author$project$Ui$Element$introTitle2 = function (scale) {
	return A2(
		$elm$core$Basics$composeR,
		$mdgriffith$elm_ui$Element$text,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$mdgriffith$elm_ui$Element$paragraph(
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$Font$size(34),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Font$blissProLight
						]),
					function () {
						switch (scale) {
							case 0:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(22)
									]);
							case 1:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(26)
									]);
							case 2:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(30)
									]);
							default:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(34)
									]);
						}
					}()))));
};
var $author$project$Page$PrivateCustomerSubscription$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Angebote'),
			A2($author$project$Ui$Element$introTitle, scale, 'für Privatkunden'),
			A2($author$project$Ui$Element$introTitle2, scale, 'schnell – günstig – regional')
		]);
};
var $author$project$Page$PrivateCustomerSubscription$PhoneTableData = F3(
	function (a, b, c) {
		return {cU: a, c_: b, c1: c};
	});
var $author$project$Page$PrivateCustomerSubscription$phoneTable = $author$project$Ui$Element$internetTableColumn(
	_List_fromArray(
		[
			$author$project$Ui$Element$internetTableTitle('Telefon'),
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$table,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$scrollbarX,
						$author$project$Ui$Style$cellSpacing,
						$author$project$Ui$Style$tableShadow
					]),
				{
					fx: _List_fromArray(
						[
							{
							bt: $mdgriffith$elm_ui$Element$none,
							iq: A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.cU;
								},
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$mdgriffith$elm_ui$Element$el(
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY])),
										$mdgriffith$elm_ui$Element$el(
											$author$project$Ui$Style$cellRed(
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width(
														A2(
															$mdgriffith$elm_ui$Element$minimum,
															30,
															A2($mdgriffith$elm_ui$Element$maximum, 30, $mdgriffith$elm_ui$Element$fill)))
													])))))),
							a7: $mdgriffith$elm_ui$Element$shrink
						},
							{
							bt: $mdgriffith$elm_ui$Element$none,
							iq: A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.c_;
								},
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										A2(
											$elm$core$Basics$composeR,
											$mdgriffith$elm_ui$Element$paragraph(
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$centerY
													])),
											$mdgriffith$elm_ui$Element$el(
												$author$project$Ui$Style$cellLightGray(
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
														]))))))),
							a7: $mdgriffith$elm_ui$Element$fill
						},
							{
							bt: $mdgriffith$elm_ui$Element$none,
							iq: A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.c1;
								},
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$List$singleton,
										A2(
											$elm$core$Basics$composeR,
											$mdgriffith$elm_ui$Element$paragraph(
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$centerY
													])),
											$mdgriffith$elm_ui$Element$el(
												$author$project$Ui$Style$cellLightGray(
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
														]))))))),
							a7: A2(
								$mdgriffith$elm_ui$Element$minimum,
								120,
								A2($mdgriffith$elm_ui$Element$maximum, 120, $mdgriffith$elm_ui$Element$fill))
						}
						]),
					fE: _List_fromArray(
						[
							A3($author$project$Page$PrivateCustomerSubscription$PhoneTableData, 'L', 'inklusive 1000 Minuten auf Schweizer Festnetze | inklusive 250 Minuten auf Schweizer Mobilnetze | inklusive 500 Minuten auf internationale Festnetze', 'CHF 45.00'),
							A3($author$project$Page$PrivateCustomerSubscription$PhoneTableData, 'M', 'inklusive 1000 Minuten auf Schweizer Festnetze | inklusive 250 Minuten auf Schweizer Mobilnetze', 'CHF 22.00'),
							A3($author$project$Page$PrivateCustomerSubscription$PhoneTableData, 'S', 'ab CHF 0.08/Min auf Festnetz und ab CHF 0.40/Min auf Mobile', 'CHF 2.00')
						])
				})),
			$author$project$Ui$Element$textForUnderTable('*Telefon M'),
			$author$project$Ui$Element$textForUnderTable('inklusive 1000 Minuten auf Schweizer Festnetze'),
			$author$project$Ui$Element$textForUnderTable('inklusive 250 Minuten auf Schweizer Mobilnetze'),
			$author$project$Ui$Element$textForUnderTable('*Telefon L'),
			$author$project$Ui$Element$textForUnderTable('inklusive 1000 Minuten auf Schweizer Festnetze'),
			$author$project$Ui$Element$textForUnderTable('inklusive 250 Minuten auf Schweizer Mobilnetze'),
			$author$project$Ui$Element$textForUnderTable('inklusive 500 Minuten auf internationale Festnetze')
		]));
var $author$project$Page$PrivateCustomerSubscription$tvTable = $author$project$Ui$Element$internetTableColumn(
	_List_fromArray(
		[
			$author$project$Ui$Element$internetTableTitle('TV'),
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$scrollbarX,
						$author$project$Ui$Style$cellSpacing,
						$author$project$Ui$Style$tableShadow
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$author$project$Ui$Style$cellSpacing,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								$author$project$Ui$Style$cellRed(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											A2(
												$mdgriffith$elm_ui$Element$minimum,
												30,
												A2($mdgriffith$elm_ui$Element$maximum, 30, $mdgriffith$elm_ui$Element$fill)))
										])),
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text('L'))),
								A2(
								$mdgriffith$elm_ui$Element$column,
								$author$project$Ui$Style$cellLightGray(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(4),
											$mdgriffith$elm_ui$Element$height(
											A2($mdgriffith$elm_ui$Element$minimum, 50, $mdgriffith$elm_ui$Element$fill))
										])),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('250+ TV Sender | 80+ davon in HD')
											])),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$author$project$Ui$Font$blissProLight,
												$mdgriffith$elm_ui$Element$Font$size(14)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('7 Tage Replay | 100 Std. Aufnahmen')
											])),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$author$project$Ui$Font$blissProLight,
												$mdgriffith$elm_ui$Element$Font$size(14)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('Mobile-streaming bis 50 Sender')
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$author$project$Ui$Style$cellSpacing,
										$mdgriffith$elm_ui$Element$width(
										A2(
											$mdgriffith$elm_ui$Element$minimum,
											120,
											A2($mdgriffith$elm_ui$Element$maximum, 120, $mdgriffith$elm_ui$Element$fill))),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
									]),
								A2(
									$mdgriffith$elm_ui$Element$row,
									$author$project$Ui$Style$cellLightGray(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(20)
											])),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('CHF'),
											$mdgriffith$elm_ui$Element$text('30.00')
										])))
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$author$project$Ui$Style$cellSpacing,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								$author$project$Ui$Style$cellRed(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											A2(
												$mdgriffith$elm_ui$Element$minimum,
												30,
												A2($mdgriffith$elm_ui$Element$maximum, 30, $mdgriffith$elm_ui$Element$fill)))
										])),
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text('M'))),
								A2(
								$mdgriffith$elm_ui$Element$column,
								$author$project$Ui$Style$cellLightGray(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(4),
											$mdgriffith$elm_ui$Element$height(
											A2($mdgriffith$elm_ui$Element$minimum, 50, $mdgriffith$elm_ui$Element$fill))
										])),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('250+ TV Sender | 80+ davon in HD')
											])),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$author$project$Ui$Font$blissProLight,
												$mdgriffith$elm_ui$Element$Font$size(14)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('30 Std. Replay | 10 Std. Aufnahmen')
											])),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$author$project$Ui$Font$blissProLight,
												$mdgriffith$elm_ui$Element$Font$size(14)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('Mobile-streaming bis 50 Sender')
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$author$project$Ui$Style$cellSpacing,
										$mdgriffith$elm_ui$Element$width(
										A2(
											$mdgriffith$elm_ui$Element$minimum,
											120,
											A2($mdgriffith$elm_ui$Element$maximum, 120, $mdgriffith$elm_ui$Element$fill))),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
									]),
								A2(
									$mdgriffith$elm_ui$Element$row,
									$author$project$Ui$Style$cellLightGray(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(20)
											])),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('CHF'),
											$mdgriffith$elm_ui$Element$text('28.00')
										])))
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$author$project$Ui$Style$cellSpacing,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								$author$project$Ui$Style$cellRed(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											A2(
												$mdgriffith$elm_ui$Element$minimum,
												30,
												A2($mdgriffith$elm_ui$Element$maximum, 30, $mdgriffith$elm_ui$Element$fill)))
										])),
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text('S*'))),
								A2(
								$mdgriffith$elm_ui$Element$column,
								$author$project$Ui$Style$cellLightGray(
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(4),
											$mdgriffith$elm_ui$Element$height(
											A2($mdgriffith$elm_ui$Element$minimum, 50, $mdgriffith$elm_ui$Element$fill))
										])),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('125+ TV Sender | 60+ davon in HD')
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$author$project$Ui$Style$cellSpacing,
										$mdgriffith$elm_ui$Element$width(
										A2(
											$mdgriffith$elm_ui$Element$minimum,
											120,
											A2($mdgriffith$elm_ui$Element$maximum, 120, $mdgriffith$elm_ui$Element$fill))),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
									]),
								A2(
									$mdgriffith$elm_ui$Element$row,
									$author$project$Ui$Style$cellLightGray(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(20)
											])),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('CHF'),
											$mdgriffith$elm_ui$Element$text('8.00')
										])))
							]))
					]))),
			A2(
			$author$project$Ui$Element$externalLink,
			$author$project$Support$contentCdnUrl + 'docs/miaEngiadina_Programmliste_TV_S-M-L.pdf',
			$author$project$Ui$Element$textForUnderTable('Link zur Programmliste für das TV-Angebot')),
			$author$project$Ui$Element$textForUnderTable('*nicht verfügbar über Kupferkabel - Technologie')
		]));
var $author$project$Page$PrivateCustomerSubscription$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$PrivateCustomerSubscription$introSectionTitle(scale),
				$author$project$Page$PrivateCustomerSubscription$introSectionText(scale)),
				$author$project$Page$PrivateCustomerSubscription$internetTable($author$project$Page$PrivateCustomerSubscription$internetPrivate),
				$author$project$Page$PrivateCustomerSubscription$tvTable,
				$author$project$Page$PrivateCustomerSubscription$phoneTable,
				$author$project$Typeform$view('fhAQMa')
			]));
};
var $author$project$Ui$Element$textParagraph = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$text,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$singleton,
		$mdgriffith$elm_ui$Element$paragraph(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$size(20),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
					$mdgriffith$elm_ui$Element$spacing(10),
					$author$project$Ui$Font$blissProRegular
				]))));
var $author$project$Page$Statuten$infoCard = A2(
	$author$project$Ui$Element$externalLink,
	$author$project$Support$contentCdnUrl + 'docs/mia_Engiadina_Verein_Statuten.pdf',
	$author$project$Ui$Element$cardWrap(
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20)
				]),
			_List_fromArray(
				[
					$author$project$Ui$Element$textParagraph('miaEngiadina Community Vereinsstatuten - PDF'),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
							$mdgriffith$elm_ui$Element$Font$underline
						]),
					$mdgriffith$elm_ui$Element$text('Hier geht es zu den Vereinstatuten'))
				]))));
var $author$project$Page$Statuten$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'miaEngiadina'),
			A2($author$project$Ui$Element$introTitle, scale, 'Vereinsstatuten')
		]);
};
var $author$project$Page$Statuten$view = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$Statuten$introSectionTitle(scale),
				_List_Nil),
				$author$project$Page$Statuten$infoCard
			]));
};
var $author$project$Types$Team_ShowInfo = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$Border$roundEach = function (_v0) {
	var topLeft = _v0.ih;
	var topRight = _v0.ii;
	var bottomLeft = _v0.fi;
	var bottomRight = _v0.fj;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + ($elm$core$String$fromInt(topLeft) + ('-' + ($elm$core$String$fromInt(topRight) + ($elm$core$String$fromInt(bottomLeft) + ('-' + $elm$core$String$fromInt(bottomRight)))))),
			'border-radius',
			$elm$core$String$fromInt(topLeft) + ('px ' + ($elm$core$String$fromInt(topRight) + ('px ' + ($elm$core$String$fromInt(bottomRight) + ('px ' + ($elm$core$String$fromInt(bottomLeft) + 'px'))))))));
};
var $author$project$Ui$Style$borderLeft = $mdgriffith$elm_ui$Element$Border$roundEach(
	{fi: 6, fj: 0, ih: 6, ii: 0});
var $author$project$Ui$Element$teamMemberText = A2(
	$elm$core$Basics$composeR,
	$mdgriffith$elm_ui$Element$text,
	$mdgriffith$elm_ui$Element$el(
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$size(20),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
				$author$project$Ui$Font$blissProRegular
			])));
var $author$project$Page$Team$crd = F4(
	function (scale, selected, id, member) {
		return _Utils_Tuple2(
			_Utils_eq(selected, id) ? 3 : 1,
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$author$project$Ui$Style$tableShadow,
						$author$project$Ui$Style$border,
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(20)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(180)),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(180)),
										$mdgriffith$elm_ui$Element$clip,
										_Utils_eq(id, selected) ? $mdgriffith$elm_ui$Element$Border$roundEach(
										{fi: 0, fj: 0, ih: 6, ii: 0}) : $author$project$Ui$Style$borderLeft
									]),
								A2($author$project$Support$imageAsHtml, scale, member.cc.dC)),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$centerY,
										$mdgriffith$elm_ui$Element$spacing(15)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												$author$project$Ui$Element$teamMemberText(member.gU)
											])),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$spacing(8)
											]),
										_List_fromArray(
											[
												function () {
												var _v0 = member.fQ;
												if (!_v0.$) {
													var email = _v0.a;
													return A2(
														$mdgriffith$elm_ui$Element$link,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
																$author$project$Ui$Style$highlightRed,
																$author$project$Ui$Font$blissProLight,
																$mdgriffith$elm_ui$Element$Font$size(16),
																$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
															]),
														{
															gw: $mdgriffith$elm_ui$Element$text('Email'),
															cQ: 'mailto:' + email
														});
												} else {
													return $mdgriffith$elm_ui$Element$none;
												}
											}(),
												function () {
												var _v1 = member.fa;
												if (_v1.$ === 1) {
													return $mdgriffith$elm_ui$Element$none;
												} else {
													var bio = _v1.a;
													return _Utils_eq(id, selected) ? A2(
														$mdgriffith$elm_ui$Element$Input$button,
														_List_Nil,
														{
															gw: A2(
																$mdgriffith$elm_ui$Element$el,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
																		$author$project$Ui$Font$blissProLight,
																		$mdgriffith$elm_ui$Element$Font$size(16),
																		$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
																		$author$project$Ui$Style$highlightRed
																	]),
																$mdgriffith$elm_ui$Element$text('Verkleinern')),
															dK: $elm$core$Maybe$Just(
																$author$project$Types$Team_ShowInfo(-1))
														}) : A2(
														$mdgriffith$elm_ui$Element$Input$button,
														_List_Nil,
														{
															gw: A2(
																$mdgriffith$elm_ui$Element$el,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
																		$author$project$Ui$Font$blissProLight,
																		$mdgriffith$elm_ui$Element$Font$size(16),
																		$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
																		$author$project$Ui$Style$highlightRed
																	]),
																$mdgriffith$elm_ui$Element$text('Beschreibung')),
															dK: $elm$core$Maybe$Just(
																$author$project$Types$Team_ShowInfo(id))
														});
												}
											}()
											]))
									]))
							])),
						function () {
						var _v2 = member.fa;
						if (!_v2.$) {
							var bio = _v2.a;
							return _Utils_eq(selected, id) ? A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$padding(20),
										$mdgriffith$elm_ui$Element$spacing(10),
										$author$project$Ui$Font$blissProRegular,
										$mdgriffith$elm_ui$Element$Font$size(18),
										$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
									]),
								A3($author$project$Support$structuredTextAsHtml, scale, $elm$core$Maybe$Nothing, bio)) : $mdgriffith$elm_ui$Element$none;
						} else {
							return $mdgriffith$elm_ui$Element$none;
						}
					}()
					])));
	});
var $author$project$Page$Team$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'miaEngiadina'),
			A2($author$project$Ui$Element$introTitle, scale, 'Team')
		]);
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Page$Team$view = F3(
	function (scale, memberID, teamMembers) {
		var lastName = function (member) {
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$elm_community$list_extra$List$Extra$last(
					$elm$core$String$words(member.gU)));
		};
		return A2(
			$author$project$Ui$Element$pageColumn,
			scale,
			_List_fromArray(
				[
					A3(
					$author$project$Ui$Element$introSection,
					scale,
					$author$project$Page$Team$introSectionTitle(scale),
					_List_Nil),
					A2(
					$author$project$Ui$Component$Matrix$view,
					{
						fY: false,
						gK: function () {
							switch (scale) {
								case 0:
									return 1;
								case 1:
									return 2;
								case 2:
									return 2;
								default:
									return 3;
							}
						}(),
						hH: $author$project$Ui$Style$spacing(scale)
					},
					A2(
						$elm$core$List$indexedMap,
						A2($author$project$Page$Team$crd, scale, memberID),
						A2(
							$elm$core$List$sortBy,
							lastName,
							A2($elm$core$Maybe$withDefault, _List_Nil, teamMembers))))
				]));
	});
var $author$project$Page$Hidden$introSectionText = function (scale) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$alignTop,
						$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
						$author$project$Ui$Font$typefaceForBody,
						$mdgriffith$elm_ui$Element$spacing(12)
					]),
				function () {
					switch (scale) {
						case 0:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(16)
								]);
						case 1:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(18)
								]);
						case 2:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(20)
								]);
						default:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(22)
								]);
					}
				}()),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Mittwoch, 14. April 2021, 17:00-18:00')
				])),
			A2($author$project$Ui$Element$introParagraph, scale, 'Arbeiten in den Bergen bringt Gedanken zum Fliegen. Weshalb ist das so? Welche Infrastruktur braucht es dazu? Wird das Bergbüro auch in Zukunft gefragt sein? Verfolgen Sie die Diskussion dazu online und lernen Sie dabei auch das neue InnHub PopUp kennen!'),
			A2(
			$author$project$Ui$Element$externalLink,
			'https://vimeo.com/524211808',
			A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$red),
							$author$project$Ui$Font$blissProLight,
							$mdgriffith$elm_ui$Element$spacing(12)
						]),
					function () {
						switch (scale) {
							case 0:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(16)
									]);
							case 1:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(18)
									]);
							case 2:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(20)
									]);
							default:
								return _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(22)
									]);
						}
					}()),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Link zur Vimeo Seite')
					])))
		]);
};
var $author$project$Page$Hidden$introSectionTitle = function (scale) {
	return _List_fromArray(
		[
			A2($author$project$Ui$Element$introTitle, scale, 'Arbeiten in den Bergen - am Beispiel des InnHub PopUp')
		]);
};
var $author$project$Page$Hidden$viewVimeo = function (scale) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 1200, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$centerX
			]),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'padding', '56.25% 0 0 0'),
						A2($elm$html$Html$Attributes$style, 'position', 'relative')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$iframe,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src('https://player.vimeo.com/video/524211808'),
								A2($elm$html$Html$Attributes$attribute, 'frameborder', '0'),
								A2($elm$html$Html$Attributes$attribute, 'allow', 'autoplay; fullscreen; picture-in-picture'),
								A2($elm$html$Html$Attributes$attribute, 'allowfullscreen', ''),
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'top', '0'),
								A2($elm$html$Html$Attributes$style, 'left', '0'),
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'height', '100%')
							]),
						_List_Nil)
					]))));
};
var $author$project$Page$Hidden$viewVimeoChat = function (scale) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$minimum, 400, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$height(
				A2($mdgriffith$elm_ui$Element$minimum, 600, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$alignTop
			]),
		$mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$iframe,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src('https://vimeo.com/live-chat/524211808/'),
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', '100%'),
						A2($elm$html$Html$Attributes$style, 'top', '0'),
						A2($elm$html$Html$Attributes$style, 'left', '0'),
						A2($elm$html$Html$Attributes$attribute, 'frameborder', '0')
					]),
				_List_Nil)));
};
var $author$project$Page$Hidden$viewArbeitenInDenBergenPage = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				A3(
				$author$project$Ui$Element$introSection,
				scale,
				$author$project$Page$Hidden$introSectionTitle(scale),
				$author$project$Page$Hidden$introSectionText(scale)),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$author$project$Page$Hidden$viewVimeo(scale),
						$author$project$Page$Hidden$viewVimeoChat(scale)
					]))
			]));
};
var $author$project$Page$Hidden$viewBewertungCoworking = function (scale) {
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				$author$project$Typeform$view('PWMTslVe')
			]));
};
var $author$project$Page$Hidden$viewWimmelbild = function (scale) {
	var textBlock = A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				$author$project$Ui$Text$view(
				$author$project$Ui$Text$headlineSmall('Und, gefunden? E, chatto?')),
				$author$project$Ui$Text$view(
				$author$project$Ui$Text$bodyLarge('Konntest du alle Produkte auf unserem Wimmelbild finden? Herzliche Gratulation! Cordielas gratulaziuns! Hier findest du die Auflösung und mit einem Klick alle weiterführenden Informationen zum Angebot.'))
			]));
	var imageSource = function (imageName) {
		return 'https://miaengiadina.blob.core.windows.net/website/' + imageName;
	};
	var iconText = F3(
		function (icon, text, url) {
			return A3(
				$author$project$Ui$Element$link,
				url,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$image,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									A2($mdgriffith$elm_ui$Element$maximum, 120, $mdgriffith$elm_ui$Element$fill))
								]),
							{
								fK: 'Wimmelbild',
								eq: imageSource(icon)
							}),
							$author$project$Ui$Text$view(
							$author$project$Ui$Text$bodyLarge(text))
						])));
		});
	var iconText1 = A3(iconText, 'wimmelbild1.png', 'Coworking', 'https://www.miaengiadina.ch/mountain-coworking');
	var iconText2 = A3(iconText, 'wimmelbild2.png', 'Community', 'https://www.miaengiadina.ch/community');
	var iconText3 = A3(iconText, 'wimmelbild3.png', 'Internet', 'https://www.miaengiadina.ch/internet');
	var iconText4 = A3(iconText, 'wimmelbild4.png', 'Digitalisierung', 'https://engadin.app/');
	var iconText5 = A3(iconText, 'wimmelbild5.png', 'Bildung', 'https://www.miaengiadina.ch/community/education');
	var iconTextListBlock = A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 300, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		_List_fromArray(
			[iconText1, iconText2, iconText3, iconText4, iconText5]));
	return A2(
		$author$project$Ui$Element$pageColumn,
		scale,
		_List_fromArray(
			[
				$author$project$Ui$Scale$isXsSm(scale) ? A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(40)
					]),
				_List_fromArray(
					[iconTextListBlock, textBlock])) : A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(80)
					]),
				_List_fromArray(
					[textBlock, iconTextListBlock]))
			]));
};
var $author$project$Page$view = function (model) {
	var _v0 = model.ee;
	switch (_v0.$) {
		case 0:
			return A3($author$project$Page$Landing$View$view, model.hq, model.hZ, model.a.bo.cj);
		case 1:
			return $author$project$Page$News$view(model.hq);
		case 2:
			return A2($author$project$Page$Digital$view, model.hq, model.a.bo.bZ);
		case 4:
			var maybePage = _v0.a;
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				A2($author$project$Page$Internet$Private$view, model, model.a.bo.ch),
				function (p) {
					switch (p) {
						case 0:
							return $author$project$Page$PrivateCustomerSubscription$view(model.hq);
						case 1:
							return A2($author$project$Page$Internet$Company$view, model, model.a.bo.cd);
						case 2:
							return A2($author$project$Page$View$view, model.hq, model.a.bo.cB);
						case 3:
							return A2($author$project$Page$Internet$Information$view, model.hq, model.a.bo.cf);
						default:
							return A2($author$project$Page$View$view, model.hq, model.a.bo.ce);
					}
				},
				maybePage);
		case 5:
			var maybePage = _v0.a;
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				A2($author$project$Page$View$view, model.hq, model.a.bo.bY),
				function (p) {
					switch (p) {
						case 0:
							return A2($author$project$Page$View$view, model.hq, model.a.bo.bX);
						case 1:
							return $author$project$Page$MountainHubPricing$view(model);
						default:
							return $author$project$Page$Accommodations$view(model.hq);
					}
				},
				maybePage);
		case 6:
			var maybePage = _v0.a;
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				A2($author$project$Page$Community$view, model.hq, model.a.bo),
				function (p) {
					switch (p.$) {
						case 0:
							return $author$project$Page$Community$Membership$view(model.hq);
						case 1:
							return A2($author$project$Page$View$view, model.hq, model.a.bo.bV);
						case 2:
							return A2($author$project$Page$View$view, model.hq, model.a.bo.b0);
						default:
							var maybeSlug = p.a;
							return A3($author$project$Page$Community$Events$view, model.a.bo.b1, model.hq, maybeSlug);
					}
				},
				maybePage);
		case 7:
			var maybePage = _v0.a;
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				A2($author$project$Page$View$view, model.hq, model.a.bo.bN),
				function (p) {
					switch (p) {
						case 0:
							return A3($author$project$Page$Team$view, model.hq, model.hY, model.a.bo.cN);
						case 1:
							return A2($author$project$Page$Media$view, model.a.bo.cl, model.hq);
						case 2:
							return $author$project$Page$MediaReleases$view(model.hq);
						case 3:
							return A2($author$project$Page$MyEngadinPartner$view, model.hq, model.a.bo.co);
						default:
							return A2($author$project$Page$View$view, model.hq, model.a.bo.bM);
					}
				},
				maybePage);
		case 8:
			return $author$project$Page$Contact$view(model.hq);
		case 3:
			return A2($author$project$Page$MintEngiadina$view, model.hq, model.a.bo.cq);
		case 9:
			return $author$project$Page$Statuten$view(model.hq);
		case 10:
			return $author$project$Page$Newsletter$view(model.hq);
		case 11:
			return $author$project$Page$Gv$view(model.hq);
		case 12:
			return $author$project$Page$BewertungMountainHubScuol$view(model.hq);
		case 13:
			return $author$project$Page$AnmeldungMountainHubScuol$view(model.hq);
		case 14:
			return $author$project$Page$AnmeldungInnHub$view(model.hq);
		case 15:
			return $author$project$Page$GutscheinCoworking$view(model.hq);
		case 16:
			return $author$project$Page$bewertungInternetPage(model.hq);
		case 17:
			return $author$project$Page$Hidden$viewArbeitenInDenBergenPage(model.hq);
		case 18:
			return $author$project$Page$Hidden$viewBewertungCoworking(model.hq);
		default:
			return $author$project$Page$Hidden$viewWimmelbild(model.hq);
	}
};
var $author$project$Ui$Element$arrange = function (scale) {
	return $author$project$Ui$Scale$isXsSm(scale) ? $mdgriffith$elm_ui$Element$column : $mdgriffith$elm_ui$Element$row;
};
var $author$project$Ui$Element$button = F2(
	function (msg, elem) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			{
				gw: elem,
				dK: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Types$Link = F2(
	function (link, title) {
		return {gB: link, ie: title};
	});
var $author$project$View$footerAbout = {
	bt: A2(
		$author$project$Types$Link,
		$author$project$Types$AboutPage($elm$core$Maybe$Nothing),
		'About'),
	gE: _List_fromArray(
		[
			A2(
			$author$project$Types$Link,
			$author$project$Types$AboutPage(
				$elm$core$Maybe$Just(0)),
			'Team'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$AboutPage(
				$elm$core$Maybe$Just(4)),
			'Organisation'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$AboutPage(
				$elm$core$Maybe$Just(3)),
			'Partner'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$AboutPage(
				$elm$core$Maybe$Just(1)),
			'Medien'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$AboutPage(
				$elm$core$Maybe$Just(2)),
			'Medienmitteilungen')
		])
};
var $author$project$View$footerCommunity = {
	bt: A2(
		$author$project$Types$Link,
		$author$project$Types$CommunityPage($elm$core$Maybe$Nothing),
		'Community'),
	gE: _List_fromArray(
		[
			A2(
			$author$project$Types$Link,
			$author$project$Types$CommunityPage(
				$elm$core$Maybe$Just($author$project$Types$MembershipPage)),
			'Mitgliedschaft'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$CommunityPage(
				$elm$core$Maybe$Just($author$project$Types$PartnershipPage)),
			'Partnerschaft'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$CommunityPage(
				$elm$core$Maybe$Just($author$project$Types$EducationPage)),
			'Bildung'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$CommunityPage(
				$elm$core$Maybe$Just(
					$author$project$Types$EventsPage($elm$core$Maybe$Nothing))),
			'Events')
		])
};
var $author$project$View$footerCoworking = {
	bt: A2(
		$author$project$Types$Link,
		$author$project$Types$CoworkingPage($elm$core$Maybe$Nothing),
		'Coworking'),
	gE: _List_fromArray(
		[
			A2(
			$author$project$Types$Link,
			$author$project$Types$CoworkingPage(
				$elm$core$Maybe$Just(0)),
			'Mountain Hubs')
		])
};
var $author$project$View$footerInternet = {
	bt: A2(
		$author$project$Types$Link,
		$author$project$Types$InternetPage($elm$core$Maybe$Nothing),
		'Internet'),
	gE: _List_fromArray(
		[
			A2(
			$author$project$Types$Link,
			$author$project$Types$InternetPage(
				$elm$core$Maybe$Just(1)),
			'Angebote für Firmen'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$InternetPage(
				$elm$core$Maybe$Just(3)),
			'Information'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$InternetPage(
				$elm$core$Maybe$Just(2)),
			'Referenzen'),
			A2(
			$author$project$Types$Link,
			$author$project$Types$InternetPage(
				$elm$core$Maybe$Just(4)),
			'Hilfe Internet & TV')
		])
};
var $author$project$View$viewFooter = function (scale) {
	var direction = $author$project$Ui$Element$arrange(scale);
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 32, 48),
				$mdgriffith$elm_ui$Element$alignBottom,
				$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$gray),
				$author$project$Ui$Style$pageMaximumWidth(scale),
				$mdgriffith$elm_ui$Element$centerX
			]),
		_List_fromArray(
			[
				A2(
				direction,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spaceEvenly
					]),
				function (xs) {
					return _Utils_ap(
						xs,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$link,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$alignTop,
										$author$project$Ui$Font$blissProBold,
										$author$project$Ui$Style$highlightRed
									]),
								{
									gw: $mdgriffith$elm_ui$Element$text('News'),
									cQ: 'https://news.miaengiadina.ch/'
								}),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$spacing(10),
										$mdgriffith$elm_ui$Element$Font$size(16)
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Ui$Element$button,
										$author$project$Types$NavigateTo($author$project$Types$ContactPage),
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
													$author$project$Ui$Font$blissProBold,
													$mdgriffith$elm_ui$Element$Font$size(24),
													$author$project$Ui$Style$highlightRed
												]),
											$mdgriffith$elm_ui$Element$text('Kontakt'))),
										A2(
										$mdgriffith$elm_ui$Element$link,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$author$project$Ui$Style$highlightRed
											]),
										{
											gw: $mdgriffith$elm_ui$Element$text('+41 (0)81 508 05 05'),
											cQ: 'tel:+41815080505'
										}),
										A2(
										$mdgriffith$elm_ui$Element$link,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$author$project$Ui$Style$highlightRed
											]),
										{
											gw: $mdgriffith$elm_ui$Element$text('info@miaEngiadina.ch'),
											cQ: 'mailto:info@miaEngiadina.ch'
										}),
										$mdgriffith$elm_ui$Element$text('Stradun 400A'),
										$mdgriffith$elm_ui$Element$text('7550 Scuol'),
										A2(
										$mdgriffith$elm_ui$Element$link,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$author$project$Ui$Style$highlightRed
											]),
										{
											gw: $mdgriffith$elm_ui$Element$text('Datenschutz'),
											cQ: '/contact'
										})
									]))
							]));
				}(
					A2(
						$elm$core$List$map,
						function (_v0) {
							var header = _v0.bt;
							var links = _v0.gE;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$spacing(10)
									]),
								A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Element$Input$button,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$author$project$Ui$Font$blissProBold,
												$author$project$Ui$Style$highlightRed
											]),
										{
											gw: $mdgriffith$elm_ui$Element$text(header.ie),
											dK: $elm$core$Maybe$Just(
												$author$project$Types$NavigateTo(header.gB))
										}),
									A2(
										$elm$core$List$map,
										function (l) {
											return A2(
												$mdgriffith$elm_ui$Element$Input$button,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$Font$size(16),
														$author$project$Ui$Style$highlightRed
													]),
												{
													gw: $mdgriffith$elm_ui$Element$text(l.ie),
													dK: $elm$core$Maybe$Just(
														$author$project$Types$NavigateTo(l.gB))
												});
										},
										links)));
						},
						_List_fromArray(
							[$author$project$View$footerInternet, $author$project$View$footerCoworking, $author$project$View$footerCommunity, $author$project$View$footerAbout])))),
				A2(
				direction,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						A2($mdgriffith$elm_ui$Element$maximum, 650, $mdgriffith$elm_ui$Element$fill)),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spaceEvenly
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(40),
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 30)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(20)
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Ui$Element$button,
										$author$project$Types$NavigateTo($author$project$Types$LandingPage),
										A2(
											$mdgriffith$elm_ui$Element$image,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(200)),
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(40))
												]),
											{fK: 'miaEngiadina', eq: '/design/mE_Logo_White_2132x439.png'})),
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$alignBottom,
												$mdgriffith$elm_ui$Element$spaceEvenly,
												$mdgriffith$elm_ui$Element$Font$size(24)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$newTabLink,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$author$project$Ui$Style$highlightRed
													]),
												{
													gw: $author$project$Ui$Element$faIcon($author$project$FontAwesome$Brands$facebookF),
													cQ: 'https://www.facebook.com/miaEngiadina/'
												}),
												A2(
												$mdgriffith$elm_ui$Element$newTabLink,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$author$project$Ui$Style$highlightRed
													]),
												{
													gw: $author$project$Ui$Element$faIcon($author$project$FontAwesome$Brands$linkedinIn),
													cQ: 'https://www.linkedin.com/company/mia-engiadina'
												}),
												A2(
												$mdgriffith$elm_ui$Element$newTabLink,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$author$project$Ui$Style$highlightRed
													]),
												{
													gw: $author$project$Ui$Element$faIcon($author$project$FontAwesome$Brands$instagram),
													cQ: 'https://www.instagram.com/miaEngiadina/'
												}),
												A2(
												$mdgriffith$elm_ui$Element$newTabLink,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$author$project$Ui$Style$highlightRed
													]),
												{
													gw: $author$project$Ui$Element$faIcon($author$project$FontAwesome$Brands$twitter),
													cQ: 'https://twitter.com/miaEngiadina'
												})
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$alignBottom,
										$author$project$Ui$Style$highlightRed
									]),
								{
									gw: $mdgriffith$elm_ui$Element$text('Newsletter abonnieren'),
									dK: $elm$core$Maybe$Just(
										$author$project$Types$NavigateTo($author$project$Types$NewsletterPage))
								}),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$alignBottom,
										$author$project$Ui$Font$blissProLight,
										$mdgriffith$elm_ui$Element$Font$size(16)
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('2019 © All rights reserved.')
									]))
							]))
					]))
			]));
};
var $author$project$Types$CoworkingMenuOpen = {$: 11};
var $author$project$FontAwesome$Solid$bars = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'bars',
	448,
	512,
	_List_fromArray(
		['M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z']));
var $mdgriffith$elm_ui$Internal$Model$Below = 1;
var $mdgriffith$elm_ui$Element$below = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 1, element);
};
var $author$project$View$menuCoworking = function (model) {
	var _v0 = model.fA;
	if (!_v0) {
		return A2($mdgriffith$elm_ui$Element$el, _List_Nil, $mdgriffith$elm_ui$Element$none);
	} else {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(250)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(160)),
					$mdgriffith$elm_ui$Element$padding(25),
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$shadow,
					$author$project$Ui$Style$border
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$Ui$Font$blissProRegular,
							$mdgriffith$elm_ui$Element$Font$size(22),
							$author$project$Ui$Style$highlightRed
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('Coworking'),
						dK: $elm$core$Maybe$Just(
							$author$project$Types$NavigateTo(
								$author$project$Types$CoworkingPage($elm$core$Maybe$Nothing)))
					}),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$author$project$Ui$Font$blissProRegular,
							$mdgriffith$elm_ui$Element$Font$size(22),
							$author$project$Ui$Style$highlightRed
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('Mountain Hubs'),
						dK: $elm$core$Maybe$Just(
							$author$project$Types$NavigateTo(
								$author$project$Types$CoworkingPage(
									$elm$core$Maybe$Just(0))))
					}),
					A2(
					$mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							$author$project$Ui$Font$blissProRegular,
							$mdgriffith$elm_ui$Element$Font$size(22),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$author$project$Ui$Style$highlightRed
						]),
					{
						gw: $mdgriffith$elm_ui$Element$text('InnHub'),
						cQ: 'https://innhub.ch/'
					})
				]));
	}
};
var $author$project$Ui$Style$navBarShadow = $mdgriffith$elm_ui$Element$Border$shadow(
	{
		fb: 8,
		aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.1),
		aH: _Utils_Tuple2(0, 0),
		aI: 4
	});
var $author$project$View$headerNavBar = function (model) {
	var shop = A2(
		$mdgriffith$elm_ui$Element$link,
		_List_Nil,
		{
			gw: $mdgriffith$elm_ui$Element$text('Shop'),
			cQ: 'https://shop.miaengiadina.ch/'
		});
	var news = A2(
		$mdgriffith$elm_ui$Element$link,
		_List_Nil,
		{
			gw: $mdgriffith$elm_ui$Element$text('News'),
			cQ: 'https://news.miaengiadina.ch/'
		});
	var menu = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_Nil,
		{
			gw: A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(5)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Menü'),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						$author$project$Ui$Element$faIcon($author$project$FontAwesome$Solid$bars))
					])),
			dK: $elm$core$Maybe$Just($author$project$Types$OpenMenuMsg)
		});
	var logo = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_Nil,
		{
			gw: A2(
				$mdgriffith$elm_ui$Element$image,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(40)),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(200))
					]),
				{fK: 'miaEngiadina', eq: '/design/mE_Logo_2132x440.png'}),
			dK: $elm$core$Maybe$Just(
				$author$project$Types$NavigateTo($author$project$Types$LandingPage))
		});
	var internet = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_Nil,
		{
			gw: $mdgriffith$elm_ui$Element$text('Internet'),
			dK: $elm$core$Maybe$Just(
				$author$project$Types$NavigateTo(
					$author$project$Types$InternetPage($elm$core$Maybe$Nothing)))
		});
	var digital = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_Nil,
		{
			gw: $mdgriffith$elm_ui$Element$text('Digital'),
			dK: $elm$core$Maybe$Just(
				$author$project$Types$NavigateTo($author$project$Types$DigitalPage))
		});
	var coworking = A2(
		$mdgriffith$elm_ui$Element$el,
		model.fA ? _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$below(
				$author$project$View$menuCoworking(model)),
				$mdgriffith$elm_ui$Element$centerX
			]) : _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Events$onClick($author$project$Types$CoworkingMenuOpen),
				$mdgriffith$elm_ui$Element$below(
				$author$project$View$menuCoworking(model)),
				$mdgriffith$elm_ui$Element$centerX
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$pointer]),
			$mdgriffith$elm_ui$Element$text('Coworking')));
	var contact = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_Nil,
		{
			gw: $mdgriffith$elm_ui$Element$text('Kontakt'),
			dK: $elm$core$Maybe$Just(
				$author$project$Types$NavigateTo($author$project$Types$ContactPage))
		});
	var community = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_Nil,
		{
			gw: $mdgriffith$elm_ui$Element$text('Community'),
			dK: $elm$core$Maybe$Just(
				$author$project$Types$NavigateTo(
					$author$project$Types$CommunityPage($elm$core$Maybe$Nothing)))
		});
	var about = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_Nil,
		{
			gw: $mdgriffith$elm_ui$Element$text('About'),
			dK: $elm$core$Maybe$Just(
				$author$project$Types$NavigateTo(
					$author$project$Types$AboutPage($elm$core$Maybe$Nothing)))
		});
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$author$project$Ui$Style$navBarShadow
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(10)
					]),
				A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$author$project$Ui$Style$pageMaximumWidth(model.hq),
							$mdgriffith$elm_ui$Element$spaceEvenly,
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
							$mdgriffith$elm_ui$Element$Font$size(20),
							$author$project$Ui$Font$blissProRegular
						]),
					_List_fromArray(
						[
							logo,
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(30)
								]),
							_List_fromArray(
								[community, coworking, internet, about, news, shop, contact])),
							menu
						])))
			]));
};
var $author$project$Ui$Element$headerSubNavRow = function (scale) {
	var mobileStyle = _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$alignLeft,
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$padding(10),
			$mdgriffith$elm_ui$Element$spacing(20),
			$author$project$Ui$Font$blissProRegular,
			$mdgriffith$elm_ui$Element$Font$size(16),
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
			$mdgriffith$elm_ui$Element$scrollbarX
		]);
	var desktopStyle = _List_fromArray(
		[
			$mdgriffith$elm_ui$Element$alignLeft,
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$paddingEach(
			{F: 10, H: 74, L: 10, O: 10}),
			$mdgriffith$elm_ui$Element$spacing(40),
			$author$project$Ui$Font$blissProRegular,
			$mdgriffith$elm_ui$Element$Font$size(16),
			$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white)
		]);
	return $mdgriffith$elm_ui$Element$row(
		_Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{F: 10, H: 74, L: 10, O: 10}),
					$mdgriffith$elm_ui$Element$spacing(40),
					$author$project$Ui$Font$blissProRegular,
					$mdgriffith$elm_ui$Element$Font$size(16),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$white)
				]),
			function () {
				switch (scale) {
					case 0:
						return mobileStyle;
					case 1:
						return mobileStyle;
					case 2:
						return desktopStyle;
					default:
						return desktopStyle;
				}
			}()));
};
var $author$project$View$headerNavBarMobileLandingPage = function (model) {
	return A2(
		$author$project$Ui$Element$headerSubNavRow,
		model.hq,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Community'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CommunityPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Coworking'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CoworkingPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Internet'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$InternetPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('About'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$AboutPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$link,
				_List_Nil,
				{
					gw: $mdgriffith$elm_ui$Element$text('News'),
					cQ: 'https://news.miaengiadina.ch/'
				}),
				A2(
				$mdgriffith$elm_ui$Element$link,
				_List_Nil,
				{
					gw: $mdgriffith$elm_ui$Element$text('Shop'),
					cQ: 'https://shop.miaengiadina.ch/'
				})
			]));
};
var $author$project$View$subNavAbout = function (model) {
	return A2(
		$author$project$Ui$Element$headerSubNavRow,
		model.hq,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProBold]),
				{
					gw: $mdgriffith$elm_ui$Element$text('About'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$AboutPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Team'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$AboutPage(
								$elm$core$Maybe$Just(0))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Organisation'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$AboutPage(
								$elm$core$Maybe$Just(4))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Partner'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$AboutPage(
								$elm$core$Maybe$Just(3))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Medien'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$AboutPage(
								$elm$core$Maybe$Just(1))))
				})
			]));
};
var $author$project$View$subNavCommunity = function (model) {
	return A2(
		$author$project$Ui$Element$headerSubNavRow,
		model.hq,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProBold]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Community'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CommunityPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Mitgliedschaft'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CommunityPage(
								$elm$core$Maybe$Just($author$project$Types$MembershipPage))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Partnerschaft'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CommunityPage(
								$elm$core$Maybe$Just($author$project$Types$PartnershipPage))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Bildung'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CommunityPage(
								$elm$core$Maybe$Just($author$project$Types$EducationPage))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Events'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CommunityPage(
								$elm$core$Maybe$Just(
									$author$project$Types$EventsPage($elm$core$Maybe$Nothing)))))
				})
			]));
};
var $author$project$Types$MountainHubsMenuOpen = {$: 12};
var $author$project$FontAwesome$Duotone$homeLg = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fad',
	'home-lg',
	576,
	512,
	_List_fromArray(
		['M496 512H368a16 16 0 0 1-16-16V368a16 16 0 0 0-16-16h-96a16 16 0 0 0-16 16v128a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V311c1.78-1.21 3.85-1.89 5.47-3.35L288 115l218.74 192.9c1.54 1.38 3.56 2 5.26 3.2V496a16 16 0 0 1-16 16z', 'M527.92 283.88L298.6 81.61a16 16 0 0 0-21.17 0L48.11 283.89a16 16 0 0 1-22.59-1.21L4.1 258.89a16 16 0 0 1 1.21-22.59l256-226a39.85 39.85 0 0 1 53.45 0L416 99.67V48a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v136.43l58.69 51.83a16 16 0 0 1 1.22 22.59l-21.4 23.82a16 16 0 0 1-22.59 1.21z']));
var $author$project$FontAwesome$Duotone$listOl = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fad',
	'list-ol',
	512,
	512,
	_List_fromArray(
		['M496 224H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z', 'M61.77 401l17.5-20.15a19.92 19.92 0 0 0 5.07-14.19v-3.31C84.34 356 80.5 352 73 352H16a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h22.83a157.41 157.41 0 0 0-11 12.31l-5.61 7c-4 5.07-5.25 10.13-2.8 14.88l1.05 1.93c3 5.76 6.29 7.88 12.25 7.88h4.73c10.33 0 15.94 2.44 15.94 9.09 0 4.72-4.2 8.22-14.36 8.22a41.54 41.54 0 0 1-15.47-3.12c-6.49-3.88-11.74-3.5-15.6 3.12l-5.59 9.31c-3.72 6.13-3.19 11.72 2.63 15.94 7.71 4.69 20.38 9.44 37 9.44 34.16 0 48.5-22.75 48.5-44.12-.03-14.38-9.12-29.76-28.73-34.88zM16 160h64a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8H64V40a8 8 0 0 0-8-8H32a8 8 0 0 0-7.14 4.42l-8 16A8 8 0 0 0 24 64h8v64H16a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8zm-3.91 160H80a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8H41.32c3.29-10.29 48.34-18.68 48.34-56.44 0-29.06-25-39.56-44.47-39.56-21.36 0-33.8 10-40.46 18.75-4.37 5.59-3 10.84 2.8 15.37l8.58 6.88c5.61 4.56 11 2.47 16.12-2.44a13.44 13.44 0 0 1 9.46-3.84c3.33 0 9.28 1.56 9.28 8.75C51 248.19 0 257.31 0 304.59v4C0 316 5.08 320 12.09 320z']));
var $author$project$View$menuMountainHubs = function (model) {
	var _v0 = model.gR;
	if (!_v0) {
		return A2($mdgriffith$elm_ui$Element$el, _List_Nil, $mdgriffith$elm_ui$Element$none);
	} else {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(250)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(160)),
					$mdgriffith$elm_ui$Element$padding(25),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
					$author$project$Ui$Style$shadow,
					$author$project$Ui$Style$border
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$author$project$Ui$Font$blissProRegular,
							$mdgriffith$elm_ui$Element$Font$size(22),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray)
						]),
					$mdgriffith$elm_ui$Element$text('Mountain Hubs')),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$paddingEach(
							{F: 0, H: 8, L: 0, O: 0}),
							$mdgriffith$elm_ui$Element$spacing(8)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$spacing(5),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Style$highlightRed
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$px(22)),
											$mdgriffith$elm_ui$Element$Font$size(20)
										]),
									$author$project$Ui$Element$faIcon($author$project$FontAwesome$Duotone$homeLg)),
									A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignLeft,
											$mdgriffith$elm_ui$Element$centerY,
											$author$project$Ui$Font$blissProLight,
											$mdgriffith$elm_ui$Element$Font$size(20),
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
											$author$project$Ui$Style$highlightRed
										]),
									{
										gw: $mdgriffith$elm_ui$Element$text('Coworking Hubs'),
										dK: $elm$core$Maybe$Just(
											$author$project$Types$NavigateTo(
												$author$project$Types$CoworkingPage(
													$elm$core$Maybe$Just(0))))
									})
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$spacing(7),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
									$author$project$Ui$Style$highlightRed
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$px(20)),
											$mdgriffith$elm_ui$Element$Font$size(20)
										]),
									$author$project$Ui$Element$faIcon($author$project$FontAwesome$Duotone$listOl)),
									A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignLeft,
											$mdgriffith$elm_ui$Element$centerY,
											$author$project$Ui$Font$blissProLight,
											$mdgriffith$elm_ui$Element$Font$size(20),
											$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
											$author$project$Ui$Style$highlightRed
										]),
									{
										gw: $mdgriffith$elm_ui$Element$text('Coworking Angebote'),
										dK: $elm$core$Maybe$Just(
											$author$project$Types$NavigateTo(
												$author$project$Types$CoworkingPage(
													$elm$core$Maybe$Just(1))))
									})
								]))
						]))
				]));
	}
};
var $author$project$View$subNavCoworking = function (model) {
	var coworkingMenu = $author$project$Ui$Scale$isXsSm(model.hq) ? A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(20)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Coworking Hubs'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CoworkingPage(
								$elm$core$Maybe$Just(0))))
				})
			])) : A2(
		$mdgriffith$elm_ui$Element$el,
		model.gR ? _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$below(
				$author$project$View$menuMountainHubs(model)),
				$mdgriffith$elm_ui$Element$centerX
			]) : _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Events$onClick($author$project$Types$MountainHubsMenuOpen),
				$mdgriffith$elm_ui$Element$below(
				$author$project$View$menuMountainHubs(model)),
				$mdgriffith$elm_ui$Element$centerX
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$pointer]),
			$mdgriffith$elm_ui$Element$text('Mountain Hubs')));
	return A2(
		$author$project$Ui$Element$headerSubNavRow,
		model.hq,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProBold]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Coworking'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CoworkingPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProBold]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Mountain Hubs'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$CoworkingPage(
								$elm$core$Maybe$Just(0))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('InnHub'),
					cQ: 'https://innhub.ch/'
				})
			]));
};
var $author$project$View$subNavInternet = function (model) {
	return A2(
		$author$project$Ui$Element$headerSubNavRow,
		model.hq,
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProBold]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Angebote für Private'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$InternetPage($elm$core$Maybe$Nothing)))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Angebote für Firmen'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$InternetPage(
								$elm$core$Maybe$Just(1))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Information'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$InternetPage(
								$elm$core$Maybe$Just(3))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Referenzen'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$InternetPage(
								$elm$core$Maybe$Just(2))))
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[$author$project$Ui$Font$blissProRegular]),
				{
					gw: $mdgriffith$elm_ui$Element$text('Hilfe Internet & TV'),
					dK: $elm$core$Maybe$Just(
						$author$project$Types$NavigateTo(
							$author$project$Types$InternetPage(
								$elm$core$Maybe$Just(4))))
				})
			]));
};
var $author$project$View$headerNavBarMobile = function (model) {
	return A2(
		$author$project$Helpers$whenJust,
		$mdgriffith$elm_ui$Element$el(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white)
				])),
		function () {
			var _v0 = model.ee;
			switch (_v0.$) {
				case 6:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavCommunity(model));
				case 4:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavInternet(model));
				case 5:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavCoworking(model));
				case 7:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavAbout(model));
				case 0:
					return $elm$core$Maybe$Just(
						$author$project$View$headerNavBarMobileLandingPage(model));
				default:
					return $elm$core$Maybe$Nothing;
			}
		}());
};
var $author$project$View$headerSubNavBar = function (model) {
	return A2(
		$author$project$Helpers$whenJust,
		$mdgriffith$elm_ui$Element$el(
			_List_fromArray(
				[
					$author$project$Ui$Style$pageMaximumWidth(model.hq),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$red),
					$mdgriffith$elm_ui$Element$centerX
				])),
		function () {
			var _v0 = model.ee;
			switch (_v0.$) {
				case 6:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavCommunity(model));
				case 4:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavInternet(model));
				case 5:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavCoworking(model));
				case 7:
					return $elm$core$Maybe$Just(
						$author$project$View$subNavAbout(model));
				default:
					return $elm$core$Maybe$Nothing;
			}
		}());
};
var $author$project$View$viewHeader = function (model) {
	return $author$project$Ui$Scale$isXsSm(model.hq) ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$alignTop
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height(
						A2(
							$mdgriffith$elm_ui$Element$maximum,
							60,
							A2($mdgriffith$elm_ui$Element$minimum, 60, $mdgriffith$elm_ui$Element$fill))),
						$mdgriffith$elm_ui$Element$spacing(10),
						$mdgriffith$elm_ui$Element$padding(6),
						$mdgriffith$elm_ui$Element$scrollbarX,
						$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
						$author$project$Ui$Style$navBarShadow
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_Nil,
						{
							gw: A2(
								$mdgriffith$elm_ui$Element$image,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(40)),
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(40))
									]),
								{fK: 'miaEngiadina', eq: '/favicon.png'}),
							dK: $elm$core$Maybe$Just(
								$author$project$Types$NavigateTo($author$project$Types$LandingPage))
						}),
						$author$project$View$headerNavBarMobile(model)
					])))) : A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				$author$project$View$headerNavBar(model),
				$author$project$View$headerSubNavBar(model)
			]));
};
var $author$project$FontAwesome$Solid$calendarAlt = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'calendar-alt',
	448,
	512,
	_List_fromArray(
		['M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z']));
var $author$project$FontAwesome$Solid$globe = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'globe',
	496,
	512,
	_List_fromArray(
		['M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z']));
var $author$project$FontAwesome$Solid$home = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'home',
	576,
	512,
	_List_fromArray(
		['M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z']));
var $author$project$FontAwesome$Solid$infoCircle = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'info-circle',
	512,
	512,
	_List_fromArray(
		['M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z']));
var $author$project$FontAwesome$Solid$mountain = A5(
	$author$project$FontAwesome$Icon$Icon,
	'fas',
	'mountain',
	640,
	512,
	_List_fromArray(
		['M634.92 462.7l-288-448C341.03 5.54 330.89 0 320 0s-21.03 5.54-26.92 14.7l-288 448a32.001 32.001 0 0 0-1.17 32.64A32.004 32.004 0 0 0 32 512h576c11.71 0 22.48-6.39 28.09-16.67a31.983 31.983 0 0 0-1.17-32.63zM320 91.18L405.39 224H320l-64 64-38.06-38.06L320 91.18z']));
var $author$project$Ui$Style$topShadow = $mdgriffith$elm_ui$Element$Border$shadow(
	{
		fb: 8,
		aB: A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0.2),
		aH: _Utils_Tuple2(0, -2),
		aI: 1
	});
var $author$project$View$viewNavIcon = F3(
	function (r, s, i) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			{
				gw: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$spacing(5),
							$mdgriffith$elm_ui$Element$Font$size(15)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$author$project$Ui$Element$faIcon(i)),
							$mdgriffith$elm_ui$Element$text(s)
						])),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$NavigateTo(r))
			});
	});
var $author$project$View$viewMobileNavBar = function (scale) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(60)),
				$mdgriffith$elm_ui$Element$alignBottom,
				A2($author$project$Ui$Style$inlineStyle, 'bottom', '0'),
				A2($author$project$Ui$Style$inlineStyle, 'position', 'fixed')
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$alignBottom,
						$mdgriffith$elm_ui$Element$Background$color($author$project$Ui$Color$white),
						$mdgriffith$elm_ui$Element$spaceEvenly,
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
						$author$project$Ui$Style$topShadow
					]),
				_List_fromArray(
					[
						A3($author$project$View$viewNavIcon, $author$project$Types$LandingPage, 'Home', $author$project$FontAwesome$Solid$home),
						A3(
						$author$project$View$viewNavIcon,
						$author$project$Types$InternetPage($elm$core$Maybe$Nothing),
						'Internet',
						$author$project$FontAwesome$Solid$globe),
						A3(
						$author$project$View$viewNavIcon,
						$author$project$Types$CoworkingPage($elm$core$Maybe$Nothing),
						'Coworking',
						$author$project$FontAwesome$Solid$mountain),
						A3(
						$author$project$View$viewNavIcon,
						$author$project$Types$CommunityPage($elm$core$Maybe$Nothing),
						'Community',
						$author$project$FontAwesome$Solid$calendarAlt),
						A3($author$project$View$viewNavIcon, $author$project$Types$ContactPage, 'Kontakt', $author$project$FontAwesome$Solid$infoCircle)
					]))
			]));
};
var $author$project$View$viewBody = function (model) {
	var header = $author$project$View$viewHeader(model);
	var body = $author$project$Page$view(model);
	return $author$project$Ui$Scale$isXsSm(model.hq) ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$inFront(header),
				$mdgriffith$elm_ui$Element$inFront(
				$author$project$View$viewMobileNavBar(model.hq))
			]),
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$paddingEach(
					{F: 60, H: 0, L: 0, O: 60})
				]),
			body)) : A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$inFront(header)
			]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$paddingEach(
					{F: 0, H: 0, L: 0, O: 60})
				]),
			_List_fromArray(
				[
					body,
					$author$project$View$viewFooter(model.hq)
				])));
};
var $author$project$Types$ClickMenuLink = function (a) {
	return {$: 16, a: a};
};
var $author$project$Ui$Component$Menu$stylePrimaryButton = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
		$mdgriffith$elm_ui$Element$Font$size(18),
		$author$project$Ui$Font$typefaceForHeading,
		$author$project$Ui$Style$highlightRed
	]);
var $author$project$Ui$Component$Menu$viewPrimaryButton = F2(
	function (scale, config) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_Nil,
			{
				gw: A2($mdgriffith$elm_ui$Element$el, $author$project$Ui$Component$Menu$stylePrimaryButton, config.gw),
				dK: config.dK
			});
	});
var $author$project$Ui$Component$Menu$styleSecondaryButton = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Font$color($author$project$Ui$Color$gray),
		$mdgriffith$elm_ui$Element$Font$size(18),
		$author$project$Ui$Font$typefaceForBody,
		$author$project$Ui$Style$highlightRed
	]);
var $author$project$Ui$Component$Menu$viewSecondaryButton = F2(
	function (scale, config) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_Nil,
			{
				gw: A2($mdgriffith$elm_ui$Element$el, $author$project$Ui$Component$Menu$styleSecondaryButton, config.gw),
				dK: config.dK
			});
	});
var $author$project$Ui$Component$Menu$viewSecondaryLink = F2(
	function (scale, config) {
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_Nil,
			{
				gw: A2($mdgriffith$elm_ui$Element$el, $author$project$Ui$Component$Menu$styleSecondaryButton, config.gw),
				cQ: config.cQ
			});
	});
var $author$project$View$viewMenu = function (model) {
	return _List_fromArray(
		[
			A2(
			$author$project$Ui$Component$Menu$viewPrimaryButton,
			model.hq,
			{
				gw: $mdgriffith$elm_ui$Element$text('Home'),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$ClickMenuLink($author$project$Types$LandingPage))
			}),
			A2(
			$author$project$Ui$Component$Menu$viewPrimaryButton,
			model.hq,
			{
				gw: $mdgriffith$elm_ui$Element$text('Community'),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$ClickMenuLink(
						$author$project$Types$CommunityPage($elm$core$Maybe$Nothing)))
			}),
			A2(
			$author$project$Ui$Component$Menu$viewPrimaryButton,
			model.hq,
			{
				gw: $mdgriffith$elm_ui$Element$text('Coworking'),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$ClickMenuLink(
						$author$project$Types$CoworkingPage($elm$core$Maybe$Nothing)))
			}),
			A2(
			$author$project$Ui$Component$Menu$viewPrimaryButton,
			model.hq,
			{
				gw: $mdgriffith$elm_ui$Element$text('Internet'),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$ClickMenuLink(
						$author$project$Types$InternetPage($elm$core$Maybe$Nothing)))
			}),
			A2(
			$author$project$Ui$Component$Menu$viewPrimaryButton,
			model.hq,
			{
				gw: $mdgriffith$elm_ui$Element$text('About'),
				dK: $elm$core$Maybe$Just(
					$author$project$Types$ClickMenuLink(
						$author$project$Types$AboutPage($elm$core$Maybe$Nothing)))
			}),
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(5)
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Ui$Component$Menu$viewSecondaryLink,
					model.hq,
					{
						gw: $mdgriffith$elm_ui$Element$text('Shop'),
						cQ: 'https://shop.miaengiadina.ch/'
					}),
					A2(
					$author$project$Ui$Component$Menu$viewSecondaryLink,
					model.hq,
					{
						gw: $mdgriffith$elm_ui$Element$text('News'),
						cQ: 'https://news.miaengiadina.ch/'
					}),
					A2(
					$author$project$Ui$Component$Menu$viewSecondaryButton,
					model.hq,
					{
						gw: $mdgriffith$elm_ui$Element$text('Events'),
						dK: $elm$core$Maybe$Just(
							$author$project$Types$ClickMenuLink(
								$author$project$Types$CommunityPage(
									$elm$core$Maybe$Just(
										$author$project$Types$EventsPage($elm$core$Maybe$Nothing)))))
					}),
					A2(
					$author$project$Ui$Component$Menu$viewSecondaryLink,
					model.hq,
					{
						gw: $mdgriffith$elm_ui$Element$text('InnHub'),
						cQ: 'https://innhub.ch/'
					}),
					A2(
					$author$project$Ui$Component$Menu$viewSecondaryButton,
					model.hq,
					{
						gw: $mdgriffith$elm_ui$Element$text('Kontakt'),
						dK: $elm$core$Maybe$Just(
							$author$project$Types$ClickMenuLink($author$project$Types$ContactPage))
					})
				]))
		]);
};
var $author$project$View$view = function (model) {
	return {
		fc: _List_fromArray(
			[
				A3(
				$mdgriffith$elm_ui$Element$layoutWith,
				{
					ct: _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$focusStyle(
							{e1: $elm$core$Maybe$Nothing, fe: $elm$core$Maybe$Nothing, hx: $elm$core$Maybe$Nothing})
						])
				},
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$author$project$Ui$Font$blissProRegular,
						$mdgriffith$elm_ui$Element$inFront(
						(!_Utils_eq(model.cz, $elm$core$Maybe$Nothing)) ? $mdgriffith$elm_ui$Element$none : A2($mdgriffith$elm_ui$Element$Lazy$lazy, $author$project$View$cookieNotification, model.hq)),
						$mdgriffith$elm_ui$Element$inFront(
						$author$project$Ui$Component$Menu$view(
							{
								gL: $author$project$Ui$Color$white,
								gM: $author$project$View$viewMenu(model),
								gN: model.g6,
								g6: $author$project$Types$OpenMenuMsg
							}))
					]),
				$author$project$View$viewBody(model))
			]),
		ie: 'miaEngiadina'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{gj: $author$project$Main$init, g2: $author$project$Types$UrlChange, g3: $author$project$Types$LinkClicked, hV: $author$project$Main$subscriptions, im: $author$project$Update$update, iq: $author$project$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (screen) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (privacyAgreed) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (audience) {
							return $elm$json$Json$Decode$succeed(
								{cZ: audience, cz: privacyAgreed, eg: screen});
						},
						A2($elm$json$Json$Decode$field, 'audience', $elm$json$Json$Decode$string));
				},
				A2(
					$elm$json$Json$Decode$field,
					'privacyAgreed',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$bool)
							]))));
		},
		A2(
			$elm$json$Json$Decode$field,
			'screen',
			A2(
				$elm$json$Json$Decode$andThen,
				function (width) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (height) {
							return $elm$json$Json$Decode$succeed(
								{b9: height, a7: width});
						},
						A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$int));
				},
				A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$int)))))(0)}});}(this));